import { Calendar, Dashboard, Inbox, SettingSliders, Ticket, Trophy, Users, Wifi } from "@atoms/icons";

const navList = [
  {
    label: "Dashboard",
    route: "/dashboard",
    icon: Dashboard,
    active: true,
  },
  {
    label: "Events",
    route: "/events",
    icon: Calendar,
    active: false,
  },
  {
    label: "Orders",
    route: "/orders",
    icon: Inbox,
    active: false,
    rightLabel: "5",
  },
  {
    label: "Customers",
    route: "/customers",
    icon: Users,
    active: false,
  },
  {
    label: "Collectables",
    route: "/collectables",
    icon: Trophy,
    active: false,
  },
  {
    label: "Airdrops",
    route: "/airdrops",
    icon: Ticket,
    active: false,
  },
  {
    label: "Scanning",
    route: "/scanners",
    icon: Wifi,
    active: false,
  },
  {
    label: "Settings",
    route: "/settings",
    icon: SettingSliders,
    active: false,
  },
];

export default navList;
