import { useCallback, useContext, useMemo } from "react";
import * as nearApi from "near-api-js";
import { AuthContext, extractUserDetailsFromAccount, keyStore } from "@context/auth";
import AuthApi, {
  Login,
  RegisterSeller,
  Seller,
  SellerConfirmEmail,
  SellerForgotPassword,
  SellerResetPassword,
} from "@api/auth";
import { useNavigate } from "react-router-dom";
import { URLS } from "@api/lib";

function useAuth() {
  const {
    currentUser,
    currentEmailUser,
    token,
    formError,
    walletConnection,
    nearConfig: nearConfiguration,
    setToken,
    setCurrentUser,
    setCurrentEmailUser,
    setFormError,
    currency,
    currencySymbol,
  } = useContext(AuthContext);
  const { contractName } = nearConfiguration;
  const navigate = useNavigate();

  const signOut = useCallback(() => {
    if (!walletConnection) throw Error("Wallet connection has not been initialised");
    walletConnection.signOut();
    setCurrentUser();
    setCurrentEmailUser();
    setToken(undefined);
  }, [walletConnection, setToken, setCurrentUser, setCurrentEmailUser]);

  const handleUnauthorization = useMemo(
    () => () => {
      signOut();
      navigate("/");
    },
    [signOut, navigate]
  );

  const authApi = useMemo(() => new AuthApi(token), [token]);

  const signIn = useCallback(() => {
    if (!walletConnection) throw Error("Wallet connection has not been initialised");
    walletConnection.signOut(); // remove the currently signed in user from localstorage if present.
    setTimeout(() => {
      walletConnection.requestSignIn(
        { contractId: contractName, successUrl: URLS.successUrl }, //contract requesting access
        "Seatlab NFT Seller Dashboard" //optional name
      );
    });
  }, [walletConnection, contractName]);

  const handleSignInSuccess = useMemo(
    () => (account: nearApi.ConnectedWalletAccount) => {
      const { accountId } = account;
      if (!accountId) Promise.reject();

      return encodeSecretKeys(accountId, nearConfiguration.networkId)
        .then((data) => {
          if (!data) throw Error("required params for getting JWT not found");
          const { pub_key, signature, wallet_id, timestamp } = data;
          return authApi.getJWT({ pubKey: pub_key, signature, walletId: wallet_id, timestamp });
        })
        .then(async ({ result: { jwt: token } }) => {
          setToken(token);
          authApi.setToken(token);
          const user = await extractUserDetailsFromAccount(account);
          setCurrentUser(user);
        });
    },
    [setToken, setCurrentUser, authApi]
  );

  const emailSignIn = useMemo(
    () => (args: Login) => {
      setFormError("");
      localStorage.email = args.rememberMe ? args.email : "";
      localStorage.password = args.rememberMe ? args.password : "";
      localStorage.rememberMe = args.rememberMe;
      return authApi
        .getJWTByEmailPassword({ email: args.email, password: args.password })
        .then(async ({ result: { jwt: token, seller } }) => {
          if (!seller.email_confirmed) {
            navigate("/email_verify", {
              replace: true,
              state: { email: args.email },
            });
          } else {
            setToken(token);
            authApi.setToken(token);
            setCurrentEmailUser({
              email: seller.email,
              name: seller.name,
              walletAddress: seller.wallet_address,
            });
            const mappedSeller = AuthApi.mapFetchedSellerToJs(seller);
            if (mappedSeller && !isCompleteProfile(mappedSeller)) navigate("/settings");
            else navigate("/", { replace: true });
          }
        })
        .catch(async ({ status, message }) => {
          setFormError(message);
        });
    },
    [setToken, setCurrentEmailUser, authApi]
  );

  const registerUser = useMemo(
    () => (args: RegisterSeller) => {
      setFormError("");
      return authApi
        .registerUser({
          email: args.email,
          password: args.password,
          password_confirmation: args.password_confirmation,
        })
        .then(async ({ result }) => {
          if (result.success) {
            navigate("/email_verify", {
              replace: true,
              state: { email: args.email },
            });
          }
        })
        .catch(async ({ status, message }) => {
          setFormError(message);
        });
    },
    [authApi, navigate, setFormError]
  );

  const forgotPassword = useMemo(
    () => (args: SellerForgotPassword) => {
      setFormError("");
      return authApi
        .forgotPassword({
          email: args.email,
        })
        .then(async ({ result }) => {
          if (result.success) {
            navigate("/email_sent", { replace: true });
          }
        })
        .catch(async ({ status, message }) => {
          setFormError(message);
        });
    },
    [authApi, navigate, setFormError]
  );

  const resetPassword = useMemo(
    () => (args: SellerResetPassword) => {
      setFormError("");
      return authApi
        .resetPassword({
          token: args.token,
          email: args.email,
          password: args.password,
          password_confirmation: args.password_confirmation,
        })
        .then(async ({ result }) => {
          if (result.success) {
            navigate("/password_reset", { replace: true });
          }
        })
        .catch(async ({ status, message }) => {
          setFormError(message);
        });
    },
    [authApi, navigate, setFormError]
  );

  const confirmEmail = useMemo(
    () => (args: SellerConfirmEmail) => {
      return authApi
        .confirmEmail({
          id: args.id,
          hash: args.hash,
          email: args.email,
        })
        .then(async ({ result: { jwt: token, seller } }) => {
          setToken(token);
          authApi.setToken(token);
          setCurrentEmailUser({
            email: seller.email,
            name: seller.name,
            walletAddress: seller.wallet_address,
          });
          return !!token;
        })
        .catch(({ status, message }) => {
          return false;
        });
    },
    [authApi]
  );

  const resendConfirmEmail = useMemo(
    () => (args: { email: string }) => {
      return authApi
        .resendConfirmEmail({
          email: args.email,
        })
        .then(async ({ result }) => {
          if (result.success) {
            navigate("/email_verify", {
              replace: true,
              state: { email: args.email },
            });
          }
        })
        .catch(({ status, message }) => {
          return true;
        });
    },
    [authApi, navigate]
  );

  const getMyProfile = useCallback(() => authApi.getMyProfile(), [authApi]);
  const updateProfile = useCallback((args: Seller) => authApi.updateProfile(args), [authApi]);

  const isCompleteProfile = useMemo(
    () => (profile: Seller) => {
      if (!!profile) {
        const { name, supportEmail, bio, thumbnailUrl, stripeTransfersEnabled } = profile;
        if ([name, supportEmail, bio, thumbnailUrl, stripeTransfersEnabled].every((val) => !!val)) {
          return true;
        }
      }
      return false;
    },
    []
  );

  const areMandatoryFieldsComplete = useMemo(
    () => (profile: Seller) => {
      if (!!profile) {
        const { name, supportEmail, bio, thumbnailUrl } = profile;
        if ([name, supportEmail, bio, thumbnailUrl].every((val) => !!val)) {
          return true;
        }
      }
      return false;
    },
    []
  );

  return {
    ...authApi,
    user: currentUser,
    emailUser: currentEmailUser,
    token,
    formError,
    wallet: walletConnection,
    signIn,
    emailSignIn,
    registerUser,
    forgotPassword,
    resetPassword,
    confirmEmail,
    resendConfirmEmail,
    signOut,
    handleSignInSuccess,
    getMyProfile,
    updateProfile,
    getStripeConnectUrl: authApi.getStripeConnectUrl,
    getStripeDashboardUrl: authApi.getStripeDashboardUrl,
    checkUsername: authApi.checkUsername,
    handleUnauthorization,
    isCompleteProfile,
    areMandatoryFieldsComplete,
    setFormError,
    currency,
    currencySymbol,
  };
}

export default useAuth;

async function encodeSecretKeys(accountId: string, networkId: string) {
  const timestamp = (new Date().getTime() / 1000).toString();
  const keyPair = await keyStore.getKey(networkId, accountId);
  const msg = new Uint8Array(Buffer.from(timestamp));
  const { signature } = keyPair.sign(msg);
  const encodedSig = nearApi.utils.serialize.base_encode(signature);
  const encodedPubKey = keyPair.getPublicKey().toString().replace("ed25519:", "");

  return {
    signature: encodedSig,
    wallet_id: accountId,
    pub_key: encodedPubKey,
    timestamp,
  };
}
