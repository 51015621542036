import * as React from "react";

function Users(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.709 25.667h9.915c.753 0 1.345-.625 1.195-1.362-.414-2.037-1.766-5.638-6.152-5.638-4.387 0-5.739 3.601-6.153 5.638-.15.737.442 1.362 1.195 1.362zM21 18.667c2.772 0 3.907 2.863 4.365 4.928.248 1.114-.654 2.072-1.796 2.072h-1.236M16.333 10A3.667 3.667 0 119 10a3.667 3.667 0 017.333 0zM19.667 13.667C21.692 13.667 23 12.025 23 10s-1.308-3.667-3.333-3.667"
        stroke="currentcolor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Users;
