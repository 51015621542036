import { ChangeEventHandler, InputHTMLAttributes, RefObject, SyntheticEvent, useMemo, useRef } from "react";
import cn from "classnames";
import { Paperclip, Trash } from "@atoms/icons";
import Text from "@atoms/text";
import { BaseInputProps, TextInputProps } from "@atoms/text-input";

type Props = BaseInputProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "onChange" | "value"> & {
    placeholder?: string;
    value?: File;
    inputRef?: RefObject<HTMLInputElement>;
    error?: string;
    onChange: (e: null | SyntheticEvent) => void;
  };

function TextFieldFile({ placeholder, value, inputRef, error, name, onChange }: Props) {
  const localRef = useRef<HTMLInputElement>(null);
  const ref = useMemo(() => inputRef || localRef, [inputRef]);

  const containerClasses = cn("border border-grey/30 px-4 rounded flex justify-between items-center h-12", {
    "ring-2 ring-inset ring-danger bg-danger/20": error,
  });

  const onUpload = () => {
    if (ref?.current) ref.current?.click();
  };

  return (
    <div>
      <div className={containerClasses}>
        <input
          name={name}
          onChange={onChange}
          ref={ref}
          hidden
          type="file"
          placeholder={placeholder}
          className="w-full"
          multiple={false}
        />
        <Text variant="heading3" className="py-3 text-ellipsis truncate opacity-50 ring-danger">
          {value ? value.name : placeholder}
        </Text>
        <div className="flex flex-row items-center">
          {value && (
            <div onClick={() => onChange(null)} className="w-7 h-7 text-danger cursor-pointer mr-2">
              <Trash />
            </div>
          )}
          <div onClick={onUpload} className="w-5 h-5 text-grey cursor-pointer opacity-50">
            <Paperclip />
          </div>
        </div>
      </div>
      {!!error && (
        <div className="flex justify-between align-top">
          <span className="inline-block text-danger">{error}</span>
        </div>
      )}
    </div>
  );
}

export default TextFieldFile;
