import React from "react";
import { Transition } from "@headlessui/react";
import cx from "classnames";
import Text from "@atoms/text";
import classNames from "classnames";

type Props = {
  open: boolean;
  options: { label: string; action: () => void; active: boolean }[];
  align?: "left" | "right";
};

const MenuList = ({ options, open, align }: Props) => {
  return (
    <Transition
      show={open}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={classNames(
        "p-3 mt-2 absolute top-full flex flex-col z-30 shadow-lg min-w-64 overflow-hidden bg-dark-grey rounded-md min-w-[320px]",
        {
          "left-0": !align || align === "left",
          "right-0": align === "right",
        }
      )}
    >
      {options?.map((option, index) => {
        const highlightClass = " hover:bg-gradient-to-r hover:from-bluer hover:to-blue";
        const activeClass = " bg-gradient-to-r from-bluer to-blue";
        return (
          <Text
            key={index}
            as="span"
            variant="heading3"
            className={cx("text-white font-bold select-none py-1 px-3 cursor-pointer rounded-sm", highlightClass, {
              "mt-2": index !== 0,
              [activeClass]: option.active,
            })}
            onClick={option.action}
          >
            {option.label}
          </Text>
        );
      })}
    </Transition>
  );
};

export default MenuList;
