import Fieldset from "@atoms/fieldset";
import TextField from "@atoms/text-field";
import Divider from "@atoms/divider";
import StoreLogoCover from "@molecules/store-logo-cover";
import { Field, FieldProps, useFormikContext } from "formik";

export type Values = {
  description: string;
  thumbnail?: string;
  coverPhoto?: string;
  newCoverPhoto?: File;
  newThumbnail?: File;
};

export const initialValues: Values = {
  description: "",
  newCoverPhoto: undefined,
  newThumbnail: undefined,
};

type PromotionsContext = Values & { eventName: string };

export default function EventPromotions() {
  const { values, setFieldValue, setFieldTouched, initialValues, errors, touched } =
    useFormikContext<PromotionsContext>();
  const getError = (keyName: keyof Values) => (!!touched[keyName] ? errors[keyName] : undefined);
  const setPhoto = (keyName: keyof Values) => {
    return ({ file }: { file: File }) => {
      setFieldValue(keyName, file);
      setFieldTouched(keyName, true);
    };
  };
  return (
    <Fieldset heading={values.eventName} paddingSize="none">
      <div className="p-4">
        <Field name="description">
          {({ field, meta }: FieldProps) => (
            <TextField
              {...field}
              type="textarea"
              label="Event description"
              isFullWidth
              error={meta.touched && meta.error}
            />
          )}
        </Field>
      </div>
      <Divider />
      <div className="">
        <StoreLogoCover
          initialCoverPhoto={initialValues.coverPhoto ?? ""}
          initialThumbnail={initialValues.thumbnail ?? ""}
          onCoverPhotoChange={setPhoto("newCoverPhoto")}
          onThumbnailChange={setPhoto("newThumbnail")}
          coverPhotoError={getError("newCoverPhoto")}
          thumbnailError={getError("newThumbnail")}
        />
      </div>
    </Fieldset>
  );
}
