import {
  URLS,
  createCustomFetchApi,
  FetchAPIOptions,
  recursivelyCamelCase,
  SearchFilters,
  SearchListingResponse,
} from "./lib";
import { parse } from "date-fns";

import { formatUrlParams } from "@utils/format";
import { priceFormatter } from "@utils/number";

import TicketsApi, { GetTicketOrdersResponse, Ticket, TicketType } from "./tickets";
import CollectiblesApi, { GetCollectibleOrdersResponse, Collectible } from "./collectibles";

export type FetchedCustomer = {
  id: string;
  username: string;
  phone_number: string;
  total_spent: number;
  total_orders: number;
  status: "live" | "pending" | "lottery";
  created_at: string;
  updated_at: string;
  last_purchase_at: string;
  last_email_used: string;
};

export type Customer = {
  id: string;
  username: string;
  phoneNumber: string;
  totalSpent: number | null;
  totalOrders: number;
  status: "live" | "pending" | "lottery";
  createdAt: Date;
  updatedAt: Date;
  lastPurchaseAt: Date;
  lastEmailUsed: string;
};

export type FetchedLotteryEntry = {
  id: number;
  buyer_id: number;
  ticket_type_id: number;
  created_at: string;
  updated_at: string;
  transaction_hash?: string | null;
};

export type LotteryEntry = {
  id: number;
  buyerId: number;
  ticketTypeId: number;
  createdAt: Date;
  updatedAt: Date;
  ticketType: TicketType;
  transactionHash?: string | null;
};

type FetchAllCustomersResponse = SearchListingResponse<Customer>;
type FetchCustomerLotteryEntriesResponse = SearchListingResponse<LotteryEntry>;

type CustomersApiOptions = FetchAPIOptions & {};

export default class CustomersApi {
  token: string;
  fetchRequest: (input: RequestInfo, init?: RequestInit) => Promise<any>;

  constructor(token: string, options?: Partial<CustomersApiOptions>) {
    if (!token) throw new Error("Error API cannot be initialised without a token");
    this.token = token;
    this.fetchRequest = createCustomFetchApi(token);
  }

  getAllCustomers = ({
    page = 1,
    perPage = 1000,
    searchValue = undefined,
  }: SearchFilters): Promise<FetchAllCustomersResponse> => {
    const urlParams = {
      page,
      per_page: perPage,
      ...(searchValue ? { search: searchValue } : {}),
    };
    const formattedUrlParams = formatUrlParams(urlParams);
    return this.fetchRequest(`${URLS.api}/seller/customers?${formattedUrlParams}`).then((response) => {
      const formattedCustomers = response.result.map(CustomersApi.mapFetchedCustomerToJs);
      return { ...response, result: formattedCustomers };
    });
  };

  getCustomer = (id: number) => {
    return this.fetchRequest(`${URLS.api}/seller/customers/${id}`).then((response) => {
      const savedCustomer = response.result;
      return CustomersApi.mapFetchedCustomerToJs(savedCustomer);
    });
  };

  getCustomerTickets = (id: number, { page = 1, perPage = 1000 }: SearchFilters): Promise<GetTicketOrdersResponse> => {
    const urlParams = {
      page,
      per_page: perPage,
    };
    return this.fetchRequest(`${URLS.api}/seller/customers/${id}/tickets?${formatUrlParams(urlParams)}`).then((res) => {
      const fetchedTickets = res.result;
      const mappedTickets = fetchedTickets.map(TicketsApi.mapFetchedTicketToJs) as Ticket[];
      const modified = { ...res, result: mappedTickets };
      return recursivelyCamelCase(modified) as GetTicketOrdersResponse;
    });
  };

  getCustomerCollectibleTokens = (
    id: number,
    { page = 1, perPage = 1000 }: SearchFilters
  ): Promise<GetCollectibleOrdersResponse> => {
    const urlParams = {
      page,
      per_page: perPage,
    };
    return this.fetchRequest(`${URLS.api}/seller/customers/${id}/collectibletokens?${formatUrlParams(urlParams)}`).then(
      (res) => {
        const fetchedCollectibleOrders = res.result;
        const mappedResults = fetchedCollectibleOrders.map(
          CollectiblesApi.mapFetchedCollectibleOrdersToJs
        ) as Collectible[];
        const modified = { ...res, result: mappedResults };
        return recursivelyCamelCase(modified) as GetCollectibleOrdersResponse;
      }
    );
  };

  getCustomerLotteryEntries = (
    id: number,
    { page = 1, perPage = 1000 }: SearchFilters
  ): Promise<FetchCustomerLotteryEntriesResponse> => {
    const urlParams = {
      page,
      per_page: perPage,
    };
    return this.fetchRequest(`${URLS.api}/seller/customers/${id}/lotteryentries?${formatUrlParams(urlParams)}`).then(
      (res) => {
        const fetchedLotteryEntries = res.result;
        const mappedLotteryEntries = fetchedLotteryEntries.map(
          CustomersApi.mapFetchedLotteryEntriesToJs
        ) as LotteryEntry[];
        const modified = { ...res, result: mappedLotteryEntries };
        return recursivelyCamelCase(modified) as FetchCustomerLotteryEntriesResponse;
      }
    );
  };

  static mapFetchedCustomerToJs(FetchedCustomer: FetchedCustomer) {
    const { total_spent, created_at, updated_at, last_purchase_at, ...rest } = FetchedCustomer;
    function getDisplayDate(dateString: string) {
      return parse(dateString, "yyyy-MM-dd HH:mm:ss", new Date());
    }
    const modified = {
      ...rest,
      ...(total_spent ? { total_spent: priceFormatter(total_spent) } : undefined),
      ...(created_at ? { created_at: getDisplayDate(created_at) } : undefined),
      ...(updated_at ? { updated_at: getDisplayDate(updated_at) } : undefined),
      ...(last_purchase_at ? { last_purchase_at: getDisplayDate(last_purchase_at) } : undefined),
    };
    return recursivelyCamelCase(modified) as Customer;
  }

  static mapFetchedLotteryEntriesToJs(FetchedCustomer: FetchedLotteryEntry) {
    const { created_at, updated_at, ...rest } = FetchedCustomer;
    function getDisplayDate(dateString: string) {
      return parse(dateString, "yyyy-MM-dd HH:mm:ss", new Date());
    }
    const modified = {
      ...rest,
      ...(created_at ? { created_at: getDisplayDate(created_at) } : undefined),
      ...(updated_at ? { created_at: getDisplayDate(updated_at) } : undefined),
    };
    return recursivelyCamelCase(modified) as LotteryEntry;
  }
}
