import Box from "@atoms/box";
import { displayDate } from "@utils/date";
import cn from "classnames";
import { Props } from "./published-event-card.types";

export const gridColClasses = "grid gap-4 grid-cols-[96px_1fr_1fr_1fr] items-center";

function PublishedEventTableRow({ eventId, thumbnail, name, venue, date, onClickRow, selected }: Props) {
  const onClick = () => onClickRow(eventId);
  const className = selected
    ? " cursor-pointer overflow-hidden outline outline-2 outline-bluer"
    : " cursor-pointer overflow-hidden";
  return (
    <Box variant="dark-grey" padding="none" className={className}>
      <div className={cn("text-white", gridColClasses)} onClick={onClick}>
        <div className="rounded-lg overflow-hidden m-1 aspect-square h-20 bg-layer-blue">
          {thumbnail ? <img src={thumbnail} alt="event thumbnail" className="h-full w-full object-cover" /> : null}
        </div>
        <div children={<span>{name}</span>} />
        <div children={<span>{venue}</span>} />
        <div children={<span>{displayDate(date)}</span>} />
      </div>
    </Box>
  );
}

export default PublishedEventTableRow;
