import Box from "@atoms/box";
import { View } from "@atoms/icons";
import Pill from "@atoms/pill";
import Svg from "@atoms/svg";
import cn from "classnames";
import { useMemo } from "react";
import { Props } from "./customer-card.types";
import StatusPill from "./status-pill";
import { displayCurrency } from "@utils/number";
import useAuth from "@hooks/use-auth";

export const gridColClasses = "pl-10 grid gap-5 grid-cols-[1.5fr_1fr_1fr_1fr_1fr_100px] items-center";

function CustomerTableRow({ username, phoneNumber, totalSpent, totalOrders, status, onView }: Props) {
  const actions = useMemo(
    () => [{ Icon: View, label: "View", onClick: onView ? () => onView() : undefined, disabled: !onView }],
    [onView]
  );
  const { currency } = useAuth();

  return (
    <Box variant="dark-grey" padding="none" className="overflow-hidden h-20 flex flex-col justify-center w-full">
      <div className={cn("text-white", gridColClasses)}>
        <div children={<span>{username || "-"}</span>} />
        <div children={<span>{phoneNumber}</span>} />
        <div
          children={
            <span>
              {totalSpent ? displayCurrency(totalSpent, "en-GB", currency) : "-"}
            </span>
          }
        />
        <div children={<span>{totalOrders ? totalOrders.toString() : "0"}</span>} />
        <div children={<StatusPill {...{ status }} />} />
        <div className="flex text-sm justify-between pr-4">
          {actions.map(({ disabled, onClick, label, Icon }) => {
            const classes = cn("text-center flex flex-col items-center", {
              "opacity-25": disabled,
              "cursor-pointer": !disabled,
            });
            return (
              <div key={label} className={classes} onClick={onClick}>
                <Svg icon={Icon} size="medium" />
                <div>{label}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Box>
  );
}

export default CustomerTableRow;
