import cn from "classnames";
import { TablePaginationFooterProps as PaginationFooterProps } from "@molecules/table-pagination-footer";
import useMediaQueries from "@hooks/use-media-queries";
import AirdropCollectibleCard, { gridColClasses } from "@molecules/airdrop-collectible-card";
import { Collectible } from "@api/collectibles";
import { isNumber } from "lodash";
import { priceFormatter } from "@utils/number";

export enum ColumnKey {
  collectibleImage = "collectibleImage",
  collectibleTitle = "collectibleTitle",
  collectibleAvailable = "collectibleAvailable",
  collectiblePrice = "collectiblePrice",
}

export type Props = PaginationFooterProps & {
  onSort: (columnKey: ColumnKey) => void;
  collectibles: Collectible[];
  noCollectibles?: boolean;
  onClickRow: (collectible: Collectible | undefined) => void;
  selectedCollectible: Collectible | undefined;
  eligibleWallets: number;
};

const columns = [
  { key: ColumnKey.collectibleImage },
  { key: ColumnKey.collectibleTitle, label: "Title" },
  { key: ColumnKey.collectibleAvailable, label: "Available Editions" },
  { key: ColumnKey.collectiblePrice, label: "Price" },
];

function CollectiblesListSmall(props: Props) {
  const { isDesktopAndUp } = useMediaQueries();
  const { collectibles, noCollectibles, onSort, onClickRow, selectedCollectible, eligibleWallets } = props;
  return (
    <div className="mb-8">
      {isDesktopAndUp && !noCollectibles && (
        <div className={cn(gridColClasses, "text-sm text-grey mb-5")}>
          {columns.map((columnHeader) => {
            const onClick = columnHeader.label ? () => onSort(columnHeader.key) : undefined;
            return (
              <div key={columnHeader.key} className="flex items-center" onClick={onClick}>
                {columnHeader.label ? <span className="mr-2">{columnHeader.label}</span> : null}
              </div>
            );
          })}
        </div>
      )}

      {collectibles.map((item) => {
        const selected = selectedCollectible?.uuid === item.uuid;

        return (
          <div className="mt-3" key={item.uuid}>
            <AirdropCollectibleCard
              collectible={item}
              image={item.imageUrl}
              title={item.title}
              available={item.quantityAvailable}
              remaining={item.tokensRemaining}
              price={item.price}
              onClickRow={onClickRow}
              selected={selected}
              eligibleWallets={eligibleWallets}
            />
          </div>
        );
      })}
      {noCollectibles && (
        <div className="w-full h-full flex flex-col items-center pt-48">
          <p className="text-4xl font-bold mb-4">No Collectibles Listed</p>
        </div>
      )}
    </div>
  );
}

export default CollectiblesListSmall;
