import Box from "@atoms/box";
import { displayCurrency } from "@utils/number";
import React from "react";
import { Props } from "./airdrop-collectible-card.types";
import { isNumber } from "lodash";
import useAuth from "@hooks/use-auth";

function CollectibleField({ label, field, className }: { label: string; field: string; className?: string }) {
  return (
    <div className={className}>
      <div className="text-grey text-sm">{label}</div>
      <span className="text-lg">{field}</span>
    </div>
  );
}

function MobileViewAirdropCollectibleCard({
  collectible,
  image,
  title,
  available,
  remaining,
  price,
  onClickRow,
  selected,
  eligibleWallets,
}: Props) {
  const { currency } = useAuth();
  const baseClassName = selected ? "overflow-hidden outline outline-2 outline-bluer" : "overflow-hidden";
  const className =
    remaining >= eligibleWallets ? `cursor-pointer ${baseClassName}` : `opacity-30 cursor-auto ${baseClassName}`;
  const onClick = remaining >= eligibleWallets ? () => onClickRow(collectible) : () => {};
  const availableEditions = remaining + "/" + available;
  return (
    <Box variant="dark-grey" padding="small" className={className}>
      <div className="flex items-center" onClick={onClick}>
        <div className="rounded-lg overflow-hidden aspect-square inline-block h-20 bg-layer-blue">
          {image ? <img src={image} alt="collectible thumbnail" className="h-full w-full object-cover" /> : null}
        </div>
        <CollectibleField label="Title" field={title} className="ml-2" />
        <CollectibleField label="Available Editions" field={availableEditions} className="w-48" />
        <CollectibleField
          label="Price"
          field={displayCurrency(isNumber(price) ? price : 0, "en-GB", currency)}
        />
      </div>
    </Box>
  );
}

export default MobileViewAirdropCollectibleCard;
