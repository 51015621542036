import Box from "@atoms/box";
import { View } from "@atoms/icons";
import cn from "classnames";
import Svg from "@atoms/svg";
import { Props } from "./customer-card.types";
import StatusPill from "./status-pill";
import { displayCurrency } from "@utils/number";
import useAuth from "@hooks/use-auth";

function CustomerField({ label, field, className }: { label: string; field: string; className?: string }) {
  return (
    <div className={className}>
      <div className="text-grey text-sm">{label}</div>
      <span className="text-lg">{field}</span>
    </div>
  );
}

function MobileViewCustomerCard({ username, phoneNumber, totalSpent, totalOrders, status, onView }: Props) {
  const { currency } = useAuth();
  const actionIconClass = (cb?: () => void) =>
    cn("text-center flex items-center flex-col", { "opacity-25": !cb, "cursor-pointer": !!cb });
  const rowSpacing = "mt-4 md:mt-0";
  return (
    <Box variant="dark-grey" padding="small" className="overflow-hidden">
      <div className="m-4 flex flex-col gap-8">
        <div className="md:grid md:grid-cols-1 items-center">
          <div className={cn(rowSpacing, "inline-grid grid-cols-3 gap-8 md:gap-0")}>
            <CustomerField label="Username" field={username ? username : "-"} />
            <CustomerField label="Phone Number" field={phoneNumber} />
            <CustomerField
              label="Total Spent"
              field={totalSpent ? displayCurrency(totalSpent, "en-GB", currency) : "-"}
            />
          </div>
        </div>

        <div className="md:grid md:grid-cols-1 items-center">
          <div className={cn(rowSpacing, "inline-grid grid-cols-3 gap-8 md:gap-0")}>
            <CustomerField label="Orders Placed" field={totalOrders.toString()} />
            <div>
              <StatusPill {...{ status }} />
            </div>
            <div>
              <button className={actionIconClass(onView)} onClick={onView}>
                <Svg icon={View} size="medium" />
                <div>View</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default MobileViewCustomerCard;
