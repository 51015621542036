import React from "react";
import cx from "classnames";
import Text from "@atoms/text";

export enum PillColor {
  danger = "danger",
  success = "success",
  warning = "warning",
  info = "info",
  slate = "slate",
}

type Props = {
  label: string;
  className?: string;
  color: keyof typeof PillColor | PillColor;
};

function Pill({ label, color, className }: Props) {
  let bgColor = "bg-dark-grey";
  let textColor = "text-white";

  switch (color) {
    case "danger":
      bgColor = "bg-danger";
      break;
    case "success":
      bgColor = "bg-success";
      break;
    case "info":
      bgColor = "bg-blue";
      break;
    case "warning":
      bgColor = "bg-warning";
      textColor = "text-black";
      break;
  }

  return (
    <span className={cx(bgColor, "py-1.5 px-2.5 inline-flex rounded-full min-w-[50px] justify-center", className)}>
      <Text variant="heading3" className={textColor}>
        {label}
      </Text>
    </span>
  );
}

export default Pill;
