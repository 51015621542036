import { useMemo, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import CreateCollectibleBreadcrumbNavigation, {
  CreateCollectibleStageEnum,
} from "@organisms/create-collectible-breadcrumb-navigation";
import { initialValues as customiseInitialValues } from "@organisms/collectibles-create-form";
import CollectiblesCreateView from "@pages/collectible-customise";
import useCollectiblesApi from "@hooks/use-collectibles-api";
import { checkImageDimensions, convertFileToBase64 } from "@utils/file";
import { CreateCollectible } from "@api/collectibles";
import { useMutation, useQuery } from "react-query";
import Modal from "@atoms/modal";
import { mapCollectibleFormFieldsToApiParams } from "./form-helpers";
import DocumentTitle from "@atoms/document-title";
import { minOrZero } from "@utils/min-or-zero";
import { LayoutTitle } from "@organisms/layout";

const CREATE_COLLECTIBLES_STAGE_URL = {
  [CreateCollectibleStageEnum.royalties]: "royalties",
  [CreateCollectibleStageEnum.customise]: "customise",
};

const defaultValues = {
  ...customiseInitialValues,
};

export type CreateCollectibleValues = typeof defaultValues;

const imgDimensionErrorMessage = (width: number, height: number) => {
  return `Image must have max dimensions of ${width}px x ${height}px`;
};

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const FILE_TYPE_ERROR_MESSAGE =
  "Unsupported file type - type must be one of:" + SUPPORTED_FORMATS.map((format) => " " + format.slice(6));

export const customiseSchema = yup.object().shape(
  {
    title: yup.string().min(3).required("Required"),
    quantityAvailable: yup.number().min(1, "Minimum 1").max(100000, "Maximum 100000").required("Required"),
    price: yup
      .number()
      .test("minOrZero", "Minimum 1 or free", (val) => minOrZero(val, 1))
      .max(1000000, "Maximum 1,000,000")
      .required("Required"),
    description: yup.string().min(1, "Minimum 1 character").max(2000, "Maximum 2000 characters").required("Required"),
    imageUrl: yup.string().when("media", {
      is: (media: any) => !media,
      then: yup.string().required("Required"),
    }),
    media: yup
      .mixed()
      .test("fileType", FILE_TYPE_ERROR_MESSAGE, (file: File, testContext: any) => {
        if (!file) return true;
        return SUPPORTED_FORMATS.includes(file.type);
      })
      .test("ticketTypeNewImageDimensions", imgDimensionErrorMessage(2500, 1500), (file: File, testContext: any) => {
        if (!file) return true;
        return checkImageDimensions(file, { width: 2500, height: 1500 }, false);
      })
      .test("mediaRequired", "Required", (file, testContext) => {
        if (!testContext.parent.imageUrl && !file) return false;
        return true;
      }),
  },
  [["imageUrl", "media"]]
);

function MutateCollectible() {
  const [isInitialValid, setIsInitialValid] = useState<boolean | null>(null);
  const [submissionError, setSubmissionError] = useState("");
  const navigate = useNavigate();
  const { collectibleId } = useParams();
  const { pathname } = useLocation();
  const [initialValues, setInitialValues] = useState<typeof defaultValues>(defaultValues);
  const collectiblesApi = useCollectiblesApi();
  const activeStage = Object.values(CreateCollectibleStageEnum).find((stage) => pathname.indexOf(`/${stage}`) >= 0);
  useQuery(
    ["collectible", collectibleId],
    () => {
      if (!collectibleId || collectibleId === "create") return Promise.resolve(null);
      return collectiblesApi.fetchCollectible(collectibleId);
    },
    {
      onSuccess: (data) => {
        if (data) setInitialValues((current) => ({ ...current, ...data }));
      },
    }
  );

  const publish = useMutation(function publish(uuid: string | undefined) {
    const id = uuid ?? collectibleId;
    if (!id || id === "create") throw new Error("collectible UUID not found");
    return collectiblesApi.publishCollectible(id).then(() => navigate("/collectables"));
  });

  const collectibleSave = useMutation(function upsertCollectible(values: Partial<CreateCollectible>) {
    const params = mapCollectibleFormFieldsToApiParams(values);
    return !!collectibleId && collectibleId !== "create"
      ? collectiblesApi.updateCollectible(collectibleId, params)
      : collectiblesApi.createCollectible(params);
  });

  const activeSchema = useMemo(() => {
    switch (activeStage) {
      case CreateCollectibleStageEnum.customise:
        return customiseSchema;
      default:
        return customiseSchema;
    }
  }, [activeStage]);

  function getRequiredValueKeys() {
    if (activeStage === CreateCollectibleStageEnum.customise) return Object.keys(customiseInitialValues);
    if (activeStage === CreateCollectibleStageEnum.royalties) return Object.keys(customiseInitialValues);
    return [];
  }

  async function getValuesToSave(values: CreateCollectibleValues): Promise<Partial<CreateCollectible>> {
    const keysToSave = getRequiredValueKeys();

    const { media, ...selectedValues } = Object.entries(values).reduce((accum, [k, v]) => {
      if (!keysToSave.includes(k)) return accum;
      return { ...accum, [k]: v };
    }, {} as Partial<CreateCollectibleValues>);

    const mediaBase64 = !!media ? await convertFileToBase64(media) : undefined;

    return {
      ...selectedValues,
      ...(mediaBase64 ? { image: mediaBase64 } : {}),
    };
  }

  function onStepBack() {
    if (activeStage === CREATE_COLLECTIBLES_STAGE_URL.customise) return;
    if (activeStage === CREATE_COLLECTIBLES_STAGE_URL.royalties) navigate(CREATE_COLLECTIBLES_STAGE_URL.customise);
  }

  async function onSubmit(values: CreateCollectibleValues) {
    try {
      const valuesToSave = await getValuesToSave(values);
      const result = await collectibleSave.mutateAsync(valuesToSave);
      setInitialValues((current) => ({ ...current, ...result }));
      if (collectibleId === "create") {
        navigate(`/collectables/${result?.uuid}/customise`);
        return result.uuid;
      }
    } catch (e: any) {
      setSubmissionError(e?.cause?.message || e.message);
    }
  }

  const onPublish = (values: CreateCollectibleValues) => {
    return onSubmit(values)
      .then((uuid) => {
        publish.mutate(uuid);
      })
      .catch((e) => {
        setSubmissionError(e?.cause.message || e.message);
      });
  };
  activeSchema?.isValid(initialValues).then((isValid) => {
    setIsInitialValid(isValid);
  });
  if (isInitialValid === null) return null;
  return (
    <>
      <DocumentTitle
        titlePrefix="Create a Collectable"
        description="Create unique NFT collectables to enhance the experience of attending your events. From digital artwork to claimable items, collectables are a powerful engagement tool."
      />
      <LayoutTitle title="Create a Collectable" />
      <Formik
        {...{ initialValues, onSubmit }}
        enableReinitialize
        validationSchema={activeSchema}
        validateOnMount
        isInitialValid={isInitialValid}
      >
        {({ handleSubmit, isValid, values }) => {
          const isLoading = publish.isLoading || collectibleSave.isLoading;
          return (
            <>
              <CreateCollectibleBreadcrumbNavigation
                {...{ activeStage }}
                onNext={handleSubmit}
                onBack={onStepBack}
                onPublish={() => (isValid ? onPublish(values) : undefined)}
                publishLoading={isLoading}
                nextLoading={isLoading}
                nextDisabled={false}
                publishEnabled={!!isValid}
              />
              <Routes>
                <Route index element={<Navigate to={CREATE_COLLECTIBLES_STAGE_URL.customise} replace={true} />} />
                <Route
                  path={CREATE_COLLECTIBLES_STAGE_URL.customise}
                  element={<CollectiblesCreateView initialThumbnail={values.imageUrl} />}
                />
              </Routes>
              <Modal
                variant="secondary"
                isVisible={!!submissionError}
                size="small"
                onClose={() => setSubmissionError("")}
                title="Could not complete the request"
              >
                <p>{submissionError}</p>
              </Modal>
            </>
          );
        }}
      </Formik>
      <Modal
        variant="secondary"
        size="small"
        title="Ooops"
        subtitle="Something went wrong"
        isVisible={publish.isError}
        onClose={publish.reset}
      >
        <p>{(publish?.error as Error)?.message}</p>
      </Modal>
    </>
  );
}

export default MutateCollectible;
