import { HeaderProps } from "./header.types";

import { LogoFull, LogoIcon, BurgerMenu } from "@atoms/icons";
import Text from "@atoms/text";
import SVG from "@atoms/svg";
import useMediaQueries from "@hooks/use-media-queries";
import UserMenu from "@organisms/user-menu/user-menu";
import cx from "classnames";
import styles from "./header.module.css";

const Header = ({ title, setShowSideNavigation }: HeaderProps): JSX.Element => {
  const { isDesktopAndUp, isMobile, isTabletOnly, isTabletAndUp } = useMediaQueries();
  const Logo = isDesktopAndUp ? LogoFull : LogoIcon;

  return (
    <div className="border-b border-dark-grey flex flex-shrink-0 justify-between items-center w-full h-24 md:justify-start px-4 md:px-10">
      <div className={cx("shrink-0", { [styles.desktopLogoWidth]: isDesktopAndUp })}>
        {isTabletOnly && (
          <button className="inline-block ml-2 mr-3 w-[32px]" onClick={setShowSideNavigation}>
            <SVG icon={BurgerMenu} size={"medium"} styles="text-white" />
          </button>
        )}
        <div className={cx("text-white inline-block", { "mr-4": !isDesktopAndUp })}>
          <Logo className="h-8" />
        </div>
      </div>
      <div className="flex flex-row items-center w-full justify-between">
        <Text variant={!isMobile ? "heading1" : "heading2"} as="p" className="text-white">
          {title}
        </Text>
        {isTabletAndUp && (
          <div className="flex items-center gap-4 relative">
            <UserMenu />
          </div>
        )}
      </div>
      {!isTabletAndUp && (
        <button onClick={setShowSideNavigation}>
          <SVG icon={BurgerMenu} size={"medium"} styles="text-white" />
        </button>
      )}
    </div>
  );
};

export default Header;
