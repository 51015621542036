import cn from "classnames";
import { useCallback, useMemo, useState } from "react";
import ColorInput from "@atoms/color-input";
import DecimalInput from "@atoms/decimal-input";
import MobilePrefixSelector from "@atoms/mobile-prefix-selector";
import TextArea from "@atoms/text-area";
import TextInput, { TextInputProps } from "@atoms/text-input";
import TimeInput from "@atoms/time-input";
import useMediaQueries from "@hooks/use-media-queries";
import NumberInput from "@atoms/number-input";

export type TextFieldBaseProps = {
  label?: string;
  helpText?: string;
  valid?: string | false;
  error?: string | false;
  labelInside?: boolean;
};

export type Props = TextInputProps & TextFieldBaseProps;

function isValueEmpty(value: Props["value"]) {
  if (typeof value === "undefined") return true;
  if (value === null) return true;
  if (value === "") return true;
  return false;
}

function TextField({ label, helpText, valid, error, onFocus, onBlur, ...inputProps }: Props) {
  const isTel = inputProps.type === "tel";
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const { isDesktopAndUp } = useMediaQueries();
  const statusType = error ? "error" : valid ? "success" : "info";
  const labelBasePositioning = cn("transition-transform origin-top-left absolute top-5 left-4", {
    "left-24": isTel,
  });
  const labelBaseClass = cn(labelBasePositioning, "text-grey z-10 items-center flex pointer-events-none ");
  const labelSmallClass = "scale-75 -translate-y-2";
  const shouldShrinkLabel =
    inputIsFocused ||
    !isValueEmpty(inputProps.value) ||
    (isValueEmpty(inputProps.value) && error) ||
    inputProps.type === "time" ||
    inputProps.type === "currency" ||
    inputProps.type === "tel";
  const validText = !!valid && <span className="inline-block text-success">{valid}</span>;
  const errorText = !!error && <span className="inline-block text-danger">{error}</span>;

  const handleFocus = useCallback(
    (type: "focus" | "blur") => (e: any) => {
      if (type === "focus" && onFocus) onFocus(e);
      else if (type === "blur" && onBlur) onBlur(e);
      setInputIsFocused(type === "focus");
    },
    [onFocus, onBlur]
  );

  const renderInput = () => {
    const commonProps = {
      statusType: statusType as typeof statusType,
      noPadding: !!label,
      className: cn({ "px-4 pt-7 pb-2": !!label }, inputProps.className, { "pl-24": isTel }),
      placeholder: shouldShrinkLabel ? inputProps.placeholder : label ? "" : inputProps.placeholder,
      onFocus: handleFocus("focus"),
      onBlur: handleFocus("blur"),
    };
    switch (inputProps.type) {
      case "color":
        return <ColorInput {...{ ...inputProps, ...commonProps }} />;
      case "decimal":
        return <DecimalInput {...{ ...inputProps, ...commonProps }} />;
      case "textarea":
        const { type, ...rest } = inputProps;
        return <TextArea {...{ ...rest, ...commonProps }} />;
      case "time":
        return <TimeInput {...{ ...inputProps, ...commonProps }} />;
      case "tel":
        return <MobilePrefixSelector {...{ ...inputProps, ...commonProps }} />;
      case "currency":
        return <DecimalInput {...{ ...inputProps, ...commonProps }} />;
      case "number":
        return <NumberInput {...{ ...inputProps, ...commonProps }} />;
      default:
        return <TextInput {...{ ...inputProps, ...commonProps }} />;
    }
  };

  return (
    <div className={cn("relative min-h-min inline-block", { "w-full": inputProps.isFullWidth })}>
      {!!helpText && isDesktopAndUp && <span className="block mt-1.5 mb-2.5 text-grey text-xs">{helpText}</span>}
      <div className={cn("relative h-full w-full")}>
        {label && (
          <label htmlFor={inputProps.id} className={cn(labelBaseClass, { [labelSmallClass]: shouldShrinkLabel })}>
            {label}
          </label>
        )}
        {(validText || errorText) && (
          <span className="z-10 items-center flex pointer-events-none transition-transform origin-top-right absolute top-5 right-4 scale-75 -translate-y-2">
            {!!errorText ? errorText : validText}
          </span>
        )}
        {renderInput()}
      </div>
    </div>
  );
}

export default TextField;
