import { CreateEventBasicFormValues } from "@organisms/event-basics-form";
import { FormTicketType } from "@organisms/event-tickets";
import { format } from "date-fns";
import { Values as PromotionValues } from "@organisms/event-promotions";
import { TicketValues } from "@organisms/event-tickets";
import { CreateTicketParams, UpdateLiveEventTicketParams } from "@api/tickets";
import { CreateEventParams, Occassion } from "@api/events";
import { convertFileToBase64 } from "@utils/file";

export type MutateEventFormValues = CreateEventBasicFormValues &
  PromotionValues &
  TicketValues & {
    readonly isLive: boolean;
    readonly slug: string;
  };

export async function mapFormFieldsToApiParams(
  values: Partial<MutateEventFormValues>
): Promise<Partial<CreateEventParams>> {
  const { startDate, endDate, entryDate, isLive, ...restEvent } = values;
  const { eventName, ticketTypes, newCoverPhoto, newThumbnail, ...rest } = restEvent;

  const formatToServerDate = (date: Date) => format(date, "dd-MM-yyyy HH:mm:ss");
  const coverPhoto = newCoverPhoto ? await convertFileToBase64(newCoverPhoto) : undefined;
  const thumbnail = newThumbnail ? await convertFileToBase64(newThumbnail) : undefined;
  const tickets = ticketTypes?.length ? await mapTicketTypesToApiParams(ticketTypes, isLive) : [];

  return {
    ...rest,
    ...(startDate ? { startDate: formatToServerDate(startDate) } : {}),
    ...(endDate ? { endDate: formatToServerDate(endDate) } : {}),
    ...(entryDate ? { entryDate: formatToServerDate(entryDate) } : {}),
    ...(eventName ? { name: eventName } : {}),
    ...(tickets ? { ticketTypes: tickets } : {}),
    ...(coverPhoto ? { coverPhoto } : {}),
    ...(thumbnail ? { thumbnail } : {}),
  };

  function mapTicketTypesToApiParams(
    ticketTypes: Array<FormTicketType>,
    isEventLive?: boolean
  ): Promise<(CreateTicketParams | UpdateLiveEventTicketParams)[]> {
    return Promise.all(
      ticketTypes.map(async ({ newImage, allowTransfers, quantityAvailable, price, ...ticket }) => {
        const image = await (async () => {
          if (!newImage) return undefined;
          if (typeof newImage === "string") return newImage;
          else return await convertFileToBase64(newImage);
        })();
        const priceFloat = parseFloat(price);
        //const maxResaleFloat = parseFloat(maxResalePrice || "0");
        const ticketPrice = priceFloat < 1 ? null : priceFloat * 100;
        //const resalePrice = maxResaleFloat < 1 ? null : maxResaleFloat * 100;

        if (isEventLive) {
          const params: UpdateLiveEventTicketParams = {
            ...(ticket.uuid ? { uuid: ticket.uuid } : {}),
            ...(ticket.name ? { name: ticket.name } : {}),
            ...(ticket.description ? { description: ticket.description } : {}),
            ...(image ? { image } : {}),
            allowTransfers,
          };
          return params;
        } else {
          return {
            ...ticket,
            price: ticketPrice,
            //maxResalePrice: resalePrice,
            quantityAvailable: parseInt(quantityAvailable, 10),
            ...(image ? { image } : {}),
            allowTransfers,
          };
        }
      })
    );
  }
}

export function mapOcassionToFormFields(occassion: Occassion) {
  const { coverPhotoUrl, thumbnailUrl, name, ticketTypes, ...rest } = occassion;
  return {
    ...rest,
    ...(coverPhotoUrl ? { coverPhoto: coverPhotoUrl } : {}),
    ...(thumbnailUrl ? { thumbnail: thumbnailUrl } : {}),
    ...(name ? { eventName: name } : {}),
    ticketTypes: (ticketTypes ?? []).map(({ price, quantityAvailable, ...ticketType }) => {
      const decimalPrice = (price / 100).toFixed(2).toString();
      //const decimalMaxResalePrice = maxResalePrice ? (maxResalePrice / 100).toFixed(2).toString() : decimalPrice;
      //const validatedRoyalties = royalties.map((r) => ({ ...r, verifiedAddress: true }));
      return {
        ...ticketType,
        price: decimalPrice,
        //maxResalePrice: decimalMaxResalePrice,
        quantityAvailable: quantityAvailable?.toString(),
        //royalties: validatedRoyalties,
      };
    }),
  };
}
