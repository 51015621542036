import * as React from "react";
import { SVGProps } from "react";

const Check = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 10 9">
    <path
      d="M.75 5.25 3 7.75l6.25-6.5"
      stroke="currentcolor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Check;
