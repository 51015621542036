import React from "react";
import cn from "classnames";
import { MoonLoader } from "react-spinners";
import { ButtonProps } from "./button.types";
import Text, { TextVariant } from "@atoms/text";
import SVG from "@atoms/svg";

const buttonVariant = (variant: string, isFullWidth?: boolean): string => {
  const baseStyles = cn(
    `inline-flex justify-center text-white items-center whitespace-nowrap leading-none rounded-lg outline-none active:ring-0 focus-visible:green-30 focus-visible:ring-4 focus-visible:ring-green-20`,
    { "w-full": isFullWidth }
  );

  // if loading bg-green-30 = bg-green-40
  const variantToClassName: Record<string, string> = {
    primary: `${baseStyles} bg-gradient-to-r from-bluer to-blue text-white hover:bg-dark-blue active:bg-blue `,
    secondary: `${baseStyles} bg-grey/10 hover:bg-opacity-25 active:bg-opacity-50`,
    outline: `${baseStyles} bg-transparent border border-white text-white hover:bg-white hover:text-dark-grey active:bg-grey-40 active:text-grey-70`,
    info: `${baseStyles} bg-blue text-white`,
    cancel: `${baseStyles} bg-red-500 text-white`,
  };

  return variantToClassName[variant];
};

const sizeVariant = {
  large: "h-14 px-8",
  medium: "h-10 px-5",
  small: "h-9 px-3",
};

const fontSize = {
  large: TextVariant.heading2,
  medium: TextVariant.heading3,
  small: TextVariant.heading4,
};

const Button = ({
  variant = "primary",
  type = "button",
  onClick,
  children,
  isFullWidth = false,
  isDisabled = false,
  size = "large",
  iconPosition = "right",
  icon,
  className,
  loading,
  iconDivider,
}: ButtonProps) => {
  const leftIconStyles = cn({ "border-r border-white pr-3": iconDivider }, size === "large" ? "mr-3" : "mr-2");
  return (
    <button
      type={type}
      className={cn(buttonVariant(variant, isFullWidth), sizeVariant[size], className, {
        "cursor-not-allowed": isDisabled || loading,
        "opacity-70": loading,
        "opacity-30": isDisabled,
      })}
      onClick={onClick}
      disabled={isDisabled}
    >
      {icon && iconPosition === "left" && (
        <span className={leftIconStyles}>
          <SVG size={size} icon={icon} />
        </span>
      )}
      <Text variant={fontSize[size]}>{children}</Text>
      {icon && iconPosition === "right" && (
        <span className={size === "large" ? "ml-3" : "ml-2"}>
          <SVG size={size} icon={icon} />
        </span>
      )}
      {loading && <MoonLoader size={18} color="white" className="ml-2" />}
    </button>
  );
};

export default Button;
