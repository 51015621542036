import * as React from "react";
import { SVGProps } from "react";

const BurgerMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 15" {...props}>
    <path
      fill="currentcolor"
      d="M1.10063 2.24026H23.8873c.6168 0 1.1127-.51136 1.1127-1.12013C25 .48701 24.5041 0 23.8873 0H1.10063C.48379 0 0 .49919 0 1.12013c0 .60877.4838 1.12013 1.10063 1.12013Zm0 6.37987H23.8873C24.5041 8.62013 25 8.10877 25 7.5c0-.63312-.4959-1.12013-1.1127-1.12013H1.10063C.48379 6.37987 0 6.87906 0 7.5c0 .60877.4838 1.12013 1.10063 1.12013Zm0 6.37987H23.8873C24.5041 15 25 14.4886 25 13.8799c0-.621-.4959-1.1202-1.1127-1.1202H1.10063C.48379 12.7597 0 13.2589 0 13.8799 0 14.4886.4838 15 1.10063 15Z"
    />
  </svg>
);

export default BurgerMenu;
