import { SingleCalendar } from "@atoms/calendar";
import classNames from "classnames";
import {
  eachMinuteOfInterval,
  endOfDay,
  format,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  isEqual,
  parse,
  setHours,
  setMinutes,
  startOfDay,
} from "date-fns";
import { useMemo, useRef, useState } from "react";
import ScrollableArea from "@atoms/scrollable-area";
import useOnClickOutside from "@hooks/use-click-outside";

type Props = {
  label?: string;
  datePlaceholder?: string;
  timePlaceholder?: string;
  value?: Date | null;
  onChange: (date: Date | null) => void;
  minDateTime?: Date;
  maxDateTime?: Date;
  error?: string;
  disabled?: boolean;
};

const timeList = eachMinuteOfInterval({ start: startOfDay(new Date()), end: endOfDay(new Date()) }, { step: 15 });

function DateTimeInput({
  value,
  onChange,
  datePlaceholder = "Date",
  timePlaceholder = "Time",
  minDateTime,
  maxDateTime,
  label,
  error,
  disabled,
}: Props) {
  const [activeInputModal, setActiveInputModal] = useState<"time" | "date">();
  const [tempDate, setTempDate] = useState<Date>();
  const ref = useRef<HTMLDivElement>(null);
  const inputOutlineClassnames = "ring ring-2 ring-blue rounded";

  const activeDate = tempDate || value;
  const activeTime = tempDate ? undefined : value ? format(value, "HH:mm") : undefined;

  const onSelectDate = (date: Date) => {
    setTempDate(date);
    onChange(null);
    setActiveInputModal("time");
  };

  const onSelectTime = (time: string) => {
    setActiveInputModal(undefined);
    if (activeDate) onChange(parse(time, "HH:mm", activeDate));
    setTempDate(undefined);
  };

  const validTimeList = useMemo(() => {
    if (!tempDate) return timeList;
    return timeList.filter((t) => {
      const time = setHours(setMinutes(tempDate, getMinutes(t)), getHours(t));
      if (!!minDateTime && !!maxDateTime)
        return (
          (isBefore(time, maxDateTime) && isAfter(time, minDateTime)) ||
          isEqual(time, minDateTime) ||
          isEqual(time, maxDateTime)
        );
      if (!!minDateTime) return isAfter(time, minDateTime) || isEqual(time, minDateTime);
      if (!!maxDateTime) return isBefore(time, maxDateTime) || isEqual(time, maxDateTime);
      return true;
    });
  }, [minDateTime, maxDateTime, tempDate]);

  const displayedDate = activeDate ? format(activeDate, "dd/MM/yyyy") : undefined;

  const renderValue = (placeholder: string, value: string | undefined, focused: boolean) => (
    <div className={classNames("relative pt-4", { [inputOutlineClassnames]: focused })}>
      <span
        className="absolute origin-top-left scale-[0.8] bottom-1/2 left-0 transition-all"
        style={!value ? { bottom: "25%", transform: "scale(1)" } : undefined}
      >
        {placeholder}
      </span>
      <div className={"h-6 text-white"}>{value}</div>
    </div>
  );

  useOnClickOutside(ref, () => {
    setTempDate(undefined);
    setActiveInputModal(undefined);
  });

  return (
    <div ref={ref} className={classNames("relative", { "opacity-50 cursor-not-allowed": disabled })}>
      <div className={classNames("bg-dark-grey rounded-md", { "ring-2 ring-inset ring-danger": !!error })}>
        <div className={classNames("p-4", { "bg-danger/20": !!error })}>
          {label && <div className="mb-2 text-sm text-white-80 -mt-2">{label}</div>}
          <div className="flex flex-row items-stretch group">
            <div className="flex-1" onClick={!disabled ? () => setActiveInputModal("date") : undefined}>
              {renderValue(datePlaceholder, displayedDate, activeInputModal === "date")}
            </div>
            <div className="border-l-2 border-grey border-dashed mx-4" />
            <div
              className={classNames("flex-1 ml-4", { "opacity-50 cursor-not-allowed": !displayedDate })}
              onClick={displayedDate && !disabled ? () => setActiveInputModal("time") : undefined}
            >
              {renderValue(timePlaceholder, activeTime, activeInputModal === "time")}
            </div>
          </div>
        </div>
      </div>
      {!!error && <div className="text-danger text-sm mt-1">{error}</div>}
      {activeInputModal === "date" && (
        <div className={`absolute top-full left-0 z-40 mt-4`}>
          <SingleCalendar
            selectedDates={activeDate ? [activeDate] : undefined}
            initialDate={new Date()}
            onClickDay={onSelectDate}
            minDate={minDateTime ? startOfDay(minDateTime) : undefined}
            maxDate={maxDateTime ? endOfDay(maxDateTime) : undefined}
          />
        </div>
      )}
      {activeInputModal === "time" && (
        <div className={`absolute top-full right-0 z-40 mt-4 h-[250px] w-32 overflow-hidden rounded-md bg-[#031629]`}>
          <ScrollableArea className="h-full">
            {validTimeList.map((date) => {
              const time = format(date, "HH:mm");
              const isSelected = time === activeTime;
              return (
                <div
                  key={date.toString()}
                  onClick={() => onSelectTime(time)}
                  className={classNames("px-4 py-2 hover:bg-dark-grey cursor-pointer", { "bg-blue": isSelected })}
                  children={time}
                />
              );
            })}
          </ScrollableArea>
        </div>
      )}
    </div>
  );
}

export default DateTimeInput;
