import cx from "classnames";
import { ReactNode } from "react";
import { priceFormatter } from "@utils/number";
import Avatar from "@atoms/avatar";
import Svg from "@atoms/svg";
import { ReactComponent as Video } from "@assets/icons/video.svg";
import { ReactComponent as Image } from "@assets/icons/image.svg";
import { ReactComponent as Music } from "@assets/icons/music.svg";
import { Collectible, Owner } from "@api/collectibles";
import { isObject, isString, isNumber } from "lodash";

export type Props = Collectible;

const LabelRow = ({ label, children, className }: { className?: string; label: string; children: ReactNode }) => {
  return (
    <div className={cx("text-lg flex justify-between", className)}>
      <span>{label}</span>
      <div className="flex flex-col items-end">{children}</div>
    </div>
  );
};

const getImage = (imageUrl: string) => {
  if (imageUrl?.length > 0) {
    return <img src={imageUrl} className="w-full object-cover aspect-2/1" alt="Collectible thumbnail" />;
  }
};

const getAvatar = (seller: Owner) => {
  if (isObject(seller) && isString(seller.thumbnailUrl) && seller.thumbnailUrl.length > 0) {
    return (
      <LabelRow label="Creator:" className="mb-2.5 flex flex-row items-center">
        <div className="flex items-center justify-center">
          <Avatar imgSrc={seller.thumbnailUrl} alt={seller.name} size="md" />
        </div>
      </LabelRow>
    );
  }
};

export default function CollectibleCard({ imageUrl, quantityAvailable, title, price, seller, ...collectable }: Props) {
  const Icon = (() => {
    if (collectable.audioMp3Url) return Music;
    return Image;
  })();

  const formattedPrice = isNumber(price) ? priceFormatter(price * 100) : priceFormatter(0);
  const decimalPrice = formattedPrice.toFixed(2);

  return (
    <div className="rounded-3xl bg-navy overflow-hidden text-white">
      <div className="relative">
        <div className="absolute bg-gradient-to-t from-black/70 inset-0 z-1" />
        {getImage(imageUrl)}
        <div className="absolute px-4 bottom-5 z-2 flex justify-between w-full items-center">
          <div className="bg-blue rounded-full aspect-square p-1" children={<Svg icon={Icon} size="large" />} />
        </div>
      </div>
      <div className="px-4 py-5">
        <h3 className="text-xl mb-4 truncate font-bold">{title}</h3>
        <LabelRow label="Price:" className="mb-2.5">
          <div className="flex items-center justify-center">
            <span className="inline-block text-blue text-xl mr-1.5 mb-[3px] w-3">
              {seller.currency_symbol}
            </span>
            <span className="inline-block text-2xl font-bold">{decimalPrice}</span>
          </div>
        </LabelRow>
        {getAvatar(seller)}
      </div>
    </div>
  );
}
