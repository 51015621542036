import { Customer } from "@api/customers";
import { displayDate, displayDateDuration } from "@utils/date";
import Fieldset from "@atoms/fieldset";
import { displayCurrency } from "@utils/number";
import CustomerTicketList from "@organisms/customer-ticket-list";
import CustomerCollectibleTokenList from "@organisms/customer-collectible-token-list";
import LotteryEntriesList from "@organisms/customer-lottery-entries-list";
import { CustomerTicketsModeButtonKeys } from "@constants/customers";
import ButtonToggle from "@molecules/button-toggle";
import { useState } from "react";
import { Props as TicketsListingProps } from "@organisms/customer-ticket-list/customer-ticket-list";
import { Props as CollectibleTokensListingProps } from "@organisms/customer-collectible-token-list/customer-collectible-token-list";
import { Props as LotteryEntriesListingProps } from "@organisms/customer-lottery-entries-list/customer-lottery-entries-list";
import Button from "@atoms/button";
import { useNavigate } from "react-router-dom";
import useAuth from "@hooks/use-auth";

export type Props = {
  customer: Customer;
  ticketsListing: TicketsListingProps;
  collectibleTokensListing: CollectibleTokensListingProps;
  lotteryEntriesListing: LotteryEntriesListingProps;
};

const toggleModeButtons = [
  { label: "Tickets", key: CustomerTicketsModeButtonKeys.tickets },
  { label: "Collectables", key: CustomerTicketsModeButtonKeys.collectables },
  { label: "Lottery", key: CustomerTicketsModeButtonKeys.lotteries },
];

const CustomerView = ({ customer, ticketsListing, collectibleTokensListing, lotteryEntriesListing }: Props) => {
  const { currency } = useAuth();
  const navigate = useNavigate();
  const [mode, setMode] = useState<string>(CustomerTicketsModeButtonKeys.tickets);
  const onSwitchMode = (newMode: string) => {
    setMode(newMode);
  };
  const detailFields = [
    ["Name", "-"],
    ["Username", customer.username || "-"],
    ["Phone", customer.phoneNumber],
    ["Last email used", customer.lastEmailUsed || "-"],
  ];
  const orderFields = [
    ["Last Order", displayDateDuration(customer.lastPurchaseAt || customer.updatedAt)],
    [
      "Averge order value",
      customer.totalSpent
        ? displayCurrency(customer.totalSpent / customer.totalOrders, "en-GB", currency)
        : "-",
    ],
    [
      "Total Spent to date",
      customer.totalSpent
        ? displayCurrency(customer.totalSpent, "en-GB", currency)
        : "-",
    ],
    ["Orders placed", customer.totalOrders.toString()],
  ];

  function renderLabelAndValue(label: string, value: string) {
    return (
      <div key={label}>
        <div className="text-grey text-sm">{label}</div>
        <div className="text-white">{value}</div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex space-x-4 mb-9">
        <Button variant="secondary" size="large" onClick={() => navigate("/customers")}>
          Back
        </Button>
        <div>
          <div className="text-white text-xl">{customer.username}</div>
          <div className="text-white/50">
            {customer.status} customer | customer since {displayDate(customer.createdAt)}
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4 mb-4">
        <Fieldset heading="Customer Details" paddingSize="sm" className="flex-1">
          <div className="flex gap-4 flex-col lg:grid lg:grid-cols-2 lg:mx-6">
            {detailFields.map(([l, v]) => renderLabelAndValue(l, v))}
          </div>
        </Fieldset>
        <Fieldset heading="Order Overview" paddingSize="sm" className="flex-1">
          <div className="flex gap-4 flex-col lg:grid lg:grid-cols-2 lg:mx-6">
            {orderFields.map(([l, v]) => renderLabelAndValue(l, v))}
          </div>
        </Fieldset>
      </div>
      <div className="bg-background-gray p-6 rounded-md">
        <ButtonToggle className="mb-8" buttons={toggleModeButtons} onToggle={onSwitchMode} activeKey={mode} />
        {mode === CustomerTicketsModeButtonKeys.tickets && <CustomerTicketList {...ticketsListing} />}
        {mode === CustomerTicketsModeButtonKeys.collectables && (
          <CustomerCollectibleTokenList {...collectibleTokensListing} />
        )}
        {mode === CustomerTicketsModeButtonKeys.lotteries && <LotteryEntriesList {...lotteryEntriesListing} />}
      </div>
    </div>
  );
};

export default CustomerView;
