import cn from "classnames";
import { TablePaginationFooterProps as PaginationFooterProps } from "@molecules/table-pagination-footer";
import useMediaQueries from "@hooks/use-media-queries";
import CustomerCard, { gridColClasses } from "@molecules/customer-card";
import { Customer } from "@api/customers";

export enum ColumnKey {
  username = "username",
  phoneNumber = "phoneNumber",
  totalSpent = "totalSpent",
  totalOrders = "totalOrders",
  status = "status",
}

export type Props = PaginationFooterProps & {
  customers: Customer[];
  onView?: (customer: Customer) => void;
  onSort: (columnKey: ColumnKey) => void;
};

const columns = [
  { key: ColumnKey.username, label: "Username" },
  { key: ColumnKey.phoneNumber, label: "Phone Number" },
  { key: ColumnKey.totalSpent, label: "Total Spent" },
  { key: ColumnKey.totalOrders, label: "Orders Placed" },
  { key: ColumnKey.status, label: "Status" },
];

function CustomersList(props: Props) {
  const { isDesktopAndUp } = useMediaQueries();
  const { customers } = props;
  const { onSort, onView } = props;

  return (
    <div className="mb-8">
      {isDesktopAndUp && (
        <div className={cn(gridColClasses, "text-sm text-grey mb-5")}>
          {columns.map((columnHeader) => {
            const onClick = columnHeader.label ? () => onSort(columnHeader.key) : undefined;
            return (
              <div key={columnHeader.key} className="flex items-center" onClick={onClick}>
                {columnHeader.label ? <span>{columnHeader.label}</span> : null}
              </div>
            );
          })}
        </div>
      )}

      {customers.map((item) => {
        return (
          <div className="mt-3" key={item.id}>
            <CustomerCard
              username={item.username}
              phoneNumber={item.phoneNumber}
              totalSpent={item.totalSpent}
              totalOrders={item.totalOrders}
              status={item.status}
              onView={onView ? () => onView(item) : undefined}
            />
          </div>
        );
      })}
    </div>
  );
}

export default CustomersList;
