import React, { useEffect, useRef, useState } from "react";
import { ColorResult, CustomPicker } from "react-color";
import { Saturation, Hue } from "react-color/lib/components/common";
import { ColorTypeProps } from "@atoms/text-input/text-input.types";
import TextInput from "@atoms/text-input";
import useOnClickOutside from "@hooks/use-click-outside";
import { convertToHex, isValidColor } from "@utils/color";
import ColorCircle from "@atoms/color-circle";
import classNames from "classnames";

type PickerExtraProps = {
  show: boolean;
  onClose: (ev: MouseEvent | TouchEvent) => void;
};

type Props = Omit<ColorTypeProps, "type"> & {
  type?: "color";
};

const MyPicker = CustomPicker<PickerExtraProps>(({ show, onClose, onChange, ...props }) => {
  const pickerRef = useRef<HTMLDivElement>(null);
  const handleChange = (color: ColorResult) => (onChange ? onChange(color) : undefined);

  useOnClickOutside(pickerRef, (e) => {
    if (show) onClose(e);
  });

  return show ? (
    <div ref={pickerRef} className="p-1.5 bg-white rounded inline-block">
      <div className="relative w-[200px] h-[100px]">
        <Saturation {...props} onChange={handleChange} />
      </div>
      <div className="relative w-[200px] h-[10px] mt-2">
        <Hue {...props} onChange={handleChange} direction={"horizontal"} />
      </div>
    </div>
  ) : null;
});

const ColorInput = ({ showColorRing, type, ...props }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [showPicker, setShowPicker] = useState(false);
  const [pickerValue, setPickerValue] = useState(props.value);
  const colorValueIsValid = isValidColor(props.value || "");
  const updateValueWithPickerValue = () => {
    if (inputRef?.current && pickerValue) {
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;
      if (nativeInputValueSetter) {
        nativeInputValueSetter.call(inputRef.current, pickerValue);
        const event = new Event("input", { bubbles: true });
        inputRef.current.dispatchEvent(event);
      }
    }
  };

  const onAutoClosePicker = (e: MouseEvent | TouchEvent) => {
    if (e.target !== inputRef.current) setShowPicker(false);
  };

  useEffect(() => {
    if (!!props.value && colorValueIsValid) setPickerValue(props.value);
  }, [props.value, colorValueIsValid]);

  const onBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (colorValueIsValid && inputRef.current && props.value) {
      inputRef.current.value = convertToHex(props.value);
      if (props.onBlur) props.onBlur(e);
    }
  };

  return (
    <>
      <div className={classNames("relative h-[55px]", { flex: props.isFullWidth, "inline-flex": !props.isFullWidth })}>
        {showColorRing && (
          <div className="mr-3">
            <ColorCircle color={pickerValue || "#000"} />
          </div>
        )}
        <TextInput
          type="text"
          ref={inputRef}
          placeholder="Enter color"
          isFullWidth
          onFocus={() => setShowPicker(true)}
          {...props}
          onBlur={onBlur}
        />
        <div className="absolute top-[110%] left-0 z-10">
          <MyPicker
            show={showPicker}
            onClose={onAutoClosePicker}
            color={pickerValue}
            onChange={(e) => setPickerValue(e.hex)}
            onChangeComplete={updateValueWithPickerValue}
          />
        </div>
      </div>
    </>
  );
};

export default ColorInput;
