import { Ticket } from "@api/tickets";
import CustomerTicketCard, { gridColClasses } from "@molecules/customer-ticket-card";
import PaginationButtons from "../../molecules/pagination-buttons";
import SpinnerLoader from "@atoms/spinner-loader";
import cn from "classnames";
import { TablePaginationFooterProps } from "@molecules/table-pagination-footer";

export type Props = TablePaginationFooterProps & {
  tickets: Ticket[];
};

export enum ColumnKey {
  image = "image",
  eventName = "eventName",
  ticketType = "ticketType",
  purchasedAt = "purchasedAt",
  transactionHash = "transactionHash",
  price = "price",
  status = "status",
}

const columns = [
  { key: ColumnKey.image, label: "" },
  { key: ColumnKey.eventName, label: "Event Name" },
  { key: ColumnKey.ticketType, label: "Ticket Name" },
  { key: ColumnKey.purchasedAt, label: "Purchase Date" },
  { key: ColumnKey.transactionHash, label: "Transaction Hash" },
  { key: ColumnKey.price, label: "Total Spent" },
];

function NoOrders() {
  return (
    <div className="w-full flex flex-col items-center pt-14 pb-10">
      <p className="text-4xl font-bold mb-6">No ticket orders yet 😔</p>
    </div>
  );
}

const CustomerTicketList = ({ tickets, totalPages, currentPageIndex, onChangePage }: Props) => {
  return (
    <div>
      {!tickets && <SpinnerLoader />}
      {tickets.length > 0 ? (
        <>
          <div className={cn(gridColClasses, "text-sm text-grey mb-5")}>
            {columns.map((columnHeader) => {
              return (
                <div key={columnHeader.key} className="flex items-center">
                  {columnHeader.label ? <span>{columnHeader.label}</span> : null}
                </div>
              );
            })}
          </div>
          {tickets.map((ticket: Ticket, index: number) => (
            <div className="mt-3">
              <CustomerTicketCard
                ticketType={ticket.ticketType}
                purchasedAt={ticket.purchasedAt}
                transactionHash={ticket.transactionHash || undefined}
                price={ticket.totalSpent}
                status={ticket.status}
                key={index}
                index={index}
              />
            </div>
          ))}
          {totalPages > 1 && (
            <div className="flex flex-row justify-center my-8">
              <PaginationButtons activePage={currentPageIndex} setPage={onChangePage} pages={totalPages} />
            </div>
          )}
        </>
      ) : (
        <NoOrders />
      )}
    </div>
  );
};

export default CustomerTicketList;
