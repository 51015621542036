import { ChevronLeft, ChevronRight } from "@atoms/icons";
import classNames from "classnames";

const buttonClass = "w-10 h-10 bg-grey/10 text-base flex items-center justify-center";

type PaginationButtonsProps = {
  pages: number;
  setPage: (page: number) => void;
  activePage: number;
};

const PaginationButtons = ({ pages, setPage, activePage = 1 }: PaginationButtonsProps) => {
  const buttons = [];

  // If there are 7 or fewer pages, we can show all the pages
  if(pages <= 7) {
    for (let i = 0; i < pages; i++) {
      buttons.push({
        label: i + 1,
        onClick: () => setPage(i + 1),
        active: activePage === (i + 1),
      });
    }
  } else {
    if(activePage <= 3 || activePage >= (pages - 2)) {
      for(let i=1; i<=3; i++) {
        buttons.push({
          label: i,
          onClick: () => setPage(i),
          active: activePage === (i),
        });
      }
      buttons.push({
        label: '...',
        onClick: () => {},
        active: false,
        dots: true,
      });
      for(let i=2; i>=0; i--) {
        buttons.push({
          label: pages - i,
          onClick: () => setPage(pages - i),
          active: activePage === (pages - i),
        });
      }
    } else {
      buttons.push({
        label: 1,
        onClick: () => setPage(1),
        active: activePage === (1),
      });
      buttons.push({
        label: '...',
        onClick: () => {},
        active: false,
        dots: true,
      });
      for(let i=activePage-1; i<=activePage+1; i++) {
        buttons.push({
          label: i,
          onClick: () => setPage(i),
          active: activePage === (i),
        });
      }
      buttons.push({
        label: '...',
        onClick: () => {},
        active: false,
        dots: true,
      });
      buttons.push({
        label: pages,
        onClick: () => setPage(pages),
        active: activePage === (pages),
      });
    }
  }

  return (
    <div className="flex flex-row gap-x-2">
      <button
        onClick={() => {
          if(activePage === 1) {
            return true;
          }
          setPage(activePage - 1)
        }}
        className={classNames(buttonClass, "text-white", { "opacity-30 cursor-default": activePage === 1 })}
      >
        <ChevronLeft className="h-4 w-4" />
      </button>
      {buttons.map((item, key) => (
        <button
          key={key}
          className={classNames(buttonClass, {
            "font-bold text-white": item.active,
            "font-light text-grey": !item.active,
            "cursor-pointer": !item.dots,
            "cursor-default": item.dots,
          })}
          onClick={item.onClick}
        >
          {item.label}
        </button>
      ))}
      <button
        onClick={() => {
          if(activePage === pages) {
            return true;
          }
          setPage(activePage + 1);
        }}
        className={classNames(buttonClass, "text-white", { "opacity-30 cursor-default": activePage === pages })}
      >
        <ChevronRight className="h-4 w-4" />
      </button>
    </div>
  );
};

export default PaginationButtons;
