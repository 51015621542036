import TicketsApi from "@api/tickets";
import useAuth from "@hooks/use-auth";
import { useMemo } from "react";

function useTicketsApi() {
  const { token, handleUnauthorization } = useAuth();

  const ticketsApi = useMemo(() => {
    if (!token) {
      handleUnauthorization();
      throw new Error("jwt token required to init tickets api");
    }
    return new TicketsApi(token);
  }, [token, handleUnauthorization]);

  return ticketsApi;
}

export default useTicketsApi;
