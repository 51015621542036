import DateTimeInput from "@molecules/date-time-input";
import FormikField from "@molecules/formik-field";
import { useFormikContext } from "formik";

export type CreateEventBasicFormValues = {
  eventName: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
  entryDate: Date | undefined;
  venueName: string;
};

function EventBasicsForm() {
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext<CreateEventBasicFormValues>();
  const startDateTimeIsValid = !errors.startDate;

  const touchDateFields = () => {
    ["startDate", "endDate", "entryDate"].forEach((field, idx) => {
      setTimeout(() => setFieldTouched(field), 100 * idx);
    });
  };

  const onSetStartDateAndTime = (date: Date | null) => {
    if (date) {
      setFieldValue("startDate", date);
      touchDateFields();
    }
  };

  const onSetEntryDateAndTime = (date: Date | null) => {
    if (date) {
      setFieldValue("entryDate", date);
      touchDateFields();
    }
  };

  const onSetEndDateAndTime = (date: Date | null) => {
    if (date) {
      setFieldValue("endDate", date);
      touchDateFields();
    }
  };

  const dateError = (...fieldNames: (keyof CreateEventBasicFormValues)[]) => {
    return fieldNames.some((fieldName) => !!touched[fieldName])
      ? fieldNames.reduce((errMsg, fieldName) => {
          const err = errors[fieldName];
          return typeof err === "string" ? err : errMsg;
        }, "" as string)
      : undefined;
  };

  return (
    <>
      <span className="mb-2 inline-block">Event details</span>
      <FormikField name="eventName" label="Event name" isFullWidth />

      <span className="mt-8 mb-2 inline-block">Event times</span>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:flex gap-4">
        <div className="xl:w-[300px]">
          <DateTimeInput
            datePlaceholder="Start date"
            onChange={onSetStartDateAndTime}
            minDateTime={new Date()}
            value={values.startDate}
            error={dateError("startDate")}
          />
        </div>
        <div className="xl:w-[300px]">
          <DateTimeInput
            datePlaceholder="Entry date"
            onChange={onSetEntryDateAndTime}
            minDateTime={new Date()}
            maxDateTime={!errors.endDate ? values.endDate : undefined}
            value={values.entryDate}
            error={dateError("entryDate")}
          />
        </div>
        <div className="xl:w-[300px]">
          <DateTimeInput
            disabled={!startDateTimeIsValid}
            datePlaceholder="End date"
            onChange={onSetEndDateAndTime}
            minDateTime={values.startDate}
            value={values.endDate}
            error={dateError("endDate")}
          />
        </div>
        {/* <DateRange from={values.startDate} to={values.endDate} onChange={onDateRangeChange} /> */}
        {/* <div className="flex-1 grid xs:grid-cols-2 md:grid-cols-3 gap-2 mt-2 lg:mt-0 xl:mt-0 xl:items-end">
        </div> */}
      </div>

      <span className="mt-8 mb-2 inline-block">Venue details</span>
      <FormikField name="venueName" label="Venue name" isFullWidth />
    </>
  );
}

export default EventBasicsForm;
