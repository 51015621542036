import useAuth from "@hooks/use-auth";
import DocumentTitle from "@atoms/document-title";
import { LogoFull } from "@atoms/icons";
import { SellerResetPassword } from "@api/auth";
import { resetPasswordValidation as validationSchema } from "@pages/settings/form-helpers";
import { Formik } from "formik";
import ResetPasswordForm from "@organisms/reset-password-form/reset-password-form";
import BadgeAlert from "@atoms/badge-alert";

function ForgotPassword() {
  const { resetPassword, formError, setFormError } = useAuth();
  const searchParams = new URLSearchParams(window.location.search);

  const emptyInitialValues: SellerResetPassword = {
    token: searchParams.get("token") || "",
    email: searchParams.get("email") || "",
    password: "",
    password_confirmation: "",
  };

  return (
    <>
      <div className="border-b border-dark-grey flex items-center w-full h-24 px-4 md:px-10">
        <LogoFull className="h-8" />
      </div>
      <div className="w-3/4 mt-4 mx-auto">
        {!!formError && (
          <BadgeAlert
            variant="error"
            className="top-0 left-0 w-full"
            message={formError}
            onClose={() => {
              setFormError("");
            }}
          />
        )}
      </div>
      <div className="text-white relative p-4 flex flex-col items-center justify-center h-full overflow-hidden">
        <DocumentTitle titlePrefix="Reset Password" />
        <div className="max-w-md">
          <h1 className="text-3xl mb-3 text-left z-30 font-bold">Reset password</h1>
          <h4>Your new password must be different from previously used passwords.</h4>
          <div className="text-left pt-4">
            <Formik
              {...{ initialValues: emptyInitialValues, onSubmit: resetPassword, validationSchema }}
              validateOnMount
              enableReinitialize
            >
              <ResetPasswordForm />
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
