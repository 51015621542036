import { Collectible } from "@api/collectibles";
import Box from "@atoms/box";
import cn from "classnames";
import { displayDate } from "@utils/date";
import Pill, { PillColor } from "@atoms/pill";
import TransactionHash from "@molecules/transaction-hash";
import { displayCurrency, priceFormatter } from "@utils/number";
import useAuth from "@hooks/use-auth";

export const gridColClasses = "grid gap-5 grid-cols-[80px_1.3fr_0.8fr_0.8fr_0.8fr_96px] items-center";

export type CustomerCollectibleTokenCardProps = {
  collectible: Collectible;
  status: "sales" | "pending";
  purchasedAt: Date;
  transactionHash?: string | null;
};

function CollectibleTokenCard({
  collectible,
  status,
  purchasedAt,
  transactionHash,
}: CustomerCollectibleTokenCardProps) {
  const { currency } = useAuth();
  const pillColor = status === "sales" ? PillColor.success : PillColor.warning;
  const pillText = status === "sales" ? "Sale" : "Pending";
  return (
    <Box variant="dark-grey" padding="none" className="overflow-hidden">
      <div className={cn("text-white", gridColClasses)}>
        <div className="p-2">
          <img
            src={collectible.imageUrl}
            alt="collectible thumbnail"
            className="w-full object-cover aspect-square rounded"
          />
        </div>
        <div children={<span>{collectible.title}</span>} />
        <div children={<span>{displayDate(purchasedAt)}</span>} />
        {transactionHash ? <TransactionHash hash={transactionHash} /> : <span children="-" />}
        <div
          children={
            <span>{displayCurrency(collectible.price || 0, "en-GB", currency)}</span>
          }
        />
        <div children={<Pill className="" label={pillText} color={pillColor} />} />
      </div>
    </Box>
  );
}

export default CollectibleTokenCard;
