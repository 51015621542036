import * as React from "react";

function Clock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M19.25 12a7.25 7.25 0 11-14.5 0 7.25 7.25 0 0114.5 0z" stroke="currentcolor" strokeWidth={1.5} />
      <path d="M12 8v4l2 2" stroke="currentcolor" strokeWidth={1.5} strokeLinecap="round" />
    </svg>
  );
}

export default Clock;
