import { getContractConfig } from "@api/config";
import { keyStore } from "@context/auth";
import { createContext, ReactNode, useEffect, useState } from "react";
import { NetworkContextType } from "./near-context-types";
const CONTRACT_NAME = process.env.REACT_APP_CONTRACT_NAME || "ticket_minter_test_2022823_748.testnet";

const getNearEndpointUrls = (networkId: string) => {
  return {
    nodeUrl: `https://rpc.${networkId}.near.org`,
    walletUrl: `https://wallet.${networkId}.near.org`,
    helperUrl: `https://helper.${networkId}.near.org`,
  };
};
export const NearContext = createContext<NetworkContextType | undefined>(undefined);

export function NearContextProvider({ children }: { children: ReactNode }) {
  const [contractConfig, setContractConfig] = useState<NetworkContextType>();

  useEffect(() => {
    (async () => {
      const { near_network: networkId, contracts } = await getContractConfig();
      const { nodeUrl, walletUrl, helperUrl } = getNearEndpointUrls(networkId);
      const contractName = contracts.marketplace || CONTRACT_NAME;

      setContractConfig({
        contracts,
        config: { networkId, keyStore, nodeUrl, walletUrl, helperUrl, headers: {}, contractName },
      });
    })();
  }, []);

  return <NearContext.Provider value={contractConfig}>{children}</NearContext.Provider>;
}
