import classNames from "classnames";
import { ISVGProps } from "./svg.types";

const iconSizeConversion = (size: string): string | number => {
  switch (size) {
    case "xsmall":
      return 12;
    case "small":
      return 16;
    case "medium":
      return 24;
    case "large":
      return 28;
    case "xlarge":
      return 32;
    case "xxlarge":
      return 36;
    default:
      return "100%";
  }
};

const SVG = ({ size = "medium", icon, styles, height, width }: ISVGProps) => {
  const Icon = icon;
  return (
    <span
      className={classNames("flex", styles)}
      role="presentation"
      style={{
        height: height ?? iconSizeConversion(size),
        width: width ?? iconSizeConversion(size),
      }}
    >
      <Icon data-testid="svg" preserveAspectRatio="xMidYMid" />
    </span>
  );
};

export default SVG;
