import Button from "@atoms/button";
import DocumentTitle from "@atoms/document-title";
import Fieldset from "@atoms/fieldset";
import TextField from "@atoms/text-field";
import useScannersApi from "@hooks/use-scanners-api";
import { LayoutTitle } from "@organisms/layout";
import { checkMinMaxLength } from "@utils/string";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

function CreateScanner() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const scannerApi = useScannersApi();

  const goToScannerListing = () => navigate("/scanners");

  const handleMutationError = (error: any) => {
    if (error?.message) {
      setUsernameError(error.message);
    }
  };

  const create = useMutation(
    function create() {
      return scannerApi.createScanner(username, password);
    },
    {
      onSuccess: goToScannerListing,
      onError: handleMutationError,
    }
  );

  const formValid = checkMinMaxLength(username, 4, 32) && checkMinMaxLength(password, 6, 200);

  const submit = () => {
    setUsernameError("");
    setPasswordError("");
    if (!formValid) {
      if (!checkMinMaxLength(username, 4, 32)) {
        setUsernameError("Username must be between 4 and 32 characters");
      }
      if (!checkMinMaxLength(password, 6, 200)) {
        setPasswordError("Password must be between 6 and 200 characters");
      }
    } else {
      create.mutate();
    }
  };

  return (
    <div>
      <DocumentTitle
        titlePrefix="Create a scanner"
        description="Create unique scanner credentials to keep track of your team and how and where each NFT ticket and collectable was scanned."
      />
      <LayoutTitle title="Create a scanner" />
      <div className="flex justify-between">
        <Button variant="secondary" onClick={goToScannerListing}>
          Back
        </Button>
        <Button variant="primary" onClick={submit} loading={create.isLoading}>
          Finish & create
        </Button>
      </div>
      <Fieldset variant="secondary" heading="Login details" paddingSize="sm" className="my-8">
        <div className="grid grid-cols-2 gap-4">
          <TextField
            className="w-full"
            label="Username"
            error={usernameError}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            className="w-full tracking-widest"
            label="Password"
            error={passwordError}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </div>
      </Fieldset>
    </div>
  );
}

export default CreateScanner;
