import useAuth from "@hooks/use-auth";
import Graph from "@atoms/graph";
import { useEffect, useState } from "react";
import DashboardSingleStats from "@molecules/dashboard-single-stats";
import DashboardTotalSold from "@organisms/dashboard-total-sold";
import DashboardGraphHeader from "@organisms/dashboard-graph-header";
import { DashboardModeButtonKeys, DashboardPeriod } from "@constants/dashboard";
import ButtonToggle from "@molecules/button-toggle";
import useDashboard from "@hooks/use-dashboard";
import { subDays, subMonths } from "date-fns";
import DocumentTitle from "@atoms/document-title";
import SpinnerLoader from "@atoms/spinner-loader";
import useMediaQueries from "@hooks/use-media-queries";
import DropdownSelect from "@molecules/dropdown-select";
import { LayoutTitle } from "@organisms/layout";

type FilteredItem = { key: string; name: string };

const toggleModeButtons = [
  { label: "Tickets", key: DashboardModeButtonKeys.tickets },
  { label: "Collectables", key: DashboardModeButtonKeys.collectables },
];

const togglePeriodButtons = [
  { label: "Week", key: DashboardPeriod.week },
  { label: "Month", key: DashboardPeriod.month },
  { label: "Custom", key: DashboardPeriod.custom },
];

function getStartAndEndDates(period: DashboardPeriod) {
  const today = new Date();
  const graphEndDate = today;
  const startDate = (() => {
    switch (period) {
      case DashboardPeriod.week:
        return subDays(graphEndDate, 6);
      case DashboardPeriod.month:
      case DashboardPeriod.custom:
        return subMonths(graphEndDate, 1);
      default:
        return subDays(graphEndDate, 1);
    }
  })();
  return { startDate, endDate: graphEndDate };
}

const Dashboard = () => {
  const [mode, setMode] = useState(DashboardModeButtonKeys.tickets);
  const [filteredEvent, setFilteredEvent] = useState<FilteredItem>();
  const [filteredCollectable, setFilteredCollectable] = useState<FilteredItem>();
  const [period, setPeriod] = useState(DashboardPeriod.week);
  const activePeriodOption = togglePeriodButtons.find(({ key }) => key === period) || {
    label: "Week",
    key: DashboardPeriod.week,
  };
  const [graphData, setGraphData] = useState<number[]>();
  const [percentageChange, setPercentageChange] = useState<number>();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const { user } = useAuth();
  const { isMobile } = useMediaQueries();

  const title = !!user ? `Welcome back, ${user.accountId}!` : "Welcome back!";

  useEffect(() => {
    const { startDate: start, endDate: end } = getStartAndEndDates(period);
    setStartDate(start);
    setEndDate(end);
  }, [period]);

  const {
    collectibleTokenSales,
    collectibleTokenSalesPercentChange,
    ticketSales,
    ticketSalesPercentChange,
    newEvents,
    newCollectibles,
    newTicketOrders,
    newCollectibleOrders,
    newTicketCustomers,
    newCollectibleCustomers,
    allEventSales,
    allCollectibleSales,
    eventTicketSalesQuery,
    singleCollectableSale,
  } = useDashboard({
    startDate,
    endDate,
    activeEventSlug: filteredEvent?.key,
    activeCollectableUUID: filteredCollectable?.key,
  });

  useEffect(() => {
    const ticketMode = mode === DashboardModeButtonKeys.tickets;
    const graphDataSet = (() => {
      const salesData = ticketMode ? ticketSales : collectibleTokenSales;
      if (!salesData) return [];
      return Object.values(salesData).map(({ total_price }) => total_price);
    })();
    setGraphData(graphDataSet);
    if (ticketMode) setPercentageChange(ticketSalesPercentChange);
    else setPercentageChange(collectibleTokenSalesPercentChange);
  }, [mode, ticketSales, collectibleTokenSales, ticketSalesPercentChange, collectibleTokenSalesPercentChange]);

  const defaultCollectableFilter = { name: "All collectables", key: "all" };
  const defaultEventFilter = { name: "All events", key: "all" };
  const defaultFilter = mode === DashboardModeButtonKeys.tickets ? defaultEventFilter : defaultCollectableFilter;
  const collectablesList = allCollectibleSales
    ? [
        { name: "All collectables", key: "all" },
        ...allCollectibleSales.collectibles.map(({ title, uuid }) => ({ name: title, key: uuid })),
      ]
    : [];
  const eventsList = allEventSales
    ? [{ name: "All events", key: "all" }, ...allEventSales.events.map(({ name, slug }) => ({ name, key: slug }))]
    : [];

  const totalsFilterList = mode === DashboardModeButtonKeys.tickets ? eventsList : collectablesList;
  const activeFilterItem = mode === DashboardModeButtonKeys.tickets ? filteredEvent : filteredCollectable;
  const onSelectFilter = (option: FilteredItem | null) => {
    if (mode === DashboardModeButtonKeys.tickets) setFilteredEvent(option || undefined);
    else setFilteredCollectable(option || undefined);
  };

  const onChangeMode = (mode: DashboardModeButtonKeys) => {
    setMode(mode);
    setFilteredEvent(undefined);
    setFilteredCollectable(undefined);
  };

  const eventTotals = (() => {
    const salesTotals = allEventSales?.events || eventTicketSalesQuery.data?.result.ticket_types;
    const formatEventSalesToSaleTotal = (sales: NonNullable<typeof salesTotals>) =>
      sales.map((sale) => ({ title: sale.name, sold: sale.tickets_sold, available: sale.total_tickets_available }));

    if (filteredEvent) {
      if (!eventTicketSalesQuery.data) return undefined;
      return {
        sold: eventTicketSalesQuery.data.result.total_tickets_sold,
        available: eventTicketSalesQuery.data.result.total_tickets_available,
        items: formatEventSalesToSaleTotal(eventTicketSalesQuery.data.result.ticket_types),
      };
    } else {
      if (!allEventSales) return undefined;
      return {
        sold: allEventSales?.total_tickets_sold,
        available: allEventSales?.total_tickets_available,
        items: formatEventSalesToSaleTotal(allEventSales.events),
      };
    }
  })();

  const collectableTotals = (() => {
    const collectableSalesTotals = !filteredCollectable ? allCollectibleSales?.collectibles : singleCollectableSale;
    const formatCollectableSalesToSaleTotal = (sales: NonNullable<typeof collectableSalesTotals>) =>
      sales.map(({ title, ...sale }) => ({
        title,
        sold: sale.collectible_tokens_sold,
        available: sale.collectible_tokens_available,
      }));
    if (filteredCollectable) {
      if (!singleCollectableSale) return undefined;
      return {
        sold: singleCollectableSale[0].collectible_tokens_sold,
        available: singleCollectableSale?.[0].collectible_tokens_available,
        items: formatCollectableSalesToSaleTotal(singleCollectableSale),
      };
    } else {
      if (!allCollectibleSales) return undefined;
      return {
        sold: allCollectibleSales.total_collectible_tokens_sold,
        available: allCollectibleSales.total_collectible_tokens_available,
        items: formatCollectableSalesToSaleTotal(allCollectibleSales.collectibles),
      };
    }
  })();

  const totalSalesListing = mode === DashboardModeButtonKeys.tickets ? eventTotals : collectableTotals;

  if (!startDate || !endDate) return <SpinnerLoader />;

  return (
    <div className="mb-4">
      <LayoutTitle {...{ title }} />
      <DocumentTitle titlePrefix="Sales and figures" />
      <div className="flex flex-row justify-between items-stretch mb-4">
        <ButtonToggle buttons={toggleModeButtons} onToggle={onChangeMode} activeKey={mode} />
        {isMobile ? (
          <div className="flex-1 ml-8">
            <DropdownSelect
              options={togglePeriodButtons}
              activeOption={activePeriodOption}
              onChange={(options) => setPeriod(options.key)}
              align="right"
              containerClass="h-full"
            />
          </div>
        ) : (
          <ButtonToggle buttons={togglePeriodButtons} onToggle={setPeriod} activeKey={period} />
        )}
      </div>
      <div className="md:bg-ebony rounded-lg md:pt-6 md:py-6">
        <DashboardGraphHeader
          mode={mode}
          percentageChange={percentageChange}
          period={period}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          className="mt-7 mb-5 md:mt-0 md:mb-7 min-h-14 md:min-h-7 sm:px-6"
        />
        <div className="bg-ebony rounded-lg  p-4 pt-6 sm:pt-0 sm:px-6 mb-7">
          {!!graphData ? (
            <Graph primaryData={graphData as number[]} {...{ startDate, endDate }} hideXAxis={isMobile} />
          ) : (
            <SpinnerLoader />
          )}
        </div>
        <div className="hidden sm:block">
          <DashboardSingleStats
            {...{
              mode,
              period,
              newEvents,
              newCollectibles,
              newTicketOrders,
              newCollectibleOrders,
              newTicketCustomers,
              newCollectibleCustomers,
            }}
          />
        </div>
      </div>
      <DashboardTotalSold
        {...{ mode, totalSalesListing, onSelectFilter }}
        activeFilter={activeFilterItem}
        filterList={totalsFilterList}
        defaultFilter={defaultFilter}
      />
    </div>
  );
};

export default Dashboard;
