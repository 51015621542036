import ProgressRing from "@atoms/progress-ring";
import ScrollableArea from "@atoms/scrollable-area";
import SpinnerLoader from "@atoms/spinner-loader";
import { DashboardModeButtonKeys } from "@constants/dashboard";
import PercentageBar from "@molecules/percentage-bar";
import Selectable from "@molecules/selectable";

type SaleTotal = {
  sold: number;
  available: number;
};
interface Props<E> {
  mode: string;
  totalSalesListing:
    | (SaleTotal & {
        items: (SaleTotal & { title: string })[];
      })
    | undefined;
  activeFilter?: E;
  onSelectFilter: (filter: E | null) => void;
  filterList: E[];
  defaultFilter: E;
}

function DashboardTotalSold<E extends { name: string; key: string }>({
  mode,
  totalSalesListing,
  filterList,
  activeFilter,
  onSelectFilter,
  defaultFilter,
}: Props<E>) {
  const getPercentageSold = (sold: number, total: number) => {
    if (total === 0) return 0;
    return Math.ceil((sold / total) * 100);
  };

  const getTotalSoldText = () => {
    const title = mode === DashboardModeButtonKeys.tickets ? "Total Tickets Sold" : "Total Collectables Sold";
    const suffix = !!activeFilter ? ` - ${activeFilter.name}` : " - All Time";
    return title + suffix;
  };

  const getFilterPlaceholder = () => {
    return mode === DashboardModeButtonKeys.tickets ? "Filter by event" : "Filter by collectable";
  };

  const renderProgressRing = () => {
    if (!totalSalesListing) return <SpinnerLoader />;
    const { sold: totalSold, available: totalAvailable } = totalSalesListing;
    return (
      <ProgressRing
        percentage={getPercentageSold(totalSold, totalAvailable)}
        size="lg"
        subtext={`${totalSold}/${totalAvailable}`}
      />
    );
  };

  const renderListing = () => {
    return (
      <ScrollableArea className="md:h-44 mt-8">
        {!totalSalesListing ? (
          <SpinnerLoader />
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-8 gap-x-6">
            {totalSalesListing.items.map(({ sold, available, title }) => {
              const footer = <p children={`Sold: ${sold}/${available}`} />;
              const label = title;
              return (
                <PercentageBar key={title} percentage={getPercentageSold(sold, available)} {...{ footer, label }} />
              );
            })}
          </div>
        )}
      </ScrollableArea>
    );
  };

  const onFilter = (opt: E | null) => {
    const val = opt?.key === defaultFilter.key ? null : opt;
    onSelectFilter(val);
  };

  return (
    <>
      <p className="text-white text-lg font-bold mt-9 mb-4 block md:hidden">{getTotalSoldText()}</p>
      <div className="bg-ebony rounded-lg pt-6 pb-12 md:mt-5">
        <div className="px-5 sm:px-6">
          <p className="text-white text-xl mb-12 font-bold hidden md:block">{getTotalSoldText()}</p>
          <div className="flex flex-col md:flex-row">
            <div className="w-[180px] aspect-square self-center md:self-auto">{renderProgressRing()}</div>
            <div className="mt-12 md:mt-0 md:ml-12 flex-1">
              <Selectable
                options={filterList}
                isSearchable
                placeholder={getFilterPlaceholder()}
                getOptionLabel={(o) => o.name}
                defaultValue={defaultFilter}
                value={activeFilter || defaultFilter}
                onChange={onFilter}
              />
              {renderListing()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardTotalSold;
