import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import useAuth from "@hooks/use-auth";
import SpinnerLoader from "@atoms/spinner-loader";

function DefaultRoutePredicate({
  errorRoute,
  inCompleteRoute,
  completeRoute,
}: {
  errorRoute: string;
  inCompleteRoute: string;
  completeRoute: string;
}) {
  const { getMyProfile, isCompleteProfile } = useAuth();
  const navigate = useNavigate();
  const redirect = (route: string) => navigate(route, { replace: true });

  const { isSuccess, data, isError, isLoading } = useQuery("myProfile", getMyProfile, {
    retry: false,
    onError: () => redirect(errorRoute),
  });

  if (isLoading) return <SpinnerLoader />;

  if (isSuccess) {
    if (!!data && isCompleteProfile(data)) redirect(completeRoute);
    else redirect(inCompleteRoute);
  }

  if (isError) redirect(errorRoute);

  return null;
}

export default DefaultRoutePredicate;
