import cn from "classnames";
import React, { ReactNode } from "react";

type Props<C, D> = {
  columns: C[];
  data: D[];
  renderColumnRow: (item: { column: C; row: D }) => ReactNode;
  renderHeader: (column: C) => ReactNode;
  keyExtractor: (info: { column?: C; row: D; header?: false } | { column: C; header: true }) => string;
};

function TableList<C extends { className?: string }, D>({
  columns,
  data,
  renderColumnRow,
  renderHeader,
  keyExtractor,
}: Props<C, D>) {
  return (
    <div className="table w-full border-separate" style={{ borderSpacing: "0 0.3rem" }}>
      <div className="table-header-group">
        <div className="table-row">
          {columns.map((column) => (
            <div
              key={keyExtractor({ column, header: true })}
              className={cn("items-center table-cell pb-6", column.className)}
            >
              {renderHeader(column)}
            </div>
          ))}
        </div>
      </div>
      <div className="table-row-group">
        {data.map((row) => (
          <div key={keyExtractor({ header: false, row })} className="table-row">
            {columns.map((column, idx, totalColumns) => (
              <div
                className={cn("table-cell align-middle bg-dark-grey", {
                  "rounded-l-lg": idx === 0,
                  "rounded-r-lg": idx + 1 === totalColumns.length,
                })}
                key={keyExtractor({ header: false, row, column })}
              >
                {renderColumnRow({ column, row })}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TableList;
