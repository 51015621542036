import Box from "@atoms/box";
import { displayDate } from "@utils/date";
import React from "react";
import { Props } from "./published-event-card.types";

function EventField({ label, field, className }: { label: string; field: string; className?: string }) {
  return (
    <div className={className}>
      <div className="text-grey text-sm">{label}</div>
      <span className="text-lg">{field}</span>
    </div>
  );
}

function MobileViewEventCard({ eventId, thumbnail, name, venue, date, onClickRow, selected }: Props) {
  const onClick = () => onClickRow(eventId);
  const className = selected ? "overflow-hidden outline outline-2 outline-bluer" : "overflow-hidden";
  return (
    <Box variant="dark-grey" padding="small" className={className}>
      <div className="flex items-center" onClick={onClick}>
        <div className="rounded-lg overflow-hidden aspect-square inline-block h-20 bg-layer-blue">
          {thumbnail ? (
            <img src={thumbnail} alt="event thumbnail" className="invisble h-full aspect-square object-cover" />
          ) : (
            <div className="flex h-full items-center text-center text-sm p-1 opacity-50">no image yet</div>
          )}
        </div>
        <EventField label="Event name" field={name} className="ml-2" />
        <EventField label="Event Venue" field={venue} className="w-48" />
        <EventField label="Event Date" field={displayDate(date)} />
      </div>
    </Box>
  );
}

export default MobileViewEventCard;
