import * as React from "react";

function Image(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M25.682 0H3.82A3.1 3.1 0 001.56 1a3.531 3.531 0 00-.936 2.41v21.433c0 .904.338 1.77.937 2.409a3.098 3.098 0 002.258.998h21.862c.846 0 1.658-.36 2.257-.998a3.527 3.527 0 00.936-2.409V3.411A3.53 3.53 0 0027.94 1 3.1 3.1 0 0025.682 0zm-6.79 5.072c.611 0 1.208.193 1.716.555.507.362.903.876 1.136 1.478.234.601.295 1.263.176 1.902a3.366 3.366 0 01-.845 1.686c-.431.46-.981.774-1.58.901a2.908 2.908 0 01-1.784-.187 3.139 3.139 0 01-1.385-1.213 3.486 3.486 0 01-.285-3.09c.155-.4.382-.762.67-1.068a3.082 3.082 0 011-.714 2.92 2.92 0 011.182-.25zm5.787 20.027H5.263a.823.823 0 01-.505-.071.894.894 0 01-.382-.36.98.98 0 01.04-1.038L9.71 12.44a.954.954 0 01.291-.452.845.845 0 01.979-.097c.154.088.28.222.364.386l5.325 7.425c.113.168.26.308.429.409a1.232 1.232 0 001.12.076c.18-.078.342-.197.474-.349l1.303-1.411c.128-.152.287-.27.465-.345a1.17 1.17 0 011.1.1c.164.105.302.25.405.423l3.373 5.14c.487.75.193 1.354-.66 1.354z"
        fill="currentcolor"
      />
    </svg>
  );
}

export default Image;
