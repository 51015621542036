import * as React from "react";
import { SVGProps } from "react";

const ChevronLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m6 11.5-5-5 5-5" stroke="currentcolor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ChevronLeft;
