import DocumentTitle from "@atoms/document-title";
import { LayoutTitle } from "@organisms/layout";

const ComingSoon = ({ title }: { title?: string }) => {
  return (
    <>
      {title && <LayoutTitle title={title} />}
      {title && <DocumentTitle titlePrefix={title} />}
      <div className="w-full flex items-center justify-center pt-48">
        <p className="text-4xl font-bold">Coming Soon.</p>
      </div>
    </>
  );
};

export default ComingSoon;
