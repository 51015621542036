import { Field, FieldProps } from "formik";
import TextField, { TextFieldBaseProps } from "@atoms/text-field";
import { TextInputProps } from "@atoms/text-input";

type Props = TextInputProps &
  TextFieldBaseProps & {
    name: string;
    minTime?: Date;
    maxTime?: Date;
  };

function FormikField({ name, ...props }: Props) {
  return (
    <Field {...{ name }}>
      {({ field, meta, form }: FieldProps) => {
        const error = !!meta.touched && meta.error;
        const setVal = (val: any) => form.setFieldValue(field.name, val);
        if (props.type === "time") {
          return <TextField {...{ ...props, ...field, error }} type="time" onChange={setVal} />;
        }
        if (props.type === "tel") {
          return <TextField {...{ ...props, ...field, error }} type="tel" onChange={(_, val) => setVal(val)} />;
        }
        return <TextField {...{ ...props, ...field, error }} />;
      }}
    </Field>
  );
}

export default FormikField;
