import { Seller } from "@api/auth";
import Modal from "@atoms/modal";
import SpinnerLoader from "@atoms/spinner-loader";
import useAuth from "@hooks/use-auth";
import SettingsForm from "@organisms/settings-form";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  mapProfileApiFieldsToForm,
  mapProfileFormFieldsToApi,
  profileFormValidation as validationSchema,
} from "./form-helpers";
import BadgeAlert from "@atoms/badge-alert";
import Button from "@atoms/button";

export interface SellerForm extends Seller {
  newCoverPhoto?: File;
  newThumbnail?: File;
}

const emptyInitialValues = {
  name: "",
  walletAddress: "",
  bio: "",
  coverPhotoUrl: "",
  newCoverPhoto: undefined,
  thumbnailUrl: "",
  newThumbnail: undefined,
  facebook: "",
  instagram: "",
  twitter: "",
  telegram: "",
  website: "",
  discord: "",
  supportEmail: "",
  supportPhone: "",
  e164Number: "",
  id: 0,
};

export type FormStatus = {
  INVALID_SUPPORT_PHONE: boolean;
  SUBMISSION_ATTEMPTED: boolean;
};

const defaultStatus: FormStatus = {
  INVALID_SUPPORT_PHONE: false,
  SUBMISSION_ATTEMPTED: false,
};

const Settings = () => {
  const [formError, setFormError] = useState<string | null>();
  const queryClient = useQueryClient();
  const [initialValues, setInitialValues] = useState<SellerForm>();
  const [initialStatus, setInitialStatus] = useState<FormStatus>(defaultStatus);
  const [selectedCountryOfBusiness, setSelectedCountryOfBusiness] = useState<string | null>(null);
  const {
    getMyProfile,
    updateProfile,
    isCompleteProfile,
    getAvailableTerritories,
    getStripeConnectUrl,
    getStripeDashboardUrl,
  } = useAuth();
  const {
    data: profile,
    isLoading,
    refetch: refetchProfile,
  } = useQuery("myProfile", getMyProfile, {
    onSuccess: (data) => {
      const profileData = mapProfileApiFieldsToForm(data);
      setInitialValues({ ...emptyInitialValues, ...profileData });
      setSelectedCountryOfBusiness(profileData.countryOfBusiness || null);
    },
  });

  const isProfileComplete = profile ? isCompleteProfile(profile) : false;
  const [showSuccess, setShowSuccess] = useState(false);
  const availableTerritoriesQuery = useQuery("availableTerritories", () => getAvailableTerritories());
  const update = useMutation(
    async function update(values: SellerForm) {
      const apiValues = await mapProfileFormFieldsToApi(values);
      return updateProfile(apiValues);
    },
    {
      onSuccess: (seller) => {
        refetchProfile();
        setShowSuccess(true);
        setInitialValues({ ...initialValues, ...mapProfileApiFieldsToForm(seller) });
        setInitialStatus({ ...initialStatus, INVALID_SUPPORT_PHONE: false });
        setSelectedCountryOfBusiness(seller.countryOfBusiness || null);
      },
      onError: (e: any) => setFormError(e.message),
    }
  );

  const onSubmit = (values: SellerForm) => {
    return update.mutateAsync({ ...values });
  };

  const onClickStripeButton = useMutation(
    () => (!profile?.stripeTransfersEnabled ? getStripeConnectUrl() : getStripeDashboardUrl()),
    { onSuccess: (url) => void window.open(url, "_blank") }
  );

  const onChangeCountryOfBusiness = useMutation(
    (countryCode: string) => {
      return updateProfile({ countryOfBusiness: countryCode });
    },
    {
      onSuccess: (r) => {
        const profileData = mapProfileApiFieldsToForm(r);
        setSelectedCountryOfBusiness(profileData.countryOfBusiness || null);
      },
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowSuccess(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [showSuccess]);

  if (isLoading || !initialValues) {
    return <SpinnerLoader />;
  }

  return (
    <Formik {...{ initialValues, initialStatus, onSubmit, validationSchema }} validateOnMount enableReinitialize>
      {({ handleSubmit, isValid, status, values, setTouched, setStatus, resetForm, ...formikBag }) => {
        const isSubmitting = formikBag.isSubmitting || update.isLoading;
        const handlePreSubmit = () => {
          setStatus({ ...status, SUBMISSION_ATTEMPTED: true });
          if (!(status as FormStatus).INVALID_SUPPORT_PHONE) return handleSubmit();
          const touchedKeys = Object.keys(values).reduce((accum, key) => ({ ...accum, [key]: true }), {});
          setTouched(touchedKeys, true);
        };
        return (
          <>
            <Modal variant="secondary" isVisible={!!formError} size="small" onClose={() => setFormError("")}>
              <p>{formError}</p>
            </Modal>
            <div className="flex flex-row py-2 space-x-4 mb-7">
              <div className="flex-1">
                {showSuccess ? (
                  <BadgeAlert variant="success" className="w-full h-full" message="Settings saved" />
                ) : !isValid && status.SUBMISSION_ATTEMPTED ? (
                  <BadgeAlert
                    variant="error"
                    className="w-full h-full"
                    onClose={() => {
                      setStatus({ ...status, SUBMISSION_ATTEMPTED: false });
                    }}
                    message={"You haven’t completed all of the mandatory fields. Please check and try again."}
                  />
                ) : !isProfileComplete ? (
                  <BadgeAlert
                    variant="warn"
                    message="You haven't completed all mandatory fields, some features will be limited"
                  />
                ) : null}
              </div>

              <Button variant={"primary"} onClick={handlePreSubmit} loading={isSubmitting}>
                Save
              </Button>
            </div>
            <SettingsForm
              initialCoverPhoto={initialValues.coverPhotoUrl}
              initialThumbnail={initialValues.thumbnailUrl}
              seller={profile}
              isLoading={isLoading}
              territories={availableTerritoriesQuery}
              onClickStripe={onClickStripeButton}
              onChangeBusinessCountry={onChangeCountryOfBusiness}
              selectedCountryOfBusiness={selectedCountryOfBusiness}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Settings;
