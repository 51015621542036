import * as React from "react";

function Calendar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.333 11.667A2.667 2.667 0 019 9h14a2.667 2.667 0 012.667 2.667V23A2.667 2.667 0 0123 25.667H9A2.667 2.667 0 016.333 23V11.667zM10.667 6.333V11M21.333 6.333V11M10.333 14.333h11.334"
        stroke="currentcolor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Calendar;
