import CustomersList, { Props as CustomerListingProps } from "@organisms/customers-list";

type Props = CustomerListingProps & {};

const Customers = ({ customers, ...props }: Props) => {
  const noCustomers = !customers || customers.length === 0;

  return (
    <>
      {!noCustomers && <CustomersList {...props} customers={customers} />}

      {noCustomers && (
        <div className="w-full h-full flex flex-col items-center pt-48">
          <p className="text-4xl font-bold mb-6">No Customers Listed</p>
        </div>
      )}
    </>
  );
};

export default Customers;
