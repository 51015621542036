import Button from "@atoms/button";
import BreadcrumbNavigation from "@molecules/breadcrumb-navigation";

export enum CreateCollectibleStageEnum {
  customise = "customise",
  royalties = "royalties",
}

type CreateCollectibleStage = CreateCollectibleStageEnum;

type Props = {
  activeStage?: CreateCollectibleStageEnum;
  onNext: () => void;
  onBack: () => void;
  onChange?: (stage?: CreateCollectibleStageEnum) => void;
  onPublish: () => void;
  publishEnabled: boolean;
  nextDisabled: boolean;
  nextLoading?: boolean;
  publishLoading?: boolean;
};

const crumbList: { key: CreateCollectibleStage; label: string; link: string }[] = [
  { key: CreateCollectibleStageEnum.customise, label: "Customise NFT", link: "customise" },
  // { key: CreateCollectibleStageEnum.royalties, label: "Royalties", link: "royalties" },
];

function CreateCollectibleBreadcrumbNavigation({
  activeStage,
  onNext,
  onBack,
  onPublish,
  publishLoading,
  nextLoading,
  nextDisabled,
  publishEnabled,
}: Props) {
  const canGoBack = activeStage !== crumbList[0].key;
  const nextText = activeStage === crumbList[crumbList.length - 1].key ? "Save As Draft" : undefined;
  const activeCrumbIndex = (!!activeStage && crumbList.findIndex(({ key }) => key === activeStage)) || 0;
  return (
    <div className="flex items-center">
      <div className="flex-1">
        <BreadcrumbNavigation
          listNav={crumbList}
          active={activeCrumbIndex}
          onBack={canGoBack ? onBack : undefined}
          nextButtonVariant="secondary"
          {...{ nextLoading, nextDisabled, nextText, onNext }}
        />
      </div>
      <Button
        onClick={onPublish}
        variant="primary"
        size="medium"
        className="ml-4"
        isDisabled={!publishEnabled}
        loading={publishLoading}
      >
        Publish
      </Button>
    </div>
  );
}

export default CreateCollectibleBreadcrumbNavigation;
