import { useRef } from "react";
import SVG from "@atoms/svg";
import Text from "@atoms/text";
import { ChevronDown } from "@atoms/icons";
import MenuList from "./menu-list";
import cx from "classnames";
import DropdownHeadless from "@molecules/dropdown-headless";
import { DropDownHeadlessProps, Option } from "@molecules/dropdown-headless";

type Props<O extends Option> = Pick<DropDownHeadlessProps<O>, "onChange" | "options"> & {
  activeOption: O;
  align: "left" | "right";
  containerClass?: string;
};

function DropDownSelect<O extends Option>({ options, activeOption, onChange, align, containerClass }: Props<O>) {
  const switcherContainerElRef = useRef<HTMLDivElement>(null);

  return (
    <DropdownHeadless {...{ options, onChange }}>
      {({ dropdown: { open }, handleToggle, optionsList }) => {
        const menuOptions = optionsList.map((o) => ({ ...o, active: o.label === activeOption.label }));
        return (
          <div ref={switcherContainerElRef} className={cx("relative w-full flex", containerClass)}>
            <div
              onClick={() => handleToggle(switcherContainerElRef)}
              className={"flex flex-1 items-center cursor-pointer px-3 py-2 justify-between bg-grey/10 rounded-md"}
            >
              <Text as="span" variant="heading3" className="text-grey font-bold select-none">
                {activeOption.label}
              </Text>
              <SVG icon={ChevronDown} size="xsmall" styles="text-white" />
            </div>
            <div className={open ? "mt-1" : undefined}>
              <MenuList open={open} options={menuOptions} align={align} />
            </div>
          </div>
        );
      }}
    </DropdownHeadless>
  );
}

export default DropDownSelect;
