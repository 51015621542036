import Button from "@atoms/button";
import Text from "@atoms/text";
import Box from "@atoms/box";
import { Collectible } from "@api/collectibles";
import { isObject } from "lodash";
import CollectibleCard from "@molecules/collectible-card/collectible-card";

type Props = {
  collectible: Collectible | undefined;
  onClickCreateNew: () => void;
};

function AirdropSelectCollectible({ collectible, onClickCreateNew }: Props) {
  return (
    <Box variant="grey">
      {isObject(collectible) && collectible.uuid.length > 0 ? (
        <div>
          <div className="mb-4">
            <Text variant="heading1">Item to Airdrop:</Text>
          </div>
          <div className="rounded-lg">
            <div className="collectible-airdrop">
              <CollectibleCard
                title={collectible.title}
                description={collectible.description}
                quantityAvailable={collectible.quantityAvailable}
                imageUrl={collectible.imageUrl}
                audioMp3Url={collectible.audioMp3Url}
                isLive={collectible.isLive}
                uuid={collectible.uuid}
                price={collectible.price}
                state={collectible.state}
                maxPerWallet={collectible.maxPerWallet}
                purchasable={collectible.purchasable}
                publiclyVisible={collectible.publiclyVisible}
                tokensRemaining={collectible.tokensRemaining}
                seller={collectible.seller}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col p-16 justify-center items-center cursor-pointer">
          <Text variant="heading2">Need to create a new collectable?</Text>
          <Text variant="heading3" className="text-grey-translucent-40 text-center w-full mt-4 px-6 mb-4">
            If you create a new collectable, you will be redirected away from this screen and have to re-select the
            event.
          </Text>
          <Button onClick={onClickCreateNew} variant="primary">
            Create new
          </Button>
        </div>
      )}
    </Box>
  );
}

export default AirdropSelectCollectible;
