import { useState, useMemo, useEffect } from "react";
import CalendarBase from "./calendar-base";
import { eachDayOfInterval, endOfDay, startOfDay } from "date-fns";
import { CalendarProps } from ".";

const SingleCalendar = ({ initialDate, selectedDates, onClickDay, ...props }: CalendarProps) => {
  const [currentView, setCurrentView] = useState(initialDate);
  const dates = useMemo(() => {
    if (!selectedDates) return [];
    const start = startOfDay(selectedDates[0]);
    const end = endOfDay(selectedDates[1] || selectedDates[0]);
    return eachDayOfInterval({ start, end });
  }, [selectedDates]);

  useEffect(() => {
    if (!selectedDates || !selectedDates.length) return;
    const lastPopulatedDate = selectedDates[selectedDates.length - 1];
    setCurrentView(lastPopulatedDate);
  }, [selectedDates]);

  return (
    <div className="inline-block bg-[#031629]">
      <div className="p-4">
        <CalendarBase
          month={currentView}
          onChangeMonth={setCurrentView}
          onDayClick={onClickDay}
          selectedDates={dates}
          {...props}
        />
      </div>
    </div>
  );
};

export default SingleCalendar;
