import useMediaQueries from "@hooks/use-media-queries";
import { Props } from "./published-event-card.types";
import PublishedEventTableRow from "./published-event-table-row";
import MobilePublishedEventCard from "./mobile-view-published-event-card";

function PublishedEventCard(props: Props) {
  const { isDesktopAndUp } = useMediaQueries();
  return isDesktopAndUp ? <PublishedEventTableRow {...props} /> : <MobilePublishedEventCard {...props} />;
}

export default PublishedEventCard;
