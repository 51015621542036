import useMediaQueries from "@hooks/use-media-queries";
import { Props } from "./airdrop-collectible-card.types";
import AirdropCollectibleTableRow from "./airdrop-collectible-table-row";
import MobileCollectibleCard from "./mobile-view-airdrop-collectible-card";

function AirdropCollectibleCard(props: Props) {
  const { isDesktopAndUp } = useMediaQueries();
  return isDesktopAndUp ? <AirdropCollectibleTableRow {...props} /> : <MobileCollectibleCard {...props} />;
}

export default AirdropCollectibleCard;
