import { format } from "date-fns";
import { useQuery } from "react-query";
import useCollectiblesApi from "./use-collectibles-api";
import useEvents from "./use-events";

interface DashboardProps {
  startDate?: Date;
  endDate?: Date;
  activeEventSlug?: string;
  activeCollectableUUID?: string;
}

function useDashboard({ startDate, endDate, activeEventSlug, activeCollectableUUID }: DashboardProps) {
  const eventsApi = useEvents();

  const ticketSalesQuery = useQuery(
    ["ticketSales", startDate, endDate],
    () => eventsApi.fetchTicketSales(format(startDate as Date, "yyyy-MM-dd"), format(endDate as Date, "yyyy-MM-dd")),
    { enabled: !!startDate && !!endDate, refetchOnWindowFocus: false }
  );
  const collectibleTokenSalesQuery = useQuery(
    ["collectibleTokenSales", startDate, endDate],
    () =>
      eventsApi.fetchCollectibleTokenSales(
        format(startDate as Date, "yyyy-MM-dd"),
        format(endDate as Date, "yyyy-MM-dd")
      ),
    { enabled: !!startDate && !!endDate, refetchOnWindowFocus: false }
  );
  const ticketSalesComparisonQuery = useQuery(
    ["ticketSalesComparison", startDate, endDate],
    () =>
      eventsApi.fetchTicketSalesComparison(
        format(startDate as Date, "yyyy-MM-dd"),
        format(endDate as Date, "yyyy-MM-dd")
      ),
    { enabled: !!startDate && !!endDate, refetchOnWindowFocus: false }
  );
  const collectibleTokenSalesComparisonQuery = useQuery(
    ["collectibleSalesComparison", startDate, endDate],
    () =>
      eventsApi.fetchCollectibleTokenSalesComparison(
        format(startDate as Date, "yyyy-MM-dd"),
        format(endDate as Date, "yyyy-MM-dd")
      ),
    { enabled: !!startDate && !!endDate, refetchOnWindowFocus: false }
  );
  const newEventsQuery = useQuery(
    ["newEvents", startDate, endDate],
    () => eventsApi.fetchNewEvents(format(startDate as Date, "yyyy-MM-dd"), format(endDate as Date, "yyyy-MM-dd")),
    { enabled: !!startDate && !!endDate, refetchOnWindowFocus: false }
  );
  const newCollectiblesQuery = useQuery(
    ["newCollectibles", startDate, endDate],
    () =>
      eventsApi.fetchNewCollectibles(format(startDate as Date, "yyyy-MM-dd"), format(endDate as Date, "yyyy-MM-dd")),
    { enabled: !!startDate && !!endDate, refetchOnWindowFocus: false }
  );
  const newTicketOrdersQuery = useQuery(
    ["newTicketOrders", startDate, endDate],
    () =>
      eventsApi.fetchNewTicketOrders(format(startDate as Date, "yyyy-MM-dd"), format(endDate as Date, "yyyy-MM-dd")),
    { enabled: !!startDate && !!endDate, refetchOnWindowFocus: false }
  );
  const newCollectibleOrdersQuery = useQuery(
    ["newCollectibleOrders", startDate, endDate],
    () =>
      eventsApi.fetchNewCollectibleOrders(
        format(startDate as Date, "yyyy-MM-dd"),
        format(endDate as Date, "yyyy-MM-dd")
      ),
    { enabled: !!startDate && !!endDate, refetchOnWindowFocus: false }
  );
  const newTicketCustomersQuery = useQuery(
    ["newTicketCustomers", startDate, endDate],
    () =>
      eventsApi.fetchNewTicketCustomers(format(startDate as Date, "yyyy-MM-dd"), format(endDate as Date, "yyyy-MM-dd")),
    { enabled: !!startDate && !!endDate, refetchOnWindowFocus: false }
  );
  const newCollectibleCustomersQuery = useQuery(
    ["newCollectibleCustomers", startDate, endDate],
    () =>
      eventsApi.fetchNewCollectibleCustomers(
        format(startDate as Date, "yyyy-MM-dd"),
        format(endDate as Date, "yyyy-MM-dd")
      ),
    { enabled: !!startDate && !!endDate, refetchOnWindowFocus: false }
  );
  const allEventSalesQuery = useQuery(["eventSales"], () => eventsApi.fetchAllEventSales(), {
    refetchOnWindowFocus: false,
  });
  const allCollectibleSalesQuery = useQuery(["collectibleSales"], () => eventsApi.fetchCollectibleSales(), {
    enabled: !!startDate && !!endDate,
    refetchOnWindowFocus: false,
  });
  const eventTicketSalesQuery = useQuery(
    ["eventTicketSales", { slug: activeEventSlug }],
    () => {
      if (!activeEventSlug) throw new Error("active event must be selected to fetch ticket sales");
      return eventsApi.fetchTicketSalesForEvent(activeEventSlug);
    },
    { enabled: !!activeEventSlug }
  );

  const singleCollectableSale = (() => {
    if (!activeCollectableUUID || !allCollectibleSalesQuery.isSuccess) return undefined;
    return allCollectibleSalesQuery.data?.result.collectibles.find(({ uuid }) => uuid === activeCollectableUUID);
  })();

  const newEvents = newEventsQuery.isSuccess ? newEventsQuery.data.result : undefined;
  const newCollectibles = newCollectiblesQuery.isSuccess ? newCollectiblesQuery.data.result : undefined;
  const newTicketOrders = newTicketOrdersQuery.isSuccess ? newTicketOrdersQuery.data.result : undefined;
  const newCollectibleOrders = newCollectibleOrdersQuery.isSuccess ? newCollectibleOrdersQuery.data.result : undefined;
  const newTicketCustomers = newTicketCustomersQuery.isSuccess ? newTicketCustomersQuery.data.result : undefined;
  const newCollectibleCustomers = newCollectibleCustomersQuery.isSuccess
    ? newCollectibleCustomersQuery.data.result
    : undefined;
  const collectibleTokenSales = collectibleTokenSalesQuery.isSuccess
    ? collectibleTokenSalesQuery.data.result
    : undefined;
  const collectibleTokenSalesPercentChange = collectibleTokenSalesComparisonQuery.isSuccess
    ? collectibleTokenSalesComparisonQuery.data.result.sales_count_percentage_change
    : undefined;
  const ticketSales = ticketSalesQuery.isSuccess ? ticketSalesQuery.data.result : undefined;
  const ticketSalesPercentChange = ticketSalesComparisonQuery.isSuccess
    ? ticketSalesComparisonQuery.data.result.sales_count_percentage_change
    : undefined;
  const allEventSales = allEventSalesQuery.isSuccess ? allEventSalesQuery.data.result : undefined;
  const allCollectibleSales = allCollectibleSalesQuery.isSuccess ? allCollectibleSalesQuery.data.result : undefined;

  return {
    collectibleTokenSales,
    collectibleTokenSalesPercentChange,
    ticketSales,
    ticketSalesPercentChange,
    newEvents,
    newCollectibles,
    newTicketOrders,
    newCollectibleOrders,
    newTicketCustomers,
    newCollectibleCustomers,
    allEventSales,
    allCollectibleSales,
    eventTicketSalesQuery,
    singleCollectableSale: singleCollectableSale ? [singleCollectableSale] : undefined,
  };
}

export default useDashboard;
