import { useMutation } from "react-query";
import useAuth from "@hooks/use-auth";
import EventsApi, { UpdateEventParams } from "@api/events";
import TicketsApi, { CreateTicketParams } from "@api/tickets";
import { useCallback, useMemo } from "react";

type UpdateEventArgs = UpdateEventParams & {
  ticketTypes?: CreateTicketParams[];
};

function useEvents() {
  const { checkUsername, token } = useAuth();

  const eventsApi = useMemo(() => new EventsApi(token || ""), [token]);
  const ticketsApi = useMemo(() => new TicketsApi(token || ""), [token]);

  const updateEvent = useCallback(
    (eventSlug: string, { ticketTypes, ...occasion }: UpdateEventArgs) => {
      const ticketMutations = ticketTypes
        ? Promise.all(
            ticketTypes.map(({ uuid, ...ticket }) => {
              if (uuid) return ticketsApi.updateTicketType(uuid, ticket);
              else return ticketsApi.createTicketType(eventSlug, ticket);
            })
          )
        : Promise.resolve();

      return ticketMutations.then(() => eventsApi.updateEvent(eventSlug, occasion));
    },
    [ticketsApi, eventsApi]
  );

  const verifyWalletAddress = useCallback(
    async (walletAddress: string) => {
      try {
        const username = walletAddress.match(/.*(?=\.(testnet|near)$)/)?.[0];
        if (!username) return false;
        const response = await checkUsername(username);
        return !response.result.available;
      } catch (e: any) {
        throw new Error(e);
      }
    },
    [checkUsername]
  );

  const deleteTicketType = useMutation(ticketsApi.deleteTicketType);

  return {
    createEvent: eventsApi.createEvent,
    updateEvent,
    fetchMyEvents: eventsApi.getAllEvents,
    fetchMyPublishedEvents: eventsApi.getAllPublishedEvents,
    fetchTicketSales: ticketsApi.getTicketSales,
    fetchAllEventSales: eventsApi.getAllEventSales,
    fetchCollectibleSales: eventsApi.getAllCollectibleSales,
    fetchNewEvents: eventsApi.getNewEvents,
    fetchNewCollectibles: eventsApi.getNewCollectibles,
    fetchNewTicketOrders: ticketsApi.getNewTicketOrders,
    fetchNewCollectibleOrders: eventsApi.getNewCollectibleOrders,
    fetchNewTicketCustomers: ticketsApi.getNewTicketCustomers,
    fetchNewCollectibleCustomers: eventsApi.getNewCollectibleCustomers,
    fetchTicketSalesComparison: ticketsApi.getTicketSalesComparison,
    fetchCollectibleTokenSales: eventsApi.getCollectibleTokenSales,
    fetchCollectibleTokenSalesComparison: eventsApi.getCollectibleTokenSalesComparison,
    fetchEventBySlug: eventsApi.getEventBySlug,
    publishEvent: eventsApi.publishEvent,
    getPreviewImage: eventsApi.getPreviewImage,
    deleteTicketType,
    verifyWalletAddress,
    fetchTicketSalesForEvent: ticketsApi.getTicketTypeSalesForEvent,
  };
}

export default useEvents;
