import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { OrderListProps } from "./order-list.types";
import OrderCard, { gridColClasses } from "@molecules/order-card";
import Text from "@atoms/text";
import useMediaQueries from "@hooks/use-media-queries";
import { Ticket } from "@api/tickets";
import { CollectibleOrder } from "@api/collectibles";
import { OrdersModeButtonKeys } from "@constants/orders";

export type OrderObject = {
  id: number;
  tokenId: string | null;
  thumbnail?: string;
  username?: string;
  purchasedAt: Date;
  price?: number | null;
  status: string;
};

const OrderList = ({ orders, mode }: OrderListProps): JSX.Element => {
  const navigate = useNavigate();
  const { isDesktopAndUp } = useMediaQueries();

  const headers = ["thumb", "Token ID", "Customer", "Order Date", "Last Sale Price", "Status"];
  const renderTableHeader = (header: string) => (
    <div className={cn("flex flex-1 items-center")} key={header}>
      {header === "thumb" ? (
        <div className="w-16" />
      ) : (
        <Text variant="heading3" as="p" className="text-sm text-grey">
          {header}
        </Text>
      )}
    </div>
  );

  return (
    <div className="mb-8">
      {isDesktopAndUp && (
        <div className={cn(gridColClasses, "mb-5")}>{headers.map((header) => renderTableHeader(header))}</div>
      )}

      {orders.map((item) => {
        let orderObj;
        if (mode === OrdersModeButtonKeys.tickets) {
          orderObj = {
            id: item.id,
            tokenId: item.tokenId,
            thumbnail: (item as Ticket).ticketType.baseImageUrl,
            username: (item as Ticket).owner?.walletAddress,
            purchasedAt: (item as Ticket).purchasedAt,
            price: (item as Ticket).ticketType.price || 0,
            status: (item as Ticket).status,
          };
        } else {
          const collectible = (item as CollectibleOrder).collectible;
          orderObj = {
            id: item.id,
            tokenId: item.tokenId,
            thumbnail: collectible.imageUrl,
            username: (item as CollectibleOrder).owner?.walletAddress,
            purchasedAt: (item as CollectibleOrder).purchasedAt,
            price: collectible.price,
            status: (item as CollectibleOrder).status,
          };
        }
        return (
          <div className="mt-3" key={item.id}>
            <OrderCard order={orderObj} onView={() => navigate(`/orders/${item.id}`)} />
          </div>
        );
      })}
    </div>
  );
};

export default OrderList;
