import DocumentTitle from "@atoms/document-title";
import Button from "@atoms/button";
import { LogoFull } from "@atoms/icons";
import Warning from "@assets/warning.png";
import SuccessCheck from "@assets/success_check.png";
import { useNavigate } from "react-router-dom";
import { SellerConfirmEmail } from "@api/auth";
import useAuth from "@hooks/use-auth";
import { useMemo, useState } from "react";
import SpinnerLoader from "@atoms/spinner-loader";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { confirmEmail, resendConfirmEmail } = useAuth();
  const [successfullyConfirmed, setSuccessfullyConfirmed] = useState("loading");

  const onClickFinish = () => {
    navigate("/settings", { replace: true });
  };

  const onClickResend = () => {
    const params = new URLSearchParams(window.location.search);
    const initialValues: { email: string } = {
      email: params.get("email") || "",
    };
    resendConfirmEmail(initialValues).then((fail) => {
      if (fail) {
        setSuccessfullyConfirmed("fail");
      }
    });
  };

  useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const initialValues: SellerConfirmEmail = {
      id: params.get("id") || "",
      hash: params.get("hash") || "",
      email: params.get("email") || "",
    };
    confirmEmail(initialValues).then((success) => {
      setSuccessfullyConfirmed(success ? "success" : "fail");
    });
  }, [confirmEmail]);

  return (
    <>
      <div className="border-b border-dark-grey flex items-center w-full h-24 px-4 md:px-10">
        <LogoFull className="h-8" />
      </div>
      <div className="text-white relative p-4 flex flex-col items-center justify-center h-full overflow-hidden">
        {successfullyConfirmed === "success" && (
          <>
            <DocumentTitle titlePrefix="Verify your email" />
            <div className=" items-center justify-center w-1/3">
              <div className="items-center justify-center w-full text-center">
                <div className="items-center mb-8">
                  <img alt="Warning" src={SuccessCheck} className="mx-auto" />
                </div>
                <h1 className="text-3xl mb-8 text-center z-30 font-bold">Verification Complete</h1>
                <p className="mb-8">Your email has been successfully verified!</p>
                <div className="w-full flex items-center justify-center">
                  <Button type="button" isFullWidth onClick={onClickFinish}>
                    Finish your setup
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
        {successfullyConfirmed === "fail" && (
          <>
            <DocumentTitle titlePrefix="Something went wrong confirming your email" />
            <div className=" items-center justify-center w-1/3">
              <div className="items-center justify-center w-full text-center">
                <div className="items-center mb-4">
                  <img alt="Warning" src={Warning} className="mx-auto" />
                </div>
                <h1 className="text-3xl mb-3 text-center z-30 font-bold">Something went wrong</h1>
                <p className="mb-4">
                  To try again to verify your email, click on the link below and we will send another email
                </p>
                <p className="mb-4">Still can’t find the email?</p>
                <div className="w-full flex items-center justify-center">
                  <Button type="button" isFullWidth onClick={onClickResend}>
                    Resend Verification Email
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
        {successfullyConfirmed === "loading" && <SpinnerLoader />}
      </div>
    </>
  );
};

export default VerifyEmail;
