import DocumentTitle from "@atoms/document-title";
import SpinnerLoader from "@atoms/spinner-loader";
import { OrdersModeButtonKeys } from "@constants/orders";
import useCollectiblesApi from "@hooks/use-collectibles-api";
import useTicketsApi from "@hooks/use-tickets-api";
import ButtonToggle from "@molecules/button-toggle";
import PaginationButtons from "@molecules/pagination-buttons";
import { LayoutTitle } from "@organisms/layout";
import OrderList from "@organisms/order-list";
import { useState } from "react";
import { useQuery } from "react-query";

const toggleModeButtons = [
  { label: "Tickets", key: OrdersModeButtonKeys.tickets },
  { label: "Collectables", key: OrdersModeButtonKeys.collectables },
];

const perPage = 5;

function Orders() {
  const [page, setPage] = useState(1);
  const [mode, setMode] = useState<string>(OrdersModeButtonKeys.tickets);
  const ticketsApi = useTicketsApi();
  const collectiblesApi = useCollectiblesApi();
  const ticketsQuery = useQuery(["ticketOrders", { page, perPage }], () =>
    ticketsApi.getTicketOrders({ page, perPage })
  );
  const collectiblesQuery = useQuery(["collectibleOrders", { page, perPage }], () =>
    collectiblesApi.getCollectibleOrders({ page, perPage })
  );
  const activeQuery = mode === OrdersModeButtonKeys.tickets ? ticketsQuery : collectiblesQuery;
  const onSwitchMode = (newMode: string) => {
    setMode(newMode);
    setPage(1);
  };

  return (
    <>
      <LayoutTitle title="Orders" />
      <DocumentTitle
        titlePrefix="Orders - Manage your SeatlabNFT orders"
        description="Keep track of your orders, manage your sales, view customer transaction history, monitor purchases, and get insights into your buyers' behaviour."
      />
      <ButtonToggle className="mb-4" buttons={toggleModeButtons} onToggle={onSwitchMode} activeKey={mode} />
      {activeQuery.isSuccess ? (
        <>
          {activeQuery.data.result.length > 0 ? (
            <>
              <OrderList mode={mode} orders={activeQuery.data.result} />
              {activeQuery.data.lastPage > 1 && (
                <div className="flex flex-row justify-center my-8">
                  <PaginationButtons activePage={page} setPage={setPage} pages={activeQuery.data.lastPage} />
                </div>
              )}
            </>
          ) : (
            <div className="w-full flex flex-col items-center pt-48">
              <p className="text-4xl font-bold mb-6">No orders yet 😔</p>
            </div>
          )}
        </>
      ) : activeQuery.isLoading ? (
        <SpinnerLoader />
      ) : null}
    </>
  );
}

export default Orders;
