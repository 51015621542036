import * as React from "react";

function Copy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.5 15.25a1.75 1.75 0 01-1.75-1.75V6.75a2 2 0 012-2h6.75c.966 0 1.75.784 1.75 1.75"
        stroke="currentcolor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 10.75a2 2 0 012-2h6.5a2 2 0 012 2v6.5a2 2 0 01-2 2h-6.5a2 2 0 01-2-2v-6.5z"
        stroke="currentcolor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Copy;
