import Button from "@atoms/button";
import classNames from "classnames";
import cn from "classnames";

interface ButtonProps<K> {
  label: string;
  key: K;
}

interface Props<K> {
  buttons: Array<ButtonProps<K>>;
  onToggle: (key: K) => void;
  activeKey: K;
  className?: string;
}

function ButtonToggle<K>({ buttons, onToggle, activeKey, className }: Props<K>) {
  const getVariant = (selected: boolean) => {
    if (selected) return "primary";
    else return "secondary";
  };

  const getClassName = (index: number, selected: boolean) => {
    const isLastButton = index === buttons.length - 1;
    const isFirstButton = index === 0;
    let className = cn({
      "mr-0.5": !isLastButton,
      "rounded-r-none": isFirstButton,
      "rounded-l-none": isLastButton,
      "rounded-none": !isFirstButton && !isLastButton,
      "text-grey": !selected,
    });
    return className;
  };

  return (
    <div className={classNames("flex flex-row", className)}>
      {buttons.map((button, index) => {
        const selected = button.key === activeKey;
        return (
          <Button
            className={getClassName(index, selected)}
            key={index}
            variant={getVariant(selected)}
            onClick={() => onToggle(button.key)}
            size="medium"
          >
            {button.label}
          </Button>
        );
      })}
    </div>
  );
}

export default ButtonToggle;
