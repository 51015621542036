export const displayCurrency = (number: number, format = "en-GB", currency = "GBP"): string => {
  const formatter = new Intl.NumberFormat(format, {
    style: "currency",
    currency,
  });

  return formatter.format(number);
};

// Converts pennies value into pounds/dollars, rounded to 2 d.p.
// Returns a number.
// e.g. 9500 -> 95.00
export const priceFormatter = (price: number | string): number => {
  return Math.round((Number(price) / 100) * 1e2) / 1e2;
};

export const isValidDecimalValue = (value: string, decimalPlaces: number): boolean => {
  return new RegExp(`^\\d+(\\.\\d{0,${decimalPlaces}})?$`).test(value);
};
