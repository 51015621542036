import cn from "classnames";
import Box from "@atoms/box";
import { displayDate } from "@utils/date";
import { trimHash } from "@utils/hash";
import { displayCurrency } from "@utils/number";
import OrderStatusPill from "./order-status-pill";
import { Props } from "./order-card.types";
import useAuth from "@hooks/use-auth";

function EventField({ label, field, className }: { label: string; field: string; className?: string }) {
  return (
    <div className={className}>
      <div className="text-grey text-sm">{label}</div>
      <span className="text-lg">{field}</span>
    </div>
  );
}

function MobileViewOrderCard({ order: { tokenId, status, username, thumbnail, purchasedAt, price }, onView }: Props) {
  const { currency } = useAuth();
  const rowSpacing = "mt-4 md:mt-0";
  return (
    <Box variant="dark-grey" padding="small" className="overflow-hidden">
      <div className="items-center">
        <div className="md:grid md:grid-cols-2 items-center">
          <div className="flex items-center">
            <div className="rounded-lg overflow-hidden aspect-square inline-block h-20 bg-layer-blue">
              {thumbnail ? (
                <img src={thumbnail} alt="ticket thumbnail" className="invisble h-full aspect-square object-cover" />
              ) : (
                <div className="flex h-full items-center text-center text-sm p-1 opacity-50">no image yet</div>
              )}
            </div>
            <EventField label="Ticket ID" field={!!tokenId ? `#${trimHash(tokenId)}` : "-"} className="ml-2" />
          </div>
          <div className={cn(rowSpacing, "inline-grid grid-cols-2 gap-8 md:gap-0")}>
            <EventField label="Order date" field={displayDate(new Date(purchasedAt))} />
            <EventField
              label="Last sale price"
              field={displayCurrency(price || 0, "en-GB", currency)}
            />
          </div>
        </div>

        <div className="md:grid md:grid-cols-2 md:mt-4">
          <div className={rowSpacing}>
            <EventField label="Customer" field={username ? username : "-"} className="w-48" />
          </div>
          <div className={cn(rowSpacing, "flex justify-between items-center")}>
            <div className="flex-1">
              <p className="text-sm mb-1">Status</p>
              <OrderStatusPill status={status} />
            </div>
            {/* <div className="flex text-sm space-x-4 md:flex-1">
              <div className="text-center flex items-center flex-col cursor-pointer" onClick={onView}>
                <SVG icon={View} size="medium" />
                <div>View</div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Box>
  );
}

export default MobileViewOrderCard;
