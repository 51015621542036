import { Collectible } from "@api/collectibles";
import Button from "@atoms/button";
import DocumentTitle from "@atoms/document-title";
import SearchBar from "@atoms/search-bar";
import SpinnerLoader from "@atoms/spinner-loader";
import useCollectiblesApi from "@hooks/use-collectibles-api";
import useListFilter from "@hooks/use-list-filter";
import PaginationButtons from "@molecules/pagination-buttons";
import CollectiblesList from "@organisms/collectibles-list";
import { LayoutTitle } from "@organisms/layout";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

const perPage = 6;

function Collectibles() {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const collectiblesApi = useCollectiblesApi();
  const collectiblesQuery = useQuery(["sellerCollectibles", { page, perPage }], () =>
    collectiblesApi.fetchAllCollectibles({ page, perPage })
  );
  const collectibles = collectiblesQuery.isSuccess ? collectiblesQuery.data.result : [];
  const noCreatedCollectibles = collectiblesQuery.isSuccess && !collectibles.length;
  const {
    filteredItems: filteredCollectibles,
    setSearchValue,
    searchValue,
  } = useListFilter({ items: collectibles, filterFn: (terms, { title }) => title.toLowerCase().indexOf(terms) >= 0 });

  const onEditCollectible = ({ uuid }: Collectible) => navigate(`/collectables/${uuid}`);
  const onViewCollectible = ({ uuid }: Collectible) => {
    const seatlabNFTDomain = process.env.REACT_APP_MARKETPLACE_DOMAIN;
    if (!seatlabNFTDomain) throw new Error("ENV_DOMAIN not set");
    window.open(`${seatlabNFTDomain}/collectables/${uuid}`, "collectibleDetail");
  };
  const onCreateNewCollectibke = () => navigate(`/collectables/create`);

  return (
    <>
      <DocumentTitle
        titlePrefix="Collectables - Create unique NFT collectables"
        description="Create unique NFT collectables to enhance the experience of attending your events. From digital artwork to claimable items, collectables are a powerful engagement tool."
      />
      <LayoutTitle title="Collectables" />
      {collectiblesQuery.isSuccess ? (
        <>
          {!noCreatedCollectibles ? (
            <>
              <div className="flex justify-between mb-16">
                <div className="flex w-full max-w-sm pr-2">
                  <SearchBar
                    placeholder="Search by collectable title"
                    id="scanner-search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
                <Button onClick={onCreateNewCollectibke} variant="primary" size="large">
                  Create NFT
                </Button>
              </div>
              <div className="mt-8">
                <CollectiblesList
                  collectibles={filteredCollectibles}
                  onEdit={onEditCollectible}
                  onView={onViewCollectible}
                />
              </div>
            </>
          ) : (
            <div className="w-full h-full flex flex-col items-center pt-48">
              <p className="text-4xl font-bold mb-6">No Collectables Listed</p>
              <Button onClick={onCreateNewCollectibke} variant="primary" size="large">
                Create Your First Collectable
              </Button>
            </div>
          )}
          {collectiblesQuery.data.last_page > 1 && (
            <div className="flex flex-row justify-center my-8">
              <PaginationButtons activePage={page} setPage={setPage} pages={collectiblesQuery.data.last_page} />
            </div>
          )}
        </>
      ) : (
        <SpinnerLoader />
      )}
    </>
  );
}

export default Collectibles;
