import classNames from "classnames";
import React, { ReactNode } from "react";
import ReactSelect, { Props as ReactSelectProps } from "react-select";

type Props<O> = Omit<ReactSelectProps<O, false>, "options" | "noOptionsMessage" | "isOptionSelected" | "classNames"> & {
  options: O[];
  onSelect?: (option: O | null) => void;
  noOptionsMessage?: ReactNode;
};

function Selectable<O extends { key: string; label?: string }>({ noOptionsMessage, onSelect, ...props }: Props<O>) {
  return (
    <ReactSelect
      {...props}
      unstyled
      noOptionsMessage={noOptionsMessage ? () => noOptionsMessage : undefined}
      classNames={{
        control: (state) =>
          classNames("bg-grey/10 text-grey rounded-md p-4", {
            ring: state.isFocused,
            "opacity-50": props.isDisabled,
          }),
        menu: () => "bg-dark-grey text-grey rounded-md mt-2 overflow-hidden drop-shadow-lg",
        noOptionsMessage: () => "p-8",
        option: ({ getValue, data }) => {
          const isSelected = data.key === getValue()[0]?.key;
          return classNames("px-4 py-2", {
            "bg-gradient-to-r from-bluer to-blue text-white": isSelected,
            "hover:bg-gradient-to-r hover:text-white hover:from-bluer/50 hover:to-blue/50": !isSelected,
          });
        },
      }}
    />
  );
}

export default Selectable;
