import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as nearApi from "near-api-js";
import { NearContext, NearConfigType } from "./near-context";
import { ConnectConfig } from "near-api-js";
import AuthApi from "@api/auth";

type CurrentUser = {
  accountId: string;
  balance: string | undefined;
};

type CurrentEmailUser = {
  email: string;
  name: string;
  walletAddress: string;
};

export type FetchState = "initial" | "pending" | "complete";

export type AuthContextParams = {
  keyStore: nearApi.keyStores.BrowserLocalStorageKeyStore;
  nearConfig: NearConfigType;
  currentUser?: CurrentUser;
  currentEmailUser?: CurrentEmailUser;
  walletConnection?: nearApi.WalletConnection;
  token?: string;
  formError?: string;
  setToken: (token?: string) => void;
  setCurrentUser: (user?: CurrentUser) => void;
  setCurrentEmailUser: (emailUser?: CurrentEmailUser) => void;
  setFormError: (formError?: string) => void;
  currency: string;
  currencySymbol: string;
};

type ContextProviderProps = { noInit?: boolean; children: (fetchState: FetchState) => ReactNode };

const JWTStorageKey = "Seatlabnft/sellerDashboard/JSON_WEB_TOKEN";
export const AuthContext = createContext<AuthContextParams | Record<string, never>>({});
export const keyStore = new nearApi.keyStores.BrowserLocalStorageKeyStore();

export async function extractUserDetailsFromAccount(account: nearApi.ConnectedWalletAccount) {
  const accountId = account.accountId; // Get the Account ID. If still unauthorized, it's just empty string
  const { amount: balance } = await (accountId ? account.state() : {});
  return accountId ? { accountId, balance } : undefined;
}

export function AuthContextProvider({ children, noInit = false }: ContextProviderProps) {
  const [walletConnection, setWalletConnection] = useState<nearApi.WalletConnection>();
  const [currentUser, setCurrentUser] = useState<CurrentUser>();
  const [currentEmailUser, setCurrentEmailUser] = useState<CurrentEmailUser>();
  const [formError, setFormError] = useState<string>();
  const [fetchState, setFetchState] = useState<FetchState>("initial");
  const [jsonWebToken, setJsonWebToken] = useState<string>();
  const [currency, setCurrency] = useState<string>("GBP");
  const [currencySymbol, setCurrencySymbol] = useState<string>("£");
  const contractConfig = useContext(NearContext);

  const setToken = useMemo(
    () => (token?: string) => {
      if (token) window.localStorage.setItem(JWTStorageKey, token);
      else window.localStorage.removeItem(JWTStorageKey);

      setJsonWebToken(token);
    },
    []
  );

  const setEmailUser = useMemo(
    () => async (token?: string) => {
      const authApi = new AuthApi(token);
      await authApi
        .getMyProfile()
        .then((seller) => {
          const userDetails: CurrentEmailUser = {
            email: seller.email || "",
            name: seller.name || "",
            walletAddress: seller.walletAddress || "",
          };
          setCurrentEmailUser(userDetails);
          setCurrency(seller.currency || "GBP");
          setCurrencySymbol(seller.currencySymbol || "£");
        })
        .catch((reason) => {});
    },
    []
  );

  useEffect(() => {
    async function setupWalletConnection() {
      const near = await nearApi.connect({ ...(contractConfig?.config as ConnectConfig) });
      const walletConnection = new nearApi.WalletConnection(near, null);
      return walletConnection;
    }

    if (!!contractConfig) {
      if (noInit) return;
      setFetchState("pending");
      const jwtFromStorage = window.localStorage.getItem(JWTStorageKey);
      if (jwtFromStorage) setToken(jwtFromStorage);

      setupWalletConnection()
        .then(async (walletConnection) => {
          setWalletConnection(walletConnection);
          const details = await extractUserDetailsFromAccount(walletConnection.account());
          return !!jwtFromStorage ? details : undefined;
        })
        .then(async (currentUser) => {
          setCurrentUser(currentUser);
          if (jwtFromStorage) {
            await setEmailUser(jwtFromStorage).then(() => {
              setFetchState("complete");
            });
          } else {
            setFetchState("complete");
          }
        });
    }
  }, [noInit, contractConfig, setToken, setEmailUser]);

  return (
    <AuthContext.Provider
      value={{
        keyStore,
        nearConfig: contractConfig?.config as NearConfigType,
        currentUser,
        currentEmailUser,
        walletConnection,
        token: jsonWebToken,
        formError,
        setToken,
        setCurrentUser,
        setCurrentEmailUser,
        setFormError,
        currency,
        currencySymbol,
      }}
    >
      {children(fetchState)}
    </AuthContext.Provider>
  );
}
