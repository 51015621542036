import React, { ComponentType, ElementType, HTMLAttributes } from "react";
import cx from "classnames";

export enum TextVariant {
  heading1 = "heading1",
  heading2 = "heading2",
  heading3 = "heading3",
  heading4 = "heading4",
}

type Props<P> = P & {
  as?: any;
  variant: keyof typeof TextVariant | TextVariant;
  className?: string;
  children: React.ReactNode;
};

function getFontWeightClass(variant: keyof typeof TextVariant) {
  switch (variant) {
    case TextVariant.heading1:
      return "font-bold";
    case TextVariant.heading2:
      return "font-normal";
    case TextVariant.heading3:
      return "font-medium";
    case TextVariant.heading4:
    default:
      return "font-normal";
  }
}

function getFontSizeClass(variant: keyof typeof TextVariant) {
  switch (variant) {
    case TextVariant.heading1:
      return "text-2xl";
    case TextVariant.heading3:
      return "text-sm";
    case TextVariant.heading4:
      return "text-xs";
    case TextVariant.heading2:
    default:
      return "text-base";
  }
}

function Text<P = HTMLAttributes<HTMLElement>>({ as: Component = "span", variant, className, ...props }: Props<P>) {
  const fontWeight = getFontWeightClass(variant);
  const fontSize = getFontSizeClass(variant);
  const classes = cx(fontWeight, fontSize, className);

  if (Component === null) return null;

  return <Component {...props} className={classes} />;
}

export default Text;
