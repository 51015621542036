import Pill from "@atoms/pill";
import Text from "@atoms/text";
import SVG from "@atoms/svg";
import cn from "classnames";
import { ComponentType, forwardRef, SVGProps } from "react";
import useMediaQueries from "@hooks/use-media-queries";

type Props = {
  label: string;
  icon: ComponentType<SVGProps<SVGSVGElement>>;
  onClick?: () => void;
  badgeLabel?: string;
  isActive?: boolean;
};

const MenuNavItem = forwardRef<any, Props>(({ badgeLabel, icon, label, isActive, onClick }, ref) => {
  const { isDesktopAndUp } = useMediaQueries();
  const color = isActive ? "text-white" : "text-grey/50";

  return (
    <span
      ref={ref}
      className={cn("flex flex-row py-3 relative cursor-pointer hover:text-white", color)}
      onClick={onClick}
    >
      <span className="inline-flex items-center justify-between w-full">
        <span className="flex flex-row items-center gap-4">
          {isActive && isDesktopAndUp && (
            <span className="h-full w-1 absolute left-0 align-middle bg-white rounded-lg" />
          )}
          <SVG icon={icon} size="xxlarge" styles="ml-2" />
          <Text variant="heading2" as="p">
            {label}
          </Text>
        </span>
        {badgeLabel && <Pill label={badgeLabel} color="danger" className="self-end" />}
      </span>
    </span>
  );
});

MenuNavItem.displayName = "MenuNavItem";

export default MenuNavItem;
