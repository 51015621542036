interface UrlParameterProps {
  [key: string]: number | string | boolean | undefined;
}

export const formatUrlParams = (urlParams: UrlParameterProps) =>
  Object.entries(urlParams)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

export const capitalise = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
