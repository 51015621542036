import React from "react";

interface ISVGProps {
  height?: string | number;
  width?: string | number;
}

const Close = (props: ISVGProps) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="m11.5 5-7 7M4.5 5l7 7"
      stroke="currentcolor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Close;
