import * as React from "react";
import { SVGProps } from "react";

const LogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" {...props}>
    <path
      fill="currentcolor"
      d="M.36127 12.2354c.07742-.2575.18064-.5407.25806-.7981.30967-.9783.8774-1.75057 1.78062-2.26544C7.35471 6.26282 12.2837 3.35378 17.161.39326c1.6774-1.02975 4.5419.10297 5.2386 1.77631.1291.28318.1549.59211.1549.90103.0258 2.4714 0 4.91705.0258 7.3884 0 1.6476-.6452 2.8833-2.0903 3.7844-4.4903 2.7803-8.9805 5.6121-13.47077 8.4439-.4387.2832-.90321.5149-1.36772.7723h-.92902c-.95482-.4119-1.41933-1.1842-1.72901-2.1625-.82579-2.5743-1.75481-5.1487-2.63221-7.7231v-1.3386Zm4.85154 9.0875c.18064-.0772.23225-.103.30967-.1287 4.69672-2.9606 9.39342-5.8953 14.09012-8.8559.6709-.4119.929-1.004.929-1.7763 0-2.41989 0-4.8398-.0258-7.25971 0-.20594-.0516-.43764-.1806-.5921-.5936-.69508-1.5484-.84954-2.3226-.38616C13.2901 5.1301 8.56759 7.93616 3.81928 10.7422c-1.2903.7723-1.59998 1.6734-1.13547 3.115.54193 1.5704 1.05805 3.1665 1.59998 4.7369.30967.901.61934 1.802.92902 2.7288ZM47.1992 35.7394c-.0516.1287-.1032.2831-.1548.4119-.3097 1.1584-.8774 2.0594-1.9613 2.703-4.9031 2.8833-9.8321 5.7924-14.7094 8.7529-1.7032 1.0297-4.5935-.103-5.2903-1.8021-.1032-.2574-.129-.5663-.129-.8238-.0258-2.4971-.0258-5.02 0-7.5171 0-1.5704.6452-2.7546 2.0129-3.6041 4.6451-2.8833 9.2386-5.8181 13.8836-8.7014.3613-.2317.7742-.3862 1.1613-.5921h.671c.9806.3347 1.4967 1.0555 1.8064 2.008.8774 2.6773 1.8064 5.3547 2.7096 8.032v1.1328Zm-4.8515-9.0876c-.2065.103-.3097.1545-.4129.206-4.6709 2.9348-9.3418 5.8695-14.0127 8.8043-.6193.3862-.8774.9525-.8774 1.6734 0 2.4456 0 4.8913.0258 7.3112 0 .2832.2065.6436.4129.798.6968.5149 1.3935.6179 2.2451.103 4.6709-2.8318 9.3676-5.5864 14.0643-8.3667 1.1871-.7208 1.5484-1.6733 1.1097-3.012-.5419-1.5961-1.0839-3.1922-1.6258-4.8141-.2839-.8495-.5935-1.7506-.929-2.7031Z"
    />
    <path
      fill="currentcolor"
      fillRule="evenodd"
      d="m4.82573 13.3423 15.30297 9.3193c1.9355 1.1584 2.7355 3.0892 2.6064 5.9982l-.0516 5.7152c-.4129 2.5743-3.6902 3.913-5.3676 2.8832L2.9935 28.5826C1.16127 27.5529.10322 26.7033 0 23.1764l.10322-8.2122c0-2.6774.15484-3.5784.61935-4.3764.59354-.9783 3.35479-2.49718 3.58704-2.6259.56773-.30892-.8516 1.10698-.69676 2.6259.18064 1.3901.05161 1.4416 1.21288 2.7545ZM42.7348 34.6581l-15.303-9.3449c-1.9355-1.1585-2.7354-3.0893-2.6064-5.9983l.0516-5.7151c.4129-2.5744 3.6903-3.91307 5.3677-2.8833l14.3223 8.6756c1.8323 1.0298 2.8903 1.8793 2.9935 5.4062l-.1032 8.2122c0 2.6774-.1548 3.5784-.6193 4.3765-.5936.9782-3.3548 2.4971-3.5871 2.6258-.5677.3089.8516-1.107.6968-2.6258-.1807-1.3645-.0516-1.4159-1.2129-2.7289Z"
      clipRule="evenodd"
    />
  </svg>
);

export default LogoIcon;
