import { ChangeEvent, useEffect, useState } from "react";
import TextInput, { DecimalTypeProps } from "@atoms/text-input";
import { isValidDecimalValue } from "@utils/number";
import classNames from "classnames";

export type DecimalInputProps = DecimalTypeProps;

const DecimalInput = ({ max, min, step = 0.01, decimalPlaces = 2, onChange, ...props }: DecimalInputProps) => {
  const { currencyPrefix: currency, ...inputProps } = props;
  const value = props.type === "decimal" ? props.value : props.value?.toString() ?? "";
  const initialValue = value ? (isValidDecimalValue(value, decimalPlaces) ? value : "") : "";
  const [inputValue, setInputValue] = useState<string>(initialValue);
  const currencyPrefix = props.type === "currency" ? props.currencyPrefix : undefined;
  const updateValue = (e: ChangeEvent<HTMLInputElement>, val: string) => {
    setInputValue(val);
    onChange && onChange(e);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue === "") return updateValue(e, "");
    if (isValidDecimalValue(newValue, decimalPlaces)) {
      return updateValue(e, newValue);
    }
  };

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  return (
    <TextInput
      {...{ max, min, step, ...inputProps }}
      type="number"
      value={inputValue}
      onChange={handleChange}
      className={classNames(props.className, { "pl-7": !!currencyPrefix })}
      iconLeft={
        currencyPrefix ? (
          <span className="absolute top-[-4px] left-[18px] text-white">{currencyPrefix}</span>
        ) : undefined
      }
    />
  );
};

export default DecimalInput;
