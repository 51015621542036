import { FieldsetProps } from "./fieldset.types";
import cx from "classnames";
import Text from "@atoms/text";

const Fieldset = ({
  variant = "primary",
  paddingSize = "md",
  heading,
  children,
  rightHeader,
  className,
}: FieldsetProps): JSX.Element => {
  const headerPadding = (paddingSize: string) => {
    switch (paddingSize) {
      case "none":
      case "sm":
        return "p-4";
      case "md":
        return "p-5";
      case "lg":
        return "p-6";
      default:
        return "p-5";
    }
  };
  const padding = (paddingSize: string) => {
    switch (paddingSize) {
      case "none":
        return "p-0";
      case "sm":
        return "p-4";
      case "md":
        return "p-5";
      case "lg":
        return "p-6";
      default:
        return "p-5";
    }
  };
  const backgroundHeader = () => {
    switch (variant) {
      case "secondary":
        return "bg-background-gray2";
      case "primary":
      default:
        return "bg-gradient-to-r from-bluer to-blue";
    }
  };

  return (
    <div className={cx("flex flex-col bg-background-gray rounded", className)}>
      <div
        className={cx(
          headerPadding(paddingSize),
          backgroundHeader(),
          "flex flex-row justify-between items-center rounded-t"
        )}
      >
        <Text variant="heading3" as="p" className="text-white font-bold">
          {heading}
        </Text>
        {rightHeader}
      </div>
      <div className={cx(padding(paddingSize), "rounded-b-lg")}>{children}</div>
    </div>
  );
};

export default Fieldset;
