import "./styles/fonts.css";
import "./styles/global.css";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "@context/auth";
import Routes from "./routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { NearContextProvider } from "@context/near-context";
import { UserException } from "@api/lib";
import SpinnerLoader from "@atoms/spinner-loader";
import { ToastContextProvider } from "@context/toast-context";

const queryClient = new QueryClient();

queryClient.setDefaultOptions({
  queries: {
    ...queryClient.defaultQueryOptions,
    refetchOnWindowFocus: (query) => !query.state.error,
    onError: (error: any) => {
      if (error instanceof UserException && error?.status === 503) window.alert("503 - Service Unavailable");
      else throw error;
    },
  },
});

function MyApp() {
  return (
    <ToastContextProvider>
      <QueryClientProvider client={queryClient}>
        <NearContextProvider>
          <AuthContextProvider>
            {(loadingState) => (
              <BrowserRouter>{loadingState !== "complete" ? <SpinnerLoader /> : <Routes />}</BrowserRouter>
            )}
          </AuthContextProvider>
        </NearContextProvider>
      </QueryClientProvider>
    </ToastContextProvider>
  );
}

export default MyApp;
