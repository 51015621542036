import { BarChart } from "@atoms/icons";
import SVG from "@atoms/svg";
import { DashboardPeriod } from "@constants/dashboard";
import DateRange from "@molecules/date-range";
import { getPercentageString, getPercentageTextColour } from "@utils/percentage";
import classNames from "classnames";

interface Props {
  mode: string;
  period: string;
  className?: string;
  percentageChange?: number;
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
}

const DashboardGraphHeader = ({
  className,
  percentageChange,
  period,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: Props) => {
  const getText = () => {
    switch (period) {
      case DashboardPeriod.week:
        return { title: "Total Sales This Week", subtitle: "(vs last week)" };
      case DashboardPeriod.month:
        return { title: "Total Sales This Month", subtitle: "(vs last month)" };
      case DashboardPeriod.custom:
      default:
        return { title: "Total Sales" };
    }
  };

  const onDateRangeChange = ([from, to]: [Date | null, Date | null]) => {
    if (!!from) {
      setStartDate(from);
    }
    if (!!to) {
      setEndDate(to);
    }
  };
  return (
    <div className={classNames("flex flex-row", className)}>
      <SVG icon={BarChart} styles="mr-2" />
      <div className="md:flex md:flex-row">
        <p className="text-white text-xl font-bold md:mr-3">{getText().title}</p>
        {period === DashboardPeriod.custom && (
          <DateRange small noMinDate maxDate={new Date()} from={startDate} to={endDate} onChange={onDateRangeChange} />
        )}
        <span>
          {!(percentageChange === undefined) && (
            <>
              <span className={classNames("text-lg", getPercentageTextColour(percentageChange))}>
                {getPercentageString(percentageChange)}
              </span>{" "}
              <span className="text-base opacity-50">{getText().subtitle}</span>
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default DashboardGraphHeader;
