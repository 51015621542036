import Text from "@atoms/text";
import cn from "classnames";
import SuccessCheck from "@assets/success_check.png";
import Button from "@atoms/button";

enum ModalSize {
  small = "small",
  medium = "medium",
  large = "large",
}
enum ModalVariant {
  primary = "primary",
  secondary = "secondary",
}

type Props = {
  children?: never[];
  size: keyof typeof ModalSize;
  variant: keyof typeof ModalVariant;
  isVisible: boolean;
  title?: string;
  subtitle?: string;
  onClick: () => void;
  onClickText: string;
};

function getModalSize(size: keyof typeof ModalSize) {
  switch (size) {
    case ModalSize.large:
      return "max-w-xl";
    case ModalSize.medium:
      return "max-w-md";
    case ModalSize.small:
    default:
      return "max-w-sm";
  }
}

function getModalBackground(variant: keyof typeof ModalVariant) {
  switch (variant) {
    case ModalVariant.primary:
      return "bg-navy";
    case ModalVariant.secondary:
    default:
      return "bg-background-blue";
  }
}

const SuccessModal = ({
  size,
  variant,
  title,
  subtitle = "",
  isVisible = false,
  onClick = () => {},
  onClickText = "Continue",
}: Props): JSX.Element => {
  return (
    <div
      id="modal"
      className={cn("fixed z-40 inset-0 bg-gray-900 bg-opacity-80 h-full w-full px-4", {
        hidden: !isVisible,
      })}
    >
      <div
        className={cn(
          `relative top-40 mx-auto shadow-lg rounded-md pr-4 pl-4 pb-4`,
          getModalSize(size),
          getModalBackground(variant)
        )}
      >
        <div className="flex flex-col justify-between items-center p-4">
          {title && (
            <Text variant="heading1" as="h2" className="text-white pb-4">
              {title}
            </Text>
          )}
          <div className="mb-4">
            <img src={SuccessCheck} />
          </div>
          {subtitle && (
            <Text variant="heading4" as="p" className="text-white text-center">
              {subtitle}
            </Text>
          )}
        </div>
        <div className="">
          <Button variant="primary" type="button" onClick={onClick} isFullWidth={true} size="large">
            {onClickText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
