import { SVGProps } from "react";

const Wifi = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14.583 21a.583.583 0 1 1-1.166 0 .583.583 0 0 1 1.166 0Z" stroke="currentcolor" />
    <path
      d="M11.083 16.99A4.936 4.936 0 0 1 14 16.04c1.09 0 2.098.352 2.917.948M19.498 13.099A9.581 9.581 0 0 0 14 11.375a9.58 9.58 0 0 0-5.498 1.724M5.833 9.27A14.226 14.226 0 0 1 14 6.71c3.037 0 5.852.947 8.167 2.562"
      stroke="currentcolor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Wifi;
