import Button from "@atoms/button";
import useListFilter from "@hooks/use-list-filter";
import ScannerUserCard, { ScannerUserCardTableHeader } from "@molecules/scanner-user-card";

type ScannerUser = {
  id: number | string;
  name: string;
  email: string;
};

type Props<S> = {
  scanners: S[];
  getScannerLink: (scanner: S) => string;
  onDeleteScanner: (scanner: S) => void;
  onCreateScanner: () => void;
};

function ScannersListing<S extends ScannerUser>({
  scanners,
  getScannerLink,
  onDeleteScanner,
  onCreateScanner,
}: Props<S>) {
  const { filteredItems: visibleScanners } = useListFilter({
    items: scanners,
    filterFn: (terms, { name }) => name.toLowerCase().indexOf(terms) >= 0,
  });
  const noScanners = !visibleScanners || visibleScanners.length === 0;

  return (
    <div>
      {!noScanners ? (
        <>
          <div className="flex justify-between mb-16">
            <div></div>
            <div className="flex space-x-4">
              <Button onClick={onCreateScanner} variant="primary" size="medium">
                Create Scanner
              </Button>
            </div>
          </div>

          <div className="space-y-3">
            <ScannerUserCardTableHeader />
            {visibleScanners.map((scanner) => (
              <ScannerUserCard
                key={scanner.id}
                {...scanner}
                editLink={getScannerLink(scanner)}
                onDelete={() => onDeleteScanner(scanner)}
              />
            ))}
          </div>
        </>
      ) : (
        <div className="w-full h-full flex flex-col items-center pt-48">
          <p className="text-4xl font-bold mb-6">No Scanners Listed</p>
          <Button variant="primary" size="large" onClick={onCreateScanner}>
            Create Scanner
          </Button>
        </div>
      )}
    </div>
  );
}

export default ScannersListing;
