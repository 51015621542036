import { format, formatDistance } from "date-fns";

export function displayDate(date: Date | number) {
  return format(date, "dd/MM/yyyy");
}

export function displayTime(date: Date | number) {
  return format(date, "HH:mm");
}

export function displayDateDuration(fromDate: Date, toDate: Date = new Date()) {
  return formatDistance(fromDate, toDate, { addSuffix: true });
}
