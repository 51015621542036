import { createCustomFetchApi, URLS } from "./lib";

export type Scanner = {
  id: number;
  username: string;
};

export default class ScannersApi {
  token: string;
  authenticatedFetch: (input: RequestInfo, init?: RequestInit) => Promise<any>;

  constructor(token: string) {
    if (!token) throw new Error("Error API cannot be initialised without a token");
    this.token = token;
    this.authenticatedFetch = createCustomFetchApi(token);
  }

  getScanners = () => {
    return this.authenticatedFetch(`${URLS.api}/seller/scanners`).then((response) => response.result as Scanner[]);
  };

  createScanner = (username: string, password: string) => {
    return this.authenticatedFetch(`${URLS.api}/scanner`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
    }).then((response) => response.result);
  };

  deleteScanner = (username: string) => {
    return this.authenticatedFetch(`${URLS.api}/scanner`, {
      method: "DELETE",
      body: JSON.stringify({ username }),
    });
  };
}
