import * as React from "react";

function ImageUpload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 183 183" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#prefix__clip0_2052_94)" fill="#2E3944" fillOpacity={0.5}>
        <path d="M92.674 67.915c-10.31 0-18.667 8.358-18.667 18.668 0 10.309 8.357 18.667 18.667 18.667 10.309 0 18.667-8.358 18.667-18.667 0-10.31-8.357-18.668-18.667-18.668zm0 28.445c-5.4 0-9.778-4.377-9.778-9.778 0-5.4 4.378-9.778 9.778-9.778s9.778 4.378 9.778 9.778-4.378 9.778-9.778 9.778z" />
        <path d="M167.12 25.248L45.785 11.47c-4.71-.67-9.48.705-13.111 3.778a16.889 16.889 0 00-6.445 11.556l-2.222 18.222h-6.89C7.34 45.026.006 53.693.006 63.471v90.89c-.246 9.324 7.113 17.083 16.437 17.329.225.006.45.008.674.005H139.12c9.778 0 18.667-7.556 18.667-17.334v-3.555a22.221 22.221 0 008.445-3.556 18.448 18.448 0 006.444-12l10.223-90.224c1.042-9.8-5.991-18.617-15.778-19.778zM148.897 154.36c0 4.889-4.889 8.445-9.778 8.445H17.118a8.001 8.001 0 01-8.223-8.445v-16.445l34.445-25.333a10.667 10.667 0 0113.778.666l24.223 21.334a20.894 20.894 0 0013.111 4.889 20.218 20.218 0 0010.667-2.889l43.779-25.334v43.112h-.001zm0-53.556l-48.445 28.222a11.332 11.332 0 01-13.112-1.111L62.896 106.36a19.779 19.779 0 00-24.667-.889L8.895 126.805V63.471c0-4.889 3.334-9.556 8.223-9.556h122.001a10.222 10.222 0 019.778 9.556v37.334zm25.12-56.979c-.003.03-.005.06-.009.089l-10.444 90.223a7.553 7.553 0 01-2.889 6c-.889.889-2.889 1.334-2.889 1.778V63.471c-.351-10.124-8.54-18.215-18.667-18.445H32.896l2-17.333a10.223 10.223 0 013.333-5.778 10.222 10.222 0 016.667-1.778l121.113 14a8.889 8.889 0 018.008 9.69z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_2052_94">
          <path fill="#fff" d="M0 0h183v183H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ImageUpload;
