import cn from "classnames";
import { SideNavigationProps } from "./side-navigation.types";
import { Plus } from "@atoms/icons";
import Button from "@atoms/button";
import Box from "@atoms/box";
import useMediaQueries from "@hooks/use-media-queries";
import SiteNavigation from "@organisms/site-navigation";

function SideNavigation({ onNewEventClick, hideNewEvent = false, hideAd = false }: SideNavigationProps) {
  const { isDesktopAndUp } = useMediaQueries();

  return (
    <div
      className={cn("flex flex-col h-full justify-between", {
        "w-full bg-transparent": !isDesktopAndUp,
      })}
    >
      <div>
        <SiteNavigation />
        {!hideNewEvent && (
          <Button
            onClick={onNewEventClick}
            isFullWidth
            variant="primary"
            size="medium"
            icon={Plus}
            iconPosition="left"
            className="mt-8"
          >
            New Event
          </Button>
        )}
      </div>
      {!hideAd && (
        <Box variant="dark-blue" padding="medium" className="my-4">
          <header className="text-white text-base font-semibold">Get Verified</header>
          <p className="text-white text-xs mt-2">Apply to become a verified seller to increase your visibility.</p>
          <a
            href="https://www.seatlabnft.com/seller-verification"
            target="_blank"
            className="text-white text-xs underline underline-offset-4"
            rel="noreferrer"
          >
            Apply Here
          </a>
        </Box>
      )}
    </div>
  );
}

export default SideNavigation;
