import useListFilter from "@hooks/use-list-filter";
import EventSearchTopSection from "@molecules/event-search-top-section";
import EventsList, { Props as EventListingProps } from "@organisms/events-list";

type Props = EventListingProps & {
  onAddNewEvent: () => void;
};

const Events = ({ events, onAddNewEvent, ...props }: Props) => {
  const { searchValue, setSearchValue, filteredItems } = useListFilter({
    items: events,
    filterFn: (terms, { name }) => name.toLowerCase().indexOf(terms) >= 0,
  });
  const noEvents = !events || events.length === 0;

  return (
    <>
      {!noEvents && (
        <EventSearchTopSection
          {...{ searchValue }}
          onChange={(e) => setSearchValue(e.target.value)}
          onCreateEvent={onAddNewEvent}
          noEvents={noEvents}
        />
      )}

      <EventsList {...props} events={filteredItems} onCreateEvent={onAddNewEvent} noEvents={noEvents} />
    </>
  );
};

export default Events;
