import useAuth from "./use-auth";
import { useMemo } from "react";
import CollectiblesApi from "@api/collectibles";

function useCollectiblesApi() {
  const { token } = useAuth();
  return useMemo(() => new CollectiblesApi(token || ""), [token]);
}

export default useCollectiblesApi;
