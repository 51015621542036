import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import cn from "classnames";
import { TelTypeProps } from "@atoms/text-input";
import { useRef } from "react";

const MobilePrefixSelector = (props: TelTypeProps) => {
  const ref = useRef<IntlTelInput>(null);

  const { isDisabled, isRequired, className, name, id, onFocus, onBlur, statusType, onChange, ...rest } = props;
  const hasError = statusType === "error";

  const inputStyle = cn(
    "outline-none text-heading3 rounded-md text-white h-16 p-4 bg-dark-grey w-full",
    hasError ? "pr-9" : "pr-4",
    { "ring-2 ring-inset ring-danger bg-danger/20": hasError },
    { "bg-opacity-60": isDisabled },
    className
  );

  return (
    <IntlTelInput
      ref={ref}
      excludeCountries={["cu", "ir", "kp", "sy"]}
      defaultCountry="gb"
      inputClassName={inputStyle}
      onPhoneNumberChange={(...args) => {
        const [, value] = args;
        // 0 represents "E164"
        // https://github.com/jackocnr/intl-tel-input/blob/master/src/js/utils.js#L104-L109
        const e164 = ref.current?.getNumber(value, 0 as unknown as string) || "";
        if (onChange) onChange(...args, e164);
      }}
      disabled={isDisabled}
      aria-required={isRequired}
      aria-invalid={hasError}
      {...{ id, name, ...rest }}
      containerClassName="intl-tel-input w-full"
      onPhoneNumberFocus={onFocus ? (_, _2, _3, _4, _5, e) => onFocus(e) : undefined}
      onPhoneNumberBlur={onBlur ? (_, _2, _3, _4, _5, e) => onBlur(e) : undefined}
    />
  );
};

export default MobilePrefixSelector;
