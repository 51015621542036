import * as React from "react";

function Trash(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.188 9.688l1.05 12.091a2.5 2.5 0 002.492 2.284h7.29a2.5 2.5 0 002.491-2.284l1.052-12.091H7.188zM12.188 13.438v6.874M16.563 13.438v6.874M10.938 9.688v-1.25a2.5 2.5 0 012.5-2.5h1.874a2.5 2.5 0 012.5 2.5v1.25M5.938 9.688h16.875"
        stroke="currentcolor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Trash;
