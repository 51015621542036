import DatePicker from "react-datepicker";
import cn from "classnames";
import { format, parse, setHours, setMinutes } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";
import { TimeTypeProps } from "@atoms/text-input";

type Props = TimeTypeProps & {};

const startOfDay = setHours(setMinutes(new Date(), 0), 0);
const endOfDay = setHours(setMinutes(new Date(), 59), 23);

const TimeInput = ({
  statusType,
  value,
  isDisabled,
  isRequired,
  className,
  maxTime = endOfDay,
  minTime = startOfDay,
  onChange,
  ...props
}: Props) => {
  const hasError = statusType === "error";
  const inputStyle = cn(
    "outline-none text-heading3 rounded-md text-white h-16 p-4 bg-dark-grey w-full",
    hasError ? "pr-9" : "pr-4",
    { "ring-2 ring-inset ring-danger bg-danger/20": hasError },
    { "bg-opacity-30": isDisabled },
    className
  );

  const onchange = (date: Date | null) => {
    if (!onChange) return;
    if (!date) return onChange(null);
    onChange(format(date, "HH:mm"));
  };

  return (
    <DatePicker
      {...{ ...props, minTime, maxTime }}
      placeholderText="--:--"
      className={inputStyle}
      onChange={onchange}
      selected={(!!value && parse(value, "HH:mm", new Date())) || null}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      dateFormat="HH:mm"
      timeFormat="HH:mm"
      disabled={isDisabled}
      required={isRequired}
      aria-required={isRequired}
      aria-invalid={hasError}
    />
  );
};

export default TimeInput;
