import Button from "@atoms/button";
import FormikField from "@molecules/formik-field";
import { Form } from "formik";

const ResetPasswordForm = () => {
  return (
    <Form>
      <div className="flex flex-col gap-y-4 pb-16">
        <fieldset className="grid gap-4">
          <FormikField name="password" label="Password" type="password" isFullWidth />
        </fieldset>
        <fieldset className="grid gap-4">
          <FormikField name="password_confirmation" label="Confirm Password" type="password" isFullWidth />
        </fieldset>
        <fieldset className="grid gap-4">
          <Button type="submit" isFullWidth>
            Reset password
          </Button>
        </fieldset>
      </div>
    </Form>
  );
};

export default ResetPasswordForm;
