import { Collectible } from "@api/collectibles";
import { Edit, Eye } from "@atoms/icons";
import Svg from "@atoms/svg";
import TableList from "@molecules/table-list";
import cn from "classnames";

type Props = {
  collectibles: Collectible[];
  onEdit: (collectible: Collectible) => void;
  onView: (collectible: Collectible) => void;
};

enum ColumnKey {
  thumb = "thumb",
  title = "title",
  mintDate = "mintDate",
  editionsSold = "editionsSold",
  collectionName = "collectionName",
  status = "status",
  actions = "actions",
}

const columns = [
  { key: ColumnKey.thumb, label: "", className: "w-24" },
  { key: ColumnKey.title, label: "Title" },
  { key: ColumnKey.mintDate, label: "" },
  { key: ColumnKey.editionsSold, label: "" },
  { key: ColumnKey.collectionName, label: "" },
  { key: ColumnKey.status, label: "Status", className: "w-32" },
  { key: ColumnKey.actions, label: "Actions", className: "w-32" },
];

function CollectiblesList({ collectibles, onEdit, onView }: Props) {
  return (
    <TableList
      data={collectibles}
      columns={columns}
      keyExtractor={(info) => {
        if (info.header) return info.column.key;
        else return `row-${info.row.uuid}-${info.column?.key}`;
      }}
      renderHeader={(column) => column.label}
      renderColumnRow={(data) => renderRowColumn({ ...data, onEdit, onView })}
    />
  );
}

type RenderRowColumnProps = Pick<Props, "onEdit" | "onView"> & {
  column: { key: ColumnKey; label: string };
  row: Collectible;
};

function getStatusTitle(status: Collectible["state"]) {
  switch (status) {
    case "unpublished":
      return "Draft";
    case "published":
      return "Live";
    case "processing":
      return "Processing";
  }
}

function renderRowColumn({ column, row, onEdit, onView }: RenderRowColumnProps) {
  if (column.key === ColumnKey.thumb) {
    return (
      <div className="rounded-lg overflow-hidden m-1 aspect-square h-20 bg-layer-blue flex">
        {row.imageUrl ? <img src={row.imageUrl} className="object-contain" alt="collectible thumbnail" /> : null}
      </div>
    );
  }
  if (column.key === ColumnKey.title) return <div children={<span>{row.title}</span>} />;
  if (column.key === ColumnKey.mintDate) return null;
  if (column.key === ColumnKey.editionsSold) return null;
  if (column.key === ColumnKey.collectionName) return null;
  if (column.key === ColumnKey.status) return <div children={<span>{getStatusTitle(row.state)}</span>} />;
  if (column.key === ColumnKey.actions) {
    return (
      <div className="flex text-sm gap-4">
        {[
          { Icon: Eye, label: "View", disabled: !row.isLive, onClick: () => onView(row) },
          { Icon: Edit, label: "Edit", disabled: row.isLive, onClick: () => onEdit(row) },
        ].map(({ label, Icon, disabled, onClick }) => (
          <button
            key={label}
            className={cn("text-center cursor-pointer", { "opacity-50": disabled })}
            {...{ disabled, onClick }}
          >
            <Svg icon={Icon} size="medium" />
            <div>{label}</div>
          </button>
        ))}
      </div>
    );
  }
}

export default CollectiblesList;
