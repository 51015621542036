import Text from "@atoms/text";
import React from "react";

type Props = {
  eligibleWallets: number;
};

const className = `inline-flex justify-center text-white items-center whitespace-nowrap leading-none rounded-lg outline-none active:ring-0 focus-visible:green-30 focus-visible:ring-4 focus-visible:ring-green-20 bg-gradient-to-r from-bluer to-blue text-white hover:bg-dark-blue active:bg-blue cursor-auto h-10 px-5`;

export default function AirdropEligibleWallets({ eligibleWallets }: Props) {
  return (
    <>
      <button type="button" className={className} onClick={() => {}} disabled={true}>
        {eligibleWallets}
      </button>
      <Text variant="heading2"> Eligible wallets</Text>
    </>
  );
}
