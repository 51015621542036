import useMediaQueries from "@hooks/use-media-queries";
import { Props } from "./customer-card.types";
import CustomerTableRow from "./customer-table-row";
import MobileCustomerCard from "./mobile-view-customer-card";

function CustomerCard(props: Props) {
  const { isDesktopAndUp } = useMediaQueries();
  return isDesktopAndUp ? <CustomerTableRow {...props} /> : <MobileCustomerCard {...props} />;
}

export default CustomerCard;
