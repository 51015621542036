import cn from "classnames";
import React, { InputHTMLAttributes, ReactNode } from "react";
import SVG from "@atoms/svg";
import { Check } from "@atoms/icons";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string | ReactNode;
  id: string;
  theme?: "light" | "dark";
};

function Checkbox({ label, id, checked = false, theme = "light", className, onChange, ...inputProps }: Props) {
  const textcolor = theme === "dark" ? "text-dark-grey" : "text-white";
  const labelClasses = `inline-block relative cursor-pointer pl-3 ${textcolor}`;
  const fauxcheckbox = cn(
    "relative inline-flex w-[24px] h-[24px] bg-transparent rounded border justify-center items-center",
    {
      "border-white": theme === "light",
      "border-dark-grey": theme !== "light",
    }
  );

  const onChangeState = inputProps.disabled ? undefined : onChange;
  return (
    <div className={cn(className, "relative inline-flex", { "opacity-30": inputProps.disabled })}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChangeState}
        className="absolute opacity-0"
        {...inputProps}
      />
      <label className={fauxcheckbox} htmlFor={id}>
        {!!checked && <SVG icon={Check} styles={cn(textcolor, "inline-block")} size="small" />}
      </label>
      {label && (
        <label htmlFor={id} className={labelClasses}>
          {label}
        </label>
      )}
    </div>
  );
}

export default Checkbox;
