import { Scanner } from "@api/scanners";
import Button from "@atoms/button";
import DocumentTitle from "@atoms/document-title";
import Modal from "@atoms/modal";
import SpinnerLoader from "@atoms/spinner-loader";
import useScannersApi from "@hooks/use-scanners-api";
import { LayoutTitle } from "@organisms/layout";
import ScannersListing from "@organisms/scanners-listing";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

function Scanners() {
  const navigate = useNavigate();
  const scannersApi = useScannersApi();
  const queryClient = useQueryClient();
  const [scannerToDelete, setScannerToDelete] = useState<Scanner | undefined>();
  const scannerQuery = useQuery("sellerScanners", scannersApi.getScanners);

  const scanners = scannerQuery.isSuccess
    ? scannerQuery.data.map(({ id, username }) => ({ id, email: "", username, name: username }))
    : [];

  const deleteScanner = useMutation(
    () => {
      if (!scannerToDelete) throw Error("A scanner must be selected first before deleting");
      return scannersApi.deleteScanner(scannerToDelete.username);
    },
    { onSuccess: () => queryClient.invalidateQueries("sellerScanners") }
  );

  const onCreateScanner = () => navigate("/scanners/new");
  const getScannerLink = (scanner: Scanner) => `/scanners/${scanner.id}`;

  const closeDeleteModal = () => setScannerToDelete(undefined);
  const onDeleteScanner = (scanner: Scanner) => setScannerToDelete(scanner);
  const onConfirmScannerDelete = () => {
    deleteScanner.mutate();
    closeDeleteModal();
  };

  return (
    <>
      <DocumentTitle
        titlePrefix="Scanners - Create scanner credentials"
        description="Create unique scanner credentials to keep track of your team and how and where each NFT ticket and collectable was scanned."
      />
      <LayoutTitle title="Scanners" />
      {scannerQuery.isSuccess ? (
        <ScannersListing {...{ scanners, getScannerLink, onCreateScanner, onDeleteScanner }} />
      ) : (
        <SpinnerLoader />
      )}
      <Modal
        isVisible={!!scannerToDelete}
        variant="secondary"
        onClose={closeDeleteModal}
        title={`Delete ${scannerToDelete?.username}?`}
        subtitle="This action is irreversible"
        size="medium"
      >
        <div className="flex justify-evenly">
          <Button variant="outline" onClick={closeDeleteModal}>
            No, cancel
          </Button>
          <Button variant="secondary" onClick={onConfirmScannerDelete}>
            Yes, delete scanner
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default Scanners;
