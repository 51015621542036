import { FieldArray } from "formik";
import { CreateTicketParams } from "@api/tickets";
import EditTicketsForm from "./edit-tickets-form";
import { Seller } from "@api/auth";

export type TicketValues = {
  ticketTypes: FormTicketType[];
};

export type FormTicketType = Omit<CreateTicketParams, "price" | "quantityAvailable" | "allowTransfers"> & {
  price: string;
  quantityAvailable: string;
  newImage?: File;
  id?: string | number;
  allowTransfers: boolean;
};

export type Values = TicketValues & {
  startDate: Date;
  endDate: Date;
  endTime: string;
  startTime: string;
  eventName: string;
  venueName: string;
};

export const initialValues: TicketValues = {
  ticketTypes: [],
};

type Props = {
  seller: Seller;
};

export default function EventTickets({ seller }: Props) {
  return (
    <FieldArray name="ticketTypes">
      {(arrayHelpers) => <EditTicketsForm helpers={arrayHelpers} seller={seller} />}
    </FieldArray>
  );
}
