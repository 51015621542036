import { ReactNode } from "react";

interface BarProps {
  label: string;
  footer: ReactNode;
  percentage: number;
}

const getBgColour = (percentage: number) => {
  if (percentage < 25) return "danger";
  if (percentage < 50 && percentage < 75) return "warning";
  return "success";
};

const PercentageBar = ({ label, percentage, footer }: BarProps) => {
  return (
    <figure className="text-left w-full">
      <figcaption className="font-bold text-base">{label}</figcaption>
      <span className="flex flex-row items-center">
        <div className="w-full h-[6px] relative bg-dark-grey rounded-lg overflow-hidden">
          <div className={`absolute inset-0 bg-${getBgColour(percentage)}`} style={{ width: `${percentage}%` }} />
        </div>
        <p className="text-sm ml-3">{percentage}%</p>
      </span>
      {footer}
    </figure>
  );
};

export default PercentageBar;
