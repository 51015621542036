import Fieldset from "@atoms/fieldset";
import Text from "@atoms/text";
import { displayCurrency } from "@utils/number";
import { TicketTypeCardProps } from "./ticket-type-card.types";
import useAuth from "@hooks/use-auth";

function TicketTypeCard({ index, ticketType, selected, onSelect }: TicketTypeCardProps) {
  const onClick = () => onSelect(ticketType);
  const { currency } = useAuth();
  return (
    <div onClick={onClick}>
      <Fieldset
        variant="secondary"
        heading={`Ticket #${index + 1}`}
        rightHeader={selected ? "Unselect" : "Select"}
        paddingSize="sm"
        className={selected ? " cursor-pointer outline outline-2 outline-bluer" : " cursor-pointer"}
      >
        <div className="flex flex-row justify-between items-center gap-4">
          <div className="flex-1">
            <Text variant="heading4" as="p" className="text-grey mr-6">
              Ticket Name
            </Text>
            <Text variant="heading3" as="p" className="text-white">
              {ticketType.name}
            </Text>
          </div>
          <div className="flex-1">
            <Text variant="heading4" as="p" className="text-grey mr-6">
              Ticket Price
            </Text>
            <Text variant="heading3" as="p" className="text-white">
              {displayCurrency(ticketType.price, "en-GB", currency)}
            </Text>
          </div>
        </div>
      </Fieldset>
    </div>
  );
}

export default TicketTypeCard;
