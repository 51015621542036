import LayoutContext from "@context/layout-context";
import { useContext, useEffect } from "react";

function LayoutTitle({ title }: { title: string }) {
  const { setTitle } = useContext(LayoutContext);
  useEffect(() => {
    setTitle(title);
  }, [title]);

  return null;
}

export default LayoutTitle;
