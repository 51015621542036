import { camelCase, snakeCase, isArray, transform, isObject, isDate } from "lodash";

export type FetchAPIOptions = {
  onUnauthorizedError: (response: Response) => void;
};

export const URLS = {
  get successUrl() {
    return `${window.location.origin}/signin-success`;
  },
  get api() {
    return process.env.REACT_APP_REST_API_URL;
  },
};
const FALLBACK_ERROR_MESSAGE = "Sorry, something went wrong please try again later";

export class UserException {
  name: "UserException" = "UserException";
  status: number;
  statusText: string;
  message: string;
  errors?: string[];

  constructor(response: Response, message: string, errors?: string[]) {
    this.status = response.status;
    this.statusText = response.statusText;
    this.message = message;
    this.errors = errors;
  }
}

type ErrorResponse = {
  errors: { [key: string]: string[] };
  message?: string;
  [key: string]: any;
};

export async function handleErrors(response: Response, suppressErrors?: boolean) {
  try {
    const json = await response.clone().json();
    if (!response.ok) {
      if (suppressErrors) return {};
      const errorsMessage = !json.errors
        ? FALLBACK_ERROR_MESSAGE
        : Object.values((json as ErrorResponse).errors).reduce((accum, v) => `${accum}\n${v.join("\n")}`, "");
      throw new UserException(response, json?.message || errorsMessage.trim(), json.errors);
    }
    return json;
  } catch (e) {
    if (e instanceof UserException) throw e;
    else {
      const text = await response.clone().text();
      throw new UserException(response, text);
    }
  }
}

export const createCustomFetchApi = function (token?: string) {
  return function (input: RequestInfo, init?: RequestInit, suppressErrors?: boolean) {
    return fetch(input, {
      ...init,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        ...init?.headers,
      },
    }).then((res) => handleErrors(res, suppressErrors));
  };
};

export function recursivelyCamelCase(obj: Record<string, unknown>) {
  return transform(obj, (result: Record<string, unknown>, value: unknown, key: string, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);
    result[camelKey] =
      isObject(value) && !isDate(value) ? recursivelyCamelCase(value as Record<string, unknown>) : value;
  });
}

export function recursivelySnakeCase(obj: Record<string, unknown>) {
  return transform(obj, (result: Record<string, unknown>, value: unknown, key: string, target) => {
    const camelKey = isArray(target) ? key : snakeCase(key);
    result[camelKey] =
      isObject(value) && !isDate(value) ? recursivelySnakeCase(value as Record<string, unknown>) : value;
  });
}

export type SearchFilters = {
  searchValue?: string;
  perPage?: number;
  page?: number;
};

export type SearchListingResponse<T> = {
  current_page: number;
  last_page: number;
  per_page: number;
  result: T[];
  total: number;
};

export function convertToPercentageInt(percentageVal: null | number) {
  if (!percentageVal) return 0;
  else return percentageVal;
}

export function mapPercentageChangeValues(keys: string[], data: { [key: string]: any }) {
  return {
    ...data,
    ...keys.reduce(
      (accum, key) => ({
        ...accum,
        [key]: convertToPercentageInt(data[key]),
      }),
      {}
    ),
  };
}
