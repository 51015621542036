import Accordion from "@atoms/accordion";
import TextField from "@atoms/text-field";
import FormikField from "@molecules/formik-field";
import { Field, FieldProps, getIn, useFormikContext } from "formik";
import { FormTicketType } from "./event-tickets";
import RiseLoader from "react-spinners/RiseLoader";
import { MinusCircle } from "@atoms/icons";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { convertFileToBase64 } from "@utils/file";
import DropUploadField from "@molecules/drop-upload-field";
import useMutateEvent from "@hooks/use-mutate-event";
import { useMutation } from "react-query";
import { MutateEventFormValues } from "routes/events/form-helpers";
import { Seller } from "@api/auth";
import useDidMount from "@hooks/use-did-mount";
import Toggle from "@atoms/toggle";
import { isObject, isString } from "lodash";

type Props = {
  nameRef: string;
  accordionTitle: string;
  initialOpen?: boolean;
  onRemoveTicket: () => void;
  seller: Seller;
};

const GenerateImageLoader = () => (
  <div className="text-center h-full w-full flex items-center justify-center">
    <div>
      <RiseLoader color="white" />
      <span className="inline-block mt-3.5">Generating Preview...</span>
    </div>
  </div>
);

function EditEventTicketForm(props: Props) {
  const { accordionTitle, initialOpen, nameRef, onRemoveTicket, seller } = props;
  const { setFieldValue, values, errors } = useFormikContext<MutateEventFormValues>();
  const isPublished = values.isLive;
  const ticket = getIn(values, nameRef) as FormTicketType;
  const [transfersAllowed, setTransfersAllowed] = useState(ticket.allowTransfers);
  const accordionContent = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<undefined | number>(undefined);
  const savedImage = ticket.baseImageUrl;
  const { getPreviewImage, saveButtonClicked } = useMutateEvent();
  function getImageError(): string | undefined {
    if (initialOpen && saveButtonClicked && !!Object.keys(errors).length) {
      if (isObject(errors.ticketTypes) && errors.ticketTypes.length > 0) {
        const hasImageMissing = errors.ticketTypes.some((element) => {
          return !!(isObject(element) && isString(element.baseImageUrl));
        });
        if (hasImageMissing) {
          return "Image is required";
        }
      }
    }
    return undefined;
  }

  const previewImageMutation = useMutation(
    (image: string) => {
      const { eventName, startDate, venueName, endDate } = values;
      return getPreviewImage({
        venueName,
        startDate: startDate || new Date(),
        endDate: endDate || new Date(),
        eventName,
        ticketTypeTitle: ticket.name,
        sellerName: seller?.name,
        image,
        sellerImage: seller?.thumbnailUrl,
      });
    },
    {
      onError: (err) => console.error(err),
    }
  );

  const hideTicketPreviewPlaceholder =
    previewImageMutation.data?.image || previewImageMutation.isLoading || previewImageMutation.isError;
  const imageUploadError = previewImageMutation.isError
    ? "Could not generate ticket preview. Please try another image"
    : getImageError();

  const onImageChange = ({ base64, file }: { base64: string; file: File }) => {
    previewImageMutation
      .mutateAsync(base64)
      .then(() => setFieldValue(`${nameRef}.newImage`, file))
      .catch((e) => {
        setFieldValue(`${nameRef}.newImage`, undefined);
        throw e;
      });
  };

  const handleRemoveTicket = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onRemoveTicket();
  };

  const renderTicketPreview = () => {
    if (previewImageMutation.isLoading) return <GenerateImageLoader />;
    if (previewImageMutation.data?.image) return <img src={previewImageMutation.data.image} alt={ticket.name} />;
    return undefined;
  };

  const debounceAndRegenerateTicketPreviewImage = useDebouncedCallback(async () => {
    const img = ticket.newImage ? await convertFileToBase64(ticket.newImage) : savedImage;
    if (img) previewImageMutation.mutate(img);
    // no need to save to the .newImage field because the saved image or the File has not changed
  }, 2000);

  useDidMount(() => {
    if (savedImage) previewImageMutation.mutate(savedImage);
  });

  useEffect(() => {
    debounceAndRegenerateTicketPreviewImage();
  }, [ticket.name, debounceAndRegenerateTicketPreviewImage]);

  useLayoutEffect(() => {
    if (accordionContent.current) {
      const accordion = accordionContent.current;
      setTimeout(() => {
        const height = accordion.clientHeight;
        setContentHeight(height);
      }, 1000);
    }
  }, [values]);

  return (
    <Accordion
      title={accordionTitle}
      defaultActive={initialOpen}
      error={(previewImageMutation.error as any)?.message || ""}
      maxHeight={contentHeight}
      renderLeftIcon={
        !isPublished ? (
          <div onClick={handleRemoveTicket} className="h-5 w-5">
            <MinusCircle stroke="white" />
          </div>
        ) : null
      }
    >
      <div ref={accordionContent}>
        <FormikField className="mb-2" name={`${nameRef}.name`} label="Ticket Name" isFullWidth />
        <div className="flex flex-col lg:flex-row lg:space-x-2">
          <div className="flex-1">
            {!isPublished && (
              <>
                <div className="grid grid-cols-3 gap-2">
                  <FormikField name={`${nameRef}.maxPerWallet`} label="Max per wallet" isFullWidth />
                  <FormikField
                    className="mb-2"
                    name={`${nameRef}.quantityAvailable`}
                    label="Quantity Available"
                    type="number"
                    isFullWidth
                  />
                  <FormikField
                    currencyPrefix={seller.currencySymbol || "£"}
                    className="mb-2"
                    name={`${nameRef}.price`}
                    label="Ticket Price (ex. tax)"
                    type="currency"
                    isFullWidth
                  />
                </div>
              </>
            )}
            <Field name={`${nameRef}.description`}>
              {({ field, meta }: FieldProps) => (
                <TextField
                  {...field}
                  error={meta.touched && meta.error}
                  type="textarea"
                  className="mb-2 min-h-[90px]"
                  label="Ticket Description"
                  isFullWidth
                />
              )}
            </Field>
            <div className="flex justify-between items-center mb-4 mt-4">
              <h4 className="font-bold text-white pr-4">Allow transfers of this ticket</h4>
              <Field type="checkbox" name={nameRef + ".allowTransfers"}>
                {({ field, meta }: FieldProps) => (
                  <Toggle
                    id={nameRef + ".allowTransfers"}
                    name={nameRef + ".allowTransfers_toggle"}
                    isChecked={field.checked}
                    onChange={() => {
                      setTransfersAllowed(!transfersAllowed);
                      setFieldValue(nameRef + ".allowTransfers", !transfersAllowed);
                    }}
                  />
                )}
              </Field>
            </div>
            <p className="mb-4">
              This will allow your customers to transfer their tickets between wallets. You will not earn any secondary
              sale revenue if a user transfers their ticket.
            </p>
          </div>
          <div className="sm:w-1/2 lg:w-1/3 xl:w-2/5 overflow-hidden">
            <DropUploadField
              id="cover-photo-upload"
              recommendedSize={[662, 390]}
              constrainLayout
              maxMB={2}
              onChange={onImageChange}
              alwaysShowFooter
              showPlaceholder={!hideTicketPreviewPlaceholder}
              errorMessage={imageUploadError}
              disabled={previewImageMutation.isLoading}
              placeholderAlign="center"
              renderValue={renderTicketPreview}
              title="Ticket preview"
            />
          </div>
        </div>
      </div>
    </Accordion>
  );
}

export default EditEventTicketForm;
