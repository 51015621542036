import cn from "classnames";
import React from "react";

type Props = {
  color: string;
  selected?: boolean;
  size?: "sm" | "md";
};

const ColorCircle = ({ color, selected, size = "md" }: Props) => {
  const smClass = "w-[42px] h-[42px]";
  const mdClass = "w-[55px] h-[55px]";
  const sizeClass = size === "sm" ? smClass : mdClass;
  return (
    <span
      className={cn("block rounded-full relative overflow-hidden", sizeClass, {
        "outline outline-slate outline-offset-4 outline-[6px]": selected,
      })}
    >
      <span className="block absolute inset-0" style={{ backgroundColor: color }} />
    </span>
  );
};

export default ColorCircle;
