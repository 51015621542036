export function convertFileToBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function () {
      const base64 = reader.result?.toString() ?? "";
      if (base64) resolve(base64);
      else reject();
    };
  });
}

//TODO: NOT WORKING
export async function getBase64FromImgUrl(url: string): Promise<string> {
  const data = await fetch(url, { mode: "no-cors" });
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data as string);
    };
  });
}

export const imgDimensionErrorMessage = (width: number, height: number) => {
  return `Image must have max dimensions of ${width}px x ${height}px`;
};

export function checkImageDimensions(
  file: File | string | undefined,
  sizes: { width?: number; height?: number },
  required: boolean
) {
  const { width, height } = sizes;
  if (!width || !height) return false;

  if (!file) return !required;
  if (typeof file === "string") return true;

  return new Promise<boolean>((resolve) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
    img.onload = function () {
      const dimensionList: ["width" | "height", number][] = [
        ["width", width],
        ["height", height],
      ];
      const validDimensions = dimensionList.every(([dimension, value]) => (!value ? true : img[dimension] <= value));
      URL.revokeObjectURL(objectUrl);
      resolve(validDimensions);
    };
    img.src = objectUrl;
  });
}

export const getFileNameFromFilePath = (filePath: string) => {
  const firstSplit = filePath.split('/').pop();
  if(!firstSplit) {
    return null;
  }
  return firstSplit.split('\\').pop();
};
