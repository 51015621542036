import { Field, FieldProps, useFormikContext } from "formik";
import TextFieldFile from "@atoms/text-field-file/text-field-file";
import { CreateCollectibleValues } from "routes/collectibles/mutate-collectible";
import { InputHTMLAttributes, RefObject } from "react";

type Props = {
  name: string;
  inputRef: RefObject<HTMLInputElement>;
};

function FormikFileField({ name, inputRef }: Props) {
  const { setFieldValue, setFieldTouched, validateField } = useFormikContext<CreateCollectibleValues>();
  const onChange = (e: InputHTMLAttributes<HTMLInputElement> | null) => {
    //@ts-ignore
    const valueToUpdate = !!e ? e.currentTarget.files[0] : null;
    setFieldValue(name, valueToUpdate);
    setFieldTouched(name, true, false);
    setTimeout(() => validateField(name)); //Workaround to force formik to re-validate with correct values
  };
  return (
    <Field {...{ name }}>
      {({ field, meta }: FieldProps) => {
        const { value } = field;
        const error = !!meta.touched ? meta.error : "";
        return <TextFieldFile {...{ inputRef, value, error, name, onChange }} />;
      }}
    </Field>
  );
}

export default FormikFileField;
