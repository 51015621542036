import ScannersApi from "@api/scanners";
import useAuth from "@hooks/use-auth";
import { useMemo } from "react";

function useScannersApi() {
  const { token } = useAuth();

  const scannersApi = useMemo(() => {
    if (!token) throw new Error("jwt token required to init scanners api");
    return new ScannersApi(token);
  }, [token]);

  return scannersApi;
}

export default useScannersApi;
