import { formatUrlParams } from "@utils/format";
import { parse } from "date-fns";
import { URLS, createCustomFetchApi, recursivelyCamelCase, SearchListingResponse, SearchFilters } from "./lib";

type FetchAllCollectiblesResponse = SearchListingResponse<Collectible>;

type FetchedCollectible = {
  id: number;
  title: string;
  description: string;
  quantity_available: number;
  image_url: string | null;
  audio_mp3_path: string | null;
  uuid: string;
  is_live: boolean;
  price: number;
  state: "unpublished" | "processing" | "published";
  max_per_wallet: number | null;
  purchasable: boolean;
  publicly_visible: boolean;
  tokens_remaining: number;
  seller: Owner;
};

export type Collectible = {
  title: string;
  description: string;
  quantityAvailable: number;
  imageUrl: string;
  audioMp3Url: string;
  isLive: boolean;
  uuid: string;
  price?: number;
  payoutAddresses?: {
    payoutAddress: string;
    percentageFee: number;
  }[];
  state: "unpublished" | "processing" | "published";
  maxPerWallet: number | null;
  purchasable: boolean;
  publiclyVisible: boolean;
  tokensRemaining: number;
  seller: Owner;
};

type FetchedOwner = {
  wallet_address: string;
  name?: string;
  bio?: string;
  facebook?: string;
  instagram?: string;
  telegram?: string;
  twitter?: string;
  website?: string;
  discord?: string;
  thumbnail_url?: string;
  cover_photo_url?: string;
  currency?: string;
  currency_symbol?: string;
};

export type Owner = {
  walletAddress: string;
  name?: string;
  bio?: string;
  facebook?: string;
  instagram?: string;
  telegram?: string;
  twitter?: string;
  website?: string;
  discord?: string;
  thumbnailUrl?: string;
  coverPhotoUrl?: string;
  currency?: string;
  currency_symbol?: string;
};

type FetchedCollectibleOrder = {
  id: number;
  token_id: string | null;
  mint_state: string;
  created_at: string;
  purchased_at: string;
  status: "sales" | "pending";
  owner: FetchedOwner | null;
  collectible: FetchedCollectible;
  transaction_hash?: string | null;
};

export type CollectibleOrder = {
  id: number;
  tokenId: string | null;
  mintState: string;
  createdAt: Date;
  purchasedAt: Date;
  status: "sales" | "pending";
  owner: Owner | null;
  collectible: Collectible;
  transactionHash?: string | null;
};

export type GetCollectibleOrdersResponse = {
  currentPage: number;
  lastPage: number;
  perPage: number;
  result: CollectibleOrder[];
  total: number;
};

export type CreateCollectible = Omit<Collectible, "imageUrl"> & {
  image: string;
};

export type AirdropCollectible = {
  collectibleUuid: string;
  ticketTypeUuids: string[];
  updateMaxPerWallet: boolean;
};

export default class CollectiblesApi {
  token: string;
  authenticatedFetch: (input: RequestInfo, init?: RequestInit) => Promise<any>;

  constructor(token: string) {
    if (!token) throw new Error("Error API cannot be initialised without a token");
    this.token = token;
    this.authenticatedFetch = createCustomFetchApi(token);
  }

  createCollectible = (values: Partial<CreateCollectible>) => {
    return this.authenticatedFetch(`${URLS.api}/collectibles`, {
      method: "POST",
      body: JSON.stringify(values),
    }).then((response) => {
      return CollectiblesApi.mapFetchedCollectibleToJs(response.result);
    });
  };

  updateCollectible = (uuid: string, values: Partial<CreateCollectible>) => {
    return this.authenticatedFetch(`${URLS.api}/collectibles/${uuid}`, {
      method: "POST",
      body: JSON.stringify(values),
    }).then((response) => {
      return CollectiblesApi.mapFetchedCollectibleToJs(response.result);
    });
  };

  publishCollectible = (uuid: string) => {
    return this.authenticatedFetch(`${URLS.api}/collectibles/${uuid}/publish`, {
      method: "POST",
    }).then((response) => {
      return response.result;
    });
  };

  airdropCollectible = ({ collectibleUuid, ticketTypeUuids, updateMaxPerWallet }: AirdropCollectible) => {
    const airdropParams = {
      collectible_uuid: collectibleUuid,
      ticket_type_ids: ticketTypeUuids,
      should_update_max_per_wallet: updateMaxPerWallet,
    };
    return this.authenticatedFetch(`${URLS.api}/seller/airdrops`, {
      method: "POST",
      body: JSON.stringify(airdropParams),
    }).then((response) => {
      return response.result;
    });
  };

  fetchCollectible(uuid: string) {
    return this.authenticatedFetch(`${URLS.api}/seller/collectibles/${uuid}`).then((response) => {
      return CollectiblesApi.mapFetchedCollectibleToJs(response.result);
    });
  }

  fetchAllCollectibles = ({ page = 1, perPage = 1000 }: SearchFilters): Promise<FetchAllCollectiblesResponse> => {
    const urlParams = {
      page,
      per_page: perPage,
    };
    const formattedUrlParams = formatUrlParams(urlParams);
    return this.authenticatedFetch(`${URLS.api}/seller/collectibles?${formattedUrlParams}`).then((response) => {
      const formattedCollectibles = (response.result as FetchedCollectible[]).map(
        CollectiblesApi.mapFetchedCollectibleToJs
      );
      return { ...response, result: formattedCollectibles };
    });
  };

  fetchAllPublishedCollectibles = ({
    page = 1,
    perPage = 1000,
  }: SearchFilters): Promise<FetchAllCollectiblesResponse> => {
    const urlParams = {
      page,
      per_page: perPage,
      published: true,
    };
    const formattedUrlParams = formatUrlParams(urlParams);
    return this.authenticatedFetch(`${URLS.api}/seller/collectibles?${formattedUrlParams}`).then((response) => {
      const formattedCollectibles = (response.result as FetchedCollectible[]).map(
        CollectiblesApi.mapFetchedCollectibleToJs
      );
      return { ...response, result: formattedCollectibles };
    });
  };

  getCollectibleOrders = ({ page = 1, perPage = 1000 }: SearchFilters): Promise<GetCollectibleOrdersResponse> => {
    const urlParams = {
      page,
      per_page: perPage,
    };
    return this.authenticatedFetch(`${URLS.api}/seller/collectible-token-orders?${formatUrlParams(urlParams)}`).then(
      (res) => {
        const fetchedCollectibleOrders: FetchedCollectibleOrder[] = res.result;
        const modifiedCollectibleOrders = Array.isArray(fetchedCollectibleOrders)
          ? fetchedCollectibleOrders.map(CollectiblesApi.mapFetchedCollectibleOrdersToJs)
          : [];
        const modified = { ...res, result: modifiedCollectibleOrders };
        return recursivelyCamelCase(modified) as GetCollectibleOrdersResponse;
      }
    );
  };

  static mapFetchedCollectibleToJs(fetchedCollectible: FetchedCollectible) {
    const { price, ...rest } = fetchedCollectible;

    const modified = {
      ...rest,
      ...(price ? { price: price / 100 } : undefined),
    };
    return recursivelyCamelCase(modified) as Collectible;
  }

  static mapFetchedCollectibleOrdersToJs(fetchedCollectibleOrder: FetchedCollectibleOrder) {
    const { created_at, purchased_at, collectible, owner, ...rest } = fetchedCollectibleOrder;

    const modified = {
      ...rest,
      created_at: parse(created_at, "yyyy-MM-dd HH:mm:ss", new Date()),
      purchased_at: parse(purchased_at, "yyyy-MM-dd HH:mm:ss", new Date()),
      collectible: CollectiblesApi.mapFetchedCollectibleToJs(collectible),
      owner: owner ? recursivelyCamelCase(owner) : null,
    };
    return recursivelyCamelCase(modified) as Collectible;
  }
}
