import TicketTypeCard from "@molecules/ticket-type-card";
import { TicketType } from "@api/tickets";
import { TicketTypeListSmallProps } from "./ticket-type-list-small.types";

const TicketTypeListSmall = (props: TicketTypeListSmallProps) => {
  const { ticketTypes, selectedTicketTypes, onSelect } = props;
  return (
    <div className="flex flex-col">
      {ticketTypes?.map((ticketType: TicketType, index: number) => (
        <div className="mt-3">
          <TicketTypeCard
            index={index}
            ticketType={ticketType}
            onSelect={onSelect}
            selected={!!selectedTicketTypes?.includes(ticketType)}
          />
        </div>
      ))}
    </div>
  );
};

export default TicketTypeListSmall;
