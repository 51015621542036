import React, { useMemo, useRef, useState } from "react";
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartArea,
  ScriptableContext,
  Chart,
  TooltipModel,
  TooltipItem,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { eachDayOfInterval, format, subDays } from "date-fns";
import { Props } from ".";
import useAuth from "@hooks/use-auth";

type ToolTip = {
  top: number;
  left: number;
  data: TooltipItem<"line">[];
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const dataSetDefaults = {
  tension: 0.6,
};

const graphOptions = {
  interaction: {
    mode: "index" as "index",
    intersect: false,
  },
  hover: {
    intersect: false,
    mode: "index" as "index",
  },
  maintainAspectRatio: false,
  plugins: {
    legend: { display: false },
    title: { display: false },
  },
};

function getGradient(ctx: CanvasRenderingContext2D, chartArea: ChartArea) {
  let width, height, gradient;

  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, 0, chartArea.right, chartArea.top);
    gradient.addColorStop(0, "#00C2FF");
    gradient.addColorStop(1, "#0067FF");
  }

  return gradient;
}

function Graph({ primaryData, startDate, endDate, hideXAxis, today = new Date() }: Props) {
  const lineChartRef = useRef<any>(null);
  const [tooltipPosition, setTooltipPosition] = useState<ToolTip | null>(null);
  const { currencySymbol } = useAuth();

  const labels = useMemo(() => {
    const isWeekView = primaryData.length === 7;
    if (isWeekView) {
      return eachDayOfInterval({ start: subDays(today, 6), end: today }).map((date) => format(date, "EEE"));
    } else {
      if (!startDate || !endDate) return [];
      return eachDayOfInterval({ start: startDate, end: endDate }).map((date) => format(date, "yyyy-MM-dd"));
    }
  }, [startDate, endDate, primaryData, today]);

  const primaryDataset = useMemo(
    () => ({
      ...dataSetDefaults,
      label: "Primary sales",
      data: primaryData,
      borderColor: function ({ chart: { ctx, chartArea } }: ScriptableContext<"line">) {
        if (!chartArea) return; // This case happens on initial chart load
        return getGradient(ctx, chartArea);
      },
      borderWidth: 4,
    }),
    [primaryData]
  );

  const data: ChartData<"line"> = {
    labels: labels,
    datasets: [primaryDataset],
  };

  const generateExternalToolTip = (context: { chart: Chart; tooltip: TooltipModel<"line"> }) => {
    const chart = lineChartRef.current;
    const tooltip = context.tooltip;
    if (!chart) return setTooltipPosition(null);

    const position = chart.canvas.getBoundingClientRect();
    const left = -88 + position.left + tooltip.caretX;
    const top = position.top + tooltip.caretY + -102 + -13; // tooptip height + pointHoverBorderRadius & pointHoverWidth
    if (top === tooltipPosition?.top && left === tooltipPosition?.left) return;

    setTooltipPosition({ top, left, data: tooltip.dataPoints });
  };

  const renderTooltipLocal = (data: any, currencySymbol: string) => {
    if (!data) {
      return;
    }
    const info = data[0];
    const value = info?.formattedValue;
    const day = info?.label;

    return (
      <div className="bg-grey-blue rounded-lg p-5 flex flex-col h-[102px]">
        <div className="text-xs text-dark-secondary">{day}</div>
        <div className="text-white-80 text-2xl font-bold mt-0.5">
          {currencySymbol}
          {value}
        </div>
      </div>
    );
  };

  return (
    <div className="relative w-full h-60" onMouseLeave={() => setTooltipPosition(null)}>
      <Line
        ref={lineChartRef}
        options={{
          ...graphOptions,
          elements: {
            point: {
              radius: 0,
              hitRadius: 2,
              hoverRadius: 10,
              hoverBorderColor: "white",
              hoverBorderWidth: 3,
              hoverBackgroundColor: "#00C2FF",
            },
          },
          plugins: {
            ...graphOptions.plugins,
            tooltip: {
              enabled: false,
              mode: "nearest",
              external: generateExternalToolTip,
            },
          },
          scales: {
            y: {
              type: "linear",
              grace: "3%",
              beginAtZero: true,
              grid: {
                color: "#3D3C41",
              },
              ticks: {
                count: 5,
                callback: (value: any) => (`${currencySymbol}${value.toFixed(2)}`),
              },
            },
            x: {
              display: !hideXAxis,
              ticks: {
                z: -10,
                padding: 10,
                maxTicksLimit: 10,
                maxRotation: 30,
              },
            },
          },
          responsive: true,
        }}
        data={data}
      />
      {!!tooltipPosition && (
        <div
          className="fixed pointer-events-none"
          style={{ top: tooltipPosition.top, left: tooltipPosition.left, width: 176 }}
        >
          {renderTooltipLocal(tooltipPosition.data, currencySymbol)}
        </div>
      )}
    </div>
  );
}

export default Graph;
