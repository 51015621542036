import { useEffect, useState } from "react";

function useListFilter<I>({
  initialSearchValue,
  items,
  filterFn,
}: {
  initialSearchValue?: string;
  items: I[];
  filterFn: (searchValue: string, item: I) => boolean;
}) {
  const [searchValue, setSearchValue] = useState(initialSearchValue || "");
  const [filteredItems, setFilteredItems] = useState(items);

  useEffect(() => {
    function filterItems() {
      if (!searchValue) return items;
      return items.filter((i) => filterFn(searchValue, i));
    }
    setFilteredItems(filterItems());
  }, [searchValue, items, filterFn]);

  return {
    filteredItems,
    setSearchValue,
    searchValue,
  };
}

export default useListFilter;
