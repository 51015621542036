import { ReactNode, RefObject, useMemo, useState } from "react";

export type Option = {
  label: string;
};

export type DropDownHeadlessProps<O extends Option = Option> = {
  options: O[];
  onChange: (option: O) => void;
  children: (args: {
    optionsList: (O & { action: () => void })[];
    dropdown: ToggleProps;
    handleToggle: <E extends HTMLElement>(ref: RefObject<E>) => void;
  }) => ReactNode;
};

type ToggleProps =
  | {
      open: false;
      top?: number;
      left?: number;
      width?: number;
      bottom?: number;
    }
  | {
      open: true;
      top: number;
      left: number;
      width: number;
      bottom: number;
    };

function DropDownHeadless<O extends Option = Option>({ options, onChange, children }: DropDownHeadlessProps<O>) {
  const [toggle, setToggle] = useState<ToggleProps>({ open: false });
  const closeDropDown = () => setToggle((current) => ({ ...current, open: false }));

  const optionsList = useMemo(() => {
    const handleOptionPress = (option: O) => () => {
      onChange(option);
      closeDropDown();
    };

    return options.map((option) => ({ ...option, action: handleOptionPress(option) }));
  }, [options, onChange]);

  function handleToggle<E extends HTMLElement>(ref: RefObject<E>) {
    const dropdownContainer = ref?.current;
    if (!dropdownContainer) return;

    if (toggle.open === false) {
      const { left, top, width, bottom } = dropdownContainer.getBoundingClientRect();
      setToggle({ open: true, top, left, width, bottom });
    } else {
      closeDropDown();
    }
  }

  return <>{children({ optionsList, dropdown: toggle, handleToggle })}</>;
}

export default DropDownHeadless;
