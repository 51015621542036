import SearchBar from "@atoms/search-bar";
import useMediaQueries from "@hooks/use-media-queries";

type Props = {
  searchValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function CustomerSearchTopSection({ searchValue, onChange }: Props) {
  const { isDesktopAndUp } = useMediaQueries();
  return isDesktopAndUp ? (
    <div className="relative flex gap-x-3 mb-14 justify-between">
      <div className="flex grow max-w-sm">
        <SearchBar
          id="customer-top-nav-search"
          placeholder="Search by username or phone number"
          isFullWidth
          value={searchValue}
          onChange={onChange}
        />
      </div>
    </div>
  ) : (
    <div>
      <div className="flex mb-2 justify-between">
        <div className="flex grow max-w-sm">
          <SearchBar
            id="customer-top-nav-search"
            placeholder="Search"
            isFullWidth
            value={searchValue}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
}

export default CustomerSearchTopSection;
