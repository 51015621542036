import React, { useMemo } from "react";
import {
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  format,
  endOfWeek,
  getDate,
  getDay,
  subDays,
  addDays,
  addMonths,
  isSameDay,
  isSameMonth,
  endOfDay,
  startOfDay,
  isBefore,
  isAfter,
} from "date-fns";
import SVG from "@atoms/svg";
import { ChevronLeft, ChevronRight } from "@atoms/icons";
import { CalendarDate } from "./calendar-date";
import { CalendarBaseProps } from ".";

const CalendarBase = ({
  month,
  weekStartsOn = 1,
  selectedDates,
  onChangeMonth,
  onDayClick,
  minDate,
  maxDate,
}: CalendarBaseProps) => {
  const daysList = useMemo(() => {
    const firstDayOfWeek = startOfWeek(month, { weekStartsOn });
    const lastDayOfWeek = endOfWeek(month, { weekStartsOn });
    return eachDayOfInterval({ start: firstDayOfWeek, end: lastDayOfWeek }).map((date) => format(date, "EEEEEE"));
  }, [weekStartsOn, month]);

  const monthStart = startOfMonth(month);
  const monthEnd = endOfMonth(month);

  const monthCalendarDays = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const previousMonthDays = isSameDay(monthStart, startOfWeek(monthStart, { weekStartsOn }))
    ? []
    : eachDayOfInterval({
        start: startOfWeek(monthStart, { weekStartsOn }),
        end: subDays(monthStart, 1),
      });

  const nextMonthDays = isSameDay(monthEnd, endOfDay(endOfWeek(monthEnd, { weekStartsOn })))
    ? []
    : eachDayOfInterval({
        start: startOfDay(addDays(monthEnd, 1)),
        end: endOfDay(endOfWeek(monthEnd, { weekStartsOn })),
      });

  const calendarDays = [...previousMonthDays, ...monthCalendarDays, ...nextMonthDays];
  const onMonthIncrement = () => onChangeMonth(addMonths(month, 1));
  const onMonthDecrement = () => onChangeMonth(addMonths(month, -1));

  return (
    <div className="w-[257px]">
      <div className="flex justify-between items-center mb-5">
        <button onClick={onMonthDecrement} className="bg-layer-blue p-3 rounded">
          <SVG icon={ChevronLeft} size="xsmall" styles="text-white" />
        </button>
        <span className="text-white text-sm">{format(month, "MMMM yyyy")}</span>
        <button onClick={onMonthIncrement} className="bg-layer-blue p-3 rounded">
          <SVG icon={ChevronRight} size="xsmall" styles="text-white" />
        </button>
      </div>
      <div className="grid grid-cols-7">
        {daysList.map((day) => (
          <div key={`day-${day}`} className="text-white text-sm text-center p-2">
            {day}
          </div>
        ))}
        {calendarDays.map((date) => {
          const dayNumber = getDay(date);
          const isSelected = selectedDates?.find((day) => isSameDay(day, date));
          const isCurrentMonth = isSameMonth(month, date);
          const selected = (() => {
            if (!isSelected || !selectedDates) return undefined;
            if (selectedDates.length === 1 && isSelected === selectedDates[0]) return "active";
            if (isSelected === selectedDates[0]) return "active-start";
            if (isSelected === selectedDates[selectedDates.length - 1]) return "active-end";
            return "between";
          })();

          const disabled = (() => {
            if (minDate && isBefore(date, minDate)) return true;
            if (maxDate && isAfter(date, maxDate)) return true;
            return false;
          })();

          return (
            <CalendarDate
              key={`${date.getMonth()}-${getDate(date)}`}
              onClick={disabled ? undefined : () => onDayClick(date)}
              className="cursor-pointer"
              isFirstDayOfWeek={dayNumber === weekStartsOn}
              {...{ date, selected, isCurrentMonth, disabled }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CalendarBase;
