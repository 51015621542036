import SpinnerLoader from "@atoms/spinner-loader";
import { DashboardModeButtonKeys, DashboardPeriod } from "@constants/dashboard";
import { getPercentageString, getPercentageTextColour } from "@utils/percentage";
import classNames from "classnames";

interface Box {
  title: string;
  number: number;
  percentage: number;
}

interface SingleStatsProps {
  mode: string;
  period: string;
  newEvents: any;
  newCollectibles: any;
  newTicketOrders: any;
  newCollectibleOrders: any;
  newTicketCustomers: any;
  newCollectibleCustomers: any;
}

const DashboardSingleStats = ({
  mode,
  period,
  newEvents,
  newCollectibles,
  newTicketOrders,
  newCollectibleOrders,
  newTicketCustomers,
  newCollectibleCustomers,
}: SingleStatsProps) => {
  const getComparisonText = () => {
    switch (period) {
      case DashboardPeriod.month:
        return "(since last month)";
      default:
        return "(since last week)";
    }
  };

  const StatsColumn = ({ title, number, percentage }: Box) => {
    return (
      <div>
        <div className="bg-slate-grey rounded-lg text-base p-4 font-medium">{title}</div>

        <p className="text-white text-4xl py-5 px-4 font-bold">
          {number}{" "}
          {period !== DashboardPeriod.custom && percentage !== undefined && (
            <>
              <span className={classNames("text-lg font-medium", getPercentageTextColour(percentage))}>
                {getPercentageString(percentage)}
              </span>{" "}
              <span className="text-base opacity-50 font-medium">{getComparisonText()}</span>
            </>
          )}
        </p>
      </div>
    );
  };

  const hasLoaded =
    !!newEvents &&
    !!newCollectibles &&
    !!newTicketOrders &&
    !!newCollectibleOrders &&
    !!newTicketCustomers &&
    !!newCollectibleCustomers;

  const ticketMode = mode === DashboardModeButtonKeys.tickets;

  let newOrders;
  let newNumbers;
  let newCustomers;
  if (ticketMode) {
    newOrders = newTicketOrders;
    newNumbers = newEvents;
    newCustomers = newTicketCustomers;
  } else {
    newOrders = newCollectibleOrders;
    newNumbers = newCollectibles;
    newCustomers = newCollectibleCustomers;
  }

  if (!hasLoaded) return <SpinnerLoader />;

  return (
    <div className="grid grid-cols-3 gap-4">
      <StatsColumn title="New Orders" number={newOrders.count} percentage={newOrders.percentage_change} />
      <StatsColumn
        title={ticketMode ? "Number of Events" : "Number of Collectables"}
        number={newNumbers.count}
        percentage={newNumbers.percentage_change}
      />
      <StatsColumn title="New Customers" number={newCustomers.count} percentage={newCustomers.percentage_change} />
    </div>
  );
};

export default DashboardSingleStats;
