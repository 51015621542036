import * as React from "react";

function Ticket(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M26 8.36A1.37 1.37 0 0024.62 7H7.38A1.37 1.37 0 006 8.36v1.762c0 .63.453 1.16 1.021 1.45 1.442.734 2.427 2.217 2.427 3.928 0 1.71-.985 3.194-2.427 3.929-.568.29-1.021.818-1.021 1.449v1.762c0 .751.618 1.36 1.38 1.36h17.24A1.37 1.37 0 0026 22.64v-1.762c0-.63-.453-1.16-1.021-1.45-1.442-.734-2.427-2.217-2.427-3.928 0-1.71.985-3.194 2.427-3.929.568-.29 1.021-.818 1.021-1.449V8.36z"
        stroke="currentcolor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Ticket;
