import useMediaQueries from "@hooks/use-media-queries";
import { Props } from "./event-card.types";
import EventTableRow from "./event-table-row";
import MobileEventCard from "./mobile-view-event-card";

function EventCard(props: Props) {
  const { isDesktopAndUp } = useMediaQueries();
  return isDesktopAndUp ? <EventTableRow {...props} /> : <MobileEventCard {...props} />;
}

export default EventCard;
