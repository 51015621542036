import * as React from "react";

function IconSwitcher(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.75 6.5L5.5 3 2.25 6.5M2.25 14.5L5.5 18l3.25-3.5"
        stroke="currentcolor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconSwitcher;
