import { MoonLoader } from "react-spinners";

function SpinnerLoader() {
  return (
    <div className="flex flex-1 justify-center mt-20">
      <MoonLoader color="white" size={20} />
    </div>
  );
}

export default SpinnerLoader;
