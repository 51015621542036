import cn from "classnames";
import Box from "@atoms/box";
import { displayDate } from "@utils/date";
import { displayCurrency } from "@utils/number";
import OrderStatusPill from "./order-status-pill";
import { Props } from "./order-card.types";
import TransactionHash from "@molecules/transaction-hash";
import useAuth from "@hooks/use-auth";

export const gridColClasses = "grid gap-5 grid-cols-[96px_1fr_1fr_1fr_1fr_104px] items-center";

function OrderTableRow({ order, onView }: Props) {
  const { tokenId, status, username, thumbnail, purchasedAt, price } = order;
  const trimWalletAddress = (address: string) => address.substring(0, address.lastIndexOf("."));
  const { currency } = useAuth();

  return (
    <Box variant="dark-grey" padding="none" className="overflow-hidden">
      <div className={cn("text-white", gridColClasses)}>
        <div className="rounded-lg overflow-hidden m-1 aspect-square h-20 bg-layer-blue">
          {thumbnail ? <img src={thumbnail} alt="ticket thumbnail" className="h-full w-full object-cover" /> : null}
        </div>
        <div children={!!tokenId ? <TransactionHash hash={tokenId} /> : <span>-</span>} />
        <div children={<span>{!!username ? trimWalletAddress(username) : "-"}</span>} />
        <div children={<span>{displayDate(purchasedAt)}</span>} />
        <div children={<span>{displayCurrency(price || 0, "en-GB", currency)}</span>} />
        <div children={<OrderStatusPill status={status} />} />
        {/* <div className="flex flex-1 items-center text-sm gap-2">
          <div className="text-center cursor-pointer" onClick={onView}>
            <SVG icon={View} size="medium" />
            <div>View</div>
          </div>
        </div> */}
      </div>
    </Box>
  );
}

export default OrderTableRow;
