import BadgeAlert from "@atoms/badge-alert";
import { ReactElement } from "react";

type Props = {
  children: string | ReactElement;
};

const StripeBadgeAlert = ({ children }: Props) => (
  <BadgeAlert message={children} variant="warn" className="w-full mb-2" />
);

export default StripeBadgeAlert;
