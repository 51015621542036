import Box from "@atoms/box";
import cn from "classnames";
import { Props } from "./airdrop-collectible-card.types";
import { displayCurrency } from "@utils/number";
import { isNumber } from "lodash";
import useAuth from "@hooks/use-auth";

export const gridColClasses = "grid gap-4 grid-cols-[96px_1fr_1fr_1fr] items-center";

function AirdropCollectibleTableRow({
  collectible,
  image,
  title,
  available,
  remaining,
  price,
  onClickRow,
  selected,
  eligibleWallets,
}: Props) {
  const baseClassName = selected ? "overflow-hidden outline outline-2 outline-bluer" : "overflow-hidden";
  const className =
    remaining >= eligibleWallets ? `cursor-pointer ${baseClassName}` : `opacity-30 cursor-auto ${baseClassName}`;
  const onClick = remaining >= eligibleWallets ? () => onClickRow(collectible) : () => {};
  const availableEditions = remaining + "/" + available;
  const { currency } = useAuth();
  return (
    <Box variant="dark-grey" padding="none" className={className}>
      <div className={cn("text-white", gridColClasses)} onClick={onClick}>
        <div className="rounded-lg overflow-hidden m-1 aspect-square h-20 bg-layer-blue">
          {image ? <img src={image} alt="collectible thumbnail" className="h-full w-full object-cover" /> : null}
        </div>
        <div children={<span>{title}</span>} />
        <div children={<span>{availableEditions}</span>} />
        <div
          children={
            <span>
              {displayCurrency(isNumber(price) ? price : 0, "en-GB", currency)}
            </span>
          }
        />
      </div>
    </Box>
  );
}

export default AirdropCollectibleTableRow;
