import Box from "@atoms/box";
import { Close, Highlighter, Refresh, View } from "@atoms/icons";
import Svg from "@atoms/svg";
import { displayDate, displayTime } from "@utils/date";
import cn from "classnames";
import { useMemo } from "react";
import { EventStatus, Props } from "./event-card.types";

export const gridColClasses = "grid gap-5 grid-cols-[96px_1fr_0.5fr_0.5fr_1fr_0.5fr_150px] items-center";
const defaultStatus = EventStatus.unpublished;

function EventTableRow({
  thumbnail,
  name,
  date,
  venue,
  status = defaultStatus,
  onCancel,
  onEdit,
  onView,
  onRefund,
}: Props) {
  const actions = useMemo(
    () => [
      {
        Icon: View,
        label: "View",
        onClick: onView,
        disabled: status !== EventStatus.published,
        title: status !== EventStatus.published ? "Publish event first in order to view it" : "",
      },
      { Icon: Highlighter, label: "Edit", onClick: onEdit },
      // { Icon: Close, label: "Cancel", onClick: onCancel ? () => onCancel() : undefined, disabled: !onCancel },
      // { Icon: Refresh, label: "Refund", onClick: onRefund ? () => onRefund() : undefined, disabled: !onRefund },
    ],
    [onEdit, onView, status]
  );

  return (
    <Box variant="dark-grey" padding="none" className="overflow-hidden">
      <div className={cn("text-white", gridColClasses)}>
        <div className="rounded-lg overflow-hidden m-1 aspect-square h-20 bg-layer-blue">
          {thumbnail ? <img src={thumbnail} alt="ticket thumbnail" className="h-full w-full object-cover" /> : null}
        </div>
        <div children={<span>{name}</span>} />
        <div children={<span>{displayDate(date)}</span>} />
        <div children={<span>{displayTime(date)}</span>} />
        <div children={<span>{venue}</span>} />
        <div children={<span>{status}</span>} />
        <div className="flex text-sm gap-8 pr-4">
          {actions.map(({ disabled, onClick, label, Icon, title }) => {
            const className = cn("text-center", disabled ? "cursor-not-allowed" : "cursor-pointer", {
              "opacity-25": disabled,
            });
            return (
              <div key={label} {...{ title, className }} onClick={disabled ? undefined : onClick}>
                <Svg icon={Icon} size="medium" styles="mx-auto" />
                <div>{label}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Box>
  );
}

export default EventTableRow;
