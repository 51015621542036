import useCustomers from "@hooks/use-customers";
import CustomersListing from "@pages/customers";
import { useQuery } from "react-query";
import { Customer } from "@api/customers";
import { useNavigate } from "react-router-dom";
import SpinnerLoader from "@atoms/spinner-loader";
import DocumentTitle from "@atoms/document-title";
import PaginationButtons from "@molecules/pagination-buttons";
import { useState } from "react";
import CustomerSearchTopSection from "@molecules/customer-search-top-section";
import { LayoutTitle } from "@organisms/layout";

const perPage = 6;

function MyCustomersListing() {
  const [page, setPage] = useState(1);
  const { fetchMyCustomers } = useCustomers();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const myCustomersQuery = useQuery(["myCustomers", { perPage, page, searchValue }], () =>
    fetchMyCustomers({ page, perPage, searchValue })
  );

  const ListArgs = {
    currentPageIndex: 0,
    totalPages: 3,
    resultsPerPage: 5,
    onChangePage: () => {},
    onSort: () => {},
  };

  const onViewCustomer = (customer: Customer) => {
    navigate(`/customers/${customer.id}`);
  };

  return (
    <>
      <DocumentTitle
        titlePrefix="Customers - Get in-depth customer insights"
        description="Get to know your audience, engage with them, and provide them with exceptional experiences to increase your sales."
      />
      <LayoutTitle title="Customers" />
      <CustomerSearchTopSection
        {...{ searchValue }}
        onChange={(e) => {
          setPage(1);
          setSearchValue(e.target.value);
        }}
      />

      {myCustomersQuery.isSuccess ? (
        <>
          <CustomersListing customers={myCustomersQuery.data.result ?? []} onView={onViewCustomer} {...ListArgs} />
          {myCustomersQuery.data.last_page > 1 && (
            <div className="flex flex-row justify-center my-8">
              <PaginationButtons activePage={page} setPage={setPage} pages={myCustomersQuery.data.last_page} />
            </div>
          )}
        </>
      ) : (
        <SpinnerLoader />
      )}
    </>
  );
}

export default MyCustomersListing;
