import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "@hooks/use-auth";
import Text from "@atoms/text";
import useMediaQueries from "@hooks/use-media-queries";
import DocumentTitle from "@atoms/document-title";

function AuthSuccess() {
  const { isMobile } = useMediaQueries();
  const { handleSignInSuccess, wallet } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const account = wallet?.account();
    if (!account?.accountId) throw Error("Account not found");
    handleSignInSuccess(account).then(() => navigate("/"));
  }, [navigate, handleSignInSuccess, wallet]);

  return (
    <div className="text-white text-center mt-10">
      <DocumentTitle />
      <Text variant={!isMobile ? "heading1" : "heading2"} as="h1">
        Wallet <em>{wallet?.account()?.accountId}</em> connected
      </Text>
      <p className="mt-8">You will be redirected shortly</p>
    </div>
  );
}

export default AuthSuccess;
