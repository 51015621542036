import { Field, FieldProps, useFormikContext } from "formik";
import Button from "@atoms/button";
import Fieldset from "@atoms/fieldset";
import Text from "@atoms/text";
import { CreateCollectibleValues } from "routes/collectibles/mutate-collectible";
import FormikField from "@molecules/formik-field";
import FormikFileField from "@molecules/formik-file-field";
import TextField from "@atoms/text-field";
import useAuth from "@hooks/use-auth";

export type Values = {
  title: string;
  description: string;
  quantityAvailable: number;
  price: number;
  media: File | undefined;
  imageUrl?: string;
};

export const initialValues: Values = {
  title: "",
  description: "",
  media: undefined,
  quantityAvailable: 1,
  price: 0,
  imageUrl: "",
};

type FormProps = {
  mediaFieldRef: React.RefObject<HTMLInputElement>;
  onUpload: () => void;
};

function CollectiblesCreateForm({ mediaFieldRef, onUpload }: FormProps) {
  const { values } = useFormikContext<CreateCollectibleValues>();
  const { currencySymbol } = useAuth();
  return (
    <Fieldset variant="primary" heading={`Create collectables${values.title ? ` - ${values.title}` : ""}`}>
      <div>
        <Text variant="heading3" className="text-grey-translucent-40">
          Edit all of your collectable NFT information here. You can also select and preview multiple different file
          types.
        </Text>
        <div className="mt-4">
          <FormikField className="mb-3" name="title" label="Title" isFullWidth />
          <FormikField
            className="mb-3  h-36"
            isFullWidth
            type="textarea"
            name="description"
            placeholder="Description"
          />
          <FormikField className="mb-3" name="quantityAvailable" label="Quantity Available" isFullWidth type="number" />
          <Field name="price">
            {({ field, meta }: FieldProps) => {
              const error = !!meta.touched && meta.error;
              return (
                <TextField
                  {...{ ...field, error }}
                  type="currency"
                  isFullWidth
                  className="mb-3"
                  currencyPrefix={currencySymbol}
                  label="Price"
                  name="price"
                />
              );
            }}
          </Field>
          <Button variant="primary" onClick={onUpload} isFullWidth className="mb-3">
            Choose file to upload
          </Button>
          <FormikFileField name="media" inputRef={mediaFieldRef} />
        </div>
      </div>
    </Fieldset>
  );
}

export default CollectiblesCreateForm;
