import Box from "@atoms/box";
import cn from "classnames";
import { displayDate } from "@utils/date";
import Pill, { PillColor } from "@atoms/pill";
import { TicketStatus, TicketType } from "@api/tickets";
import { displayCurrency } from "@utils/number";
import ClippedText from "@atoms/clipped-text";
import TransactionHash from "@molecules/transaction-hash";
import useAuth from "@hooks/use-auth";

export const gridColClasses = "grid gap-5 grid-cols-[80px_1fr_1fr_0.8fr_0.8fr_100px_80px] items-center";

export type CustomerTicketCardProps = {
  index: number;
  ticketType: TicketType;
  purchasedAt: Date;
  transactionHash?: string;
  price: number;
  status: TicketStatus;
};

function statusToText(status: TicketStatus) {
  switch (status) {
    case "sales":
      return "Sales";
    case "cancelled":
      return "Cancelled";
    case "refunded":
      return "Refunded";
    case "resale":
      return "Transfer";
  }
}

function statusToPillColour(status: TicketStatus) {
  switch (status) {
    case "sales":
      return PillColor.success;
    case "cancelled":
      return PillColor.slate;
    case "refunded":
      return PillColor.warning;
    case "resale":
      return PillColor.info;
  }
}

function CustomerTicketCard({ ticketType, purchasedAt, transactionHash, price, status }: CustomerTicketCardProps) {
  const { currency } = useAuth();
  return (
    <Box variant="dark-grey" padding="none" className="overflow-hidden">
      <div className={cn("text-white", gridColClasses)}>
        <div className="p-2">
          <img
            src={ticketType.baseImageUrl}
            alt="ticket thumbnail"
            className="w-full object-cover aspect-square rounded"
          />
        </div>
        <div children={<ClippedText allowCopy fluid text={ticketType.event?.name || ""} />} />
        <div children={<ClippedText allowCopy fluid text={ticketType.name} />} />
        <div children={<span>{displayDate(purchasedAt)}</span>} />
        <div children={<span>{transactionHash ? <TransactionHash hash={transactionHash} /> : "-"}</span>} />
        <div children={<span>{displayCurrency(price, "en-GB", currency)}</span>} />
        <div children={<Pill className="" label={statusToText(status)} color={statusToPillColour(status)} />} />
      </div>
    </Box>
  );
}

export default CustomerTicketCard;
