import { Seller } from "@api/auth";
import Button from "@atoms/button";
import { Plus } from "@atoms/icons";
import Modal from "@atoms/modal";
import Svg from "@atoms/svg";
import Text from "@atoms/text";
import useMutateEvent from "@hooks/use-mutate-event";
import { FieldArrayRenderProps, useFormikContext } from "formik";
import { useCallback, useEffect, useState } from "react";
import { MutateEventFormValues } from "routes/events/form-helpers";
import EditEventTicketForm from "./edit-ticket-form";
import { FormTicketType } from "./event-tickets";

type Props = {
  helpers: FieldArrayRenderProps;
  seller: Seller;
};

const BASE_TICKET: FormTicketType = {
  name: "",
  price: "",
  //maxResalePrice: "",
  maxPerWallet: 4,
  quantityAvailable: "",
  description: "",
  uuid: "",
  //royalties: [],
  allowTransfers: false,
};

function EditTicketsForm({ helpers, seller }: Props) {
  const { values, initialValues } = useFormikContext<MutateEventFormValues>();
  const { deleteTicketType } = useMutateEvent();
  const [ticketToDelete, setTicketToDelete] = useState<{ idx: number; ticket: FormTicketType } | undefined>();

  const addTicket = useCallback(() => helpers.push(BASE_TICKET), [helpers]);

  const closeDeleteTicketModal = () => setTicketToDelete(undefined);
  const onDeleteTicket = (idx: number, ticket: FormTicketType) => setTicketToDelete({ idx, ticket });
  const onConfirmDeleteTicket = () => {
    if (ticketToDelete) {
      const { idx, ticket } = ticketToDelete;
      helpers.remove(idx);
      if (ticket.uuid) deleteTicketType.mutate(ticket.uuid);
    }
    closeDeleteTicketModal();
  };

  useEffect(() => {
    if (!values.ticketTypes.length) addTicket();
  }, [values, addTicket]);

  return (
    <>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          {values.ticketTypes.map((ticket, idx, allTickets) => (
            <EditEventTicketForm
              key={ticket.id || ticket.uuid || idx}
              nameRef={`ticketTypes.${idx}`}
              accordionTitle={`Ticket ${idx + 1}${ticket.name ? ` - ${ticket.name}` : ""}`}
              initialOpen={idx === allTickets.length - 1}
              onRemoveTicket={() => onDeleteTicket(idx, ticket)}
              seller={seller}
            />
          ))}
        </div>
        {!initialValues.isLive && (
          <div className="flex flex-row text-white items-center my-4 justify-center">
            <Text
              variant="heading3"
              as="h3"
              className="underline underline-offset-4 cursor-pointer items-center mr-2"
              onClick={addTicket}
            >
              Add a new ticket
            </Text>
            <Svg icon={Plus} size="small" />
          </div>
        )}
      </div>
      <Modal
        isVisible={!!ticketToDelete}
        variant="secondary"
        onClose={closeDeleteTicketModal}
        title={`Delete ${ticketToDelete?.ticket?.name || "ticket type"}?`}
        subtitle="This action is irreversible"
        size="medium"
      >
        <div className="flex justify-evenly">
          <Button variant="outline" onClick={closeDeleteTicketModal}>
            No, cancel
          </Button>
          <Button variant="secondary" onClick={onConfirmDeleteTicket}>
            Yes, delete ticket type
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default EditTicketsForm;
