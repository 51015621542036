import useAuth from "@hooks/use-auth";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useEffect } from "react";
import DocumentTitle from "@atoms/document-title";
import SpinnerLoader from "@atoms/spinner-loader";
import { LogoFull } from "@atoms/icons";
import LoginForm from "@organisms/login-form";
import { Login } from "@api/auth";
import { loginFormValidation as validationSchema } from "@pages/settings/form-helpers";
import { Formik } from "formik";
import { isObject } from "lodash";
import BadgeAlert from "@atoms/badge-alert";

declare global {
  interface Window {
    Intercom: (method: string, params: any) => void;
  }
}

function EmailLogin() {
  const navigate = useNavigate();
  const { emailUser, emailSignIn, getMyProfile, isCompleteProfile, formError, setFormError } = useAuth();
  const { data: profile } = useQuery("myProfile", getMyProfile);

  const updateIntercomSettings = (name: string | undefined, email: string | undefined) => {
    window.Intercom("update", {
      email: email,
      name: name,
    });
  };

  useEffect(() => {
    if (emailUser && process.env.REACT_APP_VERCEL_ENV === "production") {
      updateIntercomSettings(profile?.name, emailUser?.email);
    }
  }, [emailUser, emailUser?.email, profile?.name]);

  useEffect(() => {
    if (!isObject(profile)) return;
    if (emailUser) {
      if (!isCompleteProfile(profile)) navigate("/settings");
      else navigate("/", { replace: true });
    }
  }, [profile, isCompleteProfile, emailUser, navigate]);

  const emptyInitialValues: Login = {
    email: localStorage.email || "",
    password: localStorage.password || "",
    rememberMe: localStorage.rememberMe === "true",
  };

  if (!emailUser) {
    return (
      <>
        <div className="border-b border-dark-grey flex items-center w-full h-24 px-4 md:px-10">
          <LogoFull className="h-8" />
        </div>
        <div className="w-3/4 mt-4 mx-auto">
          {!!formError && (
            <BadgeAlert
              variant="error"
              className="top-0 left-0 w-full"
              message={formError}
              onClose={() => {
                setFormError("");
              }}
            />
          )}
        </div>
        <div className="text-white relative p-4 flex flex-col items-center justify-center h-full overflow-hidden">
          <DocumentTitle titlePrefix="Login" />
          <div className="min-w-md max-w-md">
            <h1 className="text-3xl mb-3 text-left z-30 font-bold">Welcome back</h1>
            <h4>Welcome to the SeatlabNFT seller dashboard</h4>
            <div className="text-left pt-4">
              <Formik
                {...{ initialValues: emptyInitialValues, onSubmit: emailSignIn, validationSchema }}
                validateOnMount
                enableReinitialize
              >
                <LoginForm />
              </Formik>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <DocumentTitle titlePrefix="Login..." />
      {<SpinnerLoader />}
    </>
  );
}

export default EmailLogin;
