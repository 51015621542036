import { Helmet } from "react-helmet";
import { isNull } from "lodash";

type Props = {
  titlePrefix?: string;
  title?: string;
  canonicalUrl?: string;
  image?: string;
  description?: string;
};

function DocumentTitle({ titlePrefix, title, canonicalUrl, image, description }: Props) {
  const headTitle = title || (titlePrefix ? `${titlePrefix} | SeatlabNFT` : "SeatlabNFT | Seller Dashboard");
  if (isNull(description)) {
    description =
      "SeatlabNFT Seller Dashboard - Keep track of NFT tickets, collectables & orders in one intuitive dashboard. Create events, list tickets, deploy airdrops and more.";
  }

  return (
    <Helmet
      meta={[
        { property: "og:title", content: headTitle },
        { property: "og:description", content: description },
        { property: "description", content: description },
        ...(canonicalUrl ? [{ property: "og:url", content: canonicalUrl }] : []),
        ...(image ? [{ property: "og:image", content: image }] : []),
      ]}
    >
      <title>{headTitle}</title>
    </Helmet>
  );
}

export default DocumentTitle;
