import * as React from "react";

function Paperclip(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#prefix__clip0_2070_95)">
        <path
          d="M12 0a6.515 6.515 0 00-6.508 6.508v12.32a.795.795 0 101.592 0V6.508A4.922 4.922 0 0112 1.59a4.922 4.922 0 014.917 4.917V19.21a3.203 3.203 0 01-3.2 3.199c-.012 0-.024.007-.037.008-.014-.001-.025-.008-.039-.008a3.203 3.203 0 01-3.199-3.199v-7.62a1.559 1.559 0 013.116 0v7.238a.796.796 0 101.591 0v-7.237A3.152 3.152 0 0012 8.44a3.153 3.153 0 00-3.149 3.15v7.62C8.851 21.85 11 24 13.641 24c.014 0 .025-.007.039-.008.013.001.025.008.038.008 2.64 0 4.79-2.15 4.79-4.79V6.508A6.515 6.515 0 0012 0z"
          fill="currentcolor"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_2070_95">
          <path fill="currentcolor" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Paperclip;
