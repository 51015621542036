export enum EventStatus {
  unpublished = "Draft",
  processing = "Processing",
  published = "Live",
}

export type Props = {
  name: string;
  date: Date;
  venue: string;
  status?: EventStatus;
  thumbnail?: string;
  onEdit: () => void;
  onView: () => void;
  onCancel?: () => void;
  onRefund?: () => void;
};
