import TicketsApi, { TicketType, CreateTicketParams, FetchedTicketType } from "./tickets";
import { mapPercentageChangeValues } from "./lib";
// @TODO replace with snakeCase from lodash
import { snakeCase } from "snake-case";
import {
  URLS,
  createCustomFetchApi,
  FetchAPIOptions,
  recursivelyCamelCase,
  handleErrors,
  SearchFilters,
  SearchListingResponse,
} from "./lib";
import { format, parse } from "date-fns";

import { formatUrlParams } from "@utils/format";

export type FetchedEvent = {
  state: "unpublished" | "processing" | "published";
  id: string;
  name?: string;
  slug: string;
  description?: string;
  start_date?: string;
  end_date?: string;
  entry_date?: string;
  venue_name?: string;
  cover_photo_url?: string;
  thumbnail_url?: string;
  ticket_types?: FetchedTicketType[];
  is_live: boolean;
};

export type Occassion = {
  state?: "unpublished" | "processing" | "published";
  id: string;
  name: string;
  slug: string;
  description?: string;
  venueName: string;
  coverPhotoUrl?: string;
  thumbnailUrl?: string;
  ticketTypes?: TicketType[];
  startDate: Date;
  endDate: Date;
  entryDate: Date;
  isLive: boolean;
};

type FetchAllEventsResponse = SearchListingResponse<Occassion>;

type MutateEventParams = Omit<
  Occassion,
  "startDate" | "endDate" | "entryDate" | "coverPhotoUrl" | "thumbnailUrl" | "id" | "isLive" | "ticketTypes"
> &
  Partial<{
    startDate: string;
    endDate: string;
    entryDate: string;
    coverPhoto: string;
    thumbnail: string;
    ticketTypes: CreateTicketParams[];
  }>;

export type UpdateEventParams = Partial<MutateEventParams>;
export type CreateEventParams = Omit<MutateEventParams, "ticketTypes" | "slug">;
type EventsApiOptions = FetchAPIOptions & {};

type EventSales = {
  total_tickets_available: number;
  total_tickets_sold: number;
  events: {
    name: string;
    slug: string;
    total_tickets_available: number;
    tickets_sold: number;
  }[];
};

type CollectableSales = {
  total_collectible_tokens_available: number;
  total_collectible_tokens_sold: number;
  collectibles: {
    title: string;
    uuid: string;
    collectible_tokens_available: number;
    collectible_tokens_sold: number;
  }[];
};

export type GetImagePreviewArgs = {
  venueName: string;
  startDate: Date;
  endDate: Date;
  eventName: string;
  ticketTypeTitle: string;
  sellerName?: string;
  image: string; // this either be a base64 or imageUrl
  sellerImage?: string;
};

export default class EventsApi {
  token: string;
  fetchRequest: (input: RequestInfo, init?: RequestInit) => Promise<any>;

  constructor(token: string, options?: Partial<EventsApiOptions>) {
    if (!token) throw new Error("Error API cannot be initialised without a token");
    this.token = token;
    this.fetchRequest = createCustomFetchApi(token);
  }

  getAllEvents = ({ page = 1, perPage = 1000 }: SearchFilters): Promise<FetchAllEventsResponse> => {
    const urlParams = {
      page,
      per_page: perPage,
    };
    const formattedUrlParams = formatUrlParams(urlParams);
    return this.fetchRequest(`${URLS.api}/seller/events?${formattedUrlParams}`).then((response) => {
      const formattedEvents = response.result.map(EventsApi.mapFetchedEventToJs);
      return { ...response, result: formattedEvents };
    });
  };

  getAllPublishedEvents = ({ page = 1, perPage = 1000 }: SearchFilters): Promise<FetchAllEventsResponse> => {
    const urlParams = {
      page,
      per_page: perPage,
    };
    const formattedUrlParams = formatUrlParams(urlParams);
    return this.fetchRequest(`${URLS.api}/seller/events?published=true&${formattedUrlParams}`).then((response) => {
      const formattedEvents = response.result.map(EventsApi.mapFetchedEventToJs);
      return { ...response, result: formattedEvents };
    });
  };

  getEventBySlug = (slug: string) => {
    return this.fetchRequest(`${URLS.api}/seller/events/${slug}`).then((response) => {
      const savedEvent = response.result;
      return EventsApi.mapFetchedEventToJs(savedEvent);
    });
  };

  createEvent = (eventDetails: CreateEventParams): Promise<Occassion> => {
    const snakeCased = Object.entries(eventDetails).reduce((accum, [k, v]) => ({ ...accum, [snakeCase(k)]: v }), {});
    return this.fetchRequest(`${URLS.api}/events`, {
      method: "POST",
      body: JSON.stringify(snakeCased),
    }).then((response) => {
      return EventsApi.mapFetchedEventToJs(response.result);
    });
  };

  updateEvent = (slug: string, eventDetails: UpdateEventParams): Promise<Occassion> => {
    const snakeCased = Object.entries(eventDetails).reduce((accum, [k, v]) => ({ ...accum, [snakeCase(k)]: v }), {});
    return this.fetchRequest(`${URLS.api}/events/${slug}`, {
      method: "POST",
      body: JSON.stringify(snakeCased),
    }).then((response) => {
      const savedEvent = response.result;
      return EventsApi.mapFetchedEventToJs(savedEvent);
    });
  };

  publishEvent = (eventSlug: string) => {
    return this.fetchRequest(`${URLS.api}/events/${eventSlug}/publish`, {
      method: "POST",
      body: JSON.stringify({}),
    });
  };

  getPreviewImage = ({ startDate, endDate, ...args }: GetImagePreviewArgs): Promise<{ image: string }> => {
    return fetch(`https://approb3f6sfn4i4uvvp76koo740vfyuc.lambda-url.eu-west-2.on.aws/`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        ...args,
        startDate: format(startDate, "yyyy-MM-dd HH:mm:ss"),
        endDate: format(endDate, "yyyy-MM-dd HH:mm:ss"),
      }),
    })
      .then(handleErrors)
      .then((res) => {
        if (!res) throw new Error("The image you have uploaded is not supported - please try another one");
        return res;
      });
  };

  getAllEventSales = (): Promise<{ result: EventSales }> => {
    return this.fetchRequest(`${URLS.api}/seller/reports/event-sales`);
  };

  getAllCollectibleSales = (): Promise<{ result: CollectableSales }> => {
    return this.fetchRequest(`${URLS.api}/seller/reports/collectible-sales`);
  };

  getCollectibleTokenSales = (startDate: string, endDate: string) => {
    return this.fetchRequest(
      `${URLS.api}/seller/reports/collectible-token-sales?start_date=${startDate}&end_date=${endDate}`
    ).then((data) => {
      const result = Object.entries(data.result).reduce((accum, [key, val]) => {
        const value = val as any;
        return { ...accum, [key]: { ...value, total_price: value.total_price / 100 } };
      }, {} as { [date: string]: { total_sales: number; total_price: number } });
      return { result };
    });
  };

  getNewEvents = (startDate: string, endDate: string) => {
    return this.fetchRequest(`${URLS.api}/seller/reports/new-events?start_date=${startDate}&end_date=${endDate}`).then(
      ({ result }) => {
        return {
          result: {
            ...result,
            ...mapPercentageChangeValues(["percentage_change"], result),
          },
        };
      }
    );
  };

  getNewCollectibles = (startDate: string, endDate: string) => {
    return this.fetchRequest(
      `${URLS.api}/seller/reports/new-collectibles?start_date=${startDate}&end_date=${endDate}`
    ).then(({ result }) => {
      return {
        result: {
          ...result,
          ...mapPercentageChangeValues(["percentage_change"], result),
        },
      };
    });
  };

  getNewCollectibleOrders = (startDate: string, endDate: string) => {
    return this.fetchRequest(
      `${URLS.api}/seller/reports/new-collectible-orders?start_date=${startDate}&end_date=${endDate}`
    ).then(({ result }) => {
      return {
        result: {
          ...result,
          ...mapPercentageChangeValues(["percentage_change"], result),
        },
      };
    });
  };

  getNewCollectibleCustomers = (startDate: string, endDate: string) => {
    return this.fetchRequest(
      `${URLS.api}/seller/reports/new-collectible-customers?start_date=${startDate}&end_date=${endDate}`
    ).then(({ result }) => {
      return {
        result: {
          ...result,
          ...mapPercentageChangeValues(["percentage_change"], result),
        },
      };
    });
  };

  getCollectibleTokenSalesComparison = (startDate: string, endDate: string) => {
    return this.fetchRequest(
      `${URLS.api}/seller/reports/collectible-token-sales-comparison?start_date=${startDate}&end_date=${endDate}`
    ).then(({ result }) => {
      return {
        result: {
          ...result,
          ...mapPercentageChangeValues(["sales_count_percentage_change"], result),
        },
      };
    });
  };

  static mapFetchedEventToJs(fetchedEvent: FetchedEvent) {
    const { start_date, end_date, entry_date, ticket_types, ...rest } = fetchedEvent;
    function getDisplayDate(dateString: string) {
      return parse(dateString, "yyyy-MM-dd HH:mm:ss", new Date());
    }
    const modified = {
      ...rest,
      ...(start_date ? { start_date: getDisplayDate(start_date) } : undefined),
      ...(end_date ? { end_date: getDisplayDate(end_date) } : undefined),
      ...(entry_date ? { entry_date: getDisplayDate(entry_date) } : undefined),
      ...(ticket_types ? { ticket_types: ticket_types.map(TicketsApi.mapFetchedTicketTypeToJs) } : undefined),
    };
    return recursivelyCamelCase(modified) as Occassion;
  }
}
