import * as React from "react";

function Dashboard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.333 9A2.667 2.667 0 019 6.333h14A2.667 2.667 0 0125.667 9v14A2.667 2.667 0 0123 25.667H9A2.667 2.667 0 016.333 23V9zM13 11.667v13.666M6.667 11h18.666"
        stroke="currentcolor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Dashboard;
