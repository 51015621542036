import { useMediaQuery } from "react-responsive";
import { screens } from "tailwindcss/defaultTheme";

// sm = '640px';
// md = '768px';
// lg = '1024px';
// xl = '1280px';
// 2xl = '1536px';

function getScreenWidth(variant: string) {
  return parseInt((screens as any)[variant], 10);
}
const useMediaQueries = () => {
  const isDesktopAndUp = useMediaQuery({ minWidth: getScreenWidth("xl") });
  const isTabletOnly = useMediaQuery({ minWidth: getScreenWidth("md"), maxWidth: getScreenWidth("xl") - 1 });
  const isTabletAndUp = useMediaQuery({ minWidth: getScreenWidth("md") });
  const isMobile = useMediaQuery({ maxWidth: getScreenWidth("sm") - 1 });

  return {
    isDesktopAndUp,
    isTabletOnly,
    isTabletAndUp,
    isMobile,
  };
};

export default useMediaQueries;
