import Button from "@atoms/button";
import BreadcrumbNavigation from "@molecules/breadcrumb-navigation";

export enum CreateEventStageEnum {
  basics = "basics",
  promotions = "promotions",
  tickets = "tickets",
  royalties = "royalties",
  customise = "customise",
}

type CreateEventStage = CreateEventStageEnum;

type Props = {
  activeStage?: CreateEventStageEnum;
  onChange?: (stage?: CreateEventStageEnum) => void;
  onPublish: () => void;
  onManualSave: () => void;
  isEventPublished: boolean;
  nextLoading?: boolean;
  publishLoading?: boolean;
  onNext?: () => void;
  onBack?: () => void;
  backLoading?: boolean;
};

export const unpublishedEventCrumbList: { key: CreateEventStage; label: string; link: string }[] = [
  { key: CreateEventStageEnum.basics, label: "Event Basics", link: "basics" },
  { key: CreateEventStageEnum.promotions, label: "Promotions", link: "promotions" },
  { key: CreateEventStageEnum.tickets, label: "Tickets", link: "tickets" },
];
export const publishedEventCrumbList: { key: CreateEventStage; label: string; link: string }[] = [
  { key: CreateEventStageEnum.basics, label: "Event Basics", link: "basics" },
  { key: CreateEventStageEnum.promotions, label: "Promotions", link: "promotions" },
  { key: CreateEventStageEnum.tickets, label: "Tickets", link: "tickets" },
];

function CreateEventBreadcrumbNavigation({
  activeStage,
  onChange,
  onBack,
  onPublish,
  onManualSave,
  isEventPublished,
  publishLoading,
  onNext,
  ...breadCrumbProps
}: Props) {
  const crumbList = isEventPublished ? publishedEventCrumbList : unpublishedEventCrumbList;
  const canGoBack = activeStage !== crumbList[0].key;
  const isFinalStage = activeStage === crumbList[crumbList.length - 1].key;
  const activeCrumbIndex = (!!activeStage && crumbList.findIndex(({ key }) => key === activeStage)) || 0;
  const onChangeStep = (idx: number) => (!!onChange ? onChange(crumbList[idx]?.key) : void 0);

  const breadcrumbNextButtonProps = (() => {
    if (isEventPublished) {
      return {
        nextText: "Save & Exit",
        nextButtonVariant: isFinalStage ? ("primary" as "primary") : ("secondary" as "secondary"),
        onNext: onManualSave,
      };
    } else {
      return {
        nextText: isFinalStage ? "Save As Draft" : undefined,
        nextButtonVariant: isFinalStage ? ("secondary" as "secondary") : ("primary" as "primary"),
        onNext,
      };
    }
  })();

  const endButton = (() => {
    if (!isFinalStage && isEventPublished) {
      return (
        <Button onClick={onNext} variant="primary" size="medium" className="ml-4">
          Next step
        </Button>
      );
    }
    if (!!isFinalStage && !isEventPublished) {
      return (
        <Button onClick={onPublish} loading={publishLoading} variant="primary" size="medium" className="ml-4">
          Publish
        </Button>
      );
    } else {
      return null;
    }
  })();

  return (
    <div className="flex items-center">
      <div className="flex-1">
        <BreadcrumbNavigation
          listNav={crumbList}
          active={activeCrumbIndex}
          onBack={canGoBack ? onBack : undefined}
          setActive={onChangeStep}
          disableLinks={!isEventPublished}
          {...{ ...breadCrumbProps, ...breadcrumbNextButtonProps }}
        />
      </div>
      {endButton}
    </div>
  );
}

export default CreateEventBreadcrumbNavigation;
