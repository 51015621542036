import React, { useEffect, useState } from "react";
import { Copy, Check } from "@atoms/icons";
import Svg from "@atoms/svg";
import { trimHash } from "@utils/hash";
import { copyToClipboard } from "@utils/copy";
import { Transition } from "@headlessui/react";
import classNames from "classnames";

type Props = {
  text: string;
  allowCopy?: boolean;
  fluid?: boolean;
};

function ClippedText({ text, allowCopy, fluid }: Props) {
  const [copied, setCopied] = useState(false);
  const saveToClipboard = () => {
    copyToClipboard(text).then(() => setCopied(true));
  };
  const onClick = allowCopy ? saveToClipboard : undefined;
  const txt = fluid ? text : trimHash(text);

  useEffect(() => {
    const timer = copied
      ? setTimeout(() => {
          setCopied(false);
        }, 3000)
      : null;
    return () => (timer === null ? undefined : clearTimeout(timer));
  }, [copied]);

  return (
    <span className={classNames("flex flex-row relative", { "cursor-pointer": allowCopy })} onClick={onClick}>
      <p className={classNames({ "text-ellipsis overflow-hidden": fluid })}>
        {txt}
        {allowCopy && " "}
      </p>
      {allowCopy && <Svg styles="ml-2" size={copied ? "small" : "medium"} icon={copied ? Check : Copy} />}
      <Transition
        show={copied}
        enter="transition duration-200"
        enterFrom="opacity-0 -translate-y-full"
        enterTo="opacity-1 translate-y-0"
        leave="transition duration-300"
        leaveFrom="opacity-1 translate-y-0"
        leaveTo="opacity-0 -translate-y-full"
        className="w-full absolute top-3/4 left-0"
      >
        <span className="text-xs italic text-white/60">Saved to clipboard</span>
      </Transition>
    </span>
  );
}

export default ClippedText;
