import { URLS } from "@api/lib";
import { parse } from "date-fns";
import { createCustomFetchApi, recursivelyCamelCase, mapPercentageChangeValues } from "./lib";
import { priceFormatter } from "../utils/number";
import { formatUrlParams } from "@utils/format";
// @TODO replace with snakeCase from lodash
import { snakeCase } from "snake-case";
import { Occassion } from "./events";

export type TicketRoyalty = {
  payoutAddress: string;
  royaltyPercentage: number;
};

export type TicketStatus = "resale" | "refunded" | "cancelled" | "sales";

export type FetchedTicket = {
  id: number;
  token_id: string;
  created_at: string; //"2022-06-13 14:31:02";
  purchased_at: string; //"2022-06-13 14:31:02";
  mint_state: "minted";
  status: TicketStatus;
  owner: {
    wallet_address: string;
    "name?": string;
    "bio?": string;
    "facebook?": string;
    "instagram?": string;
    "telegram?": string;
    "twitter?": string;
    "website?": string;
    "discord?": string;
    "profile_photo_url?": string;
    "cover_photo_url?": string;
  };
  ticket_type: FetchedTicketType;
  total_spent: number;
};

export type FetchedTicketType = {
  id: number;
  event_id: number;
  name: string;
  description: string;
  price: number;
  //max_resale_price: number;
  quantity_available: number;
  uuid: string;
  allow_transfers: boolean;
  allow_resales: boolean;
  ticket_image_url: string;
  /**royalties: {
    [key: string]: string;
  };**/
  max_per_wallet: number;
  transaction_hash: string | null;
};

export type Ticket = {
  id: number;
  tokenId: string;
  createdAt: Date;
  purchasedAt: Date;
  mintState: "minted";
  status: TicketStatus;
  owner: {
    walletAddress: string;
    name?: string;
    bio?: string;
    facebook?: string;
    instagram?: string;
    telegram?: string;
    twitter?: string;
    website?: string;
    discord?: string;
    profilePhotoUrl?: string;
    coverPhotoUrl?: string;
  };
  ticketType: TicketType;
  transactionHash: string | null;
  totalSpent: number;
};

export type TicketType = {
  id: number;
  eventId: number;
  name: string;
  description: string;
  price: number;
  //maxResalePrice: number;
  maxPerWallet: number;
  quantityAvailable: number;
  uuid: string;
  allowTransfers: boolean;
  allowResales: boolean;
  ticketImageUrl: string;
  baseImageUrl?: string;
  //royalties: TicketRoyalty[];
  event?: Occassion;
};

export type GetTicketOrdersResponse = {
  currentPage: number;
  lastPage: number;
  perPage: number;
  result: Ticket[];
  total: number;
};

type SearchFilters = {
  perPage?: number;
  page?: number;
};

export type CreateTicketParams = {
  name: string;
  description?: string;
  price: number | null;
  //maxResalePrice: number | null;
  quantityAvailable: number;
  baseImageUrl?: string;
  image?: string;
  //royalties: TicketRoyalty[];
  uuid?: string;
  maxPerWallet: number;
  allowTransfers: boolean;
};

export type UpdateLiveEventTicketParams = Partial<
  Pick<CreateTicketParams, "name" | "description" | "image" | "allowTransfers">
>;

type EventTicketTypeSales = {
  total_tickets_available: number;
  total_tickets_sold: number;
  ticket_types: {
    name: string;
    total_tickets_available: number;
    tickets_sold: number;
  }[];
};

export default class TicketsApi {
  token: string;
  authenticatedFetch: (input: RequestInfo, init?: RequestInit) => Promise<any>;

  constructor(token: string) {
    if (!token) throw new Error("Error API cannot be initialised without a token");
    this.token = token;
    this.authenticatedFetch = createCustomFetchApi(token);
  }

  getTicketOrders = ({ page = 1, perPage = 1000 }: SearchFilters): Promise<GetTicketOrdersResponse> => {
    const urlParams = {
      page,
      per_page: perPage,
    };
    return this.authenticatedFetch(`${URLS.api}/seller/ticket-orders?${formatUrlParams(urlParams)}`).then((res) => {
      const fetchedTickets = res.result;
      const mappedTickets = fetchedTickets.map(TicketsApi.mapFetchedTicketToJs) as Ticket[];
      const modified = { ...res, result: mappedTickets };
      return recursivelyCamelCase(modified) as GetTicketOrdersResponse;
    });
  };

  createTicketType = (eventSlug: string, ticket: CreateTicketParams) => {
    const ticketToCreate = Object.entries(ticket).reduce((accum, [k, v]) => {
      return { ...accum, [snakeCase(k)]: v };
    }, {} as FetchedTicket);
    return this.authenticatedFetch(`${URLS.api}/events/${eventSlug}/ticket-types`, {
      method: "POST",
      body: JSON.stringify(ticketToCreate),
    });
  };

  updateTicketType = (ticketUUID: string, ticket: Omit<CreateTicketParams, "uuid" | "id">) => {
    const ticketToUpdate = Object.entries(ticket).reduce((accum, [k, v]) => {
      if (k === "royalties") {
        const value = v as unknown as TicketRoyalty[];
        const royalties = value.reduce(
          (acc, { payoutAddress, royaltyPercentage }) => ({
            ...acc,
            [payoutAddress]: royaltyPercentage * 100,
          }),
          {} as { [walletAddress: string]: number }
        );
        return { ...accum, royalties };
      }
      return { ...accum, [snakeCase(k)]: v };
    }, {} as FetchedTicket);
    return this.authenticatedFetch(`${URLS.api}/ticket-types/${ticketUUID}`, {
      method: "PATCH",
      body: JSON.stringify(ticketToUpdate),
    });
  };

  deleteTicketType = (ticketUUID: string) => {
    return this.authenticatedFetch(`${URLS.api}/ticket-types/${ticketUUID}`, {
      method: "DELETE",
    });
  };

  getTicketSales = (startDate: string, endDate: string) => {
    return this.authenticatedFetch(
      `${URLS.api}/seller/reports/ticket-sales?start_date=${startDate}&end_date=${endDate}`
    ).then((data) => {
      const result = Object.entries(data.result).reduce((accum, [key, val]) => {
        const value = val as any;
        return { ...accum, [key]: { ...value, total_price: value.total_price / 100 } };
      }, {} as { [date: string]: { total_sales: number; total_price: number } });

      return { result };
    });
  };

  getTicketTypeSalesForEvent = (eventSlug: string): Promise<{ result: EventTicketTypeSales }> => {
    return this.authenticatedFetch(`${URLS.api}/seller/reports/event-sales/${eventSlug}`);
  };

  getNewTicketOrders = (startDate: string, endDate: string) => {
    return this.authenticatedFetch(
      `${URLS.api}/seller/reports/new-ticket-orders?start_date=${startDate}&end_date=${endDate}`
    ).then(({ result }) => {
      return {
        result: {
          ...result,
          ...mapPercentageChangeValues(["percentage_change"], result),
        },
      };
    });
  };

  getNewTicketCustomers = (startDate: string, endDate: string) => {
    return this.authenticatedFetch(
      `${URLS.api}/seller/reports/new-ticket-customers?start_date=${startDate}&end_date=${endDate}`
    ).then(({ result }) => {
      return {
        result: {
          ...result,
          ...mapPercentageChangeValues(["percentage_change"], result),
        },
      };
    });
  };

  getTicketSalesComparison = (startDate: string, endDate: string) => {
    return this.authenticatedFetch(
      `${URLS.api}/seller/reports/ticket-sales-comparison?start_date=${startDate}&end_date=${endDate}`
    ).then(({ result }) => {
      return {
        result: {
          ...result,
          ...mapPercentageChangeValues(["sales_count_percentage_change"], result),
        },
      };
    });
  };

  getEligibleWallets = (ticketTypeIds: string[]) => {
    const urlParams = ticketTypeIds
      .map(function (ticketTypeId) {
        return `ticket_type_ids[]=${ticketTypeId}`;
      })
      .join("&");
    return this.authenticatedFetch(`${URLS.api}/seller/get-eligible-wallets?${urlParams}`).then((response) => {
      return response.result;
    });
  };

  static mapFetchedTicketToJs(fetchedTicket: FetchedTicket) {
    const modified = {
      ...fetchedTicket,
      created_at: parse(fetchedTicket.created_at, "yyyy-MM-dd HH:mm:ss", new Date()),
      purchased_at: parse(fetchedTicket.purchased_at, "yyyy-MM-dd HH:mm:ss", new Date()),
      ticket_type: {
        ...fetchedTicket.ticket_type,
        price: priceFormatter(fetchedTicket.ticket_type.price),
      },
      total_spent: priceFormatter(fetchedTicket.total_spent),
    };
    return recursivelyCamelCase(modified) as Ticket;
  }

  static mapFetchedTicketTypeToJs(fetchedTicketType: FetchedTicketType) {
    const { ...rest } = fetchedTicketType;
    const modified = {
      ...rest,
    };
    return recursivelyCamelCase(modified) as TicketType;
  }
}
