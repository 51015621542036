import React from "react";
import cx from "classnames";
import { getDate } from "date-fns";
import { CalendarDateProps } from ".";
import styles from "./calendar.styles.module.css";

export const CalendarDate = ({
  date,
  isFirstDayOfWeek,
  selected,
  isCurrentMonth,
  className,
  disabled,
  onClick,
}: CalendarDateProps) => {
  const textcolor = isCurrentMonth ? "text-white" : "text-dark-grey";
  const isPrimary = selected?.startsWith("active");
  const isSecondary = selected === "between";
  const classes = cx(
    "text-center relative p-2",
    "text-xs block w-full h-full",
    textcolor,
    { "opacity-10": disabled },
    isCurrentMonth
      ? {
          "bg-blue relative z-10": isPrimary,
          "rounded-l-md": selected === "active" || selected === "active-start",
          "rounded-r-md": selected === "active" || selected === "active-end",
          "bg-dark-grey": isSecondary,
          "current-month": true,
        }
      : undefined
  );
  return (
    <div className={styles.seatlabnftCalendar}>
      <div
        onClick={onClick ? () => onClick(date) : undefined}
        className={cx("text-center relative my-0.5", className, {
          "calendar-day-date__primary": isPrimary && isCurrentMonth,
          "calendar-day-date__secondary": isSecondary && isCurrentMonth,
          "first-of-week": isFirstDayOfWeek,
          "cursor-pointer": !!onClick,
        })}
      >
        <span className={classes}>{getDate(date)}</span>
      </div>
    </div>
  );
};
