import MenuNavItem from "@atoms/menu-nav-item";
import { useLocation, useNavigate } from "react-router-dom";
import navItems from "./route-list";

const SiteNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div>
      {navItems.map((item) => {
        const isActive = location.pathname.startsWith(item.route) ?? "/unknown";
        return (
          <span onClick={() => navigate(item.route)} key={item.route}>
            <MenuNavItem {...item} isActive={isActive} />
          </span>
        );
      })}
    </div>
  );
};

export default SiteNavigation;
