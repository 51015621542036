import cn from "classnames";
import { TablePaginationFooterProps as PaginationFooterProps } from "@molecules/table-pagination-footer";
import useMediaQueries from "@hooks/use-media-queries";
import EventCard, { EventStatus, gridColClasses } from "@molecules/event-card";
import { Occassion } from "@api/events";
import Button from "@atoms/button";

export enum ColumnKey {
  ticketImage = "ticketImage",
  eventName = "eventName",
  eventDate = "eventDate",
  startTime = "startTime",
  eventVenue = "eventVenue",
  isLive = "isLive",
  action = "action",
}

export type Props = PaginationFooterProps & {
  events: Occassion[];
  onEdit: (event: Occassion) => void;
  onView: (event: Occassion) => void;
  onRefund?: (event: Occassion) => void;
  onCancel?: (event: Occassion) => void;
  onSort: (columnKey: ColumnKey) => void;
  onCreateEvent?: () => void;
  noEvents?: boolean;
};

const columns = [
  { key: ColumnKey.ticketImage },
  { key: ColumnKey.eventName, label: "Event Name" },
  { key: ColumnKey.eventDate, label: "Event Date" },
  { key: ColumnKey.startTime, label: "Start Time" },
  { key: ColumnKey.eventVenue, label: "Event Venue" },
  { key: ColumnKey.isLive, label: "Status" },
  { key: ColumnKey.action, label: "Actions" },
];

const getStatusTitle = (status: Occassion["state"]) => {
  switch (status) {
    case "unpublished":
      return EventStatus.unpublished;
    case "published":
      return EventStatus.published;
    case "processing":
      return EventStatus.processing;
  }
};

function EventsList(props: Props) {
  const { isDesktopAndUp } = useMediaQueries();
  const { events, onCreateEvent, noEvents } = props;
  const { onSort, onEdit, onView, onRefund, onCancel } = props;

  return (
    <div className="mb-8">
      {isDesktopAndUp && !noEvents && (
        <div className={cn(gridColClasses, "text-sm text-grey mb-5")}>
          {columns.map((columnHeader) => {
            const onClick = columnHeader.label ? () => onSort(columnHeader.key) : undefined;
            return (
              <div key={columnHeader.key} className="flex items-center" onClick={onClick}>
                {columnHeader.label ? <span className="mr-2">{columnHeader.label}</span> : null}
              </div>
            );
          })}
        </div>
      )}

      {events.map((item) => {
        return (
          <div className="mt-3" key={item.id}>
            <EventCard
              date={item.startDate}
              name={item.name}
              thumbnail={item.thumbnailUrl}
              venue={item.venueName}
              status={getStatusTitle(item.state)}
              onEdit={() => onEdit(item)}
              onView={() => onView(item)}
              onRefund={onRefund ? () => onRefund(item) : undefined}
              onCancel={onCancel ? () => onCancel(item) : undefined}
            />
          </div>
        );
      })}
      {noEvents && (
        <div className="w-full h-full flex flex-col items-center pt-48">
          <p className="text-4xl font-bold mb-6">No Events Listed</p>
          <Button variant="primary" size="large" onClick={onCreateEvent}>
            Create Event
          </Button>
        </div>
      )}
    </div>
  );
}

export default EventsList;
