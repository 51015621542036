export enum DashboardModeButtonKeys {
  tickets = "Tickets",
  collectables = "Collectables",
}

export enum DashboardPeriod {
  week = "WEEK",
  month = "MONTH",
  custom = "CUSTOM",
}
