import { addDays, addHours, isFuture, isPast } from "date-fns";
import { number } from "yup";
import { URLS } from "./lib";
import { createCustomFetchApi } from "./lib";

type ServerApiConfig = {
  near_network: string;
  stripe: {
    publishable_key: string;
  };
  contracts: {
    tickets: string;
    collectibles: string;
    marketplace: string;
    usn: string;
    seat: string;
  };
  wallet_app: {
    ios: {
      min_version: string;
      link: string;
    };
    android: {
      min_version: string;
      link: string;
    };
  };
  scanner_app: {
    ios: {
      min_version: string;
      link: string;
    };
    android: {
      min_version: string;
      link: string;
    };
  };
};
export type ApiConfig = ServerApiConfig & {
  expiresAt: number;
};

const fetchRequest = createCustomFetchApi();

const configMode = process.env.REACT_APP_CONFIG_MODE;
const CONFIG_SESSION_KEY = "SEATLABNFT_DASHBOARD/CONFIG";

function getValidConfig(config?: Partial<ApiConfig>) {
  if (!config) return false;

  const isExpired = !config?.expiresAt || isPast(config.expiresAt);
  const hasCorrectValues = [
    config.near_network,
    config.stripe?.publishable_key,
    config.contracts?.tickets,
    config.contracts?.collectibles,
    config.contracts?.marketplace,
    config.contracts?.usn,
    config.contracts?.seat,
    config.wallet_app?.ios?.link,
    config.wallet_app?.android?.link,
    config.scanner_app?.ios?.link,
    config.scanner_app?.android?.link,
  ].every((value) => typeof value === "string");

  return !isExpired && hasCorrectValues ? (config as ApiConfig) : undefined;
}

export function getContractConfig(): Promise<ApiConfig> {
  const rawSessionConfig = configMode === "NO_CACHE" ? null : window.sessionStorage.getItem(CONFIG_SESSION_KEY);
  const sessionConfig: ApiConfig | undefined = rawSessionConfig ? JSON.parse(rawSessionConfig) : undefined;

  const validConfig = getValidConfig(sessionConfig);
  if (validConfig) return Promise.resolve(validConfig);

  return fetchRequest(`${URLS.api}/config`).then((response) => {
    const config = { ...response.result, expiresAt: addHours(new Date(), 24).getTime() };
    window.sessionStorage.setItem(CONFIG_SESSION_KEY, JSON.stringify(config));
    return config;
  });
}
