import classNames from "classnames";
import { HTMLAttributes, ReactNode, useState } from "react";

type Props = Omit<HTMLAttributes<HTMLDivElement>, "children" | "onDragEnter" | "onDragLeave" | "onDrop"> & {
  children: ReactNode;
  onDrop: (fileList: FileList) => void;
  disabled?: boolean;
  hasError?: boolean;
};

function DropArea({ children, onDrop, disabled, hasError, className, ...props }: Props) {
  const [isHovered, setIsHovered] = useState(false);

  const onDropEvent = (e: React.DragEvent<HTMLDivElement>) => {
    setIsHovered(false);
    const filelist = e.dataTransfer.files;
    onDrop(filelist);
    e.preventDefault();
  };

  return (
    <div
      {...props}
      onDragLeave={() => setIsHovered(false)}
      onDrop={onDropEvent}
      onDragOver={(e) => {
        setIsHovered(true);
        e.preventDefault();
      }}
      className={classNames(
        "outline-none border border-dashed rounded bg-dark-grey relative overflow-hidden",
        className,
        {
          "bg-opacity-60 cursor-not-allowed": disabled,
          "border-danger": hasError,
          "border-green-700": isHovered,
        }
      )}
    >
      {hasError && <div className={classNames("absolute inset-0 pointer-events-none bg-danger/10")} />}
      {children}
      {isHovered && <div className="absolute inset-0 pointer-events-none bg-green-700/30"></div>}
    </div>
  );
}

export default DropArea;
