import Box from "@atoms/box";
import { displayDate, displayTime } from "@utils/date";
import { Close, Highlighter, Refresh, View } from "@atoms/icons";
import React from "react";
import cn from "classnames";
import Svg from "@atoms/svg";
import { EventStatus, Props } from "./event-card.types";

const defaultStatus = EventStatus.unpublished;

function EventField({ label, field, className }: { label: string; field: string; className?: string }) {
  return (
    <div className={className}>
      <div className="text-grey text-sm">{label}</div>
      <span className="text-lg">{field}</span>
    </div>
  );
}

function MobileViewEventCard({
  thumbnail,
  name,
  date,
  venue,
  onEdit,
  onView,
  onCancel,
  onRefund,
  status = defaultStatus,
}: Props) {
  const isPublished = status === EventStatus.published;
  const actionIconClass = (disabled?: boolean) => {
    return cn("text-center flex items-center flex-col", disabled ? "cursor-not-allowed" : "cursor-pointer", {
      "opacity-25": !!disabled,
    });
  };
  const rowSpacing = "mt-4 md:mt-0";
  return (
    <Box variant="dark-grey" padding="small" className="overflow-hidden">
      <div className="items-center">
        <div className="md:grid md:grid-cols-2 items-center">
          <div className="flex items-center">
            <div className="rounded-lg overflow-hidden aspect-square inline-block h-20 bg-layer-blue">
              {thumbnail ? (
                <img src={thumbnail} alt="ticket thumbnail" className="invisble h-full aspect-square object-cover" />
              ) : (
                <div className="flex h-full items-center text-center text-sm p-1 opacity-50">no image yet</div>
              )}
            </div>
            <EventField label="Event name" field={name} className="ml-2" />
          </div>
          <div className={cn(rowSpacing, "inline-grid grid-cols-2 gap-8 md:gap-0")}>
            <EventField label="Event Date" field={displayDate(date)} />
            <EventField label="Start time" field={displayTime(date)} />
          </div>
        </div>

        <div className="md:grid md:grid-cols-2 md:mt-4">
          <div className={rowSpacing}>
            <EventField label="Event Venue" field={venue} className="w-48" />
          </div>
          <div className={cn(rowSpacing, "flex justify-between")}>
            <EventField label="Status" field={status} className="md:flex-1" />
            <div className="flex text-sm space-x-4 md:flex-1">
              <button
                className={actionIconClass(!isPublished)}
                onClick={onView}
                disabled={!isPublished}
                title={!isPublished ? "Publish event first in order to view it" : ""}
              >
                <Svg icon={View} size="medium" />
                <div>View</div>
              </button>
              <button className={actionIconClass()} onClick={onEdit}>
                <Svg icon={Highlighter} size="medium" />
                <div>Edit</div>
              </button>
              {/* <button className={actionIconClass(onCancel)} onClick={onCancel}>
                <Svg icon={Close} size="medium" />
                <div>Cancel</div>
              </button>
              <div className={actionIconClass(onRefund)} onClick={onRefund}>
                <Svg icon={Refresh} size="medium" />
                <div>Refund</div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default MobileViewEventCard;
