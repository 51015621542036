import { ChangeEvent, InputHTMLAttributes, ReactNode, useRef, useState } from "react";
import DropArea from "@atoms/drop-area/drop-area";
import { Image } from "@atoms/icons";
import Svg from "@atoms/svg";
import { convertFileToBase64, getFileNameFromFilePath } from "@utils/file";
import classNames from "classnames";

enum FileError {
  tooLarge = "TOO_LARGE",
}

type Props = Pick<InputHTMLAttributes<HTMLInputElement>, "id" | "title" | "disabled"> & {
  previewImgUrl?: string;
  recommendedSize: [number, number];
  ratio?: string;
  maxMB: number;
  onChange: (inputFile: { file: File; base64: string }) => void;
  placeholderAlign?: "left" | "right" | "center";
  // alwaysShowPlaceholder?: boolean;
  showPlaceholder?: boolean | "auto";
  errorMessage?: string;
  renderValue?: () => ReactNode;
  constrainLayout: boolean;
  alwaysShowFooter?: boolean;
  disableValidation?: boolean;
};

const ONE_MB = 1048576;

function DropUploadField({
  maxMB,
  disabled,
  title,
  ratio,
  placeholderAlign = "center",
  previewImgUrl,
  // alwaysShowPlaceholder,
  showPlaceholder = "auto",
  errorMessage,
  recommendedSize,
  onChange,
  renderValue,
  alwaysShowFooter,
  constrainLayout,
  disableValidation,
  ...inputprops
}: Props) {
  const ref = useRef<HTMLInputElement>(null);
  const [rw, rh] = recommendedSize;
  const aspectRatio = rw / rh;
  const [fileError, setFileError] = useState<FileError | null>(null);
  const [localInputPreview, setLocalInputPreview] = useState<string>();
  const fileName = ref.current?.value ? getFileNameFromFilePath(ref.current.value) : null;

  const error = errorMessage || (!!fileError ? getErrorMessage(fileError) : undefined);

  function getErrorMessage(fileError: FileError) {
    if (fileError === FileError.tooLarge) return `Image size must be less than ${maxMB}MB`;
  }

  const handleFileChange = (fileList: FileList) => {
    const files = Array.from(fileList);
    setFileError(null);
    if (!files.length) return;
    if (!disableValidation)
      if (files.some((file) => file.size / ONE_MB > maxMB)) return setFileError(FileError.tooLarge);
    const [file] = files;
    convertFileToBase64(file).then((base64) => {
      setLocalInputPreview(base64);
      onChange({ file, base64 });
    });
  };

  const onFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) handleFileChange(e.target.files);
  };

  const imgDisplay = localInputPreview || previewImgUrl;
  const renderImage = () =>
    imgDisplay ? <img src={imgDisplay} className="h-full w-full object-cover" alt={title} /> : null;
  const showFooter = alwaysShowFooter || !!ref.current?.value;
  const simulateInputClick = () => ref.current?.click();
  const shouldShowPlaceholder =
    (showPlaceholder === "auto" && !imgDisplay && !renderValue) || showPlaceholder === true || !!error;

  return (
    <DropArea
      onDrop={handleFileChange}
      hasError={!!error}
      disabled={disabled}
      style={constrainLayout ? { aspectRatio: aspectRatio as any } : undefined}
      className={classNames("relative text-white box-content", { "pb-11": showFooter })}
    >
      <div
        className={classNames("relative h-full flex items-center", {
          "sm:px-[10%] md:px-[15%]": placeholderAlign !== "center",
        })}
      >
        <input
          {...{ ...inputprops, ref, disabled }}
          name={title}
          className="hidden text-white"
          type="file"
          onChange={onFileInputChange}
          multiple={false}
        />
        {!error && (
          <>
            <div className="absolute inset-0">{!!renderValue ? renderValue() : renderImage()}</div>
            {shouldShowPlaceholder && <div className="bg-black/30 absolute inset-0" />}
          </>
        )}

        <div
          className={classNames("text-center max-w-[270px] p-4 relative", {
            "text-gray-500": disabled,
            "text-danger": !!error,
            "ml-auto": placeholderAlign === "right" || placeholderAlign === "center",
            "mr-auto": placeholderAlign === "left" || placeholderAlign === "center",
            hidden: !shouldShowPlaceholder,
          })}
        >
          <Svg
            icon={Image}
            size="xxlarge"
            styles={classNames("mb-2 lg:mb-4 mx-auto text-grey", { "opacity-50": disabled })}
          />
          {title && <div className="text-xl lg:text-2xl font-bold mb-2">{title}</div>}
          <div className="text-sm lg:text-base font-bold text-white mb-3">
            Drag and drop or{" "}
            <span className="underline cursor-pointer" onClick={simulateInputClick}>
              browse
            </span>
          </div>
          {!error ? (
            <div className="text-xs lg:text-sm font-light">
              We recommend using at least a {rw}px x {rh}px{ratio ? ` (${ratio} ratio)` : ``} jpg image no larger than{" "}
              <b className="font-bold">{maxMB}MB</b>
            </div>
          ) : (
            <div className="text-sm lg:text-base font-bold">{error}</div>
          )}
        </div>
      </div>
      {showFooter && (
        <div className="absolute px-3 w-full bg-dark-grey h-11 bottom-0 left-0 flex justify-between items-center">
          <span className="text-xs">{fileName || title || ""}</span>
          <span className="border rounded-full px-2 text-sm text-white cursor-pointer" onClick={simulateInputClick}>
            Change
          </span>
        </div>
      )}
    </DropArea>
  );
}

export default DropUploadField;
