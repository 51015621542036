import * as React from "react";

function BarChart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V6.75Z"
        stroke="#D1D6DB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.75 15.25V9.75" stroke="#D1D6DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.25 15.25V9.75" stroke="#D1D6DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 15.25V12.75" stroke="#D1D6DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default BarChart;
