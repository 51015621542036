// @TODO replace with camelcase from lodash
import { camelCase } from "camel-case";
import { URLS, createCustomFetchApi, recursivelySnakeCase } from "./lib";

export type Login = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export type RegisterSeller = {
  email: string;
  password: string;
  password_confirmation: string;
};

export type SellerForgotPassword = {
  email: string;
};

export type SellerResetPassword = {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
};

export type SellerConfirmEmail = {
  id: string;
  hash: string;
  email: string;
};

export type FetchedSeller = {
  bio?: string;
  cover_photo_url?: string;
  id: number;
  name?: string;
  stripe_charges_enabled: boolean;
  stripe_transfers_enabled: boolean;
  support_email?: string;
  support_phone?: string;
  thumbnail_url?: string;
  wallet_address: string;
  country_of_business: string;
  currency: string;
  currency_symbol: string;
};

export type Seller = {
  bio?: string;
  coverPhotoUrl?: string;
  id?: number;
  name?: string;
  stripeChargesEnabled?: boolean;
  stripeTransfersEnabled?: boolean;
  supportEmail?: string;
  supportPhone?: string;
  thumbnailUrl?: string;
  walletAddress?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  telegram?: string;
  discord?: string;
  website?: string;
  email?: string;
  countryOfBusiness?: string;
  currency?: string;
  currencySymbol?: string;
};

export default class AuthApi {
  token?: string;
  fetchRequest: (input: RequestInfo, init?: RequestInit) => Promise<any>;

  constructor(token?: string) {
    this.token = token;
    this.fetchRequest = createCustomFetchApi(token);
  }

  getJWT(args: { signature: string; walletId: string; pubKey: string; timestamp: string }) {
    const { signature, walletId, pubKey, timestamp } = args;
    return this.fetchRequest(`${URLS.api}/seller/sign-in`, {
      method: "POST",
      body: JSON.stringify({
        signature,
        wallet_id: walletId,
        public_key: pubKey,
        timestamp,
      }),
    });
  }

  getJWTByEmailPassword(args: { email: string; password: string }) {
    const { email, password } = args;
    return this.fetchRequest(`${URLS.api}/seller/login`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });
  }

  setToken = (token: string) => {
    this.token = token;
    this.fetchRequest = createCustomFetchApi(token);
  };

  getMyProfile = () => {
    if (!this.token) throw new Error("JWT needs to be set before making authenticated requests");
    return this.fetchRequest(`${URLS.api}/seller/profile`).then(({ result }) => AuthApi.mapFetchedSellerToJs(result));
  };

  updateProfile = (args: Seller) => {
    if (!this.token) throw new Error("JWT needs to be set before making authenticated requests");
    return this.fetchRequest(`${URLS.api}/seller/profile`, {
      method: "PATCH",
      body: JSON.stringify({
        ...recursivelySnakeCase(args),
      }),
    }).then(({ result }) => AuthApi.mapFetchedSellerToJs(result));
  };

  registerUser(args: { email: string; password: string; password_confirmation: string }) {
    const { email, password, password_confirmation } = args;
    return this.fetchRequest(`${URLS.api}/seller/register`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
        password_confirmation: password_confirmation,
      }),
    });
  }

  forgotPassword(args: { email: string }) {
    const { email } = args;
    return this.fetchRequest(`${URLS.api}/seller/forgot-password`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
    });
  }

  resetPassword(args: { token: string; email: string; password: string; password_confirmation: string }) {
    const { token, email, password, password_confirmation } = args;
    return this.fetchRequest(`${URLS.api}/seller/reset-password`, {
      method: "POST",
      body: JSON.stringify({
        token: token,
        email: email,
        password: password,
        password_confirmation: password_confirmation,
      }),
    });
  }

  confirmEmail(args: { id: string; hash: string; email: string }) {
    const { id, hash, email } = args;
    return this.fetchRequest(`${URLS.api}/seller/verify`, {
      method: "POST",
      body: JSON.stringify({
        id: id,
        hash: hash,
        email: email,
      }),
    });
  }

  resendConfirmEmail(args: { email: string }) {
    const { email } = args;
    return this.fetchRequest(`${URLS.api}/seller/verify/resend`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
    });
  }

  getStripeConnectUrl = () => {
    return this.fetchRequest(`${URLS.api}/seller/link-stripe-account`, { method: "POST" }).then(
      ({ result }) => result.url as string
    );
  };

  getStripeDashboardUrl = () => {
    return this.fetchRequest(`${URLS.api}/seller/stripe-login-link`, { method: "GET" }).then(
      ({ result }) => result.url as string
    );
  };

  checkUsername = (username: string): Promise<{ result: { available: boolean } }> => {
    return this.fetchRequest(`${URLS.api}/register/is-username-available`, {
      method: "POST",
      body: JSON.stringify({ username }),
    });
  };

  getAvailableTerritories = (): Promise<{ [countryCode: string]: string }> => {
    return this.fetchRequest(`${URLS.api}/seller/available-territories`).then(({ result }) => result);
  };

  static mapFetchedSellerToJs = function mapFetchedSellerToJs(fetchedSeller: FetchedSeller) {
    return Object.entries(fetchedSeller).reduce((accum, [k, v]) => {
      const key = k as keyof FetchedSeller;
      return { ...accum, [camelCase(key)]: v };
    }, {} as Seller);
  };
}
