import React, { useEffect, useRef, useState } from "react";
import { TextAreaProps } from "./text-area.types";
import cn from "classnames";
import SVG from "@atoms/svg";
import { ExclaimationTriangle } from "@atoms/icons";
import autosize from "autosize";

export default React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    { isDisabled, statusType, value, isRequired, className, backgroundColor, noPadding, isFullWidth, ...props },
    passedRef
  ) => {
    const isError = statusType === "error";
    const defaultRef = useRef<HTMLTextAreaElement>(null);
    const [ref] = useState(() => passedRef || defaultRef);

    const getBackgroundColor = (() => {
      switch (backgroundColor) {
        case "transparent":
          return "bg-none appearance-none";
        case "slate":
        default:
          return "bg-dark-grey";
      }
    })();

    const textAreaClasses = cn(
      "outline-none text-heading3 rounded-md text-white w-full align-top h-16",
      { "p-4": !noPadding },
      isError ? "pr-9" : "pr-4",
      { "ring-2 ring-inset ring-danger bg-danger/20": isError },
      { "bg-opacity-60": isDisabled },
      { "w-full": isFullWidth },
      getBackgroundColor,
      className
    );

    useEffect(() => {
      if (typeof ref === "object" && ref.current) autosize(ref.current);
    }, [ref]);

    return (
      <div className="relative">
        <textarea
          className={textAreaClasses}
          disabled={isDisabled}
          required={isRequired}
          aria-required={isRequired}
          aria-invalid={isError}
          {...{ ...props, ref, value }}
        />
        {isError && (
          <SVG icon={ExclaimationTriangle} size="medium" styles="text-danger absolute my-auto top-0 bottom-0 right-3" />
        )}
      </div>
    );
  }
);
