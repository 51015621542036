import BadgeAlert from "@atoms/badge-alert";
import Fieldset from "@atoms/fieldset";
import EventBasicsForm, { CreateEventBasicFormValues } from "@organisms/event-basics-form";
import { useFormikContext } from "formik";

export type FormValues = CreateEventBasicFormValues;

type Props = { formErrorMessage?: string; onCloseError?: () => void };

export const initialValues: CreateEventBasicFormValues = {
  eventName: "",
  startDate: undefined,
  endDate: undefined,
  entryDate: undefined,
  venueName: "",
};

function EventBasics({ formErrorMessage, onCloseError }: Props) {
  const { values } = useFormikContext<FormValues>();

  return (
    <>
      <div className="relative">
        <Fieldset heading="Event Name" paddingSize="sm">
          {!!formErrorMessage && (
            <BadgeAlert
              variant="error"
              className="absolute top-0 left-0 w-full"
              message={formErrorMessage}
              onClose={onCloseError}
            />
          )}
          <EventBasicsForm />
        </Fieldset>
      </div>
    </>
  );
}

export default EventBasics;
