import * as React from "react";

function Edit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.75 19.25l4.25-1 9.293-9.293a1 1 0 000-1.414l-1.836-1.836a1 1 0 00-1.414 0L5.75 15l-1 4.25zM19.25 19.25h-5.5"
        stroke="currentcolor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Edit;
