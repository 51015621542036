import UserAccountLink from "@molecules/user-account-link";
import useAuth from "@hooks/use-auth";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import LayoutContext from "@context/layout-context";
import { useQueryClient } from "react-query";

function UserMenu() {
  const { user, emailUser, signOut, signIn } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setTitle } = useContext(LayoutContext);
  const onSignOut = () => {
    setTitle("");
    signOut();
    queryClient.invalidateQueries();
    navigate("/", { replace: true });
  };
  return (
    <UserAccountLink userName={user?.accountId || emailUser?.email || ""} onSignOutClick={onSignOut} signIn={signIn} />
  );
}

export default UserMenu;
