import { Seller } from "@api/auth";
import { Stripe } from "@atoms/icons";
import FormikField from "@molecules/formik-field";
import StoreLogoCover from "@molecules/store-logo-cover";
import { SellerForm } from "@pages/settings/settings";
import { Field, FieldProps, Form, useFormikContext } from "formik";
import { UseMutationResult, UseQueryResult } from "react-query";
import SVG from "@atoms/svg";
import Text from "@atoms/text";
import Selectable from "@molecules/selectable";
import classNames from "classnames";
import { Link } from "react-router-dom";

type SettingsFormProps = {
  initialCoverPhoto?: string;
  initialThumbnail?: string;
  seller?: Seller;
  isLoading?: boolean;
  territories: UseQueryResult<{ [countryCode: string]: string }>;
  onClickStripe: UseMutationResult<any, unknown, void, unknown>;
  onChangeBusinessCountry: UseMutationResult<any, unknown, string, unknown>;
  selectedCountryOfBusiness: string | null;
};

const SettingsForm = ({
  initialCoverPhoto,
  initialThumbnail,
  territories,
  seller,
  onClickStripe,
  onChangeBusinessCountry,
  selectedCountryOfBusiness,
}: SettingsFormProps) => {
  const { setFieldValue, errors, touched, status, setFieldTouched, submitCount, setStatus } =
    useFormikContext<SellerForm>();

  const setPhoto = (keyName: keyof SellerForm) => {
    return async ({ file }: { file: File }) => {
      setFieldValue(keyName, file);
      setFieldTouched(keyName, true);
    };
  };

  const getError = (keyName: keyof SellerForm) => {
    const dirty = !!touched[keyName] || submitCount > 0;
    return dirty ? errors[keyName] : undefined;
  };

  const availableTerritories = (() => {
    if (!territories.isSuccess) return [];
    return Object.entries(territories.data).map(([key, label]) => ({ key, label }));
  })();

  const selectedCountryOfBusinessOption = (() => {
    if (!availableTerritories || !selectedCountryOfBusiness) return undefined;
    return availableTerritories.find((territory) => territory.key === selectedCountryOfBusiness);
  })();

  return (
    <Form>
      <div className="bg-gradient-to-r from-bluer to-blue px-6 py-3.5 rounded-md text-white font-bold mb-8">
        Event organiser details
      </div>

      <div className="flex flex-col gap-y-4 pb-16">
        <fieldset className="grid gap-4">
          <div className="grid md:grid-cols-1 gap-4">
            <FormikField name="name" label="Event Organiser Name" isFullWidth />
          </div>
          <FormikField name="bio" label="Event Organiser Bio" type="textarea" className="min-h-[140px]" isFullWidth />
        </fieldset>
        <fieldset className="mt-11">
          <legend className="font-bold text-xl mb-2">Support</legend>
          <div className="grid md:grid-cols-2 gap-4">
            <FormikField name="supportEmail" label="Email Address" isFullWidth />
            <FormikField name="supportPhone" label="Mobile Number" isFullWidth placeholder="+440758123456" />
          </div>
        </fieldset>
        <fieldset className="mt-11">
          <legend className="font-bold text-xl mb-2">Imagery</legend>
          <p className="mb-2">This is a preview of how the images will appear on your seller profile page.</p>
          <StoreLogoCover
            initialCoverPhoto={initialCoverPhoto}
            initialThumbnail={initialThumbnail}
            onCoverPhotoChange={setPhoto("newCoverPhoto")}
            onThumbnailChange={setPhoto("newThumbnail")}
            coverPhotoError={getError("newCoverPhoto")}
            thumbnailError={getError("newThumbnail")}
          />
        </fieldset>
        <fieldset className="mt-11">
          <legend className="font-bold text-xl mb-2">Payments</legend>
          <div className="flex gap-4">
            <div className="min-w-56 relative z-20">
              <Field name="countryOfBusiness">
                {({ field, meta }: FieldProps) => {
                  return (
                    <Selectable
                      className={meta.error ? "border-2 border-solid border-red-600" : ""}
                      id={"countryOfBusiness"}
                      name={"countryOfBusiness"}
                      isLoading={territories.isLoading || onChangeBusinessCountry.isLoading}
                      options={availableTerritories}
                      placeholder="Country of business"
                      components={
                        seller?.stripeTransfersEnabled
                          ? { DropdownIndicator: () => <div className="w-2" /> }
                          : undefined
                      }
                      isSearchable={false}
                      value={selectedCountryOfBusinessOption}
                      onChange={(option) => onChangeBusinessCountry.mutate(option?.key || "")}
                      isDisabled={!!seller?.stripeTransfersEnabled || onChangeBusinessCountry.isLoading}
                    />
                  );
                }}
              </Field>
            </div>
            <Field name="stripeTransfersEnabled">
              {({ field, meta }: FieldProps) => {
                return (
                  <button
                    onClick={() => onClickStripe.mutate()}
                    disabled={!selectedCountryOfBusiness || onClickStripe.isLoading}
                    type="button"
                    {...(!selectedCountryOfBusiness
                      ? { title: "Country of business should be set before registering with stripe" }
                      : {})}
                    className={classNames("flex flex-row bg-[#6772E5] rounded-md pt-3 pb-3 pr-6 pl-6 items-center", {
                      "opacity-30 cursor-not-allowed": !selectedCountryOfBusiness,
                      "pl-2 border-2 border-solid border-red-600":
                        meta.error && selectedCountryOfBusiness && !onClickStripe.isLoading,
                    })}
                  >
                    <span className="border-r border-white pr-2">
                      <SVG size="medium" icon={Stripe} />
                    </span>
                    <Text className={"pl-2"} variant="heading2">
                      {!seller?.stripeTransfersEnabled ? "Connect with Stripe" : "Visit your Stripe dashboard"}
                    </Text>
                  </button>
                );
              }}
            </Field>
          </div>
          <p className="text-xs text-grey/60 mt-1">
            Country of business cannot be changed once Stripe registration setup is complete
          </p>
        </fieldset>
        <p className="text-sm text-white-80 mt-5">
          Default currency is set as GBP, if you wish to change this, contact &nbsp;
          <Link
            className={"no-underline hover:underline"}
            to="#"
            onClick={(e) => {
              window.location.href = "mailto:support@seatlabnft.com";
              e.preventDefault();
            }}
          >
            {"support@seatlabnft.com"}
          </Link>
        </p>
        <fieldset className="mt-6">
          <legend className="font-bold text-xl mb-2">Social Links</legend>
          <div className="grid md:grid-cols-2 gap-4">
            <FormikField name="facebook" label="Facebook" isFullWidth placeholder="http://www.facebook.com/username" />
            <FormikField
              name="instagram"
              label="Instagram"
              isFullWidth
              placeholder="http://www.instagram.com/yournewurl"
            />
            <FormikField name="twitter" label="Twitter" isFullWidth placeholder="http://twitter.com/username" />
            <FormikField name="telegram" label="Telegram" isFullWidth placeholder="https://telegram.me/username" />
            <FormikField name="website" label="Website" isFullWidth placeholder="http://example.com" />
            <FormikField name="discord" label="Discord" isFullWidth placeholder="https://discordapp.com/users/12345" />
          </div>
        </fieldset>
      </div>
    </Form>
  );
};

export default SettingsForm;
