import Box from "@atoms/box";
import { Close, Highlighter } from "@atoms/icons";
import Svg from "@atoms/svg";
import React from "react";

type Props = {
  email: string;
  name: string;
  editLink: string;
  onDelete: () => void;
};

function ScannerUserCard({ email, name, editLink, onDelete }: Props) {
  return (
    <Box variant="dark-grey" padding="medium" className="flex flex-col md:flex-row">
      <div className="md:flex-1 items-center flex">
        <div className="w-1/3">{name}</div>
        <div className="w-2/3">{email}</div>
      </div>
      <div className="flex text-sm md:space-x-4 self-end">
        <button onClick={onDelete} className="inline-block w-12 text-center flex flex-col items-center">
          <Svg icon={Close} size="medium" />
          <div>Delete</div>
        </button>
      </div>
    </Box>
  );
}

export default ScannerUserCard;
