import Button from "@atoms/button";
import { Close } from "@atoms/icons";
import Svg from "@atoms/svg";
import cn from "classnames";
import { ReactElement } from "react";

type Props = {
  variant?: "error" | "warn" | "success" | "light" | "dark";
  message: string | ReactElement;
  className?: string;
  onClose?: () => void;
  buttons?: { label: string; onClick: () => void }[];
};

function BadgeAlert({ variant = "light", message, onClose, className, buttons }: Props) {
  const bg = (() => {
    switch (variant) {
      case "error":
        return "bg-danger";
      case "warn":
        return "bg-warning";
      case "success":
        return "bg-success";
      case "light":
        return "bg-white";
      case "dark":
        return "bg-dark-grey";
    }
  })();
  const fg = (() => {
    switch (variant) {
      case "error":
      case "dark":
      case "success":
        return "text-white";
      default:
        return "text-dark-navy";
    }
  })();

  const buttonList = (() => {
    if (!buttons) return undefined;
    return buttons.map((btn, idx) => {
      const isPrimaryButton = idx + 1 === buttons.length;
      return isPrimaryButton ? (
        <Button key={idx} size="medium" variant="primary" onClick={btn.onClick} children={btn.label} />
      ) : (
        <button key={idx} className={cn(fg)} onClick={btn.onClick} children={btn.label} />
      );
    });
  })();
  return (
    <div className={cn(bg, fg, "px-6 py-3.5 flex justify-between items-center rounded-lg", className)}>
      <span>{message}</span>
      <div className="flex gap-4">
        {buttonList ? buttonList : onClose ? <button onClick={onClose} children={<Svg icon={Close} />} /> : null}
      </div>
    </div>
  );
}

export default BadgeAlert;
