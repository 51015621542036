import useMediaQueries from "@hooks/use-media-queries";
import { Props } from "./order-card.types";
import OrderTableRow from "./order-table-row";
import MobileOrderCard from "./mobile-view-order-card";

function OrderCard(props: Props) {
  const { isDesktopAndUp } = useMediaQueries();
  return isDesktopAndUp ? <OrderTableRow {...props} /> : <MobileOrderCard {...props} />;
}

export default OrderCard;
