import Button from "@atoms/button";
import FormikField from "@molecules/formik-field";
import { Form } from "formik";
import useAuth from "@hooks/use-auth";
import { useNavigate } from "react-router-dom";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const { formError, setFormError } = useAuth();
  const onClickLogin = () => {
    setFormError("");
    navigate("/email_login", { replace: true });
  };

  return (
    <Form>
      <div className="flex flex-col gap-y-4 pb-16">
        <fieldset className="grid gap-4">
          <FormikField name="email" label="Email" type="text" isFullWidth />
        </fieldset>
        <fieldset className="grid gap-4">
          <Button type="submit" isFullWidth>
            Send instructions
          </Button>
        </fieldset>
        <fieldset className="grid gap-4">
          <div className="text-center">
            <p>
              Remembered your password?{" "}
              <span onClick={onClickLogin} className="text-blue cursor-pointer">
                Log in
              </span>
            </p>
          </div>
        </fieldset>
      </div>
    </Form>
  );
};

export default ForgotPasswordForm;
