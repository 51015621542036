import { ReactNode, useEffect, useRef, useState } from "react";

import Text from "@atoms/text";
import { ChevronDown } from "@atoms/icons";
import cn from "classnames";

type Props = {
  title: string;
  subtitle?: string;
  renderLeftIcon?: ReactNode;
  children: ReactNode;
  defaultActive?: boolean;
  maxHeight?: number;
  error?: string;
};

const Accordion = ({
  children,
  title,
  subtitle,
  defaultActive,
  renderLeftIcon,
  maxHeight = 500,
  error,
}: Props): JSX.Element => {
  const [active, setActive] = useState(defaultActive ?? false);
  const [height, setHeight] = useState(defaultActive ? maxHeight : 0);
  const accordionHeader = useRef<HTMLButtonElement>(null);
  const contentSpace = useRef<HTMLDivElement>(null);
  const headerColor = error ? "bg-danger" : active ? "bg-gradient-to-r from-bluer to-blue" : "bg-transparent";

  const renderSubtitle = (
    <Text variant="heading2" as="span" className="text-white opacity-60 mr-4">
      {subtitle}
    </Text>
  );

  useEffect(() => {
    if (active && maxHeight !== undefined) {
      const headerHeight = accordionHeader.current ? accordionHeader.current.clientHeight : 0;
      setHeight(maxHeight + headerHeight);
    }
  }, [maxHeight, active]);

  function toggleAccordion() {
    setActive(active === false ? true : false);
    setHeight(active ? 0 : maxHeight);
  }

  function renderHeader() {
    return (
      <>
        <span className="flex space-x-1 items-center">
          {renderLeftIcon}
          <Text variant="heading2" as="span" className="text-white">
            {title}
            {subtitle && ` - `}
            {subtitle && renderSubtitle}
          </Text>
        </span>
        <div className="flex flex-row items-center">
          <Text variant="heading2" as="span" className="text-white opacity-60 mr-4">
            {active ? "Collapse" : "Expand"}
          </Text>
          <ChevronDown
            height={12}
            width={12}
            className={`text-white opacity-60 transform duration-200 ease ${active ? "rotate-0" : "-rotate-90"}`}
          />
        </div>
      </>
    );
  }

  function renderError() {
    return (
      <span className="flex space-x-1 items-center">
        <Text variant="heading2" as="span" className="text-white">
          {error}
        </Text>
      </span>
    );
  }

  return (
    <div className="flex flex-col bg-background/60 rounded-lg">
      <button
        ref={accordionHeader}
        className={cn(
          "p-3 box-border appearance-none cursor-pointer focus:outline-none flex items-center justify-between rounded-t-lg",
          headerColor
        )}
        onClick={toggleAccordion}
      >
        {error ? renderError() : renderHeader()}
      </button>
      <div
        ref={contentSpace}
        style={{ maxHeight: height }}
        className="transition-max-height duration-200 ease-in-out overflow-scroll"
      >
        <div className="p-3">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
