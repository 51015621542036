import Box from "@atoms/box";
import cn from "classnames";
import React, { ReactNode } from "react";

export const gridColClassesDefault = "grid gap-5 grid-cols-[96px_1.3fr_0.85fr_88px_1.2fr_80px_1.2fr] items-center";

export type TableRowProps = {
  columns: ReactNode[];
  gridColClasses?: string;
};

function TableRow({ columns, gridColClasses }: TableRowProps) {
  return (
    <Box variant="dark-grey" padding="none" className="overflow-hidden">
      <div className={cn("text-white", gridColClasses ?? gridColClassesDefault)}>
        {columns.map((item, i) => (React.isValidElement(item) ? item : <div children={<span>{item}</span>} />))}
      </div>
    </Box>
  );
}

export default TableRow;
