import BreadcrumbNavigation from "@molecules/breadcrumb-navigation";

export enum CreateAirdropStageEnum {
  events = "events",
  collectibles = "collectibles",
}

type CreateAirdropStage = CreateAirdropStageEnum;

type Props = {
  activeStage?: CreateAirdropStageEnum;
  onNext: () => void;
  onBack: () => void;
  onChange?: (stage?: CreateAirdropStageEnum) => void;
  nextDisabled: boolean;
  nextLoading?: boolean;
};

const crumbList: { key: CreateAirdropStage; label: string; link: string }[] = [
  { key: CreateAirdropStageEnum.events, label: "Select Event", link: "" },
  { key: CreateAirdropStageEnum.collectibles, label: "Select Collectable", link: "" },
];

function CreateAirdropBreadcrumbNavigation({ activeStage, onNext, onBack, nextLoading, nextDisabled }: Props) {
  const nextText = activeStage === crumbList[crumbList.length - 1].key ? "Send" : undefined;
  const backButton = activeStage === crumbList[crumbList.length - 1].key ? onBack : undefined;
  const activeCrumbIndex = (!!activeStage && crumbList.findIndex(({ key }) => key === activeStage)) || 0;
  return (
    <div className="flex items-center">
      <div className="flex-1">
        <BreadcrumbNavigation
          listNav={crumbList}
          active={activeCrumbIndex}
          onBack={backButton}
          nextButtonVariant="primary"
          disableLinks={true}
          {...{ nextLoading, nextDisabled, nextText, onNext }}
        />
      </div>
    </div>
  );
}

export default CreateAirdropBreadcrumbNavigation;
