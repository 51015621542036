import Button from "@atoms/button";
import FormikField from "@molecules/formik-field";
import { Form, useFormikContext } from "formik";
import { Login } from "@api/auth";
import Checkbox from "@atoms/checkbox";
import { useNavigate } from "react-router-dom";
import useAuth from "@hooks/use-auth";

const LoginForm = () => {
  const navigate = useNavigate();
  const { formError, setFormError } = useAuth();
  const onClickForgotPassword = () => {
    setFormError("");
    navigate("/forgot_password", { replace: true });
  };
  const onClickSignUp = () => {
    setFormError("");
    navigate("/register", { replace: true });
  };
  const { values, setFieldValue } = useFormikContext<Login>();
  function onChangeRememberMe(e: any) {
    setFieldValue("rememberMe", !values.rememberMe);
  }

  return (
    <Form>
      <div className="flex flex-col gap-y-4 pb-16">
        <fieldset className="grid gap-4">
          <FormikField name="email" label="Email" type="text" isFullWidth />
        </fieldset>
        <fieldset className="grid gap-4">
          <FormikField name="password" label="Password" type="password" isFullWidth />
        </fieldset>
        <fieldset className="grid gap-4">
          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <Checkbox
                id="rememberMe"
                label="Remember Me"
                checked={values.rememberMe}
                onChange={onChangeRememberMe}
                disabled={false}
              />
            </div>
            <div className="text-right">
              <span onClick={onClickForgotPassword} className="cursor-pointer">
                Forgot Password?
              </span>
            </div>
          </div>
        </fieldset>
        <fieldset className="grid gap-4">
          <Button type="submit" isFullWidth>
            Sign in
          </Button>
        </fieldset>
        <fieldset className="grid gap-4">
          <div className="text-center">
            <p>
              Don’t have an account?{" "}
              <span onClick={onClickSignUp} className="text-blue cursor-pointer">
                Sign up for free
              </span>
            </p>
          </div>
        </fieldset>
      </div>
    </Form>
  );
};

export default LoginForm;
