import useAuth from "@hooks/use-auth";
import Button from "@atoms/button";
import { useNavigate } from "react-router-dom";
import bgImg from "./background.png";
import { useQuery } from "react-query";
import { useEffect } from "react";
import DocumentTitle from "@atoms/document-title";
import SpinnerLoader from "@atoms/spinner-loader";
import Header from "@organisms/header";
import { LogoFull } from "@atoms/icons";

function Login() {
  const navigate = useNavigate();
  const { user, signIn, getMyProfile, isCompleteProfile } = useAuth();
  const myProfileQuery = useQuery("myProfile", getMyProfile, {
    retry: false,
    onError: () => {},
  });

  useEffect(() => {
    if (!myProfileQuery.isSuccess) return;
    if (user) {
      if (!isCompleteProfile(myProfileQuery.data)) navigate("/settings");
      else navigate("/", { replace: true });
    }
  }, [myProfileQuery.isSuccess, myProfileQuery.data, isCompleteProfile, navigate, user]);

  if (myProfileQuery.isFetched && !user) {
    return (
      <>
        <div className="border-b border-dark-grey flex items-center w-full h-24 px-4 md:px-10">
          <LogoFull className="h-8" />
        </div>
        <div className="text-white relative p-4 flex flex-col items-center justify-center h-full overflow-hidden">
          <DocumentTitle titlePrefix="Login" />
          <img
            src={bgImg}
            className=" fixed w-[350px] h-[350px] -right-[120px] -bottom-[120px] md:w-[600px] md:h-[600px] md:-bottom-[240px] md:-right-[240px]  lg:h-[1001px] lg:w-[1001px] lg:-bottom-[400px] lg:-right-[400px]"
            alt="background"
          />
          <h1 className="text-3xl mb-3 text-center z-30 font-bold">
            Welcome to the SeatlabNFT <br /> Seller Portal
          </h1>
          <Button className="mt-10 w-64 z-30" onClick={signIn}>
            Connect Wallet to Begin
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <DocumentTitle titlePrefix="Login..." />
      {myProfileQuery.isLoading && <SpinnerLoader />}
    </>
  );
}

export default Login;
