import { LotteryEntry } from "@api/customers";
import PaginationButtons from "../../molecules/pagination-buttons";
import SpinnerLoader from "@atoms/spinner-loader";
import cn from "classnames";
import { TablePaginationFooterProps } from "@molecules/table-pagination-footer";
import TableRow from "@molecules/table-row";
import Pill, { PillColor } from "@atoms/pill";
import { displayDate } from "@utils/date";
import ClippedText from "@atoms/clipped-text";
import TransactionHash from "@molecules/transaction-hash";

const gridColClasses = "grid gap-5 grid-cols-[80px_1fr_0.4fr_0.5fr_88px] items-center";

export type Props = TablePaginationFooterProps & {
  lotteryEntries: LotteryEntry[];
};

export enum ColumnKey {
  image = "image",
  eventName = "eventName",
  createdAt = "createdAt",
  transactionHash = "transactionHash",
  price = "price",
  status = "status",
}

const columns = [
  { key: ColumnKey.image, label: "" },
  { key: ColumnKey.eventName, label: "Event Name" },
  { key: ColumnKey.createdAt, label: "Entry Date" },
  { key: ColumnKey.transactionHash, label: "Transaction Hash" },
];

function NoOrders() {
  return (
    <div className="w-full flex flex-col items-center pt-14 pb-10">
      <p className="text-4xl font-bold mb-6">No lottery entries yet 😔</p>
    </div>
  );
}

const CustomerTicketList = ({ lotteryEntries, totalPages, currentPageIndex, onChangePage }: Props) => {
  return (
    <div>
      {!lotteryEntries && <SpinnerLoader />}
      {lotteryEntries.length > 0 ? (
        <>
          <div className={cn(gridColClasses, "text-sm text-grey mb-5")}>
            {columns.map((columnHeader) => {
              return (
                <div key={columnHeader.key} className="flex items-center">
                  {columnHeader.label ? <span className="mr-2">{columnHeader.label}</span> : null}
                </div>
              );
            })}
          </div>
          {lotteryEntries.map((lotteryEntry: LotteryEntry) => (
            <div className="mt-3">
              <TableRow
                gridColClasses={gridColClasses}
                columns={[
                  <img
                    src={lotteryEntry.ticketType.event?.thumbnailUrl}
                    alt="thumbnail"
                    width={71}
                    height={95}
                    className="object-cover rounded-l"
                  />,
                  <div children={<span>{lotteryEntry.ticketType.event?.name}</span>} />,
                  <div children={<span>{displayDate(lotteryEntry.createdAt)}</span>} />,
                  lotteryEntry.transactionHash ? (
                    <TransactionHash hash={lotteryEntry.transactionHash} />
                  ) : (
                    <span children="-" />
                  ),
                  <div children={<Pill className="" label="Lottery" color={PillColor.success} />} />,
                ]}
              />
            </div>
          ))}
          {totalPages > 1 && (
            <div className="flex flex-row justify-center my-8">
              <PaginationButtons activePage={currentPageIndex} setPage={onChangePage} pages={totalPages} />
            </div>
          )}
        </>
      ) : (
        <NoOrders />
      )}
    </div>
  );
};

export default CustomerTicketList;
