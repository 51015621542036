// @TODO replace with snakeCase from lodash
import { snakeCase } from "snake-case";
import { CreateCollectible } from "@api/collectibles";

export function mapCollectibleFormFieldsToApiParams(values: Partial<CreateCollectible>) {
  return Object.entries(values).reduce((accum, [k, v]) => {
    const key = k as keyof Partial<CreateCollectible>;
    if (key === "price") {
      const price = values[key] ?? 0;
      return { ...accum, [snakeCase(key)]: price === 0 ? null : price * 100 };
    }
    return { ...accum, [snakeCase(key)]: v };
  }, {});
}
