import React, { useState } from "react";

import Avatar from "@atoms/avatar";
import Button from "@atoms/button";
import Text from "@atoms/text";
import { SignOut } from "@atoms/icons";
import cn from "classnames";

interface Props {
  userImage?: string;
  userName: string;
  onSignOutClick: () => void;
  signIn: () => void;
  beforeLogoutComponent?: React.ReactNode;
  afterLogoutComponent?: React.ReactNode;
}

function UserAccountLink({
  userImage,
  userName = "",
  onSignOutClick,
  signIn,
  beforeLogoutComponent = null,
  afterLogoutComponent = null,
}: Props) {
  const [toggle, setToggle] = useState<boolean>(false);
  const triggerRadius = toggle ? "rounded-tl rounded-tr" : "rounded";
  const handleToggle = () => setToggle(!toggle);
  const minMenuActiveWidth = "min-w-[150px]";
  const backgroundColor = toggle ? "bg-layer-blue" : "bg-grey/10";

  const onClickSignInOut = () => {
    setToggle(false);
    if (userName) onSignOutClick();
    else signIn();
  };

  return (
    <div
      className={cn(`flex flex-col z-20 relative`, triggerRadius, backgroundColor, {
        [minMenuActiveWidth]: toggle,
      })}
    >
      <div className="inline-flex items-center text-center cursor-pointer justify-end p-4" onClick={handleToggle}>
        <Avatar imgSrc={userImage} alt={userName} size="xs" />
        {userName && (
          <Text as="span" variant="heading3" className="text-white font-bold px-3 select-none">
            {userName}
          </Text>
        )}
      </div>

      {toggle && (
        <div
          className={cn(
            "flex flex-col absolute px-4 pb-4 top-full w-full right-0 rounded-bl rounded-br",
            backgroundColor
          )}
        >
          {beforeLogoutComponent}
          <Button
            size="small"
            variant="secondary"
            icon={userName ? SignOut : undefined}
            iconPosition="right"
            onClick={onClickSignInOut}
          >
            {userName ? "Sign out" : "Sign in"}
          </Button>
          {afterLogoutComponent}
        </div>
      )}
    </div>
  );
}

export default UserAccountLink;
