import DocumentTitle from "@atoms/document-title";
import Button from "@atoms/button";
import { LogoFull } from "@atoms/icons";
import SuccessCheck from "@assets/success_check.png";
import { useNavigate } from "react-router-dom";

const EmailSent = () => {
  const navigate = useNavigate();
  const onClickResend = () => {
    navigate("/forgot_password", { replace: true });
  };
  return (
    <>
      <div className="border-b border-dark-grey flex items-center w-full h-24 px-4 md:px-10">
        <LogoFull className="h-8" />
      </div>
      <div className="text-white relative p-4 flex flex-col items-center justify-center h-full overflow-hidden">
        <DocumentTitle titlePrefix="Verify your email" />
        <div className=" items-center justify-center w-1/3">
          <div className="items-center justify-center w-full text-center">
            <div className="items-center mb-4">
              <img alt="Success" src={SuccessCheck} className="mx-auto" />
            </div>
            <h1 className="text-3xl mb-3 text-center z-30 font-bold">Email sent</h1>
            <p className="mb-4">We just sent you an email with a password reset link..</p>
            <p className="mb-4">Please click the link to reset your password.</p>
            <div className="w-full flex items-center justify-center">
              <Button type="button" isFullWidth onClick={onClickResend}>
                Resend instructions
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailSent;
