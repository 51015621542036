import { AvatarProps } from "./avatar.types";
import cx from "classnames";
import defaultImage from "./default-avatar.svg";

const Avatar = ({ size, imgSrc, alt = "" }: AvatarProps): JSX.Element => {
  const getAvatarSize = (size: string) => {
    switch (size) {
      case "xs":
        return "w-5 h-5";
      case "sm":
        return "w-10 h-10";
      case "md":
        return "w-16 h-16";
      case "lg":
        return "w-20 h-20";
      case "xl":
        return "w-28 h-28";
      default:
        return "w-10 h-10";
    }
  };
  return (
    <img
      src={imgSrc || defaultImage}
      loading="lazy"
      alt={alt}
      className={cx("inline-block rounded-full object-cover aspect-square", getAvatarSize(size))}
    />
  );
};

export default Avatar;
