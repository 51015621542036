import React, { ForwardedRef, ReactNode } from "react";
import cn from "classnames";
import SVG from "@atoms/svg";
import { ExclaimationTriangle } from "@atoms/icons";
import { BaseInputProps } from ".";

type Props = BaseInputProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    iconLeft?: ReactNode;
  };

const TextInput = (
  {
    isDisabled = false,
    name,
    placeholder,
    statusType,
    type = "text",
    isFullWidth = false,
    value,
    isRequired = false,
    iconRight,
    onIconRightClick,
    className,
    backgroundColor = "slate",
    noPadding,
    iconLeft,
    ...props
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const isSuccess = statusType === "success";
  const isError = statusType === "error";

  const getBackgroundColor = (() => {
    switch (backgroundColor) {
      case "transparent":
        return "bg-none appearance-none";
      case "slate":
      default:
        return "bg-dark-grey";
    }
  })();

  const inputStyle = cn(
    "outline-none text-heading3 rounded-md text-white h-16",
    { "p-4": !noPadding },
    isError || iconRight ? "pr-9" : "pr-4",
    { "ring-2 ring-inset ring-danger bg-danger/20": isError },
    { "ring-2 ring-inset ring-success bg-success/20": isSuccess },
    { "bg-opacity-60": isDisabled },
    { "w-full": isFullWidth },
    { uppercase: type === "date" },
    getBackgroundColor,
    className
  );

  const iconRightStyle = cn("text-white my-auto absolute right-3 -top-[12px]", {
    "cursor-pointer": onIconRightClick,
  });

  return (
    <div className="inline relative">
      {iconLeft}
      <input
        data-testid="TextInput"
        className={inputStyle}
        disabled={isDisabled}
        required={isRequired}
        aria-required={isRequired}
        aria-invalid={isError}
        {...{ ...props, ref, value, type, placeholder, name }}
      />
      {isError && (
        <SVG icon={ExclaimationTriangle} size="medium" styles="text-danger absolute my-auto top-0 bottom-0 right-3" />
      )}
      {iconRight && !isError && (
        <div onClick={onIconRightClick}>
          <SVG icon={iconRight} size="medium" styles={iconRightStyle} />
        </div>
      )}
    </div>
  );
};

export default React.forwardRef(TextInput);
