import * as yup from "yup";
import { startOfDay } from "date-fns";
import { TicketRoyalty } from "@api/tickets";
import { Optional } from "types/generic";
import { checkImageDimensions } from "@utils/file";
import { minOrZero } from "@utils/min-or-zero";

declare module "yup" {
  interface ArraySchema<T> {
    unique(message: string, mapper?: (value: T, index?: number, list?: T[]) => T[]): ArraySchema<T>;
  }
}

yup.addMethod(yup.array, "unique", function (message, mapper = (val: any) => val) {
  return this.test("unique", message, (list = []) => list.length === new Set(list.map(mapper)).size);
});

export const timeFormat = "HH:mm";

const imgDimensionErrorMessage = (width: number, height: number) => {
  return `Image must have max dimensions of ${width}px x ${height}px`;
};

export const basicsValidationSchema = yup.object().shape({
  eventName: yup.string().max(27, "Maximum 27 characters allowed").required("Required"),
  startDate: yup.date().min(startOfDay(new Date()), "Date and time cannot be in the past").required("Required"),
  endDate: yup
    .date()
    .min(yup.ref("startDate"), "End date and time cannot be before the start date and time")
    .required("Required"),
  entryDate: yup
    .date()
    .min(startOfDay(new Date()), "Date and time cannot be in the past")
    .when("endDate", {
      is: (endDate: Date | undefined | null) => !!endDate,
      then: (schema) => schema.max(yup.ref("endDate"), "Entry date and time cannot be after the end date"),
    })
    .required("Required"),
  venueName: yup.string().max(24, "Maximum 24 characters allowed").required("Required"),
});

export const promotionsValidationSchema = yup.object().shape({
  description: yup.string().max(2000, "Maximum 2000 characters allowed").required("Required").nullable(),
  thumbnail: yup.mixed(),
  coverPhoto: yup.mixed(),
  newThumbnail: yup
    .mixed()
    .test("newThumbnailRequired", "Required", fileRequiredIfNotAlreadySaved("thumbnail"))
    .test("newThumbnailnewImageDimensions", imgDimensionErrorMessage(1500, 1500), (file) => {
      if (!file) return true;
      return checkImageDimensions(file, { width: 1500, height: 1500 }, false);
    }),
  newCoverPhoto: yup
    .mixed()
    .test("newCoverPhotoisAFile", "Required", fileRequiredIfNotAlreadySaved("coverPhoto"))
    .test("newCoverPhotonewImageDimensions", imgDimensionErrorMessage(2500, 1500), function (file) {
      if (!file) return true;
      return checkImageDimensions(file, { width: 2500, height: 1500 }, false);
    }),
});

export const ticketTypesValidationSchema = yup.object().shape({
  ticketTypes: yup
    .array(
      yup.object().shape(
        {
          name: yup.string().max(38, "Maximum 38 characters allowed").required("Required"),
          description: yup.string().max(2000, "Maximum 2000 characters allowed").required("Required"),
          price: yup
            .number()
            .test("minOrZero", "Minimum 1 or free", (val) => minOrZero(val, 1))
            .max(1000000, "Maximum 1,000,000")
            .required("Required"),
          maxPerWallet: yup
            .number()
            .min(1, "At least 1 per wallet required")
            .max(yup.ref("quantityAvailable"), "Cannot exceed quantity available")
            .test("cannotNotExceedOneHundredThousand", "Cannot exceed 100,000", (val) => {
              if (!val) {
                return true;
              }
              return val < 100001;
            })
            .required("Required"),
          allowTransfers: yup.boolean(),
          /**maxResalePrice: yup
            .number()
            .test("minOrZero", "Minimum 1 or free", (val) => minOrZero(val, 1))
            .max(1000000, "Cannot exceed 1,000,000")
            .required("Required"),**/
          quantityAvailable: yup.number().min(1, "Minimum 1").max(100000, "Maximum 100,000").required("Required"),
          baseImageUrl: yup.string().when("newImage", {
            is: (newImage: any) => !newImage,
            then: yup.string().required("Required"),
          }),
          newImage: yup
            .mixed()
            .test("ticketTypeNewImageDimensions", imgDimensionErrorMessage(1500, 2500), (file) => {
              if (!file) return true;
              return checkImageDimensions(file, { width: 2000, height: 2000 }, false);
            })
            .when("baseImageUrl", {
              is: (baseImageUrl: any) => !baseImageUrl,
              then: yup
                .mixed()
                .test("ticketTypeNewImageDimensions", imgDimensionErrorMessage(2000, 2000), (file) => {
                  if (!file) return true;
                  return checkImageDimensions(file, { width: 2000, height: 2000 }, false);
                })
                .required("Required"),
            }),
        },
        [["newImage", "baseImageUrl"]]
      )
    )
    .min(1),
});

export const royaltiesValidationSchema = yup.object().shape({
  ticketTypes: yup
    .array(
      yup.object().shape({
        royalties: yup
          .array(
            yup.object().shape({
              payoutAddress: yup
                .string()
                .test("isNearAddressFormat", "Must end in .near", isNearAddressFormat)
                .required("Required"),
              royaltyPercentage: yup
                .number()
                .positive()
                .min(0.01, "Minimum 0.01%")
                .max(100, "Maximum 100%")
                .test("isTwoDecimalPlaces", "Must be 2 d.p.", isTwoDecimalPlaces)
                .required("Required"),
              validAddress: yup.boolean().oneOf([true], "Wallet address must be valid"),
            })
          )
          .unique("You cannot use the same address more than once", (royalty: any) => royalty.payoutAddress)
          .test("is100PercentRoyaltyOrLess", "Royalty split must not be greater than 100%", is100PercentRoyaltyOrLess),
      })
    )
    .min(1),
});

function fileRequiredIfNotAlreadySaved(keyName: string) {
  return function (file: File, context: yup.TestContext) {
    if (!!context.parent[keyName]) return true;
    if (!file) return false;
    return file instanceof File;
  };
}

function isNearAddressFormat(address: string | undefined): boolean {
  if (!address) return true;
  return /^(([a-z\d]+[-_])*[a-z\d]+\.)*([a-z\d]+[-_])*[a-z\d]+\.(testnet|near)$/.test(address);
}

function isTwoDecimalPlaces(val: number | undefined): boolean {
  return typeof val === "undefined" ? true : /^\d+(\.\d{0,2})?$/.test(val.toString());
}

function is100PercentRoyaltyOrLess(items: Optional<TicketRoyalty>[] | undefined) {
  if (!items) return true;
  const totalPercentage = items.reduce(
    (acc, { royaltyPercentage }) => (royaltyPercentage ? acc + royaltyPercentage : acc),
    0
  );
  return !(totalPercentage > 100);
}
