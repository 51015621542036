import useAuth from "@hooks/use-auth";
import DocumentTitle from "@atoms/document-title";
import { LogoFull } from "@atoms/icons";
import { SellerForgotPassword } from "@api/auth";
import { forgotPasswordValidation as validationSchema } from "@pages/settings/form-helpers";
import { Formik } from "formik";
import ForgotPasswordForm from "@organisms/forgot-password-form/forgot-password-form";
import BadgeAlert from "@atoms/badge-alert";
function ForgotPassword() {
  const { forgotPassword, formError, setFormError } = useAuth();
  const emptyInitialValues: SellerForgotPassword = {
    email: "",
  };

  return (
    <>
      <div className="border-b border-dark-grey flex items-center w-full h-24 px-4 md:px-10">
        <LogoFull className="h-8" />
      </div>
      <div className="w-3/4 mt-4 mx-auto">
        {!!formError && (
          <BadgeAlert
            variant="error"
            className="top-0 left-0 w-full"
            message={formError}
            onClose={() => {
              setFormError("");
            }}
          />
        )}
      </div>
      <div className="text-white relative p-4 flex flex-col items-center justify-center h-full overflow-hidden">
        <DocumentTitle titlePrefix="Forgot Password" />
        <div className="max-w-md">
          <h1 className="text-3xl mb-3 text-left z-30 font-bold">Forgot password</h1>
          <h4>
            Don’t worry! It happens. Please enter the email address you usually use to log in with and we’ll send an
            email with instructions on how to reset your password.
          </h4>
          <div className="text-left pt-4">
            <Formik
              {...{ initialValues: emptyInitialValues, onSubmit: forgotPassword, validationSchema }}
              validateOnMount
              enableReinitialize
            >
              <ForgotPasswordForm />
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
