export const getPercentageString = (percentage: number) => {
  if (percentage > 9999990) return "+∞%";
  if (percentage > 0) return `+${percentage}%`;
  if (percentage < 0) return `-${percentage}%`;
  if (percentage < -9999990) return `-∞%`;
  return "+0%";
};

export const getPercentageTextColour = (percentage: number) => {
  if (percentage > 0) return "text-lime";
  if (percentage < 0) return "text-danger";
  return "text-white";
};
