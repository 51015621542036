import * as React from "react";

function Inbox(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M25.667 15.667l-2.114-7.4a2.667 2.667 0 00-2.565-1.934h-9.976c-1.191 0-2.238.79-2.565 1.934l-2.114 7.4M13.619 16.492c-.344-.448-.826-.825-1.391-.825H6.333V23A2.667 2.667 0 009 25.667h14A2.667 2.667 0 0025.667 23v-7.333h-5.895c-.565 0-1.047.377-1.39.825A2.995 2.995 0 0116 17.667c-.97 0-1.833-.46-2.381-1.175z"
        stroke="currentcolor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Inbox;
