import { Formik, FormikHelpers } from "formik";
import CreateEventBasics, { initialValues as basicInitialValues } from "@organisms/event-basics";
import CreateEventBreadcrumbNavigation, { CreateEventStageEnum } from "@organisms/create-event-breadcrumb-navigation";
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import EventPromotions, { initialValues as promotionInitialValues } from "@organisms/event-promotions";
import EventTickets, { initialValues as ticketsInitialValues } from "@organisms/event-tickets";
import { ReactNode, useEffect, useMemo, useState } from "react";
import useEvents from "@hooks/use-events";
import {
  basicsValidationSchema,
  promotionsValidationSchema,
  ticketTypesValidationSchema,
  royaltiesValidationSchema,
} from "./mutate-event-validation-schema";
import Modal from "@atoms/modal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAuth from "@hooks/use-auth";
import BadgeAlert from "@atoms/badge-alert";
import { mapFormFieldsToApiParams, mapOcassionToFormFields, MutateEventFormValues } from "./form-helpers";
import StripeBadgeAlert from "@molecules/stripe-badge-alert";
import SpinnerLoader from "@atoms/spinner-loader";
import DocumentTitle from "@atoms/document-title";
import MutateEventContext from "@context/mutate-event";
import { LayoutTitle } from "@organisms/layout";
import { isEqual } from "lodash";

const CREATE_EVENT_STAGE_URL = {
  [CreateEventStageEnum.basics]: "basics",
  [CreateEventStageEnum.customise]: "customise",
  [CreateEventStageEnum.promotions]: "promotions",
  [CreateEventStageEnum.tickets]: "tickets",
};

const defaultValues: MutateEventFormValues = {
  isLive: false,
  slug: "",
  ...basicInitialValues,
  ...promotionInitialValues,
  ...ticketsInitialValues,
};

const fullValidationSchema = basicsValidationSchema
  .concat(promotionsValidationSchema)
  .concat(ticketTypesValidationSchema);

function MutateEvent() {
  const [publishLoading, setPublishLoading] = useState(false);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const { areMandatoryFieldsComplete, getMyProfile, isCompleteProfile, getStripeConnectUrl } = useAuth();
  const { data: profile, isSuccess: hasFetchedProfile, isLoading } = useQuery("myProfile", getMyProfile);
  const stripeLinked = profile?.stripeTransfersEnabled;
  const { pathname } = useLocation();
  const { eventId: urlEventSlug } = useParams<{ eventId: string }>();
  const navigate = useNavigate();
  const { fetchEventBySlug, publishEvent, verifyWalletAddress, getPreviewImage, deleteTicketType, ...eventsAPI } =
    useEvents();
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [submissionError, setSubmissionError] = useState("");
  const activeStage = Object.values(CreateEventStageEnum).find((stage) => pathname.indexOf(`/${stage}`) >= 0);
  const profileComplete = profile && isCompleteProfile(profile);
  const mandatoryFieldsAreComplete = profile && areMandatoryFieldsComplete(profile);
  const { eventName } = initialValues;
  const queryClient = useQueryClient();
  const eventQueryKey = ["event", urlEventSlug];

  useEffect(() => {
    window.onbeforeunload = function (e) {
      e.preventDefault();
      return "There may be unsaved data.";
    };
    return () => {
      window.onbeforeunload = () => {};
    };
  }, []);

  const returnToEvents = () => navigate("/events");

  const createEvent = useMutation(
    (params: Parameters<typeof eventsAPI.createEvent>[0]) => {
      return eventsAPI.createEvent(params);
    },
    {
      onError: (e: any) => setSubmissionError(e.message),
    }
  );

  const updateEvent = useMutation(
    ({ slug, params }: { slug: string; params: Parameters<typeof eventsAPI.updateEvent>[1] }) => {
      return eventsAPI.updateEvent(slug, params);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(eventQueryKey),
      onError: (e: any) => setSubmissionError(e.message),
    }
  );

  const publish = useMutation(
    function publish() {
      if (hasFetchedProfile && !profileComplete) navigate("/settings");
      if (!urlEventSlug || urlEventSlug === "new") throw new Error("event UUID not found in URL");
      return publishEvent(urlEventSlug);
    },
    {
      onSuccess: returnToEvents,
      onError: (error: any) => {
        setSubmissionError(error.message);
      },
    }
  );

  useQuery(
    eventQueryKey,
    () => {
      if (!urlEventSlug || urlEventSlug === "new") return Promise.resolve(null);
      return fetchEventBySlug(urlEventSlug);
    },
    {
      onSuccess: (data) => {
        if (data) setInitialValues((current) => ({ ...current, ...mapOcassionToFormFields(data) }));
      },
    }
  );

  const activeSchema = useMemo(() => {
    switch (activeStage) {
      case CreateEventStageEnum.basics:
        return basicsValidationSchema;
      case CreateEventStageEnum.promotions:
        return promotionsValidationSchema;
      case CreateEventStageEnum.tickets:
        return ticketTypesValidationSchema;
      /**case CreateEventStageEnum.royalties:
        return royaltiesValidationSchema;**/
      default:
        return undefined;
    }
  }, [activeStage]);

  function getRequiredValueKeys() {
    if (activeStage === CreateEventStageEnum.basics) return Object.keys(basicInitialValues);
    if (activeStage === CreateEventStageEnum.promotions) return Object.keys(promotionInitialValues);
    if (activeStage === CreateEventStageEnum.tickets) return Object.keys(ticketsInitialValues);
    //if (activeStage === CreateEventStageEnum.royalties) return Object.keys(ticketsInitialValues);
    return [];
  }

  const save = async (values: Partial<MutateEventFormValues>) => {
    const keysToSave = getRequiredValueKeys();
    const vals = initialValues.isLive
      ? { ...values, isLive: true }
      : keysToSave.reduce(
          (accum, key) => ({ ...accum, [key]: values[key as keyof MutateEventFormValues] }),
          {} as Partial<MutateEventFormValues>
        );
    const params = await mapFormFieldsToApiParams(vals);
    const isNew = !urlEventSlug || urlEventSlug === "new";

    try {
      if (isNew) {
        const { name, venueName, ...rest } = params;
        if (!name) throw Error("Name is required to create a new event");
        if (!venueName) throw Error("Venue name is required to create a new event");
        return await createEvent.mutateAsync({ ...rest, name, venueName });
      } else {
        return await updateEvent.mutateAsync({ slug: urlEventSlug, params });
      }
    } catch (e: any) {
      throw new Error(e);
    }
  };

  const onStepBack = () => {
    const slug = urlEventSlug || "new";
    const getUrl = (urlSuffix: string) => `/events/${slug}/${urlSuffix}`;
    if (activeStage === CREATE_EVENT_STAGE_URL.basics) return;
    if (activeStage === CREATE_EVENT_STAGE_URL.promotions) navigate(getUrl(CREATE_EVENT_STAGE_URL.basics));
    if (activeStage === CREATE_EVENT_STAGE_URL.tickets) navigate(getUrl(CREATE_EVENT_STAGE_URL.promotions));
    //if (activeStage === CREATE_EVENT_STAGE_URL.royalties) navigate(getUrl(CREATE_EVENT_STAGE_URL.tickets));
  };

  const handleNextStep = (values: MutateEventFormValues, actions?: FormikHelpers<MutateEventFormValues>) => {
    return (initialValues.isLive ? Promise.resolve() : save(values)).then((savedEvent) => {
      if (savedEvent) setInitialValues({ ...defaultValues, ...mapOcassionToFormFields(savedEvent) });
      else setInitialValues({ ...defaultValues, ...values });
      if (actions) actions.resetForm();
      const evnt = savedEvent || initialValues;
      const getUrl = (urlSuffix: string) => `/events/${evnt.slug}/${urlSuffix}`;
      if (activeStage === CREATE_EVENT_STAGE_URL.basics) navigate(getUrl(CREATE_EVENT_STAGE_URL.promotions));
      if (activeStage === CREATE_EVENT_STAGE_URL.promotions) navigate(getUrl(CREATE_EVENT_STAGE_URL.tickets));
      //if (activeStage === CREATE_EVENT_STAGE_URL.tickets) navigate(getUrl(CREATE_EVENT_STAGE_URL.royalties));
    });
  };

  const withDocumentTitle = (node: ReactNode, title: string) => (
    <>
      <DocumentTitle
        titlePrefix={eventName ? `${title} | ${eventName} | Create an Event` : `${title} | Create an Event`}
        description="Our intuitive interface lets you manage existing events, create new ones, and list different ticket types in a few simple steps."
      />
      {node}
    </>
  );

  function getDirtyValues(values: MutateEventFormValues) {
    return Object.entries(values)
      .filter(([k, v]) => {
        const key = k as keyof MutateEventFormValues;
        return !isEqual(initialValues[key], v);
      })
      .reduce((accum, [k, v]) => ({ ...accum, [k]: v }), {} as Partial<MutateEventFormValues>);
  }

  if (isLoading || !profile)
    return (
      <>
        <LayoutTitle title="Create an event" />
        <SpinnerLoader />
      </>
    );

  const routeList = [
    { path: CREATE_EVENT_STAGE_URL.basics, element: withDocumentTitle(<CreateEventBasics />, "Basics") },
    { path: CREATE_EVENT_STAGE_URL.promotions, element: withDocumentTitle(<EventPromotions />, "Promotions") },
    { path: CREATE_EVENT_STAGE_URL.tickets, element: withDocumentTitle(<EventTickets seller={profile} />, "Tickets") },
    //{ path: CREATE_EVENT_STAGE_URL.royalties, element: withDocumentTitle(<EventRoyalties />, "Royalties") },
  ];

  return (
    <Formik
      {...{ initialValues }}
      onSubmit={handleNextStep}
      enableReinitialize
      validationSchema={activeSchema}
      validateOnMount
      isInitialValid={false}
    >
      {({ handleSubmit, isValid, values, isSubmitting, validateForm, dirty, setTouched, setErrors, setSubmitting }) => {
        const hasUnsavedChanges = initialValues.isLive && dirty;
        const connectStripeAccount = () => {
          if (!hasFetchedProfile) {
            return false;
          }
          if (profile && profile.countryOfBusiness) {
            getStripeConnectUrl().then((url) => window.open(url, "_newtab"));
          } else {
            redirectToSettings();
          }
        };
        const redirectToSettings = () => {
          if (isValid) {
            save(values).then(() => navigate("/settings"));
          } else {
            if (window.confirm("Are you sure you want to leave the page? Your progress will be lost.")) {
              navigate("/settings");
            }
          }
        };

        const onManualSave = async () => {
          // touch all fields
          setSaveButtonClicked(true);
          setTouched(Object.keys(values).reduce((a, k) => ({ ...a, [k]: true }), {}));
          setSubmitting(true);
          validateForm()
            .then((errors) => {
              if (!!Object.keys(errors).length) throw errors;
              else return save(getDirtyValues(values));
            })
            .then(() => {
              setSubmitting(false);
              returnToEvents();
            })
            .catch((errors) => {
              setSubmitting(false);
              setErrors(errors);
            });
        };

        const onPublish = async () => {
          setSaveButtonClicked(true);
          setTouched(Object.keys(values).reduce((a, k) => ({ ...a, [k]: true }), {}));
          setSubmitting(true);
          validateForm()
            .then((errors) => {
              if (!!Object.keys(errors).length) {
                handleSubmit();
              } else {
                setPublishLoading(true);
                return save(getDirtyValues(values)).then(() => publish.mutate());
              }
            })
            .finally(() => setPublishLoading(false));
        };

        return (
          <>
            <LayoutTitle title="Create an event" />
            {hasFetchedProfile && mandatoryFieldsAreComplete && !stripeLinked && (
              <StripeBadgeAlert>
                <span>
                  Before publishing you must{" "}
                  <span className="underline cursor-pointer" onClick={connectStripeAccount}>
                    connect your Stripe account
                  </span>
                </span>
              </StripeBadgeAlert>
            )}
            {hasFetchedProfile && !mandatoryFieldsAreComplete && (
              <BadgeAlert
                variant="warn"
                className="w-full"
                message={
                  <>
                    Before publishing you must{" "}
                    <span className="underline cursor-pointer" onClick={redirectToSettings}>
                      complete your seller profile
                    </span>
                  </>
                }
              />
            )}
            <div className="mb-10 relative">
              <CreateEventBreadcrumbNavigation
                {...{ activeStage, onPublish, onManualSave, publishLoading }}
                onNext={handleSubmit}
                onBack={onStepBack}
                isEventPublished={initialValues.isLive}
                nextLoading={isSubmitting}
              />
              {hasUnsavedChanges && (
                <p className="text-right text-white/50 text-sm absolute top-full right-0">Changes haven’t been saved</p>
              )}
            </div>
            <MutateEventContext.Provider
              value={{ verifyWalletAddress, getPreviewImage, deleteTicketType, saveButtonClicked }}
            >
              <div>
                <Routes>
                  <Route index element={<Navigate to={CREATE_EVENT_STAGE_URL.basics} replace={true} />} />
                  {routeList.map(({ path, element }) => (
                    <Route key={path} {...{ path, element }} />
                  ))}
                </Routes>
              </div>
            </MutateEventContext.Provider>
            <Modal
              variant="secondary"
              isVisible={!!submissionError}
              size="small"
              onClose={() => setSubmissionError("")}
              title="Could not complete the request"
            >
              <p>{submissionError}</p>
            </Modal>
          </>
        );
      }}
    </Formik>
  );
}

export default MutateEvent;
