import { CollectibleOrder } from "@api/collectibles";
import CustomerCollectibleTokenCard, { gridColClasses } from "@molecules/customer-collectible-token-card";
import PaginationButtons from "../../molecules/pagination-buttons";
import SpinnerLoader from "@atoms/spinner-loader";
import cn from "classnames";
import { TablePaginationFooterProps } from "@molecules/table-pagination-footer";

export type Props = TablePaginationFooterProps & {
  collectibleOrders: CollectibleOrder[];
};

export enum ColumnKey {
  image = "image",
  collectibleName = "collectibleName",
  purchasedAt = "purchasedAt",
  transactionHash = "transactionHash",
  price = "price",
  status = "status",
}

const columns = [
  { key: ColumnKey.image, label: "" },
  { key: ColumnKey.collectibleName, label: "Collectible Name" },
  { key: ColumnKey.purchasedAt, label: "Purchase Date" },
  { key: ColumnKey.transactionHash, label: "Transaction Hash" },
  { key: ColumnKey.price, label: "Total Spent" },
];

function NoOrders() {
  return (
    <div className="w-full flex flex-col items-center pt-14 pb-10">
      <p className="text-4xl font-bold mb-6">No collectable orders yet 😔</p>
    </div>
  );
}

const CustomerCollectibleTokenList = ({ collectibleOrders, totalPages, currentPageIndex, onChangePage }: Props) => {
  return (
    <div>
      {!collectibleOrders && <SpinnerLoader />}
      {!!collectibleOrders.length ? (
        <>
          <div className={cn(gridColClasses, "text-sm text-grey mb-5")}>
            {columns.map((columnHeader) => {
              return (
                <div key={columnHeader.key} className="flex items-center">
                  {columnHeader.label ? <span>{columnHeader.label}</span> : null}
                </div>
              );
            })}
          </div>
          {collectibleOrders.map((collectibleOrder: CollectibleOrder, index: number) => (
            <div className="mt-3">
              <CustomerCollectibleTokenCard
                collectible={collectibleOrder.collectible}
                status={collectibleOrder.status}
                purchasedAt={collectibleOrder.purchasedAt}
                transactionHash={collectibleOrder.transactionHash}
                key={index}
              />
            </div>
          ))}
          {totalPages > 1 && (
            <div className="flex flex-row justify-center my-8">
              <PaginationButtons activePage={currentPageIndex} setPage={onChangePage} pages={totalPages} />
            </div>
          )}
        </>
      ) : (
        <NoOrders />
      )}
    </div>
  );
};

export default CustomerCollectibleTokenList;
