import { useState, useRef, useEffect } from "react";
import { format, isBefore } from "date-fns";
import { SingleCalendar } from "@atoms/calendar";
import TextField from "@atoms/text-field";
import { useMemo } from "react";
import cx from "classnames";

type Props = {
  onChange: (dates: [Date | null, Date | null]) => void;
  from: Date | null;
  to: Date | null;
  noMinDate?: boolean;
  maxDate?: Date;
  small?: boolean;
};

const DateRange = ({ onChange, from, to, noMinDate, maxDate, small }: Props) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [calendarMode, setCalendarMode] = useState<"from" | "to">();

  const inputOutlineClassnames = "ring ring-2 ring-blue rounded";
  const getDateValue = (date?: Date | null) => (date ? format(date, "dd/MM/yyyy") : "");

  const selectedCalendarDates = useMemo((): [Date, Date] | [Date] | undefined => {
    if (!!from && !!to) return [from, to];
    else if (!!from) return [from];
    else if (!!to) return [to];
    return undefined;
  }, [from, to]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => document.removeEventListener("click", handleClickOutside, true);

    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) setCalendarMode(undefined);
    }
  }, []);

  const onClickDay = (date: Date) => {
    if (!calendarMode) return;
    if (calendarMode === "from") {
      onChange([date, null]);
      setCalendarMode("to");
    }
    if (calendarMode === "to") {
      if (from && isBefore(date, from)) onChange([date, null]);
      else onChange([from, date]);
    }
  };

  const getMinCalendarDate = () => {
    if (noMinDate) return undefined;
    else return !!from ? from : new Date();
  };

  return (
    <div ref={ref} className="relative">
      <div className="flex flex-row items-center group">
        <div
          className={cx("flex-1", { [inputOutlineClassnames]: calendarMode === "from" })}
          onClick={() => setCalendarMode("from")}
        >
          {small ? (
            <div className="text-white text-xl font-bold flex flex-row">
              {getDateValue(from)} <span className="ml-3">-</span>
            </div>
          ) : (
            <TextField label="From" value={getDateValue(from)} readOnly isFullWidth />
          )}
        </div>
        <div
          className={cx("flex-1 ml-3", { [inputOutlineClassnames]: calendarMode === "to" })}
          onClick={() => setCalendarMode("to")}
        >
          {small ? (
            <div className="text-white text-xl font-bold">{getDateValue(to)}</div>
          ) : (
            <TextField label="To" value={getDateValue(to)} readOnly isFullWidth />
          )}
        </div>
      </div>
      <div className={`absolute ${!!calendarMode ? "block" : "hidden"} top-full left-0 z-40 mt-4`}>
        <SingleCalendar
          selectedDates={selectedCalendarDates}
          initialDate={new Date()}
          onClickDay={onClickDay}
          minDate={getMinCalendarDate()}
          maxDate={maxDate}
        />
      </div>
    </div>
  );
};

export default DateRange;
