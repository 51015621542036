import * as React from "react";
import { SVGProps } from "react";

const Highlighter = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m4.75 19.75 4.25-1 9.293-9.293a1 1 0 0 0 0-1.414l-1.836-1.836a1 1 0 0 0-1.414 0L5.75 15.5l-1 4.25ZM19.25 19.75h-5.5"
      stroke="currentcolor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Highlighter;
