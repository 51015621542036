import * as React from "react";
import { SVGProps } from "react";

const Eye = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.75 12.5c0 1-1.75 6.25-7.25 6.25S5.25 13.5 5.25 12.5 7 6.25 12.5 6.25s7.25 5.25 7.25 6.25Z"
      stroke="currentcolor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.75 12.5a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
      stroke="currentcolor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Eye;
