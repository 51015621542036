import useAuth from "@hooks/use-auth";
import CustomersApi from "@api/customers";
import { useMemo } from "react";

function useCustomers() {
  const { token } = useAuth();

  const customersApi = useMemo(() => new CustomersApi(token || ""), [token]);

  return {
    fetchMyCustomers: customersApi.getAllCustomers,
    fetchCustomer: customersApi.getCustomer,
    fetchCustomerTickets: customersApi.getCustomerTickets,
    fetchCustomerCollectibleTokens: customersApi.getCustomerCollectibleTokens,
    fetchCustomerLotteryEntries: customersApi.getCustomerLotteryEntries,
  };
}

export default useCustomers;
