import LayoutContext from "@context/layout-context";
import Layout from "@organisms/layout";
import AuthSigninSuccess from "@pages/auth-signin-success";
import Settings from "./settings";
import { ReactElement, useContext, useEffect, useMemo, useState } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import MutateEvent from "./events/mutate-event";
import MyEventsListing from "./events/my-events";
import MyPublishedEventsListing from "./airdrops/my-published-events";
import MyCustomersListing from "./customers/my-customers";
import StripeOnboardingRefresh from "./stripe-onboarding-refresh";
import Scanners from "./scanners/scanners";
import CreateScanner from "./scanners/create-scanner";
import Orders from "./orders";
import Dashboard from "./dashboard";
import MutateCollectible from "./collectibles/mutate-collectible";
import Collectibles from "./collectibles/collectibles";
import ProtectedRoute from "@organisms/protected-route";
import ComingSoon from "../components/pages/coming-soon";
import { useQueryClient } from "react-query";
import useAuth from "@hooks/use-auth";
import { UserException } from "@api/lib";
import Login from "@pages/login";
import EmailLogin from "@pages/email-login";
import DefaultRoutePredicate from "@organisms/default-route-predicate";
import ViewCustomer from "./customers/view-customer";
import MyCollectiblesListing from "./airdrops/my-collectibles";
import VerifyEmail from "@pages/verify-email";
import Register from "@pages/register";
import ForgotPassword from "@pages/forgot-password";
import EmailSent from "@pages/email-sent";
import ResetPassword from "@pages/reset-password";
import PasswordReset from "@pages/password-reset";
import EmailConfirm from "@pages/email-confirm";

const GlobalLayout = () => {
  const navigate = useNavigate();
  const { title } = useContext(LayoutContext);

  return (
    <Layout title={title} onNewEventClick={() => navigate("/events/new")}>
      <Outlet />
    </Layout>
  );
};

function RootRoutes() {
  const [pageTitle, setPageTitle] = useState("");
  const queryClient = useQueryClient();
  const { handleUnauthorization } = useAuth();
  const loginPath = "/email_login";

  const AuthGuard = useMemo(
    () =>
      ({ children }: { children: ReactElement }) => {
        return <ProtectedRoute redirectUrl={loginPath} children={children} />;
      },
    []
  );

  useEffect(() => {
    const defaultQueryClientOptions = queryClient.getDefaultOptions();
    const handleQueryErrors = (error: any) => {
      if (error instanceof UserException) {
        if (error?.status === 503) window.alert("503 - Service Unavailable");
        if (error?.status === 401) handleUnauthorization();
        else window.alert(error.message);
      } else throw error;
    };
    queryClient.setDefaultOptions({
      ...defaultQueryClientOptions,
      queries: {
        ...defaultQueryClientOptions.queries,
        onError: handleQueryErrors,
      },
      mutations: {
        ...defaultQueryClientOptions.mutations,
        onError: handleQueryErrors,
      },
    });
  }, [handleUnauthorization, queryClient]);

  const airdrops = process.env.REACT_APP_AIRDROPS ? (
    <AuthGuard children={<MyPublishedEventsListing />} />
  ) : (
    <ComingSoon title="Airdrops" />
  );

  return (
    <LayoutContext.Provider value={{ title: pageTitle, setTitle: setPageTitle }}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/email_login" element={<EmailLogin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/email_sent" element={<EmailSent />} />
        <Route path="/email_verify" element={<VerifyEmail />} />
        <Route path="/confirm" element={<EmailConfirm />} />
        <Route path="/password_reset" element={<PasswordReset />} />
        <Route path="/" element={<GlobalLayout />}>
          <Route
            index
            element={
              <AuthGuard>
                <DefaultRoutePredicate errorRoute={loginPath} inCompleteRoute="/settings" completeRoute="/dashboard" />
              </AuthGuard>
            }
          />
          <Route path="signin-success" element={<AuthSigninSuccess />} />
          <Route path="strp-rfrsh-rsme" element={<AuthGuard children={<StripeOnboardingRefresh />} />} />
          <Route path="dashboard" element={<AuthGuard children={<Dashboard />} />} />
          <Route path="orders" element={<AuthGuard children={<Orders />} />} />
          <Route path="settings" element={<AuthGuard children={<Settings />} />} />
          <Route path="events">
            <Route path=":eventId/*" element={<AuthGuard children={<MutateEvent />} />} />
            <Route index element={<AuthGuard children={<MyEventsListing />} />} />
          </Route>
          <Route path="collectables">
            <Route path=":collectibleId/*" element={<AuthGuard children={<MutateCollectible />} />} />
            <Route index element={<AuthGuard children={<Collectibles />} />} />
          </Route>
          <Route path="scanners">
            <Route path="new" element={<AuthGuard children={<CreateScanner />} />} />
            <Route index element={<AuthGuard children={<Scanners />} />} />
          </Route>
          <Route path="airdrops">
            <Route path="collectible" element={<AuthGuard children={<MyCollectiblesListing />} />} />
            <Route index element={airdrops} />
          </Route>
          <Route path="customers">
            <Route path=":id/*" element={<AuthGuard children={<ViewCustomer />} />} />
            <Route index element={<AuthGuard children={<MyCustomersListing />} />} />
          </Route>
        </Route>
      </Routes>
    </LayoutContext.Provider>
  );
}

export default RootRoutes;
