import React from "react";
import { IProgressRingProps } from "./progress-ring.types";

import Text, { TextVariant } from "@atoms/text";

const ProgressRing = ({ percentage = 0, size = "md", subtext }: IProgressRingProps): JSX.Element => {
  const strokeWidth = size === "lg" ? 12 : 6;
  const fontSize = size === "lg" ? TextVariant.heading1 : TextVariant.heading4;
  const progress = percentage < 0 ? 0 : percentage > 100 ? 100 : percentage;
  const ringSize = (): {
    class: string;
    size: number;
  } => {
    switch (size) {
      case "sm":
        return {
          class: "h-12",
          size: 48,
        };
      case "lg":
        return {
          class: "h-40",
          size: 180,
        };
      case "md":
      default:
        return {
          class: "h-20",
          size: 80,
        };
    }
  };

  const ringColor = () => {
    switch (true) {
      case progress < 25:
        return "stroke-danger";
      case progress < 50:
        return "stroke-warning";
      case progress < 75:
        return "stroke-success";
      default:
        return "stroke-bluer";
    }
  };

  // Size of the enclosing square
  const ring = ringSize();
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (ring.size - strokeWidth) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${ring.size} ${ring.size}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * progress) / 100;
  //
  const strokeColor = ringColor();

  return (
    <div className="relative inline-block" style={{ width: ring.size }}>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
        <p className="text-white text-3xl font-bold">{`${progress}%`}</p>
        <p>{subtext}</p>
      </div>
      <svg width={ring.size} height={ring.size} viewBox={viewBox}>
        <circle
          className="stroke-dark-grey fill-transparent"
          cx={ring.size / 2}
          cy={ring.size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          className={`${strokeColor} fill-transparent progressStroke`}
          cx={ring.size / 2}
          cy={ring.size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${ring.size / 2} ${ring.size / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
      </svg>
    </div>
  );
};

export default ProgressRing;
