import { View } from "@atoms/icons";
import Svg from "@atoms/svg";
import useMediaQueries from "@hooks/use-media-queries";
import DropUploadField from "@molecules/drop-upload-field";
import { useState } from "react";

type Props = {
  initialCoverPhoto?: string;
  initialThumbnail?: string;
  coverPhotoError?: string;
  thumbnailError?: string;
  onCoverPhotoChange: (image: { file: File; base64: string }) => void;
  onThumbnailChange: (image: { file: File; base64: string }) => void;
};

function StoreLogoCover({
  initialCoverPhoto,
  initialThumbnail,
  onCoverPhotoChange,
  onThumbnailChange,
  coverPhotoError,
  thumbnailError,
}: Props) {
  const [showPlaceholders, setShowPlaceholders] = useState(true);
  const { isMobile } = useMediaQueries();
  return (
    <div className="bg-dark-grey rounded-md relative">
      <div
        className="sm:absolute inline-block bg-background sm:bg-dark-grey right-0.5 top-0.5 z-10 p-4 cursor-pointer text-[0px]"
        onClick={() => setShowPlaceholders(!showPlaceholders)}
      >
        <Svg icon={View} size="large" />
      </div>
      <div className="flex flex-col sm:flex-row gap-16 justify-center ">
        <div className="grow relative">
          <div>
            <DropUploadField
              constrainLayout
              id="cover-photo-upload"
              title="Cover Photo"
              maxMB={2}
              recommendedSize={[1400, 400]}
              previewImgUrl={initialCoverPhoto}
              showPlaceholder={showPlaceholders}
              placeholderAlign={isMobile ? "center" : "right"}
              onChange={onCoverPhotoChange}
              errorMessage={coverPhotoError}
            />
          </div>
          {/* {coverPhotoError && <p className="mt-2 text-right text-danger absolute">{coverPhotoError}</p>} */}
        </div>
        <div className="sm:absolute sm:left-20 min-h-[200px] h-full lg:h-[90%] lg:top-[5%] aspect-square z-20 bg-black">
          <DropUploadField
            constrainLayout
            id="thumbnail-photo-upload"
            title="Thumbnail"
            maxMB={2}
            ratio="1:1"
            recommendedSize={[500, 500]}
            previewImgUrl={initialThumbnail}
            showPlaceholder={showPlaceholders}
            placeholderAlign="center"
            onChange={onThumbnailChange}
            errorMessage={thumbnailError}
          />
          {/* {thumbnailError && <p className="mt-2 text-danger max-w-[256px]">{thumbnailError}</p>} */}
        </div>
      </div>
    </div>
  );
}

export default StoreLogoCover;
