import * as React from "react";

function Announcement(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.5 5.833L12.833 8 10.5 10.167M12.667 8h-5.5"
        stroke="currentcolor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.167 3.167H4.5c-.736 0-1.333.597-1.333 1.333v7c0 .736.597 1.333 1.333 1.333h5.667"
        stroke="currentcolor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Announcement;
