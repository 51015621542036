import useEvents from "@hooks/use-events";
import EventsListing from "@pages/events";
import { useQuery } from "react-query";
import { Occassion } from "@api/events";
import { useNavigate } from "react-router-dom";
import SpinnerLoader from "@atoms/spinner-loader";
import DocumentTitle from "@atoms/document-title";
import PaginationButtons from "@molecules/pagination-buttons";
import { useState } from "react";
import { LayoutTitle } from "@organisms/layout";

const perPage = 6;

function MyEventsListing() {
  const [page, setPage] = useState(1);
  const { fetchMyEvents } = useEvents();
  const navigate = useNavigate();
  const myEventsQuery = useQuery(["myEvents", { perPage, page }], () => fetchMyEvents({ page, perPage }));

  const nonMvpArgs = {
    currentPageIndex: 0,
    totalPages: 3,
    resultsPerPage: 5,
    onChangePage: () => {},
    onSort: () => {},
  };

  const onEditEvent = (event: Occassion) => navigate(`/events/${event.slug}`);

  const onViewEvent = (event: Occassion) => {
    const seatlabNFTDomain = process.env.REACT_APP_MARKETPLACE_DOMAIN;
    if (!seatlabNFTDomain) throw new Error("ENV_DOMAIN not set");
    window.open(`${seatlabNFTDomain}/events/${event.slug}`);
  };

  const onAddNewEvent = () => navigate("/events/new");

  return (
    <>
      <DocumentTitle
        titlePrefix="Events - Manage all your events in one place"
        description="Our intuitive interface lets you manage existing events, create new ones, and list different ticket types in a few simple steps."
      />
      <LayoutTitle title="Events" />
      {myEventsQuery.isSuccess ? (
        <>
          <EventsListing
            events={myEventsQuery.data.result ?? []}
            onEdit={onEditEvent}
            onView={onViewEvent}
            {...{ ...nonMvpArgs, onAddNewEvent }}
          />
          {myEventsQuery.data.last_page > 1 && (
            <div className="flex flex-row justify-center my-8">
              <PaginationButtons activePage={page} setPage={setPage} pages={myEventsQuery.data.last_page} />
            </div>
          )}
        </>
      ) : (
        <SpinnerLoader />
      )}
    </>
  );
}

export default MyEventsListing;
