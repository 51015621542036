import useCollectiblesApi from "@hooks/use-collectibles-api";
import AirdropCollectibles from "@pages/airdrops/collectibles";
import CreateAirdropBreadcrumbNavigation, {
  CreateAirdropStageEnum,
} from "@organisms/create-airdrop-breadcrumb-navigation/create-airdrop-breadcrumb-navigation";
import AirdropSelectCollectible from "@organisms/create-airdrop-select-collectible";
import { useMutation, useQuery } from "react-query";
import SpinnerLoader from "@atoms/spinner-loader";
import DocumentTitle from "@atoms/document-title";
import PaginationButtons from "@molecules/pagination-buttons";
import React, { useState, useReducer } from "react";
import { LayoutTitle } from "@organisms/layout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AirdropCollectible, Collectible } from "@api/collectibles";
import { isObject, isString } from "lodash";
import AirdropEligibleWallets from "@molecules/airdrop-eligible-wallets/airdrop-eligible-wallets";
import useTicketsApi from "@hooks/use-tickets-api";
import SuccessModal from "@atoms/success-modal";
import ErrorModal from "@atoms/error-modal";

const perPage = 6;
function GetEligibleWallets(ticketTypeIds: string[]) {
  const ticketsApi = useTicketsApi();
  const eligibleWalletsQuery = useQuery(["eligibleWallets", { ticketTypeIds }], () =>
    ticketsApi.getEligibleWallets(ticketTypeIds)
  );
  return eligibleWalletsQuery.isSuccess ? eligibleWalletsQuery.data : 0;
}

function MyCollectiblesListing() {
  const [page, setPage] = useState(1);
  const [success, setSuccess] = useState(false);
  const [submissionError, setSubmissionError] = useState("");
  const collectiblesApi = useCollectiblesApi();
  const collectiblesQuery = useQuery(["sellerPublishedCollectibles", { page, perPage }], () =>
    collectiblesApi.fetchAllPublishedCollectibles({ page, perPage })
  );

  const activeStage = CreateAirdropStageEnum.collectibles;
  const isLoading = collectiblesQuery.isLoading;
  const [selectedCollectible, setCollectible] = useState<Collectible>();
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const ticketTypeIdString = searchParams.get("ticket_types");
  const ticketTypeIds = isString(ticketTypeIdString) ? ticketTypeIdString.split(",") : [];
  let eligibleWallets = 0;
  if (ticketTypeIds.length > 0) {
    eligibleWallets = GetEligibleWallets(ticketTypeIds);
  }

  const onClickRow = (collectible: Collectible | undefined) => {
    setCollectible(collectible);
  };

  const onClickBack = () => {
    navigate("/airdrops", { replace: true });
  };

  const onClickCreateNew = () => {
    navigate("/collectables/create", { replace: true });
  };

  const onClickIncreaseWallet = () =>
    isObject(selectedCollectible) ? onAirdrop(selectedCollectible.uuid, ticketTypeIds, true) : {};

  const onClick = () => {
    navigate("/airdrops", { replace: true });
  };

  const airdrop = useMutation(function airdrop({
    collectibleUuid,
    ticketTypeUuids,
    updateMaxPerWallet,
  }: AirdropCollectible) {
    setSubmissionError("");
    return collectiblesApi
      .airdropCollectible({ collectibleUuid, ticketTypeUuids, updateMaxPerWallet })
      .then(() => {
        setSuccess(true);
      })
      .catch((e) => {
        setSubmissionError(e.message || "Something went wrong, please try again later.");
      });
  });

  const onAirdrop = (collectibleUuid: string, ticketTypeUuids: string[], updateMaxPerWallet: boolean) => {
    return airdrop.mutate({ collectibleUuid, ticketTypeUuids, updateMaxPerWallet });
  };

  const nonMvpArgs = {
    currentPageIndex: 0,
    totalPages: 3,
    resultsPerPage: 5,
    onChangePage: () => {},
    onSort: () => {},
    onClickRow: onClickRow,
    selectedCollectible: selectedCollectible,
  };

  return (
    <>
      <DocumentTitle
        titlePrefix="Airdrops - Heighten audience engagement"
        description="Engage with your audience in a brand new way by airdropping NFT assets directly to ticket holders' wallets before, during or after events."
      />
      <LayoutTitle title="Airdrops" />
      {collectiblesQuery.isSuccess ? (
        <>
          <div>
            <CreateAirdropBreadcrumbNavigation
              {...{ activeStage }}
              onNext={() =>
                isObject(selectedCollectible) ? onAirdrop(selectedCollectible.uuid, ticketTypeIds, false) : {}
              }
              onBack={onClickBack}
              nextLoading={isLoading}
              nextDisabled={!isObject(selectedCollectible)}
            />
          </div>
          <div className={"mt-4"}>
            <AirdropEligibleWallets eligibleWallets={eligibleWallets} />
          </div>
          <div className="mb-8 flex flex-col md:flex-row w-full gap-4 mt-0 ml-2">
            <div className="md:w-3/5">
              <AirdropCollectibles
                collectibles={collectiblesQuery.data.result ?? []}
                {...{ ...nonMvpArgs }}
                eligibleWallets={eligibleWallets}
              />
              {collectiblesQuery.data.last_page > 1 && (
                <div className="flex flex-row justify-center my-4">
                  <PaginationButtons activePage={page} setPage={setPage} pages={collectiblesQuery.data.last_page} />
                </div>
              )}
            </div>
            <div className="md:w-2/5">
              <AirdropSelectCollectible collectible={selectedCollectible} onClickCreateNew={onClickCreateNew} />
            </div>
          </div>
          <SuccessModal
            variant="secondary"
            size="small"
            title="Success"
            subtitle="Your NFT’s have been successfully airdropped to all eligible wallets!"
            isVisible={success}
            onClick={onClick}
            onClickText="Continue"
          ></SuccessModal>
          <ErrorModal
            variant="secondary"
            size="small"
            title="Oops!"
            subtitle={submissionError}
            isVisible={submissionError.length > 0}
            onClickCancel={() => {
              setSubmissionError("");
            }}
            onClickButtonTwo={onClickCreateNew}
            onClickButtonTwoText="Create new collectable"
            onClickButtonThree={eligibleWallets > 0 ? onClickIncreaseWallet : () => {}}
            onClickButtonThreeText={eligibleWallets > 0 ? "Increase max per wallet and send" : ""}
          ></ErrorModal>
        </>
      ) : (
        <SpinnerLoader />
      )}
    </>
  );
}

export default MyCollectiblesListing;
