import React, { useMemo } from "react";
import classNames from "classnames";

import { ChevronRight } from "@atoms/icons";
import SVG from "@atoms/svg";
import Text from "@atoms/text";
import Button from "@atoms/button";
import { BreadcrumbNavigationProps, ListNav } from "./breadcrumb-navigation.types";
import useMediaQueries from "@hooks/use-media-queries";

function BreadcrumbNavigation({
  listNav = [],
  active = 1,
  setActive,
  onNext,
  onBack,
  nextText,
  nextButtonVariant,
  nextDisabled,
  nextLoading,
  backLoading,
  disableLinks,
}: BreadcrumbNavigationProps) {
  const activeLink = classNames("text-white underline underline-offset-4");
  const unactiveLink = classNames("text-gray-500");

  const handleBack = () => {
    if (setActive && active > 1) {
      setActive(active - 1);
    }
    if (onBack) onBack();
  };

  const handleNext = () => {
    if (setActive && active < listNav.length) {
      setActive(active + 1);
    }
    if (onNext) onNext();
  };

  const { isMobile, isTabletAndUp } = useMediaQueries();

  const _renderList = useMemo(() => {
    return listNav.map(({ label, link }: ListNav, i: number) => {
      const isActive = i === active;
      return (
        <React.Fragment key={label}>
          {i !== 0 && <SVG icon={ChevronRight} size="xsmall" styles="text-white" />}
          <Text
            as={isActive || disableLinks ? "span" : "a"}
            {...(!disableLinks ? { href: link } : {})}
            variant={isMobile ? "heading3" : "heading2"}
            className={isActive ? activeLink : unactiveLink}
          >
            {i + 1}. {label}
          </Text>
        </React.Fragment>
      );
    });
  }, [active, listNav, unactiveLink, activeLink, isMobile, disableLinks]);

  return (
    <div>
      <div className="flex flex-row py-2 gap-4">
        <Button onClick={handleBack} variant="secondary" size="medium" loading={backLoading} isDisabled={!onBack}>
          Back
        </Button>
        {isTabletAndUp && <div className="flex flex-row flex-1 items-center gap-4">{_renderList.map((x) => x)}</div>}
        <div className="flex flex-row ml-auto">
          <Button
            onClick={handleNext}
            variant={nextButtonVariant || "primary"}
            size="medium"
            isDisabled={nextDisabled}
            loading={nextLoading}
          >
            {nextText ?? "Next Step"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default BreadcrumbNavigation;
