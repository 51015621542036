import React, { useMemo } from "react";
import { SearchBarProps, AutocompleteListProps, AutocompleteListItemProps } from "./search-bar.types";
import classNames from "classnames";
import SVG from "@atoms/svg";
import { MagnifyingGlass } from "@atoms/icons";

const SearchBar = ({
  id,
  isDisabled = false,
  name,
  placeholder,
  isFullWidth = true,
  isAutocomplete = false,
  autocompleteData = [],
  autocompleteSearchKey = "",
  autocompleteSearchLabel = "",
  value,
  iconPosition = "left",
  onChange,
}: SearchBarProps) => {
  const searchStyle = classNames(
    "flex-1 outline-none h-14 text-heading3 rounded bg-dark-grey text-white pl-4 placeholder:text-gray-400",
    { "bg-opacity-60": isDisabled },
    { "w-full": isFullWidth },
    { "pl-12 pr-5": iconPosition === "left" },
    { "pr-14": iconPosition === "right" }
  );

  return (
    <div className="flex relative w-full">
      {iconPosition === "left" && (
        <SVG icon={MagnifyingGlass} size="small" styles="text-white absolute my-auto inset-y-0 left-5" />
      )}
      <input
        data-testid="SearchBar"
        className={searchStyle}
        disabled={isDisabled}
        {...{ name, placeholder, id, value, onChange }}
      />
      {iconPosition === "right" && (
        <SVG icon={MagnifyingGlass} size="small" styles="text-white absolute my-auto inset-y-0 right-5" />
      )}
      {isAutocomplete && value && value.length > 0 && (
        <AutocompleteList
          list={autocompleteData}
          keyword={value}
          filterKey={autocompleteSearchKey}
          filterLabel={autocompleteSearchLabel}
        />
      )}
    </div>
  );
};

const AutocompleteList = ({ keyword, filterKey, filterLabel, list = [] }: AutocompleteListProps) => {
  const filteredList = useMemo(
    () =>
      list.filter((item: AutocompleteListItemProps) => {
        if (!keyword) return [];
        return item[filterKey].toString().toLowerCase().includes(keyword.toLowerCase());
      }),
    [keyword]
  );

  return (
    <div className="absolute w-full flex flex-col mt-2 border-b border-t border-black inse">
      {filteredList.map((item, i) => (
        <div
          key={i}
          className="bg-dark-grey text-white rounded px-4 py-2 border-b border-t border-black cursor-pointer hover:bg-grey-translucent-10"
        >
          {item[filterLabel]}
        </div>
      ))}
    </div>
  );
};

export default SearchBar;
