import { Seller } from "@api/auth";
import { checkImageDimensions, convertFileToBase64, imgDimensionErrorMessage } from "@utils/file";
import * as yup from "yup";
import { SellerForm } from "./settings";
import { isNull, isString } from "lodash";

export const mapProfileFormFieldsToApi = async (values: SellerForm) => {
  const { supportPhone, newThumbnail, newCoverPhoto, supportEmail, countryOfBusiness, ...restValue } = values;
  const coverBase64 = newCoverPhoto && (await convertFileToBase64(newCoverPhoto));
  const thumbBase64 = newThumbnail && (await convertFileToBase64(newThumbnail));
  return {
    ...restValue,
    support_phone: supportPhone,
    support_email: supportEmail,
    cover_photo: coverBase64,
    thumbnail: thumbBase64,
  };
};

export const mapProfileApiFieldsToForm = (data: Seller): SellerForm => {
  const formFields = Object.entries(data).reduce(
    (accum, [key, val]) => (val === undefined || val === null ? accum : { ...accum, [key]: val }),
    {} as Seller
  );
  return {
    ...formFields,
  };
};

function validUrlTest(): [string, string, (val: any) => boolean] {
  const testName = "startsWithHttp";
  const errorMessage = "Not a valid url";
  const test = (val: any) => {
    return !val ? true : new RegExp(/^(http|https):\/\/[^ "]+$/).test(val);
  };
  return [testName, errorMessage, test];
}

export const loginFormValidation = yup.object().shape({
  email: yup.string().email().required("Required"),
  password: yup.string().max(200).required("Required"),
  rememberMe: yup.boolean(),
});

export const registerValidation = yup.object().shape({
  email: yup.string().email().required("Required"),
  password: yup.string().max(200).required("Required"),
});

export const forgotPasswordValidation = yup.object().shape({
  email: yup.string().email().required("Required"),
});

export const resetPasswordValidation = yup.object().shape({
  token: yup.string().max(200).required("Required"),
  email: yup.string().email().required("Required"),
  password: yup.string().max(200).required("Required"),
});

export const profileFormValidation = yup.object().shape(
  {
    name: yup.string().max(24, "Maximum 24 characters allowed").required("Required").nullable(),
    facebook: yup
      .string()
      .max(255)
      .test(...validUrlTest()),
    instagram: yup
      .string()
      .max(255)
      .test(...validUrlTest()),
    telegram: yup
      .string()
      .max(255)
      .test(...validUrlTest()),
    twitter: yup
      .string()
      .max(255)
      .test(...validUrlTest()),
    website: yup
      .string()
      .max(255)
      .test(...validUrlTest()),
    discord: yup
      .string()
      .max(255)
      .test(...validUrlTest()),
    supportEmail: yup.string().email().required("Required"),
    thumbnailUrl: yup.string().nullable(),
    newCoverPhoto: yup
      .mixed()
      .test("coverPhotoNewImageDimensions", imgDimensionErrorMessage(2500, 1500), (file) => {
        if (!file) return true;
        return checkImageDimensions(file, { width: 2500, height: 1500 }, false);
      })
      .nullable(),
    newThumbnail: yup
      .mixed()
      .test("thumbnailNewImageDimensions", imgDimensionErrorMessage(1500, 1500), (file) => {
        if (!file) return true;
        return checkImageDimensions(file, { width: 1500, height: 1500 }, false);
      })
      .when("thumbnailUrl", {
        is: (thumbnailUrl: any) => !thumbnailUrl,
        then: yup
          .mixed()
          .test("thumbnailNewImageDimensions", imgDimensionErrorMessage(1500, 1500), (file) => {
            if (!file) return true;
            return checkImageDimensions(file, { width: 1500, height: 1500 }, false);
          })
          .required("Required"),
      }),
    bio: yup.string().max(2000).required(),
  },
  [["thumbnailUrl", "newThumbnail"]]
);
