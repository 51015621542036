import * as React from "react";

function SettingSliders(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.333 10.667h3.334M17 10.667h8.667M6.333 21.333h10M23.667 21.333h2M16.333 10.667a3 3 0 11-6 0 3 3 0 016 0zM23 21.333a3 3 0 11-6 0 3 3 0 016 0z"
        stroke="currentcolor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SettingSliders;
