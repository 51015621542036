import Pill, { PillColor } from "@atoms/pill";
import { capitalise } from "@utils/format";

type OrderStatusPillProps = {
  className?: string;
  status: string;
};

const OrderStatusPill = ({ className, status }: OrderStatusPillProps) => {
  const pillProps = (() => {
    switch (status) {
      case "resale":
        return { label: "Resale", color: PillColor.info };
      case "refunded":
        return { label: "Refund", color: PillColor.warning };
      case "cancelled":
        return { label: "Cancelled", color: PillColor.danger };
      case "sales":
        return { label: "Sale", color: PillColor.success };
      default:
        return { label: capitalise(status), color: PillColor.info };
    }
  })();

  return <Pill className={className} {...pillProps} />;
};

export default OrderStatusPill;
