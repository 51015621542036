import DocumentTitle from "@atoms/document-title";
import Modal from "@atoms/modal";
import useAuth from "@hooks/use-auth";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

function StripeOnboardingRefresh() {
  const { getStripeConnectUrl } = useAuth();
  const [error, setError] = useState("");
  const stripeConnectRefresh = useQuery("stripeConnectRefresh", getStripeConnectUrl, {
    onSuccess: (url) => window.open(url, "_blank"),
    onError: (e: Error) => {
      setError(e.message);
    },
  });

  return (
    <div className="text-center p-16 flex justify-center">
      <DocumentTitle titlePrefix="Connect account to Stripe" />
      {stripeConnectRefresh.isFetching && (
        <svg
          className="animate-spin -ml-1 mr-3 h-8 w-8 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {stripeConnectRefresh.isSuccess && "Redirecting you to stripe onboarding"}
      <Modal variant="secondary" isVisible={!!error} size="small" onClose={() => setError("")}>
        <p>{error}</p>
      </Modal>
    </div>
  );
}

export default StripeOnboardingRefresh;
