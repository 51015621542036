import SearchBar from "@atoms/search-bar";
import Button from "@atoms/button";
import { Props } from "./event-search-top-section.types";
import useMediaQueries from "@hooks/use-media-queries";

function EventSearchTopSection({ searchValue, onChange, onCreateEvent }: Props) {
  const { isDesktopAndUp } = useMediaQueries();
  return isDesktopAndUp ? (
    <div className="relative flex gap-x-3 mb-14 justify-between">
      <div className="flex grow max-w-sm">
        <SearchBar
          id="top-nav-search"
          placeholder="Filter by event name"
          isFullWidth
          value={searchValue}
          onChange={onChange}
        />
      </div>
      <Button className="ml-14" onClick={onCreateEvent}>
        Create Event
      </Button>
    </div>
  ) : (
    <div>
      <div className="flex mb-2 justify-between">
        <div className="flex grow max-w-sm">
          <SearchBar id="top-nav-search" placeholder="Search" isFullWidth value={searchValue} onChange={onChange} />
        </div>
        <Button className="ml-2 h-full" size="medium" onClick={onCreateEvent}>
          Create Event
        </Button>
      </div>
    </div>
  );
}

export default EventSearchTopSection;
