import React from "react";
import { ScrollableAreaProps } from "./scrollable-area.types";
import cn from "classnames";

function ScrollableArea({ children, height, className }: ScrollableAreaProps) {
  return (
    <div
      className={cn(
        "overflow-y-auto pr-2 scrollbar-thin scrollbar-thumb-grey scrollbar-track-slate/50 scrollbar-thumb-rounded",
        className
      )}
      style={{ height }}
    >
      {children}
    </div>
  );
}

export default ScrollableArea;
