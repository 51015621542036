function ScannerUserCardTableHeader() {
  return (
    <div className="flex px-6">
      <div className="md:flex-1 justify-center flex">
        <div className="w-full">Username</div>
      </div>
      <div className="flex text-sm md:space-x-4 self-end">
        <div className="inline-block w-12 text-center" />
        <div className="inline-block w-12 text-center" />
      </div>
    </div>
  );
}

export default ScannerUserCardTableHeader;
