import Button from "@atoms/button";
import Text from "@atoms/text";
import Box from "@atoms/box";
import TicketTypeListSmall from "@organisms/ticket-type-list-small";
import useEvents from "@hooks/use-events";
import { useQuery } from "react-query";
import { TicketType } from "@api/tickets";

type Props = {
  eventId: string;
  onBack: () => void;
  selectedTicketTypes: TicketType[];
  onSelect: (ticketType: TicketType) => void;
};

function GetTicketTypes(eventId: string) {
  const { fetchEventBySlug } = useEvents();
  const myPublishedEventQuery = useQuery(["myPublishedEvents", { eventId }], () => fetchEventBySlug(eventId));
  return myPublishedEventQuery.isSuccess ? myPublishedEventQuery.data.ticketTypes : [];
}

function AirdropSelectTicketTypes({ eventId, onBack, selectedTicketTypes, onSelect }: Props) {
  let ticketTypes;
  if (eventId.length > 0) {
    ticketTypes = GetTicketTypes(eventId);
  }
  return (
    <Box variant="grey">
      {eventId.length > 0 ? (
        <div>
          <div className="flex justify-between mb-4">
            <Button onClick={onBack} variant="secondary" size="medium">
              Back
            </Button>
          </div>
          <div>
            <Text variant="heading2">Ticket Types</Text>
          </div>
          <div className={"mb-8"}>
            <Text variant="heading3" className="text-grey-translucent-40">
              Choose whether to Airdrop collectables to certain ticket types only or everyone attending the event.
            </Text>
          </div>
          {/**
            <div className={'mb-8'}>
              <span className={'float-right'}>
                <Toggle
                  id={'all_ticket_types'}
                  name={'all_ticket_types'}
                />
              </span>
              <Text variant="heading3" className="text-white">
                Send Airdrop to all ticket types
              </Text>
            </div>
            <div className={'mb-2'}>
              <Text variant="heading3" className="text-white">
                Select ticket types to Airdrop to:
              </Text>
            </div>**/}

          <TicketTypeListSmall
            ticketTypes={ticketTypes}
            selectedTicketTypes={selectedTicketTypes}
            onSelect={onSelect}
          ></TicketTypeListSmall>
        </div>
      ) : (
        <div className="flex flex-col p-16 justify-center items-center cursor-pointer">
          <Text variant="heading2">Nothing selected yet</Text>
          <Text variant="heading3" className="text-grey-translucent-40 text-center w-full mt-4 px-6">
            Select from your events, collectables or enter specific wallet addresses to choose potential users for your
            Airdrop.
          </Text>
        </div>
      )}
    </Box>
  );
}

export default AirdropSelectTicketTypes;
