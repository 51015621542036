import cn from "classnames";
import { TablePaginationFooterProps as PaginationFooterProps } from "@molecules/table-pagination-footer";
import useMediaQueries from "@hooks/use-media-queries";
import PublishedEventCard, { gridColClasses } from "@molecules/published-event-card";
import { Occassion } from "@api/events";
import { useNavigate } from "react-router-dom";
import Button from "@atoms/button";

export enum ColumnKey {
  ticketImage = "ticketImage",
  eventName = "eventName",
  eventVenue = "eventVenue",
  eventDate = "eventDate",
}

export type Props = PaginationFooterProps & {
  onSort: (columnKey: ColumnKey) => void;
  events: Occassion[];
  noEvents?: boolean;
  onClickRow: (eventId: string) => void;
  selectedEvent: string;
};

const columns = [
  { key: ColumnKey.ticketImage },
  { key: ColumnKey.eventName, label: "Event Name" },
  { key: ColumnKey.eventVenue, label: "Event Venue" },
  { key: ColumnKey.eventDate, label: "Event Date" },
];

function PublishedEventsList(props: Props) {
  const { isDesktopAndUp } = useMediaQueries();
  const navigate = useNavigate();
  const { events, onSort, onClickRow, selectedEvent } = props;
  const noEvents = !events || events.length === 0;
  const onAddNewEvent = () => navigate("/events/new");
  return (
    <div className="mb-8">
      {isDesktopAndUp && !noEvents && (
        <div className={cn(gridColClasses, "text-sm text-grey mb-5")}>
          {columns.map((columnHeader) => {
            const onClick = columnHeader.label ? () => onSort(columnHeader.key) : undefined;
            return (
              <div key={columnHeader.key} className="flex items-center" onClick={onClick}>
                {columnHeader.label ? <span className="mr-2">{columnHeader.label}</span> : null}
              </div>
            );
          })}
        </div>
      )}

      {events.map((item) => {
        const selected = selectedEvent === item.slug;
        return (
          <div className="mt-3" key={item.id}>
            <PublishedEventCard
              eventId={item.slug}
              thumbnail={item.thumbnailUrl}
              name={item.name}
              venue={item.venueName}
              date={item.startDate}
              onClickRow={onClickRow}
              selected={selected}
            />
          </div>
        );
      })}
      {noEvents && (
        <div className="w-full h-full flex flex-col items-center pt-20">
          <p className="text-4xl font-bold mb-4">No Published Events Listed</p>
          <Button className="ml-2 h-full mt-4" size="medium" onClick={onAddNewEvent}>
            Create Event
          </Button>
        </div>
      )}
    </div>
  );
}

export default PublishedEventsList;
