import useCustomers from "@hooks/use-customers";
import CustomerView from "@pages/customers/customer-view";
import { useQuery } from "react-query";
import SpinnerLoader from "@atoms/spinner-loader";
import DocumentTitle from "@atoms/document-title";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Props as TicketsListingProps } from "@organisms/customer-ticket-list/customer-ticket-list";
import { Props as CollectibleTokensListingProps } from "@organisms/customer-collectible-token-list/customer-collectible-token-list";
import { Props as LotteryEntriesListingProps } from "@organisms/customer-lottery-entries-list/customer-lottery-entries-list";
import { LayoutTitle } from "@organisms/layout";

const perPage = 5;

function ViewCustomer() {
  const params = useParams<{ id: string }>();
  const { id } = params;

  const { fetchCustomer } = useCustomers();
  const customerQuery = useQuery(["customer", { id }], () => fetchCustomer(id ? parseInt(id) : 0));

  const [ticketsPage, setTicketsPage] = useState(1);
  const { fetchCustomerTickets } = useCustomers();
  const customerTicketsQuery = useQuery(["customerTickets", { id, perPage, ticketsPage }], () =>
    fetchCustomerTickets(id ? parseInt(id) : 0, { page: ticketsPage, perPage })
  );
  const ticketListingProps: TicketsListingProps = {
    currentPageIndex: ticketsPage,
    totalPages: customerTicketsQuery.isSuccess ? customerTicketsQuery.data.total / perPage : 0,
    resultsPerPage: perPage,
    onChangePage: (pageNumber: number) => setTicketsPage(pageNumber),
    tickets: customerTicketsQuery.isSuccess ? customerTicketsQuery.data.result : [],
  };

  const [CollectibleTokensPage, setCollectibleTokensPage] = useState(1);
  const { fetchCustomerCollectibleTokens } = useCustomers();
  const customerCollectibleTokensQuery = useQuery(
    ["customerCollectibleTokens", { id, perPage, CollectibleTokensPage }],
    () => fetchCustomerCollectibleTokens(id ? parseInt(id) : 0, { page: CollectibleTokensPage, perPage })
  );
  const collectibleTokenListingProps: CollectibleTokensListingProps = {
    currentPageIndex: CollectibleTokensPage,
    totalPages: customerCollectibleTokensQuery.isSuccess ? customerCollectibleTokensQuery.data.total / perPage : 0,
    resultsPerPage: perPage,
    onChangePage: (pageNumber: number) => setCollectibleTokensPage(pageNumber),
    collectibleOrders: customerCollectibleTokensQuery.isSuccess ? customerCollectibleTokensQuery.data.result : [],
  };

  const [LotteryEntriesPage, setLotteryEntriesPage] = useState(1);
  const { fetchCustomerLotteryEntries } = useCustomers();
  const customerLotteryEntriesQuery = useQuery(["customerLotteryEntries", { id, perPage, LotteryEntriesPage }], () =>
    fetchCustomerLotteryEntries(id ? parseInt(id) : 0, { page: LotteryEntriesPage, perPage })
  );
  const lotteryEntriesListingProps: LotteryEntriesListingProps = {
    currentPageIndex: LotteryEntriesPage,
    totalPages: customerLotteryEntriesQuery.isSuccess ? customerLotteryEntriesQuery.data.total / perPage : 0,
    resultsPerPage: perPage,
    onChangePage: (pageNumber: number) => setLotteryEntriesPage(pageNumber),
    lotteryEntries: customerLotteryEntriesQuery.isSuccess ? customerLotteryEntriesQuery.data.result : [],
  };

  return (
    <>
      <DocumentTitle
        titlePrefix="Customers - Get in-depth customer insights"
        description="Get to know your audience, engage with them, and provide them with exceptional experiences to increase your sales."
      />
      <LayoutTitle title="Customers" />
      {customerQuery.isSuccess ? (
        <>
          <CustomerView
            customer={customerQuery.data}
            ticketsListing={ticketListingProps}
            collectibleTokensListing={collectibleTokenListingProps}
            lotteryEntriesListing={lotteryEntriesListingProps}
          />
        </>
      ) : (
        <SpinnerLoader />
      )}
    </>
  );
}

export default ViewCustomer;
