import React, { ReactNode } from "react";
import cx from "classnames";

export type Variant = "translucent" | "slate" | "blue" | "border" | "border-lg" | "dark-grey" | "grey" | "dark-blue";

type Props = {
  padding?: "none" | "xSmall" | "small" | "medium" | "large";
  variant: Variant;
  children: ReactNode;
  className?: string;
  as?: React.ElementType | string;
};

function Box({ padding, variant, className, children, as: Component = "div" }: Props) {
  const yPadding = (() => {
    switch (padding) {
      case "none":
        return "py-0";
      case "xSmall":
        return "py-1";
      case "small":
        return "py-3";
      case "large":
        return "py-7";
      case "medium":
      default:
        return "py-4";
    }
  })();

  const xPadding = (() => {
    switch (padding) {
      case "none":
        return "px-0";
      case "small":
        return "px-3";
      default:
        return "px-6";
    }
  })();

  const bgClass = (() => {
    switch (variant) {
      case "dark-blue":
        return "bg-dark-blue";
      case "blue":
        return "bg-blue";
      case "slate":
        return "bg-layer-blue";
      case "dark-grey":
        return "bg-dark-grey";
      case "grey":
        return "bg-background-gray";
      case "border":
        return "bg-none";
      case "translucent":
      default:
        return "bg-grey-translucent-10";
    }
  })();

  const blendMode = (() => {
    switch (variant) {
      case "blue":
      case "dark-blue":
        return "bg-blend-hard-light";
      case "slate":
        return "mix-blend-normal";
      case "dark-grey":
        return "mix-blend-normal";
      case "translucent":
      default:
        return "bg-blend-hard-light";
    }
  })();

  const border = (() => {
    switch (variant) {
      case "border":
        return "border border-dark-grey";
      case "border-lg":
        return "border-4 border-dark-grey";
      default:
        return "bg-none";
    }
  })();

  return (
    <Component className={cx("rounded-lg", blendMode, bgClass, xPadding, yPadding, border, className)}>
      {children}
    </Component>
  );
}

export default Box;
