import { useState, ReactNode } from "react";
import Header from "@organisms/header";
import SideNavigation from "@organisms/side-navigation";
import cn from "classnames";
import useMediaQueries from "@hooks/use-media-queries";
import Svg from "@atoms/svg";
import { Close } from "@atoms/icons";
import useAuth from "@hooks/use-auth";
import styles from "./layout.module.css";
import classNames from "classnames";

export type Props = {
  title: string;
  onNewEventClick: () => void;
  children: ReactNode;
};

const scrollbarClasses = "scrollbar-thin scrollbar-thumb-grey scrollbar-track-slate/50 scrollbar-thumb-rounded";

function Layout({ title, onNewEventClick, children }: Props) {
  const { isTabletOnly, isMobile } = useMediaQueries();
  const { user, emailUser } = useAuth();
  const [showSideNavigation, setShowSideNavigation] = useState(false);
  const toggleSideNav = () => setShowSideNavigation((current) => !current);

  return (
    <div className="relative flex flex-col h-full">
      <Header title={title} setShowSideNavigation={toggleSideNav} />
      <div className={classNames("flex", styles.maincontent)}>
        <div
          className={cn(
            "fixed inset-0 z-40 bg-dark-navy p-8 overflow-auto md:p-0 md:pt-2 md:pl-10",
            "md:relative md:h-full md:mr-4 md:block md:bg-transparent",
            "xl:w-[276px] xl:pr-4",
            {
              "hidden md:w-[88px]": !showSideNavigation,
              [`md:w-[276px] md:pr-4 ${scrollbarClasses}`]: showSideNavigation,
            }
          )}
        >
          {isMobile && (
            <button className="absolute right-4 top-4 z-40" onClick={() => setShowSideNavigation(false)}>
              <Svg icon={Close} size="medium" />
            </button>
          )}
          {(!!user || !!emailUser) && (
            <SideNavigation
              onNewEventClick={onNewEventClick}
              hideAd={isTabletOnly && !showSideNavigation}
              hideNewEvent={isTabletOnly && !showSideNavigation}
            />
          )}
        </div>
        <main className={`flex-1 overflow-auto pt-4 relative px-4 h-full md:pl-0 md:pr-10 ${scrollbarClasses}`}>
          {children}
        </main>
      </div>
    </div>
  );
}

export default Layout;
