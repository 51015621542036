import DocumentTitle from "@atoms/document-title";
import { LayoutTitle } from "@organisms/layout";
import SettingsPage from "../components/pages/settings";

function Settings() {
  return (
    <>
      <DocumentTitle
        titlePrefix="Settings - Manage your profile"
        description="Customise your seller profile, update your preferences, and connect your Stripe account to get paid. "
      />
      <LayoutTitle title="Profile settings" />
      <SettingsPage />
    </>
  );
}

export default Settings;
