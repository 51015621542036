import Text from "@atoms/text";
import SVG from "@atoms/svg";
import { Close } from "@atoms/icons";
import cn from "classnames";
import { ReactNode } from "react";

enum ModalSize {
  small = "small",
  medium = "medium",
  large = "large",
}
enum ModalVariant {
  primary = "primary",
  secondary = "secondary",
}

type Props = {
  size: keyof typeof ModalSize;
  variant: keyof typeof ModalVariant;
  isVisible: boolean;
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  renderHeader?: () => ReactNode;
  disableCloseButton?: boolean;
  onClose?: () => void;
  noMaxHeight?: boolean;
};

function getModalSize(size: keyof typeof ModalSize) {
  switch (size) {
    case ModalSize.large:
      return "max-w-xl";
    case ModalSize.medium:
      return "max-w-md";
    case ModalSize.small:
    default:
      return "max-w-sm";
  }
}

function getModalHeaderBackground(variant: keyof typeof ModalVariant) {
  switch (variant) {
    case ModalVariant.primary:
      return "bg-navy";
    case ModalVariant.secondary:
    default:
      return "bg-background-blue";
  }
}
function getModalBackground(variant: keyof typeof ModalVariant) {
  switch (variant) {
    case ModalVariant.primary:
      return "bg-dark-navy";
    case ModalVariant.secondary:
    default:
      return "bg-background-dark-blue";
  }
}

const Modal = ({
  children,
  size,
  variant,
  title,
  subtitle = "",
  isVisible = false,
  disableCloseButton = false,
  renderHeader,
  onClose,
  noMaxHeight,
}: Props): JSX.Element => {
  return (
    <div
      id="modal"
      className={cn("fixed z-40 inset-0 bg-gray-900 bg-opacity-80 h-full w-full px-4", {
        hidden: !isVisible,
      })}
    >
      <div
        className={cn(`relative top-40 mx-auto shadow-lg rounded-md `, getModalSize(size), getModalBackground(variant))}
      >
        <div
          className={cn(
            "flex justify-between items-center text-white text-xl rounded-t-md p-6",
            getModalHeaderBackground(variant)
          )}
        >
          <div className="flex flex-col">
            {title && (
              <Text variant="heading1" as="h2" className="text-white">
                {title}
              </Text>
            )}
            {subtitle && (
              <Text variant="heading4" as="p" className="text-white">
                {subtitle}
              </Text>
            )}
            {!!renderHeader && renderHeader()}
          </div>
          {!disableCloseButton && (
            <button onClick={onClose}>
              <SVG icon={Close} size="large" />
            </button>
          )}
        </div>
        <div className={cn("p-6", { "max-h-80": !noMaxHeight })}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
