import { GetImagePreviewArgs } from "@api/events";
import { createContext } from "react";
import { UseMutationResult } from "react-query";

const MutateEventContext = createContext<{
  verifyWalletAddress: (address: string) => Promise<boolean>;
  getPreviewImage: (params: GetImagePreviewArgs) => Promise<{ image: string }>;
  deleteTicketType: UseMutationResult<any, unknown, string, any>;
  saveButtonClicked: boolean;
}>({
  verifyWalletAddress: () => Promise.resolve(false),
  getPreviewImage: () => ({} as any),
  deleteTicketType: (() => {}) as any,
  saveButtonClicked: false,
});

export default MutateEventContext;
