import BadgeAlert from "@atoms/badge-alert";
import { Transition } from "@headlessui/react";
import useDidMount from "@hooks/use-did-mount";
import { createContext, ReactNode, useState } from "react";

type ToastItem = {
  variant: "error" | "warn" | "success" | "light" | "dark";
  message: string;
  autoClose: boolean;
  actions?: { onClick: (close: () => void) => void; label: string }[];
};

type ToastContextType = {
  queueToast: (toastItem: ToastItem) => void;
};

export const ToastContext = createContext<ToastContextType>({
  queueToast: () => {},
});

function Toast({ variant, message, autoClose, onClose: handleClose }: ToastItem & { onClose: () => void }) {
  const [show, setShow] = useState(true);
  const onClose = () => setShow(false);

  useDidMount(() => (autoClose ? void setTimeout(onClose, 3000) : undefined));

  return (
    <Transition
      show={show}
      appear
      enter="transition duration-300"
      enterFrom="opacity-0 translate-y-full"
      enterTo="opacity-100 translate-y-0"
      leave="transition duration-300"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-full"
      afterLeave={handleClose}
    >
      <BadgeAlert {...{ variant, message, onClose }} />
    </Transition>
  );
}

export function ToastContextProvider({ children }: { children: ReactNode }) {
  const [toastItems, setToastItems] = useState<ToastItem[]>([]);
  const queueToast = (toastItem: ToastItem) => setToastItems([...(toastItems || []), toastItem]);
  const closeItem = (idx: number) => () => setToastItems(toastItems.filter((_, index) => index !== idx));

  return (
    <ToastContext.Provider value={{ queueToast }}>
      {children}
      {!!toastItems.length && (
        <div className="fixed w-full left-0 bottom-0 pb-12 pt-5 z-50">
          <Transition show appear enter="transition duration-300" enterFrom="opacity-0" enterTo="opacity-100">
            <div className="bg-gradient-to-t from-black/80 absolute inset-0" />
          </Transition>
          {toastItems.map((toastItem, idx) => {
            return (
              <div
                key={idx}
                className="mt-4 mx-6"
                children={<Toast {...toastItem} autoClose onClose={closeItem(idx)} />}
              />
            );
          })}
        </div>
      )}
    </ToastContext.Provider>
  );
}
