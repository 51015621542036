import DocumentTitle from "@atoms/document-title";
import Button from "@atoms/button";
import { useLocation } from "react-router-dom";
import { LogoFull } from "@atoms/icons";
import Warning from "@assets/warning.png";
import useAuth from "@hooks/use-auth";

const VerifyEmail = () => {
  const { resendConfirmEmail } = useAuth();
  const state = useLocation().state as { email: string };
  const email = state.email;

  const onClickResend = () => {
    const initialValues: { email: string } = {
      email: email || "",
    };
    resendConfirmEmail(initialValues);
  };

  return (
    <>
      <div className="border-b border-dark-grey flex items-center w-full h-24 px-4 md:px-10">
        <LogoFull className="h-8" />
      </div>
      <div className="text-white relative p-4 flex flex-col items-center justify-center h-full overflow-hidden">
        <DocumentTitle titlePrefix="Verify your email" />
        <div className=" items-center justify-center w-1/3">
          <div className="items-center justify-center w-full text-center">
            <div className="items-center mb-4">
              <img alt="Warning" src={Warning} className="mx-auto" />
            </div>
            <h1 className="text-3xl mb-3 text-center z-30 font-bold">Verify your email</h1>
            <p className="mb-4">You’re almost there! We sent an email to {email}.</p>
            <p className="mb-4">
              To verify your email, click on the link in the email we have sent to you to complete your signup. If you
              don’t see it, you may need to check your spam folder.
            </p>
            <p className="mb-4">Still can’t find the email?</p>
            <div className="w-full flex items-center justify-center">
              <Button type="button" isFullWidth onClick={onClickResend}>
                Resend Verification Email
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
