import useEvents from "@hooks/use-events";
import PublishedEventsListing from "@pages/airdrops/published-events";
import CreateAirdropBreadcrumbNavigation, {
  CreateAirdropStageEnum,
} from "@organisms/create-airdrop-breadcrumb-navigation/create-airdrop-breadcrumb-navigation";
import { useQuery } from "react-query";
import SpinnerLoader from "@atoms/spinner-loader";
import DocumentTitle from "@atoms/document-title";
import PaginationButtons from "@molecules/pagination-buttons";
import { useState, useReducer } from "react";
import { LayoutTitle } from "@organisms/layout";
import AirdropSelectTicketTypes from "@organisms/create-airdrop-select-ticket-types/create-airdrop-select-ticket-types";
import { TicketType } from "@api/tickets";
import { useNavigate } from "react-router-dom";

const perPage = 6;

function MyPublishedEventsListing() {
  const [page, setPage] = useState(1);
  const { fetchMyPublishedEvents } = useEvents();
  const myPublishedEventsQuery = useQuery(["myPublishedEvents", { perPage, page }], () =>
    fetchMyPublishedEvents({ page, perPage })
  );
  const activeStage = CreateAirdropStageEnum.events;
  const isLoading = myPublishedEventsQuery.isLoading;
  const [selectedEvent, setEvent] = useState<string>("");
  const [selectedTicketTypes, setTicketTypes] = useState<TicketType[]>([]);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();
  const onClickNext = () => {
    let ticketTypeIds = selectedTicketTypes.map(function (ticketType: TicketType) {
      return ticketType.uuid;
    });
    let ticketTypesParams = ticketTypeIds.join(",");
    navigate("/airdrops/collectible?ticket_types=" + ticketTypesParams, { replace: true });
  };

  const onClickRow = (eventId: string) => {
    setEvent(eventId);
    setTicketTypes([]);
  };

  const onBack = () => {
    setEvent("");
    setTicketTypes([]);
  };

  const onSelect = (ticketType: TicketType) => {
    if (selectedTicketTypes?.includes(ticketType)) {
      let index = selectedTicketTypes?.indexOf(ticketType);
      if (index !== -1) {
        selectedTicketTypes?.splice(index, 1);
      }
    } else {
      selectedTicketTypes?.push(ticketType);
    }
    forceUpdate();
  };

  const nonMvpArgs = {
    currentPageIndex: 0,
    totalPages: 3,
    resultsPerPage: 5,
    onChangePage: () => {},
    onSort: () => {},
    onClickRow: onClickRow,
    selectedEvent: selectedEvent,
  };

  return (
    <>
      <DocumentTitle
        titlePrefix="Airdrops"
        description="Engage with your audience in a brand new way by airdropping NFT assets directly to ticket holders' wallets before, during or after events."
      />
      <LayoutTitle title="Airdrops" />
      {myPublishedEventsQuery.isSuccess ? (
        <>
          <div>
            <CreateAirdropBreadcrumbNavigation
              {...{ activeStage }}
              onNext={onClickNext}
              onBack={() => {}}
              nextLoading={isLoading}
              nextDisabled={selectedTicketTypes.length <= 0}
            />
          </div>
          <div className="mb-8 flex flex-col md:flex-row w-full gap-4 mt-4 ml-2">
            <div className="md:w-3/5">
              <PublishedEventsListing events={myPublishedEventsQuery.data.result ?? []} {...{ ...nonMvpArgs }} />
              {myPublishedEventsQuery.data.last_page > 1 && (
                <div className="flex flex-row justify-center my-4">
                  <PaginationButtons
                    activePage={page}
                    setPage={setPage}
                    pages={myPublishedEventsQuery.data.last_page}
                  />
                </div>
              )}
            </div>
            <div className="md:w-2/5">
              <AirdropSelectTicketTypes
                eventId={selectedEvent}
                onBack={onBack}
                selectedTicketTypes={selectedTicketTypes}
                onSelect={onSelect}
              />
            </div>
          </div>
        </>
      ) : (
        <SpinnerLoader />
      )}
    </>
  );
}

export default MyPublishedEventsListing;
