import Box from "@atoms/box";
import CollectiblesCreateForm from "@organisms/collectibles-create-form";
import { ImageUpload } from "@atoms/icons";
import Text from "@atoms/text";
import { useEffect, useRef, useState } from "react";
import { useFormikContext } from "formik";
import { CreateCollectibleValues } from "routes/collectibles/mutate-collectible";

type Props = {
  initialThumbnail?: string;
};

function CollectiblesCreateView({ initialThumbnail }: Props) {
  const { values, errors, touched } = useFormikContext<CreateCollectibleValues>();
  const [url, setUrl] = useState("");
  const mediaFieldRef = useRef<HTMLInputElement>(null);
  const onUpload = () => {
    mediaFieldRef.current?.click();
  };
  const mediaError = !!touched.media && !!errors.media;
  useEffect(() => {
    const url = values.media && !mediaError ? URL.createObjectURL(values.media) : "";
    setUrl(url);
  }, [values.media, mediaError]);

  const previewImage = url || initialThumbnail;

  return (
    <div className="mb-8 flex flex-col md:flex-row w-full gap-4 mt-4">
      <div className="md:w-3/5">
        <CollectiblesCreateForm mediaFieldRef={mediaFieldRef} onUpload={onUpload} />
      </div>
      <div className="md:w-2/5">
        <Box variant="grey">
          {previewImage ? (
            <div className="flex flex-col">
              <Text variant="heading2">Preview</Text>
              <div className="w-full mt-2">
                <img src={previewImage} className="mx-auto rounded h-80 object-contain" alt="NFT preview" />
              </div>
              <Text variant="heading2" className="mt-4">
                {values.title}
              </Text>
              <Text variant="heading2" className="mt-2">
                {values.description}
              </Text>
            </div>
          ) : (
            <div onClick={onUpload} className="flex flex-col p-16 justify-center items-center cursor-pointer">
              <ImageUpload height={183} width={183} />
              <Text variant="heading3" className="text-grey-translucent-40 text-center w-full mt-4 px-6">
                Once you upload media, your preview will appear here
              </Text>
            </div>
          )}
        </Box>
      </div>
    </div>
  );
}

export default CollectiblesCreateView;
