import { ToggleProps } from "./toggle.types";

const Toggle = ({ id, name, isChecked = false, onChange }: ToggleProps): JSX.Element => {
  return (
    <label htmlFor={id} className="flex items-center cursor-pointer">
      <span className="block relative bg-dark-grey rounded-full">
        <input
          id={id}
          type="checkbox"
          className="peer sr-only"
          name={name}
          defaultChecked={isChecked}
          onChange={onChange}
        />

        <span className="block h-10 w-20 rounded-full transition peer-checked:bg-blue" />

        <span className="block bg-white absolute w-9 h-9 top-0.5 left-1 rounded-full transition peer-checked:translate-x-full" />
      </span>
    </label>
  );
};

export default Toggle;
