import { Customer } from "@api/customers";
import Pill, { PillColor } from "@atoms/pill";
import React from "react";

export function statusToText(status: Customer["status"]) {
  switch (status) {
    case "live":
      return "Live";
    case "lottery":
      return "Lottery";
    case "pending":
      return "Pending";
  }
}

export function statusToPillColour(status: Customer["status"]) {
  switch (status) {
    case "live":
      return PillColor.success;
    case "lottery":
      return PillColor.info;
    case "pending":
      return PillColor.warning;
  }
}

function StatusPill({ status }: { status: Customer["status"] }) {
  return <Pill color={statusToPillColour(status)} label={statusToText(status)} />;
}

export default StatusPill;
